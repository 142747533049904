import React from 'react';

// import * as Components from '../Components.js';
// import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

// import the Darwin API classes
import API from '../API.js';
// import logo from '../images/GeojiLogoWhite.svg';
import ImageInfinity from '../images/Infinity.svg';
import ImageClock from '../images/Clock.svg';
import ImageDemoBallroom from '../images/Demos/spiderhouse.png';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export class Demo extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    let newState = {
      loading: true,
      data: data,
      forceCheck: false,
      shakeButton: false,
      error: false,

      view: "Home",
      subview: "Home",

      demoID: props.demoID ? props.demoID : "",
      demoEvents: [],
    }

    this.state = newState

    this.submitForm = this.submitForm.bind(this)
    this.formChanged = this.formChanged.bind(this)
    this.shakeTheButton = this.shakeTheButton.bind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  calculateView() {
    //load the ballroom handle
    if (this.state.demoID === "ballroom") {
      this.loadBallroomData()
    }
  }

  loadBallroomData() {
    this.setState({
      loading: true,
      error: false,
    }, () => {
      API.callDarwinAPIUnsecured("GET", "handle/ballroomatx", {}, (result) => {
        //console.log("result", result)
        if ("error" in result) {
          console.log("Error GET Handle", result)
          this.setState({
            loading: false,
            error: "Invalid link.",
          })
          return
        }

        let events = []
        let businesses = []
        for (let i = 0; i < result.data.events.length; i = i + 1) {
          events.push(Helpers.formatGeoji(result.data.events[i]))
        }
        for (let i = 0; i < result.data.businesses.length; i = i + 1) {
          businesses.push(Helpers.formatGeoji(result.data.businesses[i]))
        }

        this.setState({
          demoEvents: events,
          demoBusinesses: businesses,
          loading: false,
          error: false,
        })
      })
    })
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Purchase":
        requiredFields = ["name"]
        optionals = ["notes"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Purchase":
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    let shouldSubmit = false
    this.setState((prevState) => {
      let d = prevState.data
      let newRet = {
        data: d,
        forceCheck: false
      }
      if (name === "phoneNumber" && prevState.subview === "Phone") {
        if (((d[name] && d[name].value && d[name].value.length === 0) || !d[name] || !d[name].value) && value.length >= 10) {
          //should submit
          shouldSubmit = true
        }
      }
      if (name === "code" && prevState.subview === "Code") {
        if (value.length >= 6) {
          //should submit
          shouldSubmit = true
        }
      }
      if (prevState.view === "Purchase" && name === "name" && value !== this.props.userInfo.user.name) {
        newRet.nameSavedCount = 0
      }
      if (prevState.view === "Purchase" && name === "notes" && value !== (this.state.purchase.notes || "")) {
        newRet.notesSavedCount = 0
      }
      d[name] = {
        value: value,
        valid: valid
      }
      newRet.data = d
      return newRet
    }, () => {
      if (shouldSubmit) {
        this.submitForm()
      }
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  Goes to this Geoji's page.
  */
  viewGeoji(geoji) {
    console.log("View Geoji", geoji)
    this.props.changeView("Geoji", geoji.geojiID)
  }

  render() {

    let demoList = [
      {
        "id": "ballroom",
        "path": "/demo/ballroom"
      },
      {
        "id": "lemonade stand",
        "path": "/g/1817"
      }
    ]

    return (
      <div className="Demo">
        {/* Ballroom Demo */}
        { this.state.demoID === "ballroom" &&
          <div className="DemoBallroom">
            {/* Top Bar */}
            <div className="DemoBallroomTopBar">
              <img className="DemoBallroomTopBarLogo" src={ImageDemoBallroom} alt="Ballroom logo" />
              <div className="DemoBallroomTopBarLinks">
                <div className="DemoBallroomTopBarItem">
                  Home
                </div>
                <div className="DemoBallroomTopBarItem DemoBallroomTopBarItemRed">
                  Events
                </div>
                <div className="DemoBallroomTopBarItem DemoBallroomTopBarItemWhite">
                  About
                </div>
              </div>
            </div>
            {/* Event List */}
            <div className="DemoBallroomEvents">
              { this.state.demoEvents.map((event, i) => (
                <div className="DemoBallroomEvent" key={"event_" + event.id + "_" + i}>
                  {/* Top Dates */}
                  <div className="DemoBallroomEventTop">
                    <div className="DemoBallroomEventTopDate">
                      {Helpers.geojiGetNextEventDateString(event, "day")}
                    </div>
                    <div className="DemoBallroomEventTopTime">
                      {event.when}
                    </div>
                  </div>

                  {/* Main Content */}
                  <div className="DemoBallroomEventContent">
                    {/* Left Side */}
                    <div className="DemoBallroomEventContentLeft">
                      <div className="DemoBallroomEventContentLeftTickets" onClick={this.viewGeoji.bind(this, event)}>
                        Get Tickets
                      </div>
                    </div>

                    {/* Middle Content */}
                    <div className="DemoBallroomEventContentMiddle">
                      <div className="DemoBallroomEventContentMiddleTitle">
                        {event.title}
                      </div>
                      <div className="DemoBallroomEventContentMiddleDescription">
                        {event.description}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Bottom Bar */}
            <div className="DemoBallroomBottomBar">
              <div className="DemoBallroomBottomBarItem">
                2906 Fruth St, Austin, TX 78705
              </div>
            </div>
          </div>
        }
        { this.state.demoID !== "ballroom" &&
          <div className="DemoList">
            <div className="DemoListTitle">
              Geoji Demos
            </div>
            <div className="DemoListDescription">
              Select a demo from the list below.
            </div>
            { demoList.map((demo) => (
              <a key={"demo_" + demo.id} href={demo.path} className="DemoListItem">
                {demo.id}
              </a>
            ))}
          </div>
        }
      </div>
    )
  }
}

/*
Displays a collection view of Geojis.
Pass in:
title - name of the category
geojis - list of geojis to show
action - method to call on click with the provided geoji
*/
export class DemoCategory extends React.Component {

  render() {
    return (
      <div className="HandlePageCategory">
        <div className="HandlePageCategoryTitle">
          {this.props.title}
        </div>
        { this.props.geojis.map((geoji) => (
          <a className="HandlePageGeoji Pushable" href={"/g/" + geoji.geojiID} key={"geoji_" + geoji.geojiID}>
            <div className="HandlePageGeojiBody">
              <div className="HandlePageGeojiBody">
                <div className="HandlePageGeojiEmoji">
                  {geoji.emoji}
                </div>
                <div className="HandlePageGeojiRight">
                  <div className="HandlePageGeojiRightTop">
                    <div className="HandlePageGeojiRightDate">
                      { geoji.infinite &&
                        <img src={ImageInfinity} alt="Infinity"/>
                      }
                      { !geoji.infinite &&
                        <span>{Helpers.geojiGetNextEventDateString(geoji, "short")}</span>
                      }
                    </div>
                    <div className="HandlePageGeojiRightButton">
                      GET
                    </div>
                  </div>
                  <div className="HandlePageGeojiRightTitle">
                    {geoji.title}
                  </div>
                  { geoji.when && geoji.when.length > 0 &&
                    <div className="HandlePageGeojiRightWhen">
                      <img src={ImageClock} alt="Clock" />
                      <div className="HandlePageGeojiRightWhenText">
                        {geoji.when}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    )
  }
}
