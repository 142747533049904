import React from 'react';
import autoBind from 'react-autobind';
import { sortable } from 'react-sortable';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';
// import { DateObject } from "react-multi-date-picker"

// import the Darwin API classes
import API from '../API.js';
import logo from '../images/GeojiLogoGreenBlue.svg';
import ImagePreview from '../images/Preview.svg';
import ImageDeploy from '../images/BlueDeploy.svg';
import ImageSections from '../images/Sections.svg';
import ImageTheme from '../images/Theme.svg';
import ImageGeoji from '../images/GeojiLogoGreenBlue.svg';



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export class PageEditor extends React.Component {

  constructor(props) {
    super(props)

    let data = {
      underground: {
        value: "0",
        valid: true
      },
      forever: {
        value: "0",
        valid: true
      }
    }

    let newState = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      error: false,

      view: "home",
      subview: false,
      page: {
      },

      geojis: [],

      section: "Geoji",
      creatingSection: false,
    }

    this.state = newState

    autoBind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  calculateView() {
    if (this.props.pageID === undefined || this.props.pageID === false) {
        //we are creating a page - we need to select a Geoji first.
        this.setState({
          section: "Geoji"
        }, () => {
          this.loadMyGeojis()
        })
    } else {
        //load this page.
        this.loadMyPage()
    }
  }

  loadMyPage() {
    this.setState({
      loading: true,
      error: false,
    }, () => {
      API.callDarwinAPI("GET", "myPage/" + this.props.pageID, {}, (result) => {
        if ("error" in result) {
          console.log("Error GET my pages", result)
          this.setState({
            loading: false,
            error: "Invalid link.",
          })
          return
        }
        console.log("page", result.data.page)

        this.setState({
          page: result.data.page,
          loading: false,
          error: false,
        }, () => {
          this.loadMyGeojis()
        })
      })
    })
  }

  loadMyGeojis() {
    this.setState({
      loadingMyGeojis: true,
      error: false,
    }, () => {
      API.callDarwinAPI("GET", "myGeojis", {"owner": 1}, (result) => {
        if ("error" in result) {
          console.log("Error GET my geojis", result)
          this.setState({
            loadingMyGeojis: false,
            error: "Invalid link.",
          })
          return
        }

        let geojis = []
        for (let value of Object.values(result.data.geojis)) {
          geojis.push(Helpers.formatGeoji(value))
        }
        geojis.sort((a, b) => {
          let aNext = Helpers.geojiNextEventDate(a)
          if (aNext === false) {
            aNext = Helpers.parseSQLDate(a.endDate)
          }
          if (a.infinite) {
            aNext = Helpers.year3000
          }
          let bNext = Helpers.geojiNextEventDate(b)
          if (bNext === false) {
            bNext = Helpers.parseSQLDate(b.endDate)
          }
          if (b.infinite) {
            bNext = Helpers.year3000
          }

          if (aNext < bNext) {
            return 1
          } else if (aNext > bNext) {
            return -1
          } else {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1
            } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1
            }
            return 0
          }
        })
        if (this.state.page && this.state.page.geoji) {
          let svalue = this.state.page.geoji.geojiID
          console.log("LOG BASED ON PAGE GEOJI", svalue)
          geojis.sort((a, b) => {
            if (svalue === a.geojiID) {
              return -1
            } else if (svalue === b.geojiID) {
              return 1
            }
            return 0
          })
        }
        console.log("geojis", geojis)

        this.setState({
          geojis: geojis,
          loadingMyGeojis: false,
          error: false,
        })
      })
    })
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "create":
            this.saveChanges(results.data)
            break;
        default:
            console.error("Unhandled submitForm", this.state.view)
            break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "create":
        if (this.state.selectedToken !== false) {
          requiredFields = ["tokenName", "tokenPrice", "tokenQuantity", "tokenFrozen", "tokenUserHidden"]
          optionals = ["tokenAbout", "tokenAboutImage", "tokenPurchaseLimit"]
          requiredIf = []
          requiredSometimes = []
        }
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    //console.log(name, value, valid);
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      }, () => {
        if (res.valid) {
          this.updatePageData()
        }
      })
    })
  }

  updatePageData() {
    this.setState((old) => {
      let newPage = old.page
      newPage.theme.title = old.data.title.value
      newPage.theme.description = old.data.title.value
      newPage.theme.titleFont = {
        font: old.data.titleFontFont.value,
        size: old.data.titleFontSize.value + "px",
        color: old.data.titleFontColor.value,
      }
      newPage.theme.bodyFont = {
        font: old.data.bodyFontFont.value,
        size: old.data.bodyFontSize.value + "px",
        color: old.data.bodyFontColor.value,
      }
      newPage.theme.colors = {
        background: old.data.backgroundColor.value,
        accent: old.data.accentColor.value,
      }
      return {
        page: newPage
      }
    })
  }

  onToggle(name, newVal) {
    console.log(name, newVal)
    /*if (name === "tokenFrozen") {
      if (newVal === "0") {
        this.formChanged(name, "1", true)
      } else {
        this.formChanged(name, "0", true)
      }
    } else if (name === "tokenUserHidden") {
      if (newVal === "0") {
        this.formChanged(name, 1, true)
      } else {
        this.formChanged(name, 0, true)
      }
    } else {*/
      this.formChanged(name, newVal, true)
    //}
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  * Shows a confirmation dialog with an optional callback passing true or false
  * to whether they have accepted the action
  */
  showConfirmation(title, description, accept, deny, action) {
    this.setState({
      confirmation: true,
      confirmationTitle: title,
      confirmationDescription: description,
      confirmationAccept: accept,
      confirmationDeny: deny,
      confirmationAction: (result) => {
        this.setState({
          confirmation: false
        })
        action(result)
      }
    })
  }

  /*
  Shows an error overlay that can be dismissed.
  */
  showError(title, description) {
    this.setState({
      showError: true,
      showErrorTitle: title,
      showErrorDescription: description,
      showErrorAction: (result) => {
        this.setState({
          showError: false
        })
      }
    })
  }

  /*
  Changes the tab/section that we have selected on the left side of the screen.
  */
  changeSection(newSection) {
    if (this.state.page && this.state.page.geoji && this.state.page.geoji.geojiID) {
      //We can only switch sections if we have selected a Geoji.
      this.setState((old) => {
        let newPage = old.page
        if (newSection === "Sections") {
          for (let i = 0; i < newPage.sections.length; i = i + 1) {
            newPage.sections[i].selected = false
          }
        }
        return {
          section: newSection,
          sectionSelected: false,
          page: newPage,
          creatingSection: false,
        }
      })
    } else {
      this.showError("Select a Geoji First", "You must first select a Geoji for which to create a page. Then, you can click on Theme & Sections to edit how the page looks.")
    }
  }

  newPageConfiguration(geoji) {
    return {
      theme: {
        title: geoji.title,
        description: geoji.description,
        titleFont: {
          font: "Syncopate",
          size: "32px",
          color: "#EEA224",
        },
        bodyFont: {
          font: "Lato",
          size: "16px",
          color: "#F7ECB3",
        },
        colors: {
          background: "#5C3D3F",
          accent: "#C35F41",
        }
      },
      sections: [{
        id: 0,
        selected: false,
        type: "Header",
        logo: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/Images/NorthlandsMusic.png",
        links: [{
          text: "About",
          link: "#About",
        }, {
          text: "Activities",
          link: "#Activities",
        }, {
          text: "Map",
          link: "#Map",
        }],
      }, {
        id: 1,
        selected: false,
        type: "Main",
        image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/Images/NorthlandsFestival.png",
        hiddenText: geoji.title,
        hiddenDescription: geoji.description,
      }, {
        id: 2,
        selected: false,
        type: "Text",
        header: "Enter a land where fantasy reigns",
        body: "Open 10am - 1opm on June 13 - June 20. Bring your pets. Admission includes everything. Bring out your pets, your family, your friends, etc. It will be a blast from the past!",
      }, {
        id: 3,
        selected: false,
        type: "TextParts",
        parts: [{
          header: "Hours of Operation",
          body: "Every day 10am-10pm",
        }, {
          header: "Admission",
          body: "$15 per person includes all attractions",
        }, {
          header: "Event Parking",
          body: "Parking is limited. Uber if possible",
        }, {
          header: "Location",
          body: "100 W rd Houston, TX 78700",
        }],
      }, {
        id: 4,
        selected: false,
        type: "Activities",
        header: "Enchanting Activities",
        activities: [{
          type: "Large",
          header: "Ferris Wheel",
          body: "Fly 100 ft int he sky on the miracle worker",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/Images/ValentinesGeoji.jpg",
        }, {
          type: "Small",
          header: "Pumpkins",
          body: "Over 300 varieties of pumpkins and over 10,000 in total",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/Images/ValentinesGeoji.jpg",
        }, {
          type: "Small",
          header: "Train Ride",
          body: "Ride on Thomas the Train's ride around the festival",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/Images/ValentinesGeoji.jpg",
        }, {
          type: "Small",
          header: "Hay Fort",
          body: "Play in Davy Crockett's hay fort and find the Alamo",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/Images/ValentinesGeoji.jpg",
        }],
      }, {
        id: 5,
        selected: false,
        type: "Map",
        header: "Festival Map",
        image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/Images/FestivalMap.png",
      }, {
        id: 6,
        selected: false,
        type: "Text",
        header: "Contact us",
        body: "Reach out to support@geoji.com for inquiries, group outings, etc.",
      }, {
        id: 7,
        selected: false,
        type: "GetTickets",
        header: "Get Tickets",
        body: "Order your tickets now to reserve your spot. Walk ups are on a first come first serve basis.",
      }],
      geoji: geoji,
    }
  }

  /*
  Updates the selected Geoji for this page.
  */
  selectGeoji(geoji) {
    this.setState((old) => {
      let newPage = old.page
      if (!newPage.geoji || !newPage.geoji.geojiID) {
        //need to add the default newPage configuration.
        newPage = this.newPageConfiguration(geoji)
      }
      newPage.geoji = geoji

      let geojis = old.geojis
      let svalue = geoji.geojiID
      geojis.sort((a, b) => {
        if (svalue === a.geojiID) {
          return -1
        } else if (svalue === b.geojiID) {
          return 1
        }
        return 0
      })

      return {
        page: newPage,
        geojis: geojis
      }
    }, () => {
      //TODO: Handle changing the Geoji/Page.
      this.updateDataFromPage()
      //TODO: Have options for duplicating current page, opening a new page, opening an old page, etc.
      //TODO: The Geojis should have someway of showing that they have an existing page, that you can overwrite it, etc.
    })
  }

  updateDataFromPage() {
    this.setState((old) => {
      let data = {
        title: {
          value: old.page.theme.title,
          valid: true,
        },
        description: {
          value: old.page.theme.description,
          valid: true,
        },
        titleFontFont: {
          value: old.page.theme.titleFont.font,
          valid: true,
        },
        titleFontSize: {
          value: old.page.theme.titleFont.size.replace("px", ""),
          valid: true,
        },
        titleFontColor: {
          value: old.page.theme.titleFont.color,
          valid: true,
        },
        bodyFontFont: {
          value: old.page.theme.bodyFont.font,
          valid: true,
        },
        bodyFontSize: {
          value: old.page.theme.bodyFont.size.replace("px", ""),
          valid: true,
        },
        bodyFontColor: {
          value: old.page.theme.bodyFont.color,
          valid: true,
        },
        backgroundColor: {
          value: old.page.theme.colors.background,
          valid: true,
        },
        accentColor: {
          value: old.page.theme.colors.accent,
          valid: true,
        },
      }
      return {
        data: data
      }
    })
  }

  toggleCreatingSection() {
    this.setState((old) => {
      return {
        creatingSection: !old.creatingSection,
      }
    })
  }

  /*
  * Sorts the sections after they have been dragged.
  */
  onSortSections(sortedSections) {
    this.setState((old) => {
      let newPage = old.page
      newPage.sections = sortedSections

      //set the indices
      for (let i = 0; i < newPage.sections.length; i = i + 1) {
        newPage.sections[i].index = i
      }

      return {
        page: newPage
      }
    })
  }

  onSelectSection(section) {
    this.setState((old) => {
      let newPage = old.page
      for (let i = 0; i < newPage.sections.length; i = i + 1) {
        if (newPage.sections[i].id === section.id) {
          newPage.sections[i].selected = true
        } else {
          newPage.sections[i].selected = false
        }
      }
      return {
        page: newPage,
        sectionSelected: section,
      }
    })
  }

  render() {

    let confirmationProps = {
      main: "Confirmation",
      title: this.state.confirmationTitle,
      description: this.state.confirmationDescription,
      accept: this.state.confirmationAccept,
      deny: this.state.confirmationDeny,
      action: this.state.confirmationAction
    }

    let showErrorProps = {
      main: "Error",
      title: this.state.showErrorTitle,
      description: this.state.showErrorDescription,
      dismiss: this.state.showErrorAction
    }

    let fontOptions = [
      {value: "", label: "Select an Option"},
      {value: "Lato", label: "Lato"},
      {value: "Syncopate", label: "Syncopate"},
    ]

    return (
      <div className="PageEditor">

        {/* Confirmation */}
        { this.state.confirmation &&
          <Components.Confirmation {...confirmationProps} />
        }
        {/* Error Popopver */}
        { this.state.showError &&
          <Components.FullScreenAlert {...showErrorProps} />
        }
        {/* Loading Indicator */}
        { this.state.loading &&
          <div className="GeojiLoading">
            <img src={logo} className="GeojiLoadingLogo" alt="logo" />
          </div>
        }

        {/* Home View */}
        { this.state.view === "home" && !this.state.loading && this.state.error === false &&
          <div className="PageEditorContent">
            <div className="PageEditorHeader">
              <div className="PageEditorHeaderSpace"></div>
              <div className="PageEditorHeaderText">
                { (this.state.page && this.state.page.geoji) ? this.state.page.geoji.emoji + " " : ""}
                { (this.state.page && this.state.page.theme) ? this.state.page.theme.title : "New Page" }
              </div>
              <div className="PageEditorActions">
                <img src={ImagePreview} className="PageEditorAction" alt="Preview Site" />
                <img src={ImageDeploy} className="PageEditorAction" alt="Deploy Site" />
              </div>
            </div>
            <div className="PageEditorSplit">
              <div className="PageEditorSplitLeft">
                <div className={"PageEditorSplitLeftSection PageEditorSplitLeftSectionIcon" + (this.state.section === "Geoji" ? " PageEditorSplitLeftSectionSelected" : "")} onClick={this.changeSection.bind(this, "Geoji")}>
                <img src={ImageGeoji} alt="Geoji" />
                  <div>Geoji</div>
                </div>
                <div className={"PageEditorSplitLeftSection" + (this.state.section === "Theme" ? " PageEditorSplitLeftSectionSelected" : "")} onClick={this.changeSection.bind(this, "Theme")}>
                  <img src={ImageTheme} alt="Theme" />
                  <div>Theme</div>
                </div>
                <div className={"PageEditorSplitLeftSection" + (this.state.section === "Sections" ? " PageEditorSplitLeftSectionSelected" : "")} onClick={this.changeSection.bind(this, "Sections")}>
                  <img src={ImageSections} alt="Sections" />
                  <div>Sections</div>
                </div>
              </div>
              {/* List of Geojis to Select From */}
              { this.state.section === "Geoji" &&
                <div className="PageEditorSplitLeftExpanded GeojisSection">
                  { this.state.loadingMyGeojis &&
                    <div className="GeojisSectionLoading">
                      <img src={logo} className="GeojiLoadingLogo" alt="logo" />
                    </div>
                  }
                  <div className="GeojisSectionBody">
                    Select the Geoji / Page you wish to edit below.
                  </div>
                  { !this.state.loadingMyGeojis && this.state.geojis.map((geoji) => (
                    <div className={"GeojisSectionGeoji" + ((this.state.page && this.state.page.geoji && this.state.page.geoji.geojiID === geoji.geojiID) ? " GeojisSectionGeojiSelected" : "")} key={"geoji_" + geoji.geojiID}
                      onClick={this.selectGeoji.bind(this, geoji)}>
                      { geoji.photoURL &&
                        <img className="GeojisSectionGeojiImage" src={geoji.photoURL} alt="geoji" />
                      }
                      { !geoji.photoURL &&
                        <div className="GeojisSectionGeojiEmoji">
                          {geoji.emoji}
                        </div>
                      }
                      <div className="GeojisSectionGeojiTitle">
                        {geoji.title}
                      </div>
                    </div>
                  ))}
                </div>
              }
              {/* Edit the Theming of the Page */}
              { this.state.section === "Theme" &&
                <div className="PageEditorSplitLeftExpanded ThemeSection">
                  <div className="PageEditorSplitLeftExpandedXVisible">
                    <div className="ThemeSectionHeader">
                      Theme
                    </div>
                    <div className="ThemeSectionInputs">
                      {/* Title */}
                      <div className="InputDiv">
                        <Components.InputBottomLine type="text" name="title" placeholder="Title of this event" validation="text" required="true"
                          title="Title" maximum={255} value={this.state.data.title ? this.state.data.title.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                      {/* Description */}
                      <div className="InputDiv">
                        <Components.InputBottomLine type="text" name="description" placeholder="Description of the event" validation="text" required="true"
                          title="Description" value={this.state.data.description ? this.state.data.description.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                      {/* Title Font */}
                      <div className="InputDiv">
                        <Components.InputBottomLine type="selection" options={fontOptions} name="titleFontFont" placeholder="" validation="text" required="true"
                          title="Title Font"
                          value={this.state.data.titleFontFont ? this.state.data.titleFontFont.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        <div className="InputDivFontBottom">
                          <Components.InputBottomLine className="InputFontSize" type="text" inputType="number" name="titleFontSize" placeholder="32" validation="positiveNumber" min={1} max={128} required="true"
                            value={this.state.data.titleFontSize ? this.state.data.titleFontSize.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          <Components.InputBottomLine className="InputFontColor" type="color" name="titleFontColor" placeholder="#F7ECB3" validation="text" required="true"
                            value={this.state.data.titleFontColor ? this.state.data.titleFontColor.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        </div>
                      </div>
                      {/* Body Font */}
                      <div className="InputDiv">
                        <Components.InputBottomLine type="selection" options={fontOptions} name="bodyFontFont" placeholder="" validation="text" required="true"
                          title="Body Font"
                          value={this.state.data.bodyFontFont ? this.state.data.bodyFontFont.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        <div className="InputDivFontBottom">
                          <Components.InputBottomLine className="InputFontSize" type="text" inputType="number" name="bodyFontSize" placeholder="32" validation="positiveNumber" min={1} max={128} required="true"
                            value={this.state.data.bodyFontSize ? this.state.data.bodyFontSize.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          <Components.InputBottomLine className="InputFontColor" type="color" name="bodyFontColor" placeholder="#F7ECB3" validation="text" required="true"
                            value={this.state.data.bodyFontColor ? this.state.data.bodyFontColor.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        </div>
                      </div>
                      {/* Background Color */}
                      <div className="InputDiv">
                        <Components.InputBottomLine type="color" name="backgroundColor" placeholder="#303030" validation="text" required="true"
                          title="Background Color" value={this.state.data.backgroundColor ? this.state.data.backgroundColor.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                      {/* Accent Color */}
                      <div className="InputDiv">
                        <Components.InputBottomLine type="color" name="accentColor" placeholder="#676767" validation="text" required="true"
                          title="Accent Color" value={this.state.data.accentColor ? this.state.data.accentColor.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                    </div>
                  </div>
                </div>
              }
              {/* Edit the Sections of the Page and their content */}
              { this.state.section === "Sections" &&
                <div className="PageEditorSplitLeftExpanded SectionsSection">
                  { !this.state.creatingSection &&
                    <div className="SectionsSectionList">
                      <div className="SectionsSectionHeader">
                        <div className="SectionsSectionHeaderLeft">
                          Sections
                        </div>
                        <div className="SectionsSectionHeaderPlus" onClick={this.toggleCreatingSection}>
                          +
                        </div>
                      </div>
                      <div className="SectionsSectionListItems">
                        {/* List out the sections that already exist. */}
                        { this.state.page.sections.map((section, sectionIter) => (
                          <SortablePageSection key={"section_" + section.id}
                            onSortItems={this.onSortSections.bind(this)}
                            items={this.state.page.sections}
                            sortId={sectionIter}
                            section={section}
                            onClick={this.onSelectSection.bind(this, section)}
                          />
                        ))}
                      </div>
                    </div>
                  }
                  { this.state.creatingSection &&
                    <div className="SectionsSectionNew">
                      <div className="SectionsSectionHeader">
                        <div className="SectionsSectionHeaderLeft">
                          New Section
                        </div>
                        <div className="SectionsSectionHeaderPlus" onClick={this.toggleCreatingSection}>
                          x
                        </div>
                      </div>
                      {/* TODO: Create a new section. */}
                    </div>
                  }
                </div>
              }
              {/* Web Page Preview goes here */}
              <div className="PageEditorSplitPreview">
                { this.state.page && this.state.page.geoji && this.state.page.theme &&
                  <PageRender page={this.state.page} preview={true} changeView={this.props.changeView}></PageRender>
                }
              </div>
              {/* Editor Details go here for the page */}
              { this.state.section === "Sections" && this.state.sectionSelected &&
                <div className="PageEditorSplitRight">
                  <div className="PageEditorSplitRightHeader">
                    {this.state.sectionSelected.type}
                  </div>
                </div>
              }
            </div>
          </div>
        }

      </div>
    )
  }
}

export class PageRender extends React.Component {

  constructor(props) {
    super(props)

    let newState = {
      loading: false,
      data: {},
      forceCheck: false,
      shakeButton: false,
      error: false,

      view: "home",
      subview: false,

      pageSize: "Medium",
    }

    this.state = newState

    autoBind(this)
  }

  scrollToLink(link) {
    console.log(link)
    if (!this.props.preview) {
      //TODO: scroll to this link if not in preview.
    }
  }

  getTickets() {
    console.log("Get Tickets")
    if (!this.props.preview) {
      //TODO: go to the page to get tickets if not in preview.
      this.props.changeView("Geoji", this.props.page.geoji.geojiID)
    }
  }

  setPageSize(newSize) {
    this.setState({
      pageSize: newSize
    })
  }

  getPageZoomFactor(inverse = false) {
    let factor = 1.0
    switch (this.state.pageSize) {
      case "Small":
        factor = 1.5
        break;
      case "Medium":
        factor = 1.0
        break;
      case "Large":
        factor = 0.7
        break;
      default:
        factor = 1.0
        break;
    }
    if (inverse) {
      factor = 1.0 / factor
    }
    return factor
  }

  render() {
    return (
      <div className="PageRender" style={{
        fontFamily:this.props.page.theme.bodyFont.font,
        fontSize:this.props.page.theme.bodyFont.size,
        color:this.props.page.theme.bodyFont.color,
        zoom:this.getPageZoomFactor()
      }}>
        { this.props.preview &&
          <div className="PageRenderSizePicker" style={{
            zoom:this.getPageZoomFactor(true)
          }}>
            <div className={"PageRenderSizePickerSize PageRenderSizePickerSizeSmall" + (this.state.pageSize === "Small" ? " PageRenderSizePickerSizeSelected" : "")} onClick={this.setPageSize.bind(this, "Small")}>
              Sm
            </div>
            <div className={"PageRenderSizePickerSize PageRenderSizePickerSizeMedium"  + (this.state.pageSize === "Medium" ? " PageRenderSizePickerSizeSelected" : "")} onClick={this.setPageSize.bind(this, "Medium")}>
              Md
            </div>
            <div className={"PageRenderSizePickerSize PageRenderSizePickerSizeLarge" + (this.state.pageSize === "Large" ? " PageRenderSizePickerSizeSelected" : "")} onClick={this.setPageSize.bind(this, "Large")}>
              Lg
            </div>
          </div>
        }
        { this.props.page.sections.map((section, sectionIter) => (
          <div className="PageRenderSection" id={section.id ? section.id : "section_" + sectionIter} style={{
            backgroundColor:this.props.page.theme.colors.background,
            fontFamily:this.props.page.theme.titleFont.font,
          }} key={"section_" + sectionIter}>
            { section.type === "Header" &&
              <div className="PageRenderSectionHeader">
                <div className="PageRenderSectionHeaderLeft">
                  <img src={section.logo} alt={this.props.page.theme.title} />
                  { section.links.map((link, linkIter) => (
                    <a key={"link_" + linkIter} href={link.link} className="PageRenderSectionHeaderLeftLink" style={{
                      color:this.props.page.theme.bodyFont.color,
                    }}>
                      {link.text}
                    </a>
                  ))}
                </div>
                <a className="PageRenderSectionHeaderRight" style={{
                  backgroundColor:this.props.page.theme.bodyFont.color,
                  color:this.props.page.theme.colors.background,
                }} href={"/g/" + this.props.page.geoji.geojiID}>
                  Get Tickets
                </a>
              </div>
            }
            { section.type === "Main" &&
              <div className="PageRenderSectionMain">
                <img src={section.image} alt="Main" />
                <h1>{section.hiddenText}</h1>
                <h2>{section.hiddenDescription}</h2>
              </div>
            }
            { section.type === "Text" &&
              <div className="PageRenderSectionText" style={{
                backgroundColor:this.props.page.theme.colors.background,
              }}>
                <h2 className="PageRenderSectionTextHeader" style={{
                  fontFamily:this.props.page.theme.titleFont.font,
                  fontSize:this.props.page.theme.titleFont.size,
                  color:this.props.page.theme.titleFont.color,
                }}>
                  {section.header}
                </h2>
                <div className="PageRenderSectionTextBody" style={{
                  fontFamily:this.props.page.theme.bodyFont.font,
                  fontSize:this.props.page.theme.bodyFont.size,
                  color:this.props.page.theme.bodyFont.color,
                }}>
                  {section.body}
                </div>
              </div>
            }
            { section.type === "TextParts" &&
              <div className="PageRenderSectionTextParts">
                {section.parts.map((part, partIter) => (
                  <div className="PageRenderSectionTextPart" key={"part_" + partIter}>
                    <h2 className="PageRenderSectionTextPartHeader" style={{
                      fontFamily:this.props.page.theme.titleFont.font,
                      fontSize:this.props.page.theme.titleFont.size,
                      color:this.props.page.theme.titleFont.color,
                    }}>
                      {part.header}
                    </h2>
                    <div className="PageRenderSectionTextPartBody" style={{
                      fontFamily:this.props.page.theme.bodyFont.font,
                      fontSize:this.props.page.theme.bodyFont.size,
                      color:this.props.page.theme.bodyFont.color,
                    }}>
                      {part.body}
                    </div>
                  </div>
                ))}
              </div>
            }
            { section.type === "Activities" &&
              <div className="PageRenderSectionActivities">
                <h2 className="PageRenderSectionActivitiesHeader" style={{
                  fontFamily:this.props.page.theme.titleFont.font,
                  fontSize:this.props.page.theme.titleFont.size,
                  color:this.props.page.theme.titleFont.color,
                }}>
                  {section.header}
                </h2>
                <div className="PageRenderSectionActivitiesContainer">
                  { section.activities.map((activity, activityIter) => (
                    <div className={"PageRenderSectionActivity PageRenderSectionActivity" + activity.type} key={"activity_" + activityIter}>
                      { activity.type === "Large" &&
                        <div className="PageRenderSectionActivityLargeContainer" style={{
                          backgroundColor:this.props.page.theme.colors.accent
                        }}>
                          <div className="PageRenderSectionActivityLargeLeft" style={{
                            border:"1px solid " + this.props.page.theme.bodyFont.color,
                          }}>
                            <h3 className="PageRenderSectionActivityLargeLeftHeader" style={{
                              fontFamily:this.props.page.theme.titleFont.font,
                              color:this.props.page.theme.bodyFont.color,
                            }}>
                              {activity.header}
                            </h3>
                            <div className="PageRenderSectionActivityLargeLeftBody" style={{
                              fontFamily:this.props.page.theme.bodyFont.font,
                              color:this.props.page.theme.bodyFont.color,
                            }}>
                              {activity.body}
                            </div>
                          </div>
                          <img src={activity.image} alt="activity" />
                        </div>
                      }
                      { activity.type === "Small" &&
                        <div className="PageRenderSectionActivitySmallContainer">
                          <img src={activity.image} alt="activity" />
                          <h3 className="PageRenderSectionActivitySmallHeader" style={{
                            fontFamily:this.props.page.theme.titleFont.font,
                            color:this.props.page.theme.titleFont.color,
                          }}>
                            {activity.header}
                          </h3>
                          <div className="PageRenderSectionActivitySmallBody" style={{
                            fontFamily:this.props.page.theme.bodyFont.font,
                            color:this.props.page.theme.bodyFont.color,
                          }}>
                            {activity.body}
                          </div>
                        </div>
                      }
                    </div>
                  ))}
                </div>
              </div>
            }
            { section.type === "Map" &&
              <div className="PageRenderSectionMap">
                <h2 className="PageRenderSectionMapHeader" style={{
                  fontFamily:this.props.page.theme.titleFont.font,
                  fontSize:this.props.page.theme.titleFont.size,
                  color:this.props.page.theme.titleFont.color,
                }}>
                  {section.header}
                </h2>
                <img src={section.image} alt="Map" />
              </div>
            }
            { section.type === "GetTickets" &&
              <div className="PageRenderSectionGetTickets">
                <a className="PageRenderSectionGetTicketsButton" href={"/g/" + this.props.page.geoji.geojiID} style={{
                  fontFamily:this.props.page.theme.titleFont.font,
                  fontSize:this.props.page.theme.titleFont.size,
                  color:this.props.page.theme.colors.background,
                  backgroundColor:this.props.page.theme.bodyFont.color,
                }}>
                  {section.header}
                </a>
                <div className="PageRenderSectionGetTicketsBody" style={{
                  fontFamily:this.props.page.theme.bodyFont.font,
                  fontSize:this.props.page.theme.bodyFont.size,
                  color:this.props.page.theme.bodyFont.color,
                }}>
                  {section.body}
                </div>
              </div>
            }
          </div>
        ))}
      </div>
    )
  }
}

export class PageSection extends React.Component {

  render() {
    return (
      <div className={"PageSectionElement" + (this.props.section.selected ? " PageSectionElementSelected" : "")} {...this.props}>
        {this.props.section.type}
      </div>
    )
  }
}

export var SortablePageSection = sortable(PageSection);