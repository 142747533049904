import React from 'react';
import Slider from "react-slick";
//import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
//import * as Helpers from '../Helpers.js';
import * as HomePage from './HomePage.js';

import ImageCustomer from '../images/shots/SammyHeadshot.png';
import ImageCustomer2 from '../images/shots/ScottHeadshot.jpg';
import ImageCustomer3 from '../images/shots/AustinHeadshot.png';
import ImageCustomer4 from '../images/shots/TaylorHeadshot.png';

import ImageCompanyAmazon from '../images/companies/AmazonGray.svg';
import ImageCompanyApple from '../images/companies/AppleGray.svg';
import ImageCompanyGoogle from '../images/companies/GoogleGray.svg';
import ImageCompanyPayPal from '../images/companies/PayPalGray.svg';
import ImageCompanyVenmo from '../images/companies/VenmoGray.svg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import the Darwin API classes
import API from '../API.js';

export class Reachout extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",
    }

    this.formChanged = this.formChanged.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        switch (this.state.subview) {
          case "Home":
            requiredFields = ["phoneNumber"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Home":
          switch (this.state.subview) {
            case "Home":
              this.reachout(data)
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    let shouldSubmit = false
    this.setState((prevState) => {
      let d = prevState.data
      let newRet = {
        data: d,
        forceCheck: false
      }
      d[name] = {
        value: value,
        valid: valid
      }
      newRet.data = d
      return newRet
    }, () => {
      if (shouldSubmit) {
        this.submitForm()
      }
    })
  }

  reachout(data) {
    this.setState({
      loading: true,
    }, () => {
      //call the API to submit the phone number to our contact list in the database
      //and send a slack notification.
      let dd = {
        phoneNumber: data.phoneNumber,
        capturedFrom: "website",
      }
      API.callDarwinAPIUnsecured("POST", "reachout", dd, (result) => {
        if ("error" in result) {
          console.log("Error Submitting Phone Number", result)
          this.setState({
            loading: false,
            view: "Home",
          })
          return
        }
        this.setState({
          loading: false,
          view: "Success",
        })
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  render() {

    let testimonialSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          adaptiveHeight: true,
        }
      }]
    }

    return (
      <div className="ReachoutPage">
        
        {/* Page Header */}
        <HomePage.PageHeader />

        <HomePage.HomeHeader selected="reachout" openCloseMenu={this.props.openCloseMenu} userInfo={this.props.userInfo} />

        <div className="ReachoutPageContent">
          <div className="ReachoutPageTitle">
            It's a win-win
          </div>
          <div className="ReachoutPageDescription">
            Geoji will bring you more sales and all you and your team need is your phone.
            Put our expertise to use with a quick call.
          </div>
          { this.state.view === "Home" && !this.state.loading &&
            <span>
              <div className="InputDiv">
                <Components.InputBottomLine type="text" name="phoneNumber" placeholder="Phone Number" validation="phoneNumber10" required="true" validateTyping={false} autoComplete="tel"
                  tabIndex="1"
                  value={this.state.data.phoneNumber ? this.state.data.phoneNumber.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>
              <div className="ReachoutPageButton" onClick={this.submitForm}>
                Reach Out
              </div>
            </span>
          }
          { this.state.view === "Home" && this.state.loading &&
            <div className="ReachoutPageLoading">
            </div>
          }
          { this.state.view === "Success" &&
            <div className="ReachoutPageSuccess">
              <div className="ReachoutPageSuccessTitle">
                <span role="img" aria-label="success">🎉</span> Success <span role="img" aria-label="success">🎉</span>
              </div>
              <div className="ReachoutPageSuccessBody">
                We will reach out to you shortly!
              </div>
            </div>
          }
          <div className="ReachoutPageTestimonials">
            <Slider {...testimonialSettings}>
              {/* Taylor - Indian Springs Ranch */}
              <div className="ReachoutPageTestimonial">
                <div className="ReachoutPageTestimonialLeft">
                  <img src={ImageCustomer4} alt="Taylor Wier - Indian Springs Ranch" />
                </div>
                <div className="ReachoutPageTestimonialRight">
                  <div className="ReachoutPageTestimonialRightQuote">
                    "We use Geoji at our family ranch and it has worked well. We host a variety of events, goods, and services at our ranch from a Christmas festival, to a Pumpkin Patch, and other holiday events throughout the year and Geoji has been perfect for providing a single platform for all pre and post sales from admission to merchandise to anything we provide with their in app credit card processor all you need is your phone allowing all of our employees to become credit card terminals for walkups at the door or seamless transactions on goods or experiences at the event... I highly recommend to anyone starting their own event, popup, or experience and looking for an all in one ticketing and Point of sale platform."
                  </div>
                  <div className="ReachoutPageTestimonialRightName">
                    Taylor Wier
                  </div>
                  <div className="ReachoutPageTestimonialRightTitle">
                    Owner of Indian Springs Ranch
                  </div>
                </div>
              </div>

              {/* Sammy - Wanderlust Wine */}
              <div className="ReachoutPageTestimonial">
                <div className="ReachoutPageTestimonialLeft">
                  <img src={ImageCustomer} alt="Sammy Lamb - Wanderlust Wine" />
                </div>
                <div className="ReachoutPageTestimonialRight">
                  <div className="ReachoutPageTestimonialRightQuote">
                    "Geoji allows us to capture our audience at the moment of their interest – helping us earn the most that we can off of our events, popups, and experiences."
                  </div>
                  <div className="ReachoutPageTestimonialRightName">
                    Sammy Lamb
                  </div>
                  <div className="ReachoutPageTestimonialRightTitle">
                    Owner of Wanderlust Wine
                  </div>
                </div>
              </div>

              {/* Scott - Texas Pumpkin Fest */}
              <div className="ReachoutPageTestimonial">
                <div className="ReachoutPageTestimonialLeft">
                  <img src={ImageCustomer2} alt="Scott Smith - Texas Pumpkin Fest" />
                </div>
                <div className="ReachoutPageTestimonialRight">
                  <div className="ReachoutPageTestimonialRightQuote">
                    "As founder of one of the largest Pumpkin Patches in Texas and 28 years of festival experience… I’m referred to or contacted by many companies that claim to have a flawless product and great customer service— the reality is almost all of them never live up to my expectations. Well Geoji not only lived up to my expectations they seamlessly exceeded them by a 10 fold margin. I recommend this company to anyone. They’re versatile, knowledgeable, and understand their industry. They have the best customer service in their market space and with their map they will continue to become the ultimate platform for all experiential commerce."
                  </div>
                  <div className="ReachoutPageTestimonialRightName">
                    Scott Smith
                  </div>
                  <div className="ReachoutPageTestimonialRightTitle">
                    Owner of Texas Pumpkin Fest
                  </div>
                </div>
              </div>

              {/* Austin - Blue Norther */}
              <div className="ReachoutPageTestimonial">
                <div className="ReachoutPageTestimonialLeft">
                  <img src={ImageCustomer3} alt="Austin Pittman - Blue Norther" />
                </div>
                <div className="ReachoutPageTestimonialRight">
                  <div className="ReachoutPageTestimonialRightQuote">
                    "Geoji helped us get up and running fast and fee-less. We opened a new tasting room and the tool is a ticketing app and POS all-in-1. Frankly, it’s a speedy and nimble way to sell just about anything."
                  </div>
                  <div className="ReachoutPageTestimonialRightName">
                    Austin Pittman
                  </div>
                  <div className="ReachoutPageTestimonialRightTitle">
                    Owner of Blue Norther
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className="ReachoutPageTrust">
            <div className="ReachoutPageTrustTitle">
              Trusted by the world's leading brands.
            </div>
            <div className="ReachoutPageTrustBrands">
              <img src={ImageCompanyAmazon} alt="Amazon" className="ReachoutPageTrustBrand" style={{paddingTop:"16px"}} />
              <img src={ImageCompanyPayPal} alt="PayPal" className="ReachoutPageTrustBrand" style={{paddingTop:"4px"}} />
              <img src={ImageCompanyVenmo} alt="Venmo" className="ReachoutPageTrustBrand" style={{height:"20px"}} />
              <img src={ImageCompanyApple} alt="Apple" className="ReachoutPageTrustBrand" style={{height:"34px", paddingBottom:"6px"}} />
              <img src={ImageCompanyGoogle} alt="Google" className="ReachoutPageTrustBrand" style={{height:"30px"}} />
            </div>
          </div>
        </div>

        {/* Footer */}
        <Components.GeojiFooter nohide={true} />
      </div>
    )
  }
}
