
/*
Class used for Validating inputs.
*/
export default class Validation {

  /*
  Validates the provided value based on its type.
  */
  static validate(val, type, required, min = -1, max = -1) {
    return Validation.validateMatch(val, type, required, "", min, max)
  }

  static validateMatch(val, type, required, matchValue, min = -1, max = -1) {
    let retVal = "yes"
    let regex = /^((\s|.)*?)$/;
    let numeric = false
    switch (type) {
      case "choice":
        //find the element and construct the regex string from the choices
        /*let regexString = "^("
        for (var i = 0; i < this.props.elements.length; i++) {
          if (this.props.elements[i].name == name) {
            let e = this.props.elements[i];
            for (var j = 0; j < e.choices.length; j++) {
              if (j == 0) {
                regexString += e.choices[j]
              } else {
                regexString += "|" + e.choices[j]
              }
            }
          }
        }
        regexString += ")$"
        regex = new RegExp(regexString, "g")*/
        break;
      case "selection":
        break;
      case "text":
        regex = /^((\s|.)*?)$/;
        break;
      case "file":
        regex = /^((\s|.)*?)$/;
        break;
      case "folder":
        regex = /^((\s|.)*?)$/;
        break;
      case "prefix":
        regex = /^(\/([A-Za-z0-9 \-_.!*'()]+))?([A-Za-z0-9 \-_.!*'()]+)?(\/([A-Za-z0-9 \-_.!*'()]+))*\/?$/;
        break;
      case "digits":
        regex = /^\d+$/;
        break;
      case "number":
        regex = /^-?\d+$/;
        numeric = true
        break;
      case "positiveNumber":
        regex = /^\d+$/;
        numeric = true
        break;
      case "decimal":
        regex = /^-?\d+(?:\.\d*)?$/;
        numeric = true
        break;
      case "money":
        regex = /^-?\d+(?:\.\d*)?$/;
        numeric = true
        break;
      case "versionNumber":
        regex = /^[0-9]+([.][0-9]+)*$/;
        break;
      case "email":
        regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$/;
        break;
      case "password":
        //regex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z\d\s])([^\s]){8,100}$/;
        regex = /^.{8,100}$/;
        break;
      case "date":
        regex = /^(\d{1,2}-\d{1,2}-\d{4})|(\d{4}-\d{1,2}-\d{1,2})$/;
        break;
      case "time":
        regex = /^\d{1,2}:\d{2}(AM|am|PM|pm)+$/;
        break;
      case "alphanumeric":
        regex = /^[A-Za-z0-9]+$/;
        break;
      case "alphanumeric+":
        regex = /^[A-Za-z0-9]+([A-Za-z0-9]|[-_ ][A-Za-z0-9])*$/;
        break;
      case "variableName":
        regex = /^[A-Za-z]{1}[A-Za-z0-9]{0,30}$/;
        break;
      case "hex":
        regex = /^[0-9A-Fa-f]+$/;
        break;
      case "zip":
        regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        break;
      case "color":
        regex = /^[0-9A-Fa-f]{6}$/;
        break;
      case "domain":
        regex = /^(?=^.{4,253}$)(^((?!(-|_))[a-zA-Z0-9-_]{0,62}[a-zA-Z0-9]\.){2,}[a-zA-Z]{2,63}$)$/;
        break;
      case "url":
        regex = /^https?:\/\/(?=.{4,253})(((?!(-|_))[a-zA-Z0-9-_]{0,62}[a-zA-Z0-9]\.){1,}[a-zA-Z]{2,63})(\/.*)?$/;
        break;
      case "urlSimple":
        regex = /^([a-zA-Z0-9]+:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
        break;
      case "handle":
        regex = /^@[a-zA-Z0-9._]+$/;
        break;
      case "link":
        regex = /^[a-zA-Z0-9]+:\/\/.+$/;
        break;
      case "phoneNumber":
        regex = /^\d{7,20}$/;
        break;
      case "phoneNumber10":
        regex = /^\d{10,20}$/;
        break;
      case "ein":
        regex = /^\d{9}$/;
        break;
      case "bool":
        regex = /^(0|1)$/;
        numeric = true
        break;
      case "checkbox":
        regex = /^(1)$/;
        numeric = true
        break;
      case "json":
        try {
          JSON.parse(val)
        } catch (err) {
          //the value is not valid json
          retVal = "failed"
        }
        break;
      default:
        regex = /^(.*?)$/;
        break;
    }
    if (val === null || val === "") {
      if (required) {
        //required field not filled out
        retVal = "empty"
      }
    } else if (type === "files") {
      //this is an array of files, just make sure there is at least one
      if (val.length === 0) {
        retVal = "failed";
      }
    } else if (type === "match") {
      if (val !== matchValue) {
        retVal = "failed"
      }
    } else if (type === "shopHours") {
      //TODO: Parse the shopHours and make sure that each timepair's end is greater than beginning.
      let times = val.split(",")
      for (let i = 0; i < times.length; i = i + 1) {
        let range = times[i].split("-")
        let rangeStartTime = parseFloat(range[0].replace(":", "."))
        let rangeEndTime = parseFloat(range[1].replace(":", "."))
        if (rangeStartTime > rangeEndTime) {
          retVal = "failed"
          break
        }
      }
    } else if (!regex.test(val)) {
      //the regex failed
      retVal = "failed";
    }
    if (retVal === "yes" && min !== -1) {
      //make sure the min is valid if necessary
      if (numeric) {
        if (val < min) {
          retVal = "minimum";
        }
      } else if (val.length < min) {
        //string test
        retVal = "minimum";
      }
    }
    if (retVal === "yes" && max !== -1) {
      //make sure the max is valid if necessary
      if (numeric) {
        if (val > max) {
          retVal = "maximum";
        }
      } else if (val.length > max) {
        //string test
        retVal = "maximum";
      }
    }

    if (retVal === "yes") {
      return {
        valid: true,
        validResponse: "valid",
        failMessage: ""
      }
    } else {
      return {
        valid: false,
        validResponse: retVal,
        failMessage: Validation.failMessage(type, retVal, min, max)
      }
    }
  }

  /*
  Returns a failure message
  */
  static failMessage(type, error, min = -1, max = -1) {

    let numeric = false
    let errorText = "Remove special characters";
    switch (type) {
      case "choice":
        errorText = "Choose a valid option!"
        break;
      case "selection":
        errorText = "Choose a valid option"
        break;
      case "file":
        errorText = "Must choose a valid file";
        break;
      case "files":
        errorText = "Must choose a valid file(s)";
        break;
      case "folder":
        errorText = "Must choose a valid folder";
        break;
      case "prefix":
        errorText = "Remove special characters";
        break;
      case "text":
        errorText = "Remove special characters";
        break;
      case "digits":
        errorText = "Must be digits";
        break;
      case "number":
        errorText = "Must be a number like 231";
        numeric = true;
        break;
      case "decimal":
        errorText = "Must be a decimal like 0.324 or 527.28";
        numeric = true;
        break;
      case "money":
        errorText = "Must be a dollar amount like $0.32 or $527.28";
        numeric = true;
        break;
      case "versionNumber":
        errorText = "Must be a version number like 1.0.7";
        break;
      case "email":
        errorText = "Invalid email";
        break;
      case "password":
        errorText = "Requirement not met";
        //errorText = "Must contain a lowercase letter, uppercase letter, number, a special character (!@#$ ...), and be at least 8 characters";
        break;
      case "date":
        errorText = "Must be a date like 3-31-1995";
        break;
      case "time":
        errorText = "Must be a time like 9:41AM or 3:30PM";
        break;
      case "alphanumeric":
        errorText = "Must contain only letters and numbers";
        break;
      case "alphanumeric+":
        errorText = "Must contain only letters, numbers and spaces";
        break;
      case "variableName":
        errorText = "Must start with a letter, can contain letters and numbers, max 31 characters";
        break;
      case "hex":
        errorText = "Must be a hex value like EC494E! (0-9 and A-F)";
        break;
      case "zip":
        errorText = "Must be a zip code like 12345 or 12345-6789";
        break;
      case "color":
        errorText = "Must be a 6 character hex value of 0-9 and A-F";
        break;
      case "domain":
        errorText = "Must be a valid domain like www.example.com or test.app.example.com";
        break;
      case "url":
        errorText = "Must be a valid url like https://www.example.com/page.html";
        break;
      case "urlSimple":
        errorText = "Must be a valid url like www.example.com/page.html";
        break;
      case "handle":
        errorText = "Don't forget the @ symbol ex. @Username";
        break;
      case "link":
        errorText = "Must be a valid link like https://www.example.com/page.txt";
        break;
      case "phoneNumber":
        errorText = "Must be a valid phone number. Digits only like 5120004444";
        break;
      case "phoneNumber10":
        errorText = "Must be a 10 digit phone number. Digits only like 5120004444";
        break;
      case "ein":
        errorText = "Must be a 9 digit EIN. Digits only like 12-3456789";
        break;
      case "bool":
        errorText = "Must be yes or no";
        numeric = true
        break;
      case "checkbox":
        errorText = "You must check this box";
        numeric = true
        break;
      case "json":
        errorText = "Must be valid json";
        break;
      case "match":
        errorText = "Passwords do not match";
        break;
      case "shopHours":
        errorText = "Closing time must be after Opening time";
        break;
      default:
        errorText = "Remove special characters!";
        break;
    }

    if (error === "minimum") {
      if (numeric) {
        if (type === "money") {
          return "Must be a minimum of $" + Validation.formatNumberDecimals(min, 2) + "."
        } else {
          return "Must be a minimum of " + min + "."
        }
      } else {
        return "Must be a minimum of " + min + " characters."
      }
    } else if (error === "maximum") {
      if (numeric) {
        if (type === "money") {
          return "There is a maximum of $" + Validation.formatNumberDecimals(max, 2) + "."
        } else {
          return "There is a maximum of " + max + "."
        }
      } else {
        return "There is a maximum of " + max + " characters."
      }
    } else if (error === "empty") {
      switch (type) {
        case "file":
          return "You must choose a file!"
        case "files":
          return "You must choose a file(s)!"
        case "folder":
          return "You must choose a folder!"
        case "choice":
        case "selection":
          return "You must choose an option!"
        case "shopHours":
          return "You must be open sometime!"
        default:
          return "Required field"
      }
    }
    return errorText
  }

  static formatNumberDecimals(numb, decimals = 2) {
    if (numb === undefined) {
      return ""
    }
    let j = parseFloat(numb).toFixed(decimals)
    return j.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
}
