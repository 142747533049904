import React from 'react';
import autoBind from 'react-autobind';
import ReactPlayer from "react-player"
import ReactMarkdown from 'react-markdown'

import * as Components from '../Components.js';
import * as Helpers from '../Helpers.js';

import * as HomePage from './HomePage.js';

//import the Darwin API classes
import API from '../API.js';

/**
Main Home Page for people to land on when going to Geoji.com
*/
export class BlogPage extends React.Component {

  constructor(props) {
    super(props)

    let data = {
      /*eventType: {
        value: "Festival",
        valid: true,
      }*/
    }

    let newState = {
      loading: true,
      error: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",

      blog: false,
    }

    if (props.blog !== undefined) {
      console.log("Blog passsed in through props")
      newState.loading = false
      newState.blog = props.blog
    }

    this.state = newState

    autoBind(this)
  }

  componentDidMount() {
    this.calculateView()

    //load the featured Geojis.
    this.loadBlog()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //reload the blog
    //this.loadBlog()
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        switch (this.state.subview) {
          case "Home":
            requiredFields = []
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Home":
          switch (this.state.subview) {
            case "Home":
              console.log("submitting calculator form", data)
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    let shouldSubmit = false
    this.setState((prevState) => {
      let d = prevState.data
      let newRet = {
        data: d,
        forceCheck: false
      }
      d[name] = {
        value: value,
        valid: valid
      }
      //recalculate the calculator numbers if the changed value was eventType
      if (name === "eventType") {
        switch (value) {
          case "Festival":
            d["calculatorPeople"] = {
              value: "9837",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "120",
              valid: true
            }
            break;
          case "Venue":
            d["calculatorPeople"] = {
              value: "423",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "60",
              valid: true
            }
            break;
          case "Concert":
            d["calculatorPeople"] = {
              value: "217",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "40",
              valid: true
            }
            break;
          case "Event":
            d["calculatorPeople"] = {
              value: "185",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "30",
              valid: true
            }
            break;
          case "Popup":
            d["calculatorPeople"] = {
              value: "69",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "20",
              valid: true
            }
            break;
          case "Experience":
            d["calculatorPeople"] = {
              value: "30",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "80",
              valid: true
            }
            break;
          default:
            break;
        }
      }

      newRet.data = d
      return newRet
    }, () => {
      if (shouldSubmit) {
        this.submitForm()
      }
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  loadBlog(signed = false) {
    this.setState({
      error: false,
    }, () => {
      let url = "blog/"
      if (signed) {
        url = "blogSigned/"
      }

      let callback = (result) => {
        //console.log("result", result)
        if ("error" in result) {
          //console.log("Error GET " + url + this.props.blogID, result)
          if (result.error === "Not Published") {
            if (!signed) {
              //try loading signed if logged in.
              if (API.getAccessToken() === "") {
                //not logged in
                this.setState({
                  loading: false,
                  error: "Not Published",
                })
              } else {
                this.loadBlog(true)
              }
            } else {
              this.setState({
                loading: false,
                error: "Not Published",
              })
            }
          } else {
            this.props.changeView("Home")
          }
          return
        }

        let blog = result.data.blog
        try {
          blog.content = JSON.parse(blog.content)
        } catch (enot) {
          console.log("couldn't parse content", enot)
          blog.content = []
        }
        blog.publishedDate = (blog.publishedTimestamp && blog.publishedTimestamp.length > 0) ? Helpers.formatDateObjectShort(blog.publishedTimestamp) : "Draft"

        this.setState({
          blog: blog,
          loading: false,
          error: false,
        })
      }

      if (signed) {
        API.callDarwinAPI("GET", url + this.props.blogID, {}, callback)
      } else {
        API.callDarwinAPIUnsecured("GET", url + this.props.blogID, {}, callback)
      }
    })
  }

  render() {
    return (
      <div className="HomePage">

        {/* Page Header */}
        <HomePage.PageHeader />

        {/* Header */}
        <HomePage.HomeHeader selected="" openCloseMenu={this.props.openCloseMenu} userInfo={this.props.userInfo} />

        {/* Loading and no blog data yet. Show loading bars for title, subtitle, and image. */}
        { this.state.loading && !this.state.blog &&
          <div className="BlogLoading">
            <div className="BlogLoadingTitle">
            </div>
            <div className="BlogLoadingSubtitle">
            </div>
            <div className="BlogLoadingAuthor">
              <div className="BlogLoadingAuthorImage">
              </div>
              <div className="BlogLoadingAuthorContent">
                <div className="BlogLoadingAuthorContentName">
                </div>
                <div className="BlogLoadingAuthorContentPublished">
                </div>
              </div>
            </div>
            <div className="BlogLoadingImage">
            </div>
          </div>
        }
        {/* Blog loaded and ready for display */}
        { this.state.blog &&
          <article className="BlogArticle">
            <h1 className="BlogArticleTitle">
              {this.state.blog.title}
            </h1>
            <p className="BlogArticleSubtitle">
              {this.state.blog.subtitle}
            </p>
            <div className="BlogArticleAuthor">
              <img className="BlogArticleAuthorImage" src={this.state.blog.author && this.state.blog.author.profilePicture} alt={this.state.blog.author && this.state.blog.author.name} />
              <div className="BlogArticleAuthorBody">
                <div className="BlogArticleAuthorBodyName">
                  {this.state.blog.author && this.state.blog.author.name}
                </div>
                <div className="BlogArticleAuthorBodyDate">
                  {this.state.blog.publishedDate}
                </div>
              </div>
            </div>

            { this.state.blog.imageURL &&
              <img src={this.state.blog.imageURL} alt="Blog" className="BlogArticleMainImage" />
            }

            { this.state.blog.content.map((block) => (
              <section className="BlogArticleBlock" key={"block_" + block.id}>
                { block.type === "Header" &&
                  <h2 className="BlogArticleBlockHeader">
                    {block.content}
                  </h2>
                }
                { block.type === "Text" &&
                  <div className="BlogArticleBlockText">
                    <ReactMarkdown linkTarget="_blank">
                      {block.content}
                    </ReactMarkdown>
                  </div>
                }
                { block.type === "Image" &&
                  <div className="BlogArticleBlockImage">
                    <img src={block.content} alt="Blog" />
                  </div>
                }
                { block.type === "Video" &&
                  <div className="BlogArticleBlockVideo">
                    <div className='player-wrapper'>
                      <ReactPlayer
                        className='react-player'
                        url={block.content}
                        controls={true} pip={true}
                        width='100%'
                        height='100%'
                      />
                    </div>
                  </div>
                }
              </section>
            ))}
          </article>
        }
        {/* Couldn't find the blog. Maybe we need to login? */}
        { !this.state.loading && !this.state.blog &&
          <div className="BlogError">
            { this.state.error === "Not Published" &&
              <div className="BlogErrorContent">
                <h1 className="BlogErrorContentTitle">
                  Not Published
                </h1>
                <p className="BlogErrorContentBody">
                  This article has not been published. Please sign in with an Admin account or check back later.
                </p>
              </div>
            }
            { this.state.error !== "Not Published" &&
              <div className="BlogErrorContent">
                <h1 className="BlogErrorContentTitle">
                  Not Found
                </h1>
                <p className="BlogErrorContentBody">
                  We couldn't find this article.
                </p>
              </div>
            }
          </div>
        }


        {/* Footer */}
        <Components.GeojiFooter nohide={true} />
      </div>
    )
  }
}
