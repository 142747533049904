import React from 'react';
//import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
//import * as Helpers from '../Helpers.js';
import * as HomePage from './HomePage.js';

import IconAITB from '../images/logos/AppIconTextBlack.svg';
import IconAITW from '../images/logos/AppIconTextWhite.svg';
import IconC from '../images/logos/LogoColored.svg';
import IconCB from '../images/logos/LogoColoredBackground.svg';
import IconTB from '../images/logos/TextBlack.svg';
import IconTW from '../images/logos/TextWhite.svg';


//import the Darwin API classes
//import API from '../API.js';

export class Brand extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",
    }

    this.colorsBW = [
      {
      name: "White",
      hex: "#FFFFFF",
    }, {
      name: "Off White",
      hex: "#F6F6F6",
    }, {
      name: "Gray Line",
      hex: "#D8D8D8",
    }, {
      name: "Gray Light",
      hex: "#B8B8B7",
    }, {
      name: "Gray",
      hex: "#8E8E93",
    }, {
      name: "Black",
      hex: "#000000",
    }]

    this.colorsC = [
      {
      name: "Blue",
      hex: "#03A9F4",
    }, {
      name: "Green",
      hex: "#8AC349",
    }, {
      name: "Dashboard",
      hex: "#3D39CC",
    }, {
      name: "Dashboard Light",
      hex: "#9E99E5",
    }, {
      name: "Orange",
      hex: "#F49703",
    }, {
      name: "Red",
      hex: "#FF3B30",
    }]

    this.colorsT = [
      {
      name: "Token Blue",
      hex: "#67A7EE",
    }, {
      name: "Token Green",
      hex: "#6DC887",
    }, {
      name: "Token Orange",
      hex: "#F6C26F",
    }, {
      name: "Token Pink",
      hex: "#EE67BD",
    }, {
      name: "Token Red",
      hex: "#EE677E",
    }]

    this.logos = [IconCB, IconC, IconAITW, IconAITB, IconTW, IconTB]
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
  }

  render() {

    return (
      <div className="BrandPage">

        {/* Page Header */}
        <HomePage.PageHeader />
        
        <HomePage.HomeHeader selected="brand" openCloseMenu={this.props.openCloseMenu} userInfo={this.props.userInfo} />

        <div className="BrandPageContent">
          {/* Logos */}
          <div className="BrandPageContentLogos">
            <div className="BrandPageContentTitle">
              Geoji Logos
            </div>
            <div className="BrandPageContentTitleLine">
            </div>
            <div className="BrandPageContentDescription">
              The Geoji logo is fixed artwork. It may not be altered or recreated in any way.
              <br/><br/>
              Use the App Icon variation if referring to the App version of Geoji such as 'Download on the App Store'. Otherwise, use the Geoji logo by itself or the text version.
              <br/><br/>
              Use the colored icons on white backgrounds, and the white icons on dark or colored backgrounds.
              <br/><br/>
              The logo text is a custom mark and not a font. You can download all the Geoji assets by clicking below to pick the best icon in SVG or PNG format for your use.
            </div>
            <a className="BrandPageContentDownloadLink" href="https://galaxy.darwincloud.com/Apps/fs/19/Resources/brand/GeojiAssets.zip" download>
              <div className="BrandPageContentDownload">
                Download Assets
              </div>
            </a>
            <div className="BrandPageContentLogosList">
              { this.logos.map((logo, i) => (
                <div className={"BrandPageContentLogosListItem " + (i % 2 === 1 ? "BrandPageContentLogosListItemOdd" : "")} key={"logo_" + i}>
                  <img className="BrandPageContentLogosListItemImage" src={logo} alt="Geoji" />
                </div>
              ))}
            </div>
          </div>

          {/* Colors */}
          <div className="BrandPageContentColors">
            <div className="BrandPageContentTitle">
              Geoji Colors
            </div>
            <div className="BrandPageContentTitleLine">
            </div>
            <div className="BrandPageContentDescription">
              Use the following colors for theming and brand consistency.
              <br/><br/>
              Geoji is meant to be a clean product using grayscale for most of the interface.
              White and Off White are used to create separation and focus for the user.
              Black and Gray are used for textual elements on headers and body text.
              <br/><br/>
              Use the Geoji Theme colors to make elements stand out and create focus.
              <br/><br/>
              Use the Geoji Token colors sparingly.
              These are meant to create visual separation in a list of items where grayscale isn't enough.
            </div>
            <a className="BrandPageContentDownloadLink" href="https://galaxy.darwincloud.com/Apps/fs/19/Resources/brand/GeojiColors.txt" download>
              <div className="BrandPageContentDownload">
                Download Colors
              </div>
            </a>

            <div className="BrandPageContentColorsSection">
              Geoji Grayscale
            </div>
            <div className="BrandPageContentColorsList">
              { this.colorsBW.map((color, i) => (
                <div className="BrandPageContentColorsListItem" key={"color_" + i}>
                  <div className="BrandPageContentColorsListItemBar" style={color.name === "White" ? {background:color.hex, border: "2px solid #F6F6F6"} : {background:color.hex}}>
                  </div>
                  <div className="BrandPageContentColorsListItemName">
                    {color.name}
                  </div>
                  <div className="BrandPageContentColorsListItemHex">
                    <b>HEX</b>&nbsp;&nbsp;&nbsp;&nbsp;{color.hex}
                  </div>
                </div>
              ))}
            </div>

            <div className="BrandPageContentColorsSection">
              Geoji Theme
            </div>
            <div className="BrandPageContentColorsList">
              { this.colorsC.map((color, i) => (
                <div className="BrandPageContentColorsListItem" key={"color_" + i}>
                  <div className="BrandPageContentColorsListItemBar" style={color.name === "White" ? {background:color.hex, border: "2px solid #F6F6F6"} : {background:color.hex}}>
                  </div>
                  <div className="BrandPageContentColorsListItemName">
                    {color.name}
                  </div>
                  <div className="BrandPageContentColorsListItemHex">
                    <b>HEX</b>&nbsp;&nbsp;&nbsp;&nbsp;{color.hex}
                  </div>
                </div>
              ))}
            </div>

            <div className="BrandPageContentColorsSection">
              Geoji Tokens
            </div>
            <div className="BrandPageContentColorsList">
              { this.colorsT.map((color, i) => (
                <div className="BrandPageContentColorsListItem" key={"color_" + i}>
                  <div className="BrandPageContentColorsListItemBar" style={color.name === "White" ? {background:color.hex, border: "2px solid #F6F6F6"} : {background:color.hex}}>
                  </div>
                  <div className="BrandPageContentColorsListItemName">
                    {color.name}
                  </div>
                  <div className="BrandPageContentColorsListItemHex">
                    <b>HEX</b>&nbsp;&nbsp;&nbsp;&nbsp;{color.hex}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Footer */}
        <Components.GeojiFooter nohide={true} />
      </div>
    )
  }
}
