import React from 'react';
// import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {ElementsConsumer, CardElement, Elements} from '@stripe/react-stripe-js';
import { Calendar, DateObject } from "react-multi-date-picker"
import DatePicker from "react-multi-date-picker"
import colors from "react-multi-date-picker/plugins/colors"
import gregorian from "react-date-object/calendars/gregorian"
import gregorian_en from "react-date-object/locales/gregorian_en"
import Toggle from 'react-toggle'
import io from 'socket.io-client';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Bar } from 'react-chartjs-2';
import {QRCodeSVG} from 'qrcode.react';
import { sortable } from 'react-sortable';
import { ContextMenuTrigger, ContextMenu, ContextMenuItem } from 'rctx-contextmenu2';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';
import * as HomePage from './HomePage.js';


// import the Darwin API classes
import API from '../API.js';
import logo from '../images/GeojiLogoGreenBlue.svg';
import logoColored from '../images/GeojiLogoColored.svg';
import ImageLocation from '../images/Location.svg';
import ImageMessage from '../images/Message.svg';
import ImageWebsite from '../images/Website.svg';
import ImageInfinity from '../images/Infinity.svg';
import ImageInfinityWhite from '../images/InfinityWhite.svg';
import ImageShoppingCart from '../images/ShoppingCart.svg';
import ImageBack from '../images/Back.svg';
import ImageBackWhite from '../images/BackWhite.svg';
// import ImageChecked from '../images/Checked.svg';
import ImageCreditCard from '../images/CreditCard.svg';
import ImagePencilWhite from '../images/PencilWhite.svg';
import ImagePencilBlack from '../images/PencilBlack.svg';
import ImagePersonGray from '../images/PersonGray.svg';
import ImageGreenOctopus from '../images/GreenOctopus.svg';
// import ImagePayPal from '../images/PayPal.svg';
import ImageApplePay from '../images/ApplePay.svg';
import ImageGooglePay from '../images/GooglePay.svg';
import ImageAmazonPay from '../images/AmazonPay.svg';
import ImageRightArrowGray from '../images/RightArrowGray.svg';
import ImageSignature from '../images/Signature.svg';
import ImageDashboard from '../images/Dashboard.svg';
// import ImageGeojiCoin from '../images/GeojiCoin.svg';
import ImageGeojiCoinShape from '../images/GeojiCoinShape.svg';
import ImageCopy from '../images/Copy.svg';
import ImageCheckmark from '../images/Checkmark.svg';
import ImageAZ from '../images/AZ.svg';
import ImageQueue from '../images/Queue.svg';
import ImageVirtualTerminal from '../images/VirtualTerminal.svg';
// import ImagePeople from '../images/People.svg';
import ImageNotes from '../images/Notes.svg';
import ImageNotesBlack from '../images/NotesBlack.svg';
import ImageGuest from '../images/StarBlue.svg';
import ImageWaiverSuccess from '../images/ShieldGreen.svg';
import ImageWaiverFailed from '../images/ShieldRed.svg';
import ImageCheckmarkBlack from '../images/CheckmarkBlack.svg';
import ImageCheckmarkWhite from '../images/CheckmarkWhite.svg';
import ImageEmptyCheckmark from '../images/EmptyCheckmark.svg';
import ImageRefund from '../images/Refund.svg';
import ImageText from '../images/Text.svg';
import ImagePhone from '../images/Phone.svg';
import ImageChart from '../images/Chart.svg';
import ImageTable from '../images/Table.svg';
import ImageTableBlack from '../images/TableBlack.svg';
import ImagePlane from '../images/Plane.svg';
import ImageUpArrow from '../images/UpArrow.svg';
import ImageRightArrow from '../images/RightArrow.svg';
import ImageRightArrowWhite from '../images/RightArrowWhite.svg';
import ImagePrint from '../images/Print.svg';
import ImageQRCodeBackground from '../images/shots/QRCodeBackground.png';
import ImageQRCode from '../images/QRCode.svg';
import ImageSettings from '../images/Settings.svg';
import ImagePromoterLinks from '../images/PromoterLinks.svg';
import ImagePromoCodes from '../images/PromoCodes.svg';
import ImagePurchaseNotifications from '../images/PurchaseNotifications.svg';
import ImageAcceptCash from '../images/AcceptCash.svg';
import ImageRequireEmail from '../images/EmailSetting.svg';
import ImageShowWhosGoing from '../images/ShowWhosGoing.svg';
import ImageShowWhosGoingAlways from '../images/ShowWhosGoingAlways.svg';
import ImageHideLocation from '../images/HideLocation.svg';
import ImageInviteOnly from '../images/InviteOnly.svg';
import ImagePointOfSale from '../images/PointOfSale.svg';

import ImageChevronDown from '../images/ChevronDown.svg';
import ImageCalendar from '../images/Calendar.svg';
import ImageCalendarDark from '../images/CalendarDark.svg';
import ImageInvitePerson from '../images/InvitePerson.svg';
import ImagePoweredByGeoji from '../images/logos/PoweredByBlackText.svg';
//import ImagePoweredByGeoji from '../images/PoweredByGeoji.svg';
//import ImagePoweredByGeojiWhite from '../images/PoweredByGeojiWhite.svg';
//import ImageMoneyWhite from '../images/MoneyWhite.svg';
import ImageFireMarshal from '../images/Person3.svg';
import ImagePersonQuestionMark from '../images/PersonQuestionMark.svg';
import ImageEllipse from '../images/Ellipse.svg';
import ImageArrowUp from '../images/ArrowUp.svg';
import ImageArrowDown from '../images/ArrowDown.svg';
import ImagePercent from '../images/Percent.svg';
import ImageMessagesGreen from '../images/MessagesGreen.svg';
import ImageCancel from '../images/Cancel.svg';
import ImageRemove from '../images/Remove.svg';

const MassColors = require('../MassColors.json')

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

/*var PayPalButton = false;
if (typeof window !== "undefined") {
  if (window.paypal !== undefined && window.paypal.Buttons !== undefined) {
    PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
  }
}
console.log("PayPal Buttons Loaded:", PayPalButton)*/

export class Geoji extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    let newState = {
      loading: true,
      data: data,
      forceCheck: false,
      shakeButton: false,
      geoji: {},
      purchase: {},
      error: false,
      creatingAccount: false,
      card: false,
      tokenIncrement: 0,
      notesSavedCount: 0,
      nameSavedCount: 0,
      acceptTerms: true,

      view: "Home",
      subview: "Home",
      // view: "Login",
      // subview: "Code",

      purchaseLoading: false,
      subloading: false,//subloading or not
      suberror: false,//error message if there is one.
      showAllPurchases: false,//are we showing all of our purchases?
      adminShowingSendReceipt: false, //are we showing the send receipt form

      focusToken: false,//focus on a specific token or no?
      embedded: false,//if embedded, only the tokens are shown.
      theme: "default",//the display theme for the geoji.

      //promoter link stuff
      promoterLinks: [],//the list of promoter links
      promoterLinkIndi: 0,
      promoterLinkSelected: "",
      promoterLinkEdit: false,//the promoterLink we are editing - use 'new' for a new one.
      kickbackLink: false,//the publicly loaded promoter link for kickbacks.
      copiedKickback: false,

      //promo codes for dashboard stuff
      pcodes: [],//the list of promo codes
      pcodesFull: [],//the full list of promo codes including deleted codes
      pcodesShowDeleted: false,//are we showing the deleted codes?
      pcodesIndi: 0,
      pcodesSelected: "",
      pcodesEdit: false,//the promoCode we are editing - use 'new' for a new one.

      //geoji settings
      geojiSettings: {
        purchaseNotifications: false,
        acceptCash: false,
        hideRevenue: false,
        captureEmail: false,
        showInstagram: false,
        showInstagramAlways: false,
        hideLocation: false,
        inviteOnly: false,
        pointOfSale: false,
        processingFeeCents: 30,
        processingFeePercent: 0.0825,
      },
      geojiSettingsSaving: 0,

      //Chart Values
      chartDateValue: [new Date(), new Date()],
      reportDateValue: [new Date()],

      profiles: [],
      profilesPage: 0,
      profilesLimit: 100,
      profilesMoreToLoad: true,
      qrCodeScrollIndex: 0,
      adminSendReciptByText: false,
      adminShowingEditNotes: false,
    }

    if (props.geoji !== undefined) {
      //console.log("Geoji passsed in through props")
      newState.loading = false
      newState.geoji = props.geoji
    }

    let qparams = Helpers.getAllUrlParams()
    console.log("queryParams", qparams)
    if (qparams.focus && qparams.focus.length > 0) {
      newState.focusToken = qparams.focus
    }
    if (qparams.embedded && qparams.embedded === "true") {
      newState.embedded = true
    }
    if (qparams.theme) {
      newState.theme = qparams.theme
    }

    this.state = newState

    this.submitForm = this.submitForm.bind(this)
    this.formChanged = this.formChanged.bind(this)
    this.shakeTheButton = this.shakeTheButton.bind(this)
    this.attemptPayment = this.attemptPayment.bind(this)
    this.attemptPayment2 = this.attemptPayment2.bind(this)
    this.showCreditCardScreen = this.showCreditCardScreen.bind(this)
    this.getCreditCardPaymentIntent = this.getCreditCardPaymentIntent.bind(this)

    this.stripePromiseKey = "pk_test_51IB3RsDkSyEZXQZ0htD4HzMTzpUJYgP5DfsveN65X01enYUrDqBNDwvsqfHl7htlBCGi6gXvL6WJntUwxD80aNtL00jqpAUFag"
    this.stripePromise = loadStripe("pk_test_51IB3RsDkSyEZXQZ0htD4HzMTzpUJYgP5DfsveN65X01enYUrDqBNDwvsqfHl7htlBCGi6gXvL6WJntUwxD80aNtL00jqpAUFag")
    this.goToSuccessPage = this.goToSuccessPage.bind(this)
    this.payPalCreateOrder = this.payPalCreateOrder.bind(this)
    this.payPalOnApprove = this.payPalOnApprove.bind(this)


    this.adminListAZEnd = React.createRef();
    this.adminListQueueEnd = React.createRef();
    this.profileListEnd = React.createRef();
    this.csvFilePicker = React.createRef();
    this.qrCodeList = React.createRef();

    this.qrCodeScrollTimer = -1
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {

    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
    if (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.stripePublishableKey && this.stripePromiseKey !== this.props.userInfo.user.stripePublishableKey) {
      //console.log("Update Stripe Promise", this.props.userInfo.user.stripePublishableKey)
      this.stripePromiseKey = this.props.userInfo.user.stripePublishableKey
      this.stripePromise = loadStripe(this.props.userInfo.user.stripePublishableKey)
    }
    let newUserID = (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.id) ? this.props.userInfo.user.id : false
    let oldUserID = (prevProps.userInfo && prevProps.userInfo.user && prevProps.userInfo.user.id) ? prevProps.userInfo.user.id : false
    if (newUserID !== false && newUserID !== oldUserID) {
      //console.log("NEW USER INFO: LOAD THE AUTHED GEOJI!", this.props.userInfo)
      this.loadGeojiAuthed(this.processPossiblePurchase.bind(this))
      return
    }

    //console.log("purchaseID", this.props.purchaseID, prevProps.purchaseID)
    if (this.props.purchaseID !== prevProps.purchaseID) {
      if (this.props.purchaseID === false) {
        this.dismissAdminView()
      } else {
        this.processPossiblePurchase()
      }
    }
  }

  calculateView() {
    //console.log("Calculate Geoji View")
    let newUserID = (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.id) ? this.props.userInfo.user.id : false
    if (newUserID !== false) {
      this.loadGeojiAuthed(this.processPossiblePurchase.bind(this), true)
    } else {
      this.loadGeoji(this.processPossiblePurchase.bind(this))
    }
  }

  processPossiblePurchase() {

    //console.log("process possible purchase")
    if (this.props.purchaseID !== false) {
      //console.log("Process Possible Purchase", this.props.purchaseID)
      if (this.props.purchaseID.startsWith("dashboard")) {
        //Attempt to show the dashboard if we have access.
        if (Helpers.hasAdminAccess(this.state.geoji, this.props.userInfo.user)) {
          let callback = false
          switch (this.props.purchaseID) {
            case "dashboard":
              callback = false
              break;
            case "dashboard_az":
              callback = () => {
                this.openRedemptions("AZ")
              }
              break;
            case "dashboard_queue":
              callback = () => {
                this.openRedemptions("Queue")
              }
              break;
            case "dashboard_mass":
              callback = () => {
                this.openRedemptions("Mass")
              }
              break;
            case "dashboard_sft":
              callback = () => {
                //Send Free Tokens
                this.showSendFreeTokensView()
              }
              break;
            case "dashboard_qrcode":
              callback = () => {
                //Show QR Code
                this.showAdminQRCode()
              }
              break;
            case "dashboard_invite":
              callback = () => {
                //Show QR Code
                this.showInviteTeamMember()
              }
              break;
            case "dashboard_pl":
              callback = () => {
                this.openPromoterLinks()
              }
              break;
            case "dashboard_pc":
              callback = () => {
                this.openPCodes()
              }
              break;
            case "dashboard_settings":
              callback = () => {
                this.openSettings()
              }
              break;
            case "dashboard_reports":
              callback = () => {
                this.openReports()
              }
              break;
            default:
              if (this.props.purchaseID.startsWith("dashboard_token_")) {
                callback = () => {
                  //find the token
                  let tokID = this.props.purchaseID.substring(16)
                  let toks = this.state.geoji.tokens
                  let foundToken = false
                  for (let i = 0; i < toks.length; i = i + 1) {
                    if (tokID === toks[i].id) {
                      foundToken = toks[i]
                      foundToken.displaySales = "-"
                      foundToken.displayMoney = "-"
                      foundToken.displayCash = "-"
                      break
                    }
                  }
                  if (foundToken === false) {
                    foundToken = {
                      id: tokID,
                      displaySales: "-",
                      displayMoney: "-",
                      displayCash: "-",
                    }
                  }

                  //Dashboard Token Details
                  this.viewAdminTokenDetails(foundToken)
                }
              } else {
                //purchase id
                callback = () => {
                  let pieces = this.props.purchaseID.split("_")
                  if (pieces.length > 1) {
                    let pid = pieces[1]
                    let subv = "AZRedemptions"
                    if (pid.length > 0 && pid.substring(0, 1) === "q") {
                      subv = "QueueRedemptions"
                      pid = pid.substring(1)
                    }
                    //View Admin Purchase
                    this.setState({
                      subview: subv,
                      adminPurchases: [],
                    }, () => {
                      this.viewOrderDetails({
                        purchase: {
                          id: pid
                        }
                      })
                    })
                  }
                }
              }
              break;
          }

          this.viewAdminDashboard(callback)
        } else {
          this.setState({
            loading: false
          })
        }
      } else {
        //Show the success page for this purchase.
        this.setState({
          purchase: {
            id: this.props.purchaseID
          },
        }, () => {
          this.viewPurchase(this.state.purchase)
        })
      }
    }
  }

  loadGeoji(callback = false) {
    //console.log("Load Geoji")
    //1) Load the Geoji information from the API
    this.setState({
      geoji: {},
      loading: this.props.geoji !== undefined ? false : true,
      error: false,
    }, () => {
      let method = "ugeoji/"
      if (this.props.geojiAccess === "private") {
        method = "uprivateLink/"
      }
      API.callDarwinAPIUnsecured("GET", method + this.props.geojiLink, {}, (result) => {
        if ("error" in result) {
          console.log("Error Unsigned Geoji", result)
          this.setState({
            loading: false,
            geoji: {},
            error: "This Geoji has expired",
          })
          return
        }
        let geo = Helpers.formatGeoji(result.data.geoji)
        console.log("Unsigned Geoji", geo)
        //update metadata for the page
        let queryParams = Helpers.getAllUrlParams()
        if (this.props.geojiAccess === "private") {
          Helpers.updatePageMeta("/p/" + this.props.geojiLink, geo, queryParams)
        } else {
          Helpers.updatePageMeta("/g/" + this.props.geojiLink, geo, queryParams)
        }

        //set the theme.
        let newTheme = geo.theme
        let qparams = Helpers.getAllUrlParams()
        if (qparams.theme) {
          newTheme = qparams.theme
        }

        //apply the promo codes if possible
        let pcodeToken = false
        if (qparams.c && qparams.c.length > 0) {
          for (let tok = 0; tok < geo.tokens.length; tok = tok + 1) {
            if (geo.tokens[tok].theme === "promocode") {
              //found it!
              geo.tokens[tok].promoCode = qparams.c
              pcodeToken = geo.tokens[tok]
            }
          }
        }

        this.setState({
          geoji: geo,
          theme: newTheme,
          loading: this.props.purchaseID !== false ? true : false,
          error: false,
        }, () => {
          //scroll to the Tokens header
          if (this.props.purchaseID !== false) {
            if (API.hasLoggedIn()) {
              //waiting to load the authed version.
            } else if (callback !== false) {
              //call the callback
              callback()
            }
          } else {
            let dd = document.querySelector("#GeojiTokenHeader")
            if (dd) {
              dd.scrollIntoView({behavior: 'smooth'})
            }
          }
          //check the promo code
          if (qparams.c && qparams.c.length > 0 && pcodeToken !== false) {
            this.promoCodeSubmit(pcodeToken)
          }
        })
      })
    })
  }

  loadGeojiAuthed(callback = false, force = false) {
    //console.log("Load Geoji Authed")
    if (this.state.loading && this.props.purchaseID === false && !force) {
      //wait for the loading request to complete in 300 ms increments.
      setTimeout(() => {
        this.loadGeojiAuthed(callback)
      }, 300)
      return
    }
    //1) Load the Geoji information from the API
    let getGeoji = (geojiID) => {
      API.callDarwinAPI("GET", "geoji/" + geojiID, {}, (result) => {
        if ("error" in result) {
          console.log("Error Signed Geoji", result)
          this.setState({
            loading: false,
            geoji: {},
            error: "This Geoji has expired",
          })
          return
        }
        let geo = Helpers.formatGeoji(result.data.geoji)
        console.log("Signed Geoji", geo)
        //update metadata for the page
        let queryParams = Helpers.getAllUrlParams()
        if (this.props.geojiAccess === "private") {
          Helpers.updatePageMeta("/p/" + this.props.geojiLink, geo, queryParams)
        } else {
          Helpers.updatePageMeta("/g/" + geojiID, geo, queryParams)
        }

        this.setState((oldState) => {
          let combined = Object.assign({}, oldState.geoji)
          if (combined.geojiID) {
            //add the purchases and creators
            combined.purchases = geo.purchases
            combined.creators = geo.creators
          } else {
            combined = geo
          }

          if (geo.inviteOnlyAccess === "Denied") {
            combined.inviteOnlyNumber = 0;
          } else if (geo.inviteOnlyAccess === "Requested") {
            combined.inviteOnlyNumber = 1;
          } else {
            combined.inviteOnlyNumber = 2;
          }

          //set the theme.
          let newTheme = combined.theme
          let qparams = Helpers.getAllUrlParams()
          if (qparams.theme) {
            newTheme = qparams.theme
          }

          let newSettings = {
            purchaseNotifications: false,
            hideRevenue: false,
            acceptCash: false,
            captureEmail: false,
            processingFeeCents: 30,
            processingFeePercent: 0.0825,
          }
          //update the settings.
          newSettings.captureEmail = combined.sendEmailReceipt === "1" ? true : false
          newSettings.showInstagram = combined.showInstagram === "1" ? true : false
          newSettings.showInstagramAlways = combined.showInstagramAlways === "1" ? true : false
          newSettings.hideLocation = combined.hideLocation === "1" ? true : false
          newSettings.inviteOnly = combined.inviteOnly === "1" ? true : false
          newSettings.pointOfSale = combined.pointOfSale === "1" ? true : false

          newSettings.processingFeeCents = combined.processingFeeCents
          newSettings.processingFeePercent = combined.processingFeePercent
          //find the creator so we can update the settings.
          if (combined.creators) {
            for (let i = 0; i < combined.creators.length; i = i + 1) {
              if (combined.creators[i].id === this.props.userInfo.user.id) {
                //found the user.
                newSettings.purchaseNotifications = combined.creators[i].purchaseNotification === "1" ? true : false
                newSettings.hideRevenue = combined.creators[i].hideRevenue === "1" ? true : false
                newSettings.acceptCash = combined.creators[i].showCashNumbers === "1" ? true : false
              }
            }
          }

          //console.log("Combined", combined)
          return {
            geoji: combined,
            theme: newTheme,
            loading: (this.props.purchaseID !== false && callback !== false) ? true : false,
            error: false,
            //update the geoji settings
            geojiSettings: newSettings,
          }
        }, () => {
          if (this.props.purchaseID !== false) {
            //no need to scroll as we will load the purchase soon enough.
          } else {
            let dd = document.querySelector("#GeojiTokenHeader")
            if (dd) {
              dd.scrollIntoView({behavior: 'smooth'})
            }
          }

          //run the callback
          if (callback !== false) {
            callback()
          }
        })
      })
    }

    if (this.props.geojiAccess === "private") {
      //2) Redeem the geoji link.
      API.callDarwinAPI("POST", "geojiLink/" + this.props.geojiLink, {}, (result) => {
        if ("error" in result) {
          console.log("Error Redeeming Link", result)
          this.setState({
            loading: false,
            geoji: {},
            error: "This link has expired",
          })
          return
        }
        //console.log("Link Redemption", result)
        getGeoji(result.data.geojiID)
      })
    } else {
      getGeoji(this.props.geojiLink)
    }
  }

  goHome() {
    this.props.changeView("Home")
  }

  openLocation() {
    let link = "https://www.google.com/maps/search/?api=1&&query=" + this.state.geoji.geojiLatitude + "," + this.state.geoji.geojiLongitude
    window.open(link, '_blank')
  }

  openWebsite() {
    let link = this.state.geoji.websiteURL
    if (!link.includes("://")) {
      //add in http
      link = "http://" + link
    }
    window.open(link, '_blank')
  }

  openContact() {
    if (this.state.geoji.contactInfoPhoneNumber) {
      //call the person
      let link = "tel:+" + this.state.geoji.contactInfo
      if (this.state.geoji.contactInfo.length === 10) {
        link = "tel:+1" + this.state.geoji.contactInfo
      }
      window.open(link, '_blank')
    } else {
      //instagram
      let link = "https://instagram.com/" + this.state.geoji.contactInfo
      window.open(link, '_blank')
    }
  }

  incrementToken(token) {
    if (token.soldOut) {
      return
    }
    this.setState((old) => {
      let gg = old.geoji
      for (let i = 0; i < gg.tokens.length; i = i + 1) {
        if (gg.tokens[i].id === token.id) {
          //found the token.
          let newVal = gg.tokens[i].cart + 1
          if (gg.tokens[i].quantity > 0) {
            newVal = Math.min(gg.tokens[i].quantity - gg.tokens[i].tokensSold, newVal)
          }
          if (gg.tokens[i].purchaseLimit > 0) {
            newVal = Math.min(newVal, gg.tokens[i].purchaseLimit)
          }
          if (gg.tokens[i].theme === "datetime") {
            //find the selected datetime if there is one.
            for (let d = 0; d < gg.tokens[i].datetimes.length; d = d + 1) {
              if (gg.tokens[i].datetimes[d].selected) {
                //found the selected datetime.
                if (gg.tokens[i].datetimes[d].quantity > 0) {
                  newVal = Math.min(gg.tokens[i].datetimes[d].quantity - gg.tokens[i].datetimes[d].tokensSold, newVal)
                }
              }
            }
          }
          gg.tokens[i].cart = newVal
        }
      }
      return {
        geoji: gg,
        tokenIncrement: old.tokenIncrement + 1
      }
    })
  }

  decrementToken(token) {
    if (token.soldOut) {
      return
    }
    this.setState((old) => {
      let gg = old.geoji
      for (let i = 0; i < gg.tokens.length; i = i + 1) {
        if (gg.tokens[i].id === token.id) {
          gg.tokens[i].cart = Math.max(0, gg.tokens[i].cart - 1)
        }
      }
      return {
        geoji: gg,
        tokenIncrement: old.tokenIncrement + 1
      }
    })
  }

  selectedTokenMapSeat(token, seat) {
    //console.log("selected token map seat", token, seat)
    if (token.soldOut) {
      return
    }
    //determine status of seat
    if (seat.status === "Sold") {
      //this seat has been taken - nothing to do.
      return
    } else if (seat.status === "Selected") {
      //unselect this seat.
      this.setState((old) => {
        let gg = old.geoji
        for (let i = 0; i < gg.tokens.length; i = i + 1) {
          if (gg.tokens[i].id === token.id) {
            //now find the seat
            for (let s = 0; s < gg.tokens[i].mapData.seats.length; s = s + 1) {
              if (gg.tokens[i].mapData.seats[s].name === seat.name) {
                gg.tokens[i].mapData.seats[s].status = "ForSale"
                break
              }
            }
            gg.tokens[i].cart -= 1
          }
        }
        return {
          geoji: gg,
          tokenIncrement: old.tokenIncrement + 1
        }
      })
    } else if (seat.status === "ForSale") {
      //select this seat.
      this.setState((old) => {
        let gg = old.geoji

        for (let i = 0; i < gg.tokens.length; i = i + 1) {
          if (gg.tokens[i].id === token.id) {
            //found the token, now find the seat
            let incDiff = 1
            for (let s = 0; s < gg.tokens[i].mapData.seats.length; s = s + 1) {
              if (gg.tokens[i].purchaseLimit === 1) {
                //select this seat and deselect all others.
                if (gg.tokens[i].mapData.seats[s].name === seat.name) {
                  gg.tokens[i].mapData.seats[s].status = "Selected"
                } else if (gg.tokens[i].mapData.seats[s].status === "Selected") {
                  gg.tokens[i].mapData.seats[s].status = "ForSale"
                  incDiff -= 1
                }
              } else if (gg.tokens[i].purchaseLimit > 1 && gg.tokens[i].cart >= gg.tokens[i].purchaseLimit) {
                //don't select this seat as we have reached our capacity.
                incDiff = 0
              } else {
                //select this seat - no capacity limit.
                if (gg.tokens[i].mapData.seats[s].name === seat.name) {
                  gg.tokens[i].mapData.seats[s].status = "Selected"
                }
              }
            }
            gg.tokens[i].cart += incDiff
          }
        }
        return {
          geoji: gg,
          tokenIncrement: old.tokenIncrement + 1
        }
      })
    } else {
      //unknown seat status. Don't do anything.
    }
  }

  selectedTokenAutoTip(token, tip) {
    console.log("selected token auto tip", token, tip)
    //determine status of seat
    if (tip.status === "Selected") {
      //nothing to do
    } else if (tip.status === "None") {
      //select this tip.
      this.setState((old) => {
        let gg = old.geoji
        for (let i = 0; i < gg.tokens.length; i = i + 1) {
          if (gg.tokens[i].id === token.id) {
            //now find the tip
            for (let s = 0; s < gg.tokens[i].mapData.tips.length; s = s + 1) {
              if (gg.tokens[i].mapData.tips[s].name === tip.name) {
                gg.tokens[i].mapData.tips[s].status = "Selected"
              } else {
                gg.tokens[i].mapData.tips[s].status = "None"
              }
            }
          }
        }
        return {
          geoji: gg,
          tokenIncrement: old.tokenIncrement + 1
        }
      })
    } else {
      //unknown seat status. Don't do anything.
    }
  }

  cartTapped() {

    this.setState({
      view: "Login",
      subview: "Phone",
      checkout: true,
      viewPurchase: false,
      data: {},
      subloading: false,
      suberror: false,
    })
  }

  openTerms() {
    window.open("/terms", '_blank')
  }

  openPrivacy() {
    window.open("/privacy", '_blank')
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "RequestAccess":
        requiredFields = ["name"]
        optionals = ["instagramUsername"]
        requiredIf = []
        requiredSometimes = []
        break
      case "Purchase":
        switch (this.state.subview) {
          case "Home":
            requiredFields = ["name"]
            optionals = ["notes"]
            requiredIf = []
            requiredSometimes = []
            break;
          case "Waiver":
            requiredFields = ["name"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          case "Email":
            requiredFields = []
            optionals = ["email", "notes", "instagramUsername"]
            requiredIf = []
            requiredSometimes = []
            break;
          default:
            break;
        }
        break;
      case "Admin":
        switch (this.state.subview) {
          case "AZRedemptions":
            requiredFields = []
            optionals = ["adminSearch"]
            requiredIf = []
            requiredSometimes = []
            break;
          case "OrderDetails":
            //sending receipt
            if (this.state.adminShowingSendReceipt) {
              if (this.state.adminSendReceiptByText) {
                requiredFields = ["sendReceiptPhoneNumber"]
                optionals = []
              } else {
                requiredFields = ["sendReceiptEmail"]
                optionals = []
              }
              requiredIf = []
              requiredSometimes = []
            } else if (this.state.adminShowingEditNotes) {
              requiredFields = []
              optionals = ["editNotesNotes"]
              requiredIf = []
              requiredSometimes = []
            }
            break;
          case "SendFreeTokens":
            requiredFields = ["freeTokenName"]
            optionals = ["freeTokenPhone", "freeTokenEmail"]
            requiredIf = []
            requiredSometimes = []
            break;
          case "PromoterLinks":
            requiredFields = ["promoterLinkName"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          case "PromoCodes":
            requiredFields = ["pcodeName", "pcodeDescription", "pcodeDiscount"]
            optionals = ["pcodeMaxUses"]
            requiredIf = []
            requiredSometimes = []
            break;
          case "InviteTeamMember":
            requiredFields = ["name", "phoneNumber"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          default:
            break;
        }
        break;
      default:
        switch (this.state.subview) {
          case "Phone":
            requiredFields = ["phoneNumber"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          case "Code":
            requiredFields = ["phoneNumber", "code"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          case "Name":
            requiredFields = ["name"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          default:
            break;
        }
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "RequestAccess":
          this.requestAccess(data)
          break;
        case "Purchase":
          switch (this.state.subview) {
            case "Home":
              this.saveNotes(data)
              break;
            case "Waiver":
              //nothing to do here - must tap the I Agree button manually.
              break;
            case "Email":
              this.saveEmail(data)
              break;
            default:
              break;
          }
          break;
        case "Admin":
          switch (this.state.subview) {
            case "AZRedemptions":
              this.loadOrders("name", data.adminSearch ? data.adminSearch : "", 0)
              break;
            case "OrderDetails":
              //sending receipt
              if (this.state.adminShowingSendReceipt) {
                this.adminSendReceipt(data)
              } else if (this.state.adminShowingEditNotes) {
                this.adminSubmitEditNotes(data)
              }
              break;
            case "SendFreeTokens":
              this.sendFreeTokens(data)
              break;
            case "PromoterLinks":
              this.savePromoterLink(data)
              break;
            case "PromoCodes":
              this.savePCode(data)
              break;
            case "InviteTeamMember":
              this.inviteTeamMember(data)
              break;
            default:
              break;
          }
          break;
        default:
          switch (this.state.subview) {
            case "Phone":
              this.submitPhoneNumber(data)
              break;
            case "Code":
              this.submitCode(data)
              break;
            case "Name":
              this.updateName(data)
              break;
            default:
              break;
          }
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    console.log("form changed", name, value, valid)
    let shouldSubmit = false
    this.setState((prevState) => {
      let d = prevState.data
      let newRet = {
        data: d,
        forceCheck: false
      }
      if (name === "phoneNumber" && prevState.subview === "Phone") {
        if (((d[name] && d[name].value && d[name].value.length === 0) || !d[name] || !d[name].value) && value.length >= 10) {
          //should submit
          shouldSubmit = true
        }
      }
      if (name === "code" && prevState.subview === "Code") {
        if (value.length >= 6) {
          //should submit
          shouldSubmit = true
        }
      }
      if (prevState.view === "Purchase" && name === "name" && value !== this.props.userInfo.user.name) {
        newRet.nameSavedCount = 0
      }
      if (prevState.view === "Purchase" && name === "notes" && value !== (this.state.purchase.notes || "")) {
        newRet.notesSavedCount = 0
      }
      d[name] = {
        value: value,
        valid: valid
      }
      newRet.data = d
      return newRet
    }, () => {
      if (shouldSubmit) {
        this.submitForm()
      } else if (this.state.view === "Admin" && this.state.subview === "AZRedemptions" && name === "adminSearch") {
        //increment the search iter
        let newSearchIter = -1
        this.setState((oldData) => {
          newSearchIter = (oldData.azSearchIter ? oldData.azSearchIter : 0) + 1
          return {
            azSearchIter: newSearchIter
          }
        }, () => {
          //delay 400ms
          setTimeout(() => {
            if (this.state.azSearchIter === newSearchIter) {
              //search
              this.loadOrders("name", (this.state.data.adminSearch && this.state.data.adminSearch.value) ? this.state.data.adminSearch.value : "", 0)
            }
          }, 400)
        })
      }
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  Send phone number code.
  */
  submitPhoneNumber(data) {

    this.setState({
      subloading: true,
      suberror: false,
    }, () => {
      //Determine if this is a phone number
      let pn = data.phoneNumber
      if (pn.length === 10) {
        pn = "1" + pn
      }
      let dd = {
        phoneNumber: pn
      }
      if (pn.startsWith("300011122")) {
        //This is a test account
        this.setState({
          subloading: false,
          suberror: false,
          subview: "Code",
          pnState: "12345678901234567890123456789012345678901234567890",
        })
      } else {
        API.callDarwinAPI("POST", "checkPhoneNumber", dd, (result) => {
          if ("error" in result) {
            console.log("User doesn't exist - create account.", result)
            //create new account with phone number.
            let pnState = Math.random().toString(36).replace('0.', '')
            pnState += Math.random().toString(36).replace('0.', '')
            pnState += Math.random().toString(36).replace('0.', '')
            this.setState({
              pnState: pnState,
              creatingAccount: true,
            }, () => {
              API.phoneNumberCreateAccount(pn, pnState, (res2) => {
                if ("error" in res2) {
                  console.log("phoneNumberCreateAccount", res2, pnState)
                  //Show an error for an invalid phone number.
                  this.setState({
                    subloading: false,
                    suberror: "Invalid phone number. Please try again.",
                  })
                  return
                }
                this.setState({
                  subloading: false,
                  subview: "Code",
                  suberror: false,
                })
              })
            })
            return
          }
          console.log("User exists - login.", result)
          //Login the user by phone number.
          let pnState = Math.random().toString(36).replace('0.', '')
          pnState += Math.random().toString(36).replace('0.', '')
          pnState += Math.random().toString(36).replace('0.', '')
          this.setState({
            pnState: pnState,
            creatingAccount: false,
          }, () => {
            API.phoneNumberAuthenticate(pn, pnState, (res2) => {
              if ("error" in res2) {
                console.log("phoneNumberAuthenticate", res2, pnState)
                //show an error for an invalid phone number.
                this.setState({
                  subloading: false,
                  suberror: "Couldn't reach this phone number. Please try again.",
                })
                return
              }
              this.setState({
                subloading: false,
                subview: "Code",
                suberror: false,
              })
            })
          })
        })
      }
    })
  }

  resendCode() {

    this.setState({
      subloading: true,
      suberror: false,
    }, () => {
      //Determine if this is a phone number
      let pn = this.state.data.phoneNumber.value
      if (pn.length === 10) {
        pn = "1" + pn
      }
      if (pn.startsWith("300011122")) {
        //This is a test account
        this.setState({
          subloading: false,
        })
      } else {
        if (this.state.creatingAccount) {
          API.phoneNumberCreateAccountResend("text", this.state.pnState, (res2) => {
            if ("error" in res2) {
              console.log("phoneNumberCreateAccountResend", res2, this.state.pnState)
              //show an error for an invalid phone number.
              this.setState({
                subloading: false,
                suberror: "Couldn't reach this phone number. Please try again."
              })
              return
            }
            //success
            this.setState({
              subloading: false,
              suberror: false,
            })
          })
        } else {
          API.phoneNumberAuthenticateResend("text", this.state.pnState, (res2) => {
            if ("error" in res2) {
              console.log("phoneNumberAuthenticateResend", res2, this.state.pnState)
              //show an error for an invalid phone number.
              this.setState({
                subloading: false,
                suberror: "Couldn't reach this phone number. Please try again."
              })
              return
            }
            //success
            this.setState({
              subloading: false,
              suberror: false,
            })
          })
        }
      }
    })
  }

  /*
  Send verification code to login the user.
  */
  submitCode(data) {

    let successFunc = () => {
      //success - load the user.
      this.props.reloadUser(() => {
        console.log("User loaded", this.props.userInfo)
        if (this.state.checkout === true) {
          //go to the name form
          this.setState((old) => {
            let newData = old.data
            if (this.props.userInfo.user.name && this.props.userInfo.user.name.length > 0) {
              newData.name = {
                value: this.props.userInfo.user.name,
                valid: true
              }
            }
            return {
              data: newData,
              subview: "Name",
              subloading: false,
              suberror: false,
            }
          })
        } else if (this.state.viewPurchase === true) {
          //show the purchase view.
          this.processPossiblePurchase()
        } else if (this.state.loginCallback === "viewRequestAccess") {
          this.setState({
            loginCallback: null,
          }, () => {
            this.viewRequestAccess()
          })
        } else if (this.state.loginCallback === "viewKickback") {
          this.setState({
            loginCallback: null,
          }, () => {
            this.viewKickback()
          })
        } else {
          //go back home to the Geoji view.
          this.backHome()
        }
      })
    }

    this.setState({
      subloading: true,
      suberror: false,
    }, () => {
      //Determine if this is a phone number
      let pn = data.phoneNumber
      if (pn.length === 10) {
        pn = "1" + pn
      }
      if (pn.startsWith("300011122")) {
        //This is a test account
        if (data.code === "111222") {
          API.manualLogin(pn, "GeojiTestAccount!", (result) => {

            if ("error" in result) {
              console.log("phone number code check", result)
              //show an error for an invalid code.
              this.setState({
                subloading: false,
                suberror: "Invalid code. Please try again.",
              })
              return
            }
            successFunc()
          })
        } else {
          this.setState({
            subloading: false,
            suberror: "Invalid code. Please try again.",
          })
        }
      } else {
        if (this.state.creatingAccount) {
          //check the code.
          API.phoneNumberCreateAccountVerify(data.code, this.state.pnState, (result) => {
            if ("error" in result) {
              console.log("phoneNumberCreateAccountVerify", result)
              //show an error for an invalid code.
              this.setState({
                subloading: false,
                suberror: "Invalid code. Please try again.",
              })
              return
            }
            //success - now create the account.
            API.phoneNumberCreateAccountFinal("", "", this.state.pnState, (res2) => {
              if ("error" in res2) {
                console.log("phoneNumberCreateAccountFinal", res2)
                //show an error for an invalid code.
                this.setState({
                  subloading: false,
                  suberror: "Internal error, please try again.",
                })
                return
              }
              //success - login the user
              API.manualLogin(pn, data.code + "_" + this.state.pnState, (res3) => {
                if ("error" in res3) {
                  console.log("Manual Login Error", res3)
                  //show an error.
                  this.setState({
                    subloading: false,
                    suberror: "Internal error, please try again.",
                  })
                  return
                }
                //success - load the user.
                successFunc()
              })
            })
          })
        } else {
          API.manualLogin(pn, data.code + "_" + this.state.pnState, (result) => {
            if ("error" in result) {
              console.log("Manual Login Error", result)
              //show an error for an invalid code.
              this.setState({
                subloading: false,
                suberror: "Invalid code, please try again.",
              })
              return
            }
            //success - load the user.
            successFunc()
          })
        }
      }
    })
  }

  updateName(data = {}) {
    if (data.name && data.name.length > 0) {
      //Update the name.
      let parts = data.name.split(" ")
      let shortName = parts[0]
      let dd = {
        name: data.name,
        shortName: shortName,
      }
      API.callDarwinAPI("PUT", "userProfile", dd, (result) => {
        if ("error" in result) {
          console.log("Couldn't update user", result)
          return
        }
        console.log("Updated user name to:", dd.name)

        //refresh the user's data with the new name.
        this.props.loadUserInfo()
      })
    }
  }

  attemptPayment(data = {}) {
    //0) Set up the callback to attempt payment.
    //1) If name is set inside data, then update the name.
    if (data.name && data.name.length > 0) {
      //Update the name.
      this.setState({
        subloading: true,
        suberror: false,
      }, () => {
        let parts = data.name.split(" ")
        let shortName = parts[0]
        let dd = {
          name: data.name,
          shortName: shortName,
        }
        API.callDarwinAPI("PUT", "userProfile", dd, (result) => {
          if ("error" in result) {
            console.log("Couldn't update user", result)
            //show error
            this.setState({
              subloading: false,
              suberror: "Internal error, please try again.",
            })
            return
          }
          this.attemptPayment2()
        })
      })
    } else {
      this.attemptPayment2()
    }
  }

  attemptPayment2() {
    console.log("attempting payment")
    //2.1) Check if Free and make purchase immediately.
    let priceCents = Helpers.geojiCartPrice(this.state.geoji)
    console.log("total price", priceCents)
    if (priceCents === 0) {
      //Make free purchase
      let shoppingCart = Helpers.getTokenJSONwithCart(this.state.geoji)
      let dd = {
        amountCents: priceCents,
        type: "Free",
        tokens: JSON.stringify(shoppingCart),
        device: "website",
        redirect: window.location.hostname === "localhost" ? "localhost" : (window.location.hostname === "www.geoji.com" ? "www" : "geoji"),
      }
      let pls = API.getCookie("promoterLinks")
      try {
        pls = JSON.parse(pls)
      } catch (e) {
        pls = {}
      }
      if (pls[this.state.geoji.geojiID]) {
        dd["promoterLinkID"] = pls[this.state.geoji.geojiID]
      }

      API.callDarwinAPI("POST", "stripePaymentIntent/" + this.state.geoji.geojiID, dd, (result) => {
        if ("error" in result) {
          console.log("Couldn't get stripe payment intent free", result)
          //log error
          API.logIssue("Stripe Payment Intent", {
            "result": result,
            "data": dd,
            "geojiID": this.state.geoji.geojiID
          })
          //show error
          let title = "Internal Error"
          let description = "Couldn't checkout, please try again"
          if (result.error.issue && (result.error.issue === "invalid seat" || result.error.issue === "seat not for sale")) {
            title = "Seat " + result.error.seat + " is no longer available."
            description = "Please choose a different seat."
          }
          this.showConfirmation(title, description, "ok", "", (res) => {
            this.backHome()
          })
          return
        }
        console.log("Free Payment Complete", result)
        //What to do once we have completed payment!
        this.goToSuccessPage()
      })
    } else {
      //2.2) Go to the credit card screen
      this.showCreditCardScreen()
    }
  }

  showCreditCardScreen() {
    this.setState({
      view: "Checkout",
      subview: "Home",
      subloading: true,
      suberror: false,
    }, () => {
      //Load the credit card if there is one.
      API.callDarwinAPI("GET", "userCreditCard", {}, (result) => {
        if ("error" in result) {
          console.log("Couldn't get user credit card", result)
          this.setState({
            card: false,
          }, () => {
            this.getCreditCardPaymentIntent()
          })
        } else {
          console.log("User credit card.", result)
          this.setState({
            card: (result.data && result.data.card) ? result.data.card : false,
          }, () => {
            this.getCreditCardPaymentIntent()
          })
        }
      })
    })
  }

  getCreditCardPaymentIntent() {
    let priceCents = Helpers.geojiCartPrice(this.state.geoji)
    let shoppingCart = Helpers.getTokenJSONwithCart(this.state.geoji)
    this.setState({
      amazonPayButtonSettings: undefined,
      payPalClientID: undefined,
    }, () => {
      let dd = {
        amountCents: priceCents,
        type: "Credit Card",
        tokens: JSON.stringify(shoppingCart),
        amazonSupport: 1,
        payPalSupport: 1,
        device: "website",
        redirect: window.location.hostname === "localhost" ? "localhost" : (window.location.hostname === "www.geoji.com" ? "www" : "geoji"),
      }
      let pls = API.getCookie("promoterLinks")
      try {
        pls = JSON.parse(pls)
      } catch (e) {
        pls = {}
      }
      if (pls[this.state.geoji.geojiID]) {
        dd["promoterLinkID"] = pls[this.state.geoji.geojiID]
      }

      API.callDarwinAPI("POST", "stripePaymentIntent/" + this.state.geoji.geojiID, dd, (result) => {
        if ("error" in result) {
          console.log("Couldn't get payment intent", result)
          //log error
          API.logIssue("Stripe Payment Intent", {
            "result": result,
            "data": dd,
            "geojiID": this.state.geoji.geojiID
          })

          //show error
          let ee = (result.error.issue && result.error.issue.length > 0) ? result.error.issue : "Error Checking Out"
          let ed = ((result.error.tokenName && result.error.tokenName.length > 0) || typeof result.error.tokenName === "number") ? result.error.tokenName : false
          this.updateSubloading(false, ee, ed)
          return
        }
        console.log("Stripe Payment Intent:", result)

        //create the hidden amazon button if necessary
        if (window.amazon !== undefined && result.data.amazonPayButtonSettings !== undefined) {
          //Render the amazon button in a hidden box.
          window.amazon.Pay.renderButton("#AmazonPayHiddenBox", result.data.amazonPayButtonSettings)
        }

        this.setState({
          subloading: false,
          suberror: false,
          paymentIntent: result.data,
          purchase: {
            id: result.data.purchaseID,
            purchaseTimestamp: new Date(),
            totalCents: priceCents,
          },
          amazonPayButtonSettings: (window.amazon !== undefined && result.data.amazonPayButtonSettings !== undefined) ? result.data.amazonPayButtonSettings : undefined,
          payPalClientID: result.data.payPalClientID,
        })
      })
    })
  }

  payPalCreateOrder(data, actions) {
    console.log(data, actions)
    return new Promise((resolve, reject) => {
      //Call the Darwin API to create the order.
      API.callDarwinAPI("POST", "payPalCreateOrder/" + this.state.purchase.id, {}, (result) => {
        if ("error" in result) {
          console.log("Couldn't create PayPal order", result)
          //log error
          API.logIssue("PayPal Create Order", {
            "result": result,
            "purchase": this.state.purchase,
            "geojiID": this.state.geoji.geojiID
          })
          //show error
          reject("There was an issue checking out with PayPal")
          return
        }
        console.log("PayPal Create Order", result.data.id)
        resolve(result.data.id)
      })
    })
  }

  payPalOnApprove(data, actions) {
    console.log("payPalOnApprove orderID", data.orderID)
    return new Promise((resolve, reject) => {
      this.setState({
        purchaseLoading: true
      }, () => {
        let dd = {
          orderID: data.orderID
        }
        API.callDarwinAPI("POST", "payPalCaptureOrder/" + this.state.purchase.id, dd, (result) => {
          if ("error" in result) {
            console.log("Couldn't capture PayPal order", result)
            //log error
            API.logIssue("PayPal Capture Order", {
              "result": result,
              "purchase": this.state.purchase,
              "data": dd,
              "geojiID": this.state.geoji.geojiID
            })
            reject("There was an issue capturing the order with PayPal")
            //show error
            this.setState({
              suberror: "There was an issue with PayPal. Please try again.",
              purchaseLoading: false
            })
            return
          }
          console.log("PayPal Capture Order", result.data)
          //show the success method.
          this.setState({
            purchaseLoading: false
          }, () => {
            this.goToSuccessPage(this.state.purchase.id)
            resolve("success")
          })
        })
      })
    })
  }

  checkoutWithMethod(method) {
    switch (method) {
      case "AmazonPay":
        //render the amazon pay button - then press the button.
        if (window.amazon !== undefined && this.state.amazonPayButtonSettings !== undefined) {
          //Start the checkout with Amazon.
          window.amazon.Pay.initCheckout(this.state.amazonPayButtonSettings);
        }
        break;
      default:
        console.error("Unhandled Checkout Method: ", method)
        break;
    }
  }

  backHome() {
    this.setState({
      view: "Home",
      subview: "Home",
      subloading: false,
      suberror: false,
    }, () => {
      //reload the data for this user.
      this.calculateView()
    })
  }

  updateSubloading(newValue, newError, newErrorDescription = false) {

    this.setState({
      subloading: newValue,
      suberror: newError,
    }, () => {
      if (newError !== false) {
        console.log("newError", newValue, newError, newErrorDescription)
        let title = "Internal Error"
        let description = "Couldn't checkout, please try again"
        let goBackHome = true
        let reloadAndEmptyCart = false
        if (newError === "Your card was declined.") {
          title = "Card Declined"
          description = "Check your credit card details and try again."
          goBackHome = false
          reloadAndEmptyCart = false
        } else if (newError === "invalid seat" || newError === "seat not for sale") {
          title = "Seat is no longer available."
          description = "Please choose a different seat."
          goBackHome = false
          reloadAndEmptyCart = true
        } else if (newError === "out of tokens") {
          title = "Sold Out"
          description = "The supply has run out. Try buying something else or contact the creator for more information."
          if (newErrorDescription !== false && typeof newErrorDescription === 'string') {
            title = newErrorDescription + " Sold Out"
          }
          goBackHome = false
          reloadAndEmptyCart = true
        } else if (newError === "Purchase Limit") {
          if (newErrorDescription <= 0) {
            title = "You have reached your limit."
            description = "You can only get a certain number of these items and you have reached your limit. If you are checking out for a friend, have them checkout instead."
          } else {
            title = "Over your limit."
            description = "You can only get " + newErrorDescription + " more of these items. Please reduce the quantity and try again."
          }
          if (newErrorDescription !== false && typeof newErrorDescription === 'string') {
            title = newErrorDescription + " Sold Out"
          }
          goBackHome = false
          reloadAndEmptyCart = true
        } else if (newError === "Token Frozen" || newError === "Token No Longer For Sale") {
          title = "No Longer For Sale"
          description = "The supply has run out. Try buying something else or contact the creator for more information."
          if (newErrorDescription !== false && typeof newErrorDescription === 'string') {
            title = newErrorDescription + " No Longer For Sale"
          }
          goBackHome = false
          reloadAndEmptyCart = true
        } else if (newError === "Mismatch of amountCents and calculated value") {
          title = "Price Changed"
          description = "The price has changed for these items. Please try checking out again."
          goBackHome = false
          reloadAndEmptyCart = true
        }
        this.showConfirmation(title, description, "ok", "", (res) => {
          if (goBackHome) {
            this.backHome()
          } else if (reloadAndEmptyCart) {
            //reload the geoji and empty the cart.
            this.setState({
              loading: true,
              geoji: {},
            }, () => {
              this.backHome()
            })
          }
        })
      }
    })
  }

  goToSuccessPage(purchaseID) {

    let ppid = this.state.purchase && this.state.purchase.id
    if (purchaseID !== undefined && purchaseID !== false) {
      ppid = purchaseID
    }
    console.log("purchaseID", purchaseID, ppid)
    this.setState((old) => {
      let newGeoji = old.geoji
      //clear the cart
      let cartPrice = Helpers.geojiCartPrice(old.geoji)
      for (let i = 0; i < newGeoji.tokens.length; i = i + 1) {
        newGeoji.tokens[i].cart = 0
      }

      return {
        geoji: newGeoji,
        purchase: {
          id: ppid,
          purchaseTimestamp: new Date(),
          totalCents: cartPrice,
        },
      }
    }, () => {
      this.props.updatePurchaseID(ppid.toString())
      //this.viewPurchase(this.state.purchase)
    })
  }

  editCard() {
    this.setState({
      card: false,
      suberror: false,
    })
  }

  backToPhoneNumber() {
    this.setState({
      view: "Login",
      subview: "Phone",
      data: {},
      subloading: false,
      suberror: false,
    })
  }

  viewPurchase(purchase) {
    //console.log("View Purchase", purchase)
    this.setState({
      loading: false,
      view: "Purchase",
      subview: "Home",
      subloading: true,
      suberror: false,
      purchase: purchase,
      notesSavedCount: 0,
      nameSavedCount: 0,
      data: {
        name: {
          value: (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.name) ? this.props.userInfo.user.name : "",
          valid: true,
        },
        notes: {
          value: "",
          valid: true,
        },
      },
    }, () => {
      //refresh the user's data with the new name.
      this.props.loadUserInfo(() => {
        //Load this purchase
        API.callDarwinAPI("GET", "purchase/" + purchase.id, {}, (result) => {
          //console.log("result", result)
          if ("error" in result) {
            console.log("Error GET purchase", result)
            if (result.error === "not logged in") {
              //not logged in - show the login page.
              console.log("Not Logged In - Show The Login View.")
              this.setState({
                view: "Login",
                subview: "Phone",
                checkout: false,
                viewPurchase: true,
                data: {},
                subloading: false,
                suberror: false,
              })
            } else {
              //no access - show no access error
              console.log("No Access - show no access error")
              this.setState({
                subloading: false,
                suberror: "No Access",
              })
            }
            return
          }
          console.log("GET purchase", result.data.geoji.purchase)
          let ppr = result.data.geoji.purchase
          ppr.totalCents = parseInt(ppr.totalCents)
          ppr.waiversSigned = parseInt(ppr.waiversSigned)
          ppr.waiversRequired = parseInt(ppr.waiversRequired)
          if (ppr.tokens) {
            for (let tt = 0; tt < ppr.tokens.length; tt = tt + 1) {
              if (ppr.tokens[tt].details && ppr.tokens[tt].details.length > 0) {
                ppr.tokens[tt].details = JSON.parse(ppr.tokens[tt].details)
              }
            }
          }

          this.setState((old) => {
            let newData = Object.assign({}, old.data)
            newData.notes = {
              value: ppr.notes ? ppr.notes : "",
              valid: true,
            }
            newData.name = {
              value: this.props.userInfo.user.name,
              valid: true,
            }

            let ddreturn = {
              purchase: ppr,
              subloading: true,
              suberror: false,
              data: newData,
            }

            //make sure we have an email if required.
            if (result.data.geoji.sendEmailReceipt === "1" && (!this.props.userInfo.user.email || this.props.userInfo.user.email.length === 0)) {
              //we need an email address.
              ddreturn.subview = "Email"
            } else if (result.data.geoji.notesRequired === "1" && (!ppr.notes || ppr.notes.length === 0)) {
              //we need to fill out the notes
              ddreturn.subview = "Email"
            } else if (result.data.geoji.showInstagram === "1" && (!this.props.userInfo.user.instagramUsername || this.props.userInfo.user.instagramUsername.length === 0) && ppr.skipInstagram === "0") {
              //we need an instagram address.
              ddreturn.subview = "Email"
            } else if (ppr.waiversSigned < ppr.waiversRequired) {
              //we need to sign some waivers.
              let nextWaiver = Helpers.getNextWaiver(ppr)
              if (nextWaiver !== false) {
                ddreturn.waiver = nextWaiver
                ddreturn.subview = "Waiver"

                ddreturn.waiverParticipant = "Participant " + (ppr.waiversSigned + 1) + "/" + ppr.waiversRequired

                //set the name field if necessary
                if (ppr.waiversSigned === 0) {
                  //set the name.
                  ddreturn.data.name = {
                    value: this.props.userInfo.user.name,
                    valid: true
                  }
                } else {
                  //set name to be blank.
                  ddreturn.data.name = {
                    value: "",
                    valid: false
                  }
                }
              }
            }

            return ddreturn
          }, () => {

            window.scrollTo(0, 0)

            let link = "/g/" + this.props.geojiLink + "/" + purchase.id
            if (this.props.geojiAccess === "private") {
              link = "/p/" + this.props.geojiLink + "/" + purchase.id
            }
            Helpers.updateWindowPath(link)

            setTimeout(() => {
              this.setState({
                subloading: false
              })
            }, 1000)
          })
        })
      }, true)
    })
  }

  waiverAgree() {
    let dataName = (this.state.data && this.state.data.name) ? this.state.data.name.value : ""
    if (dataName.length === 0) {
      //name not set - show an error
      this.setState({
        suberror: "Fill out the name field.",
        subloading: false,
      })
      return
    }
    console.log("Agree to the waiver")

    //1) Show loading in place of the I Agree button.
    this.setState({
      subloading: true,
      suberror: false,
    }, () => {
      //2) Call API to agree to the waiver.
      let data = {
        waiverID: this.state.waiver.id,
        name: dataName
      }
      API.callDarwinAPI("POST", "signWaiver/" + this.state.purchase.id, data, (result) => {
        if ("error" in result) {
          console.log("Error Signing Waiver", result)
          this.setState({
            subloading: false,
            suberror: "Couldn't sign waiver. Please try again.",
          })
          return
        }

        //3) Call the viewPurchase again.
        this.viewPurchase(this.state.purchase)
      })
    })
  }

  saveEmail(data) {
    console.log("saveEmail", data)
    this.setState({
      subloading: true
    }, () => {
      let dd = {
        name: this.props.userInfo.user.name,
        shortName: this.props.userInfo.user.shortName,
      }
      if (data.email && data.email.length > 0) {
        dd["email"] = data.email
      }
      let shouldSkipInstagram = false
      if (data.instagramUsername && data.instagramUsername.length > 0) {
        let iu = data.instagramUsername
        //remove the @ symbol if necessary.
        if (iu.startsWith("@")) {
          iu = iu.substr(1)
        }
        dd["instagramUsername"] = iu
      } else {
        //need to skip instagram for this purchase.
        shouldSkipInstagram = true
      }
      API.callDarwinAPI("PUT", "userProfile", dd, (result) => {
        if ("error" in result) {
          console.log("Error Updating User Profile", result)
          this.showError("Error", "Couldn't update your information", result)
          this.setState({
            subloading: false,
          })
          return
        }

        //Update the notes & skip instagram if necessary.
        if ((data.notes && data.notes.length > 0) || shouldSkipInstagram) {
          //Update the notes for this purchase.
          let dd2 = {
            notes: data.notes || ""
          }
          if (shouldSkipInstagram) {
            dd2["skipInstagram"] = "1"
          }
          API.callDarwinAPI("PUT", "purchase/" + this.state.purchase.id, dd2, (result2) => {
            if ("error" in result2) {
              console.log("Error Updating Purchase", result2, dd2)
              this.showError("Error", "Couldn't update the purchase", result2)
              return
            }
            console.log("Updated Purchase Notes & skipInstagram")
            //Call the viewPurchase again.
            this.viewPurchase(this.state.purchase)
          })
        } else {
          //Call the viewPurchase again.
          this.viewPurchase(this.state.purchase)
        }
      })
    })
  }

  goToLogin(callback = null) {
    this.setState({
      view: "Login",
      subview: "Phone",
      checkout: false,
      viewPurchase: false,
      data: {},
      subloading: false,
      suberror: false,
      loginCallback: callback,
    }, () => {
      //scroll to the top of the screen in case we need to.
      window.scrollTo(0, 0)
    })
  }

  saveNotes(data) {
    console.log("saveNotes", data)
    if (data.name && data.name !== this.props.userInfo.user.name) {
      //save the new name for this user.
      console.log("Save the name of this user.", data)
      let nameParts = data.name.split(" ")
      let shortName = data.name
      if (nameParts.length > 0) {
        shortName = nameParts[0]
      }
      let dd = {
        name: data.name,
        shortName: shortName,
      }
      API.callDarwinAPI("PUT", "userProfile", dd, (result) => {
        if ("error" in result) {
          console.log("Error Updating User Profile", result)
          this.showError("Error", "Couldn't update your information", result)
          return
        }
        console.log("Updated User Profile")
        this.props.loadUserInfo()
        //update name saved count
        this.setState((old) => {
          return {
            nameSavedCount: old.nameSavedCount + 1
          }
        })
      })
    }
    if ((data.notes && data.notes !== (this.state.purchase.notes || "")) || (!data.notes && (this.state.purchase.notes || "").length > 0)) {
      //save the user's notes
      let dd = {
        notes: data.notes || "",
      }
      API.callDarwinAPI("PUT", "purchase/" + this.state.purchase.id, dd, (result) => {
        if ("error" in result) {
          console.log("Error Updating Purchase Notes", result)
          this.showError("Error", "Couldn't update the notes", result)
          return
        }
        console.log("Updated Purchase Notes")
        this.setState((old) => {
          let pp = old.purchase
          pp.notes = dd.notes

          return {
            purchase: pp,
            notesSavedCount: old.notesSavedCount + 1
          }
        })
      })
    }
  }

  showAllPurchases() {
    this.setState({
      showAllPurchases: true
    })
  }

  showAllTokens() {
    this.setState({
      focusToken: false
    })
  }

  tokenChanged(token, name, value, valid) {
    console.log(token, name, value, valid)
    if (token.soldOut) {
      return
    }
    let newValue = 0
    try {
      newValue = parseInt(value)
      if (isNaN(newValue)) {
        newValue = 0
      }
    } catch (e) {
      newValue = 0
    }
    //max value of $100,000
    newValue = Math.max(0, Math.min(newValue, 100000))
    //apply purchase limit if necessary
    if (token.purchaseLimit > 0) {
      newValue = Math.min(newValue, token.purchaseLimit)
    }
    this.setState((old) => {
      let gg = old.geoji
      for (let i = 0; i < gg.tokens.length; i = i + 1) {
        if (gg.tokens[i].id === token.id) {
          gg.tokens[i].cart = newValue
        }
      }
      return {
        geoji: gg,
        tokenIncrement: old.tokenIncrement + 1
      }
    })
  }

  /**
  Shows the waiver and the details about when it was signed.
  */
  viewWaiver(signed) {
    let waiver = {}
    for (let i = 0; i < this.state.purchase.waivers.length; i = i + 1) {
      if (this.state.purchase.waivers[i].id === signed.waiverID) {
        waiver = this.state.purchase.waivers[i]
        break;
      }
    }
    this.setState({
      subview: "ViewWaiver",
      waiver: waiver,
      signedWaiver: signed,
    }, () => {
      window.scrollTo(0, 0)
    })
  }

  /**
  Dismiss the waiver and show the purchase.
  */
  dismissWaiver() {
    this.setState({
      subview: "Home"
    })
  }

  /**
  Shows the Admin dashboard.
  */
  viewAdminDashboard(callback = false) {
    this.setState({
      view: "Admin",
      subview: "Dashboard",
      loading: false,
      subloading: false,
      suberror: false,
    }, () => {
      window.scrollTo(0, 0)
      this.refreshGeoji()
      //update the path - if necessary
      if (callback === false) {
        let link = "/g/" + this.props.geojiLink + "/dashboard"
        if (this.props.geojiAccess === "private") {
          link = "/p/" + this.props.geojiLink + "/dashboard"
        }
        Helpers.updateWindowPath(link)
      } else {
        callback()
      }
    })
  }

  /**
  Dismisses the Admin View and goes back to the Geoji View.
  */
  dismissAdminView() {
    this.setState({
      view: "Home",
      subview: "Home",
    }, () => {
      //update the path.
      let link = "/g/" + this.props.geojiLink
      if (this.props.geojiAccess === "private") {
        link = "/p/" + this.props.geojiLink
      }
      Helpers.updateWindowPath(link)
    })
  }

  /**
  Regets and refreshes the Geoji for the Admin Dashboard.
  */
  refreshGeoji() {
    API.callDarwinAPI("GET", "geoji/" + this.state.geoji.geojiID, {}, (result) => {
      if ("error" in result) {
        console.log("Error Refresh Geoji", result)
        this.setState({
          geoji: {},
          error: "Could not get the updated Geoji Information.",
        })
        return
      }
      let geo = Helpers.formatGeoji(result.data.geoji)
      console.log("Refreshed Geoji", geo)

      this.setState({
        geoji: geo,
        error: false,
      })
    })
  }

  /**
  Copies the link for this Geoji to the clipboard.
  */
  copyAdminLink() {
    let block = (copyLink) => {
      this.setState({
        copyingAdminLink: true,
        copyAdminLink: undefined,
      }, () => {
        //Copy the link
        Helpers.copyToClipboard(copyLink)

        setTimeout(
          () => this.setState({ copyingAdminLink: false }),
          1000
        );
      })
    }

    let linkString = "https://geoji.com/g/" + this.state.geoji.geojiID

    let ptitle = this.state.geoji.title
    ptitle = ptitle.replace(/ /g, "-");
    ptitle = ptitle.replace(/[^0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-]/g, '');

    linkString = "https://geoji.com/g/" + ptitle + "-" + this.state.geoji.geojiID
    while (true) {
      let newString = linkString.replace(/--/g, "-");
      if (newString === linkString) {
        break
      }
      linkString = newString
    }

    if (this.state.geoji.private === "1" && !this.state.copyAdminLink) {
      //create private link
      API.callDarwinAPI("GET", "geojiLink/" + this.state.geoji.geojiID, {}, (result) => {
        if ("error" in result) {
          console.log("Error Creating Link", result)
          this.setState({
            error: "Could not create the private link.",
          })
          return
        }
        this.setState({
          copyAdminLink: result.data.link,
        })
      })
    } else if (this.state.geoji.private === "1" && this.state.copyAdminLink) {
      block(this.state.copyAdminLink)
    } else {
      block(linkString)
    }
  }

  /**
  Opens the redemption view for this type of redemption.
  Pass in AZ, Queue, Mass, ...
  */
  openRedemptions(type) {
    if (type === "AZ") {
      this.setState((oldData) => {
        oldData.data.adminSearch = {
          value: "",
          valid: true
        }

        return {
          data: oldData,
          subview: "AZRedemptions",
          adminPurchases: [],
        }
      }, () => {
        window.scrollTo(0, 0)
        this.loadOrders("name", "", 0)

        if (this.adminListAZEnd.current) {
          //setup the observer if possible
          if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries) => {
              let entry = entries[0]
              if (entry.isIntersecting) {
                //load more orders
                if (!this.state.loadingAdminOrders) {
                  let searchVal = ""
                  if (this.state.data && this.state.data.adminSearch && this.state.data.adminSearch.value) {
                    searchVal = this.state.data.adminSearch.value
                  }
                  this.loadOrders("name", searchVal, this.state.adminOrdersPage + 1)
                }
              }
            }, {
              root: null,
              rootMargin: "0px",
              threshold: 1.0,
            })
            observer.observe(this.adminListAZEnd.current)
          } else {
            console.log("No IntersectionObserver found")
          }
        }

        let link = "/g/" + this.props.geojiLink + "/dashboard_az"
        if (this.props.geojiAccess === "private") {
          link = "/p/" + this.props.geojiLink + "/dashboard_az"
        }
        Helpers.updateWindowPath(link)
      })
    } else if (type === "Queue") {
      this.setState((oldData) => {
        oldData.data.adminSearch = {
          value: "",
          valid: true
        }

        return {
          data: oldData,
          subview: "QueueRedemptions",
          adminPurchases: [],
        }
      }, () => {
        window.scrollTo(0, 0)
        this.loadOrders("queue", "", 0)

        if (this.adminListQueueEnd.current) {
          //setup the observer
          if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries) => {
              let entry = entries[0]
              if (entry.isIntersecting) {
                //load more orders
                let searchVal = ""
                if (this.state.data && this.state.data.adminSearch && this.state.data.adminSearch.value) {
                  searchVal = this.state.data.adminSearch.value
                }
                this.loadOrders("queue", searchVal, this.state.adminOrdersPage + 1)
              }
            }, {
              root: null,
              rootMargin: "0px",
              threshold: 1.0,
            })
            observer.observe(this.adminListQueueEnd.current)
          } else {
            console.log("No IntersectionObserver found")
          }
        }

        let link = "/g/" + this.props.geojiLink + "/dashboard_queue"
        if (this.props.geojiAccess === "private") {
          link = "/p/" + this.props.geojiLink + "/dashboard_queue"
        }
        Helpers.updateWindowPath(link)
      })
    } else {
      console.log("Unhandled openRedemptions", type)
    }
  }

  /**
  Loads the orders provided the sort (name, queue), search, loadPage - usually 0, and limit.
  */
  loadOrders(sort, search, loadPage, limit = 100) {
    console.log("loadOrders", sort, search, loadPage, limit)
    if (loadPage !== 0 && !this.state.morePurchasesToLoad) {
      console.log("Nothing more to load")
      return
    }
    let loadIter = 0
    this.setState((oldStuff) => {
      loadIter = (oldStuff.loadingAdminOrdersIter ? oldStuff.loadingAdminOrdersIter : 0) + 1
      return {
        loadingAdminOrdersIter: loadIter,
        loadingAdminOrders: true,
        adminOrdersPage: loadPage,
      }
    }, () => {
      let data = {
        "sort": sort,
        "page": loadPage,
        "limit": limit,
      }
      if (search && search.length > 0) {
        data.search = search
      }
      API.callDarwinAPI("GET", "purchases/" + this.state.geoji.geojiID, data, (result) => {
        if ("error" in result) {
          console.log("Error Get Purchases", result)
          this.setState({
            error: "Could not get the purchases.",
            loadingAdminOrders: false,
          })
          return
        }

        this.setState((oldState) => {
          let lastLoaded = oldState.lastLoadedAdminOrdersIter ? oldState.lastLoadedAdminOrdersIter : 0
          if (loadIter <= lastLoaded) {
            //discard this result as it is old.
            return {}
          }

          let newPurchases = oldState.adminPurchases
          if (loadPage === 0) {
            //set the purchases.
            newPurchases = result.data.purchases
          } else {
            //append the new purchases.
            newPurchases = newPurchases.concat(result.data.purchases)
          }
          console.log(newPurchases)

          return {
            error: false,
            loadingAdminOrders: false,
            adminPurchases: newPurchases,
            morePurchasesToLoad: result.data.purchases.length === limit,
            lastLoadedAdminOrdersIter: loadIter,
          }
        })
      })
    })
  }

  /**
  Tip was tapped.
  */
  tipTapped(tip) {
    console.log("Tip Tapped", tip)
  }

  /**
  Opens the order details for the provided order.
  */
  viewOrderDetails(order) {
    this.setState((oldData) => {
      return {
        subview: "OrderDetails",
        adminOrder: order,
        adminOldView: oldData.subview,
      }
    }, () => {
      this.loadOrderDetails()
      window.scrollTo(0, 0)
    })
  }

  /**
  Load the order details from the API
  */
  loadOrderDetails() {
    console.log("Load Order Details")
    this.setState({
      adminOrderLoading: true,
      changingTicketDate: false,
    }, () => {
      API.callDarwinAPI("GET", "adminPurchase/" + this.state.adminOrder.purchase.id, {}, (result) => {
        if ("error" in result) {
          console.log("Error Get Admin Purchase", result, this.state.adminOldView)
          //go back to the redemption list.
          this.setState({
            adminOrderLoading: false,
          }, () => {
            if (this.state.adminOldView === "AZRedemptions") {
              this.props.updatePurchaseID("dashboard_az")
            } else if (this.state.adminOldView === "QueueRedemptions") {
              this.props.updatePurchaseID("dashboard_queue")
            }
          })
          return
        }
        let newOrder = result.data.user
        //format the order
        for (let i = 0; i  < newOrder.purchase.tokens.length; i = i + 1) {
          newOrder.purchase.tokens[i].itemPriceCents = parseInt(newOrder.purchase.tokens[i].itemPriceCents)
          newOrder.purchase.tokens[i].quantity = parseInt(newOrder.purchase.tokens[i].quantity)
          newOrder.purchase.tokens[i].redeemed = parseInt(newOrder.purchase.tokens[i].redeemed)
          newOrder.purchase.tokens[i].totalPriceCents = parseInt(newOrder.purchase.tokens[i].totalPriceCents)
        }
        console.log(newOrder)
        this.setState({
          adminOrderLoading: false,
          adminOrder: newOrder
        }, () => {
          let obs = ""
          if (this.state.adminOldView === "QueueRedemptions") {
            obs = "q"
          }
          let link = "/g/" + this.props.geojiLink + "/dashboard_" + obs + newOrder.purchase.id
          if (this.props.geojiAccess === "private") {
            link = "/p/" + this.props.geojiLink + "/dashboard_" + obs + newOrder.purchase.id
          }
          Helpers.updateWindowPath(link)
        })
      })
    })
  }

  /**
  Close the order details and go back to the previous view.
  */
  backFromOrderDetails() {
    console.log("back from order details", this.state.adminOldView)
    if (this.state.adminOldView === "AZRedemptions") {
      this.props.updatePurchaseID("dashboard_az")
      //this.openRedemptions("AZ")
    } else if (this.state.adminOldView === "QueueRedemptions") {
      this.props.updatePurchaseID("dashboard_queue")
      //this.openRedemptions("Queue")
    }
  }

  /**
  Redeems the order token - pass in false to redeem/unredeem all.
  */
  adminRedeemOrderToken(token) {
    console.log("adminRedeemOrderToken", token)
    if (this.state.adminOrder.purchase.status === "Refunded") {
      //can't change redemption on refunded orders.
      return
    }
    let data = {}
    if (token === false) {
      //redeem / unredeem all.
      if (this.state.adminOrder.purchase.redeemed === "1") {
        //unredeem all.
        data.quantity = 0
        this.setState((oldState) => {
          let nao = oldState.adminOrder
          nao.purchase.redeemed = "0"
          if (!nao.purchase.tokens || !Array.isArray(nao.purchase.tokens)) {
            nao.purchase.tokens = []
          }
          for (let i = 0; i < nao.purchase.tokens.length; i = i + 1) {
            nao.purchase.tokens[i].redeemed = 0
          }
          return {
            adminOrder: nao
          }
        })
      } else {
        //redeem all.
        data.quantity = 1
        this.setState((oldState) => {
          let nao = oldState.adminOrder
          nao.purchase.redeemed = "1"
          if (!nao.purchase.tokens || !Array.isArray(nao.purchase.tokens)) {
            nao.purchase.tokens = []
          }
          let today = new Date()
          nao.purchase.redemptionTimestamp = Helpers.convertDateToStamp(today)
          for (let i = 0; i < nao.purchase.tokens.length; i = i + 1) {
            nao.purchase.tokens[i].redeemed = nao.purchase.tokens[i].quantity
          }
          console.log("nao", nao)
          return {
            adminOrder: nao
          }
        })
      }
    } else {
      //redeem an indi token.
      let toks = this.state.adminOrder.purchase.tokens
      if (!Array.isArray(toks)) {
        toks = []
      }
      for (let i = 0; i < toks.length; i = i + 1) {
        if (toks[i].id === token.id) {
          if (token.theme === "tipjar") {
            if (token.checked) {
              //uncheck all
              data.quantity = 0
            } else {
              //check all
              data.quantity = token.quantity
            }
          } else {
            if (token.checked) {
              //decrement
              data.quantity = Math.max(0, token.redeemed - 1)
            } else {
              //increment
              data.quantity = Math.min(token.quantity, token.redeemed + 1)
            }
          }
          data.tokenID = token.id

          //now update the state
          this.setState((oldState) => {
            let nao = oldState.adminOrder
            if (!nao.purchase.tokens || !Array.isArray(nao.purchase.tokens)) {
              nao.purchase.tokens = []
            }
            let today = new Date()
            nao.purchase.redemptionTimestamp = Helpers.convertDateToStamp(today)
            for (let i = 0; i < nao.purchase.tokens.length; i = i + 1) {
              if (nao.purchase.tokens[i].id === token.id) {
                nao.purchase.tokens[i].redeemed = data.quantity
              }
            }
            //update the redeemed status
            nao.purchase.redeemed = "1"
            for (let i = 0; i < nao.purchase.tokens.length; i = i + 1) {
              if (nao.purchase.tokens[i].redeemed !== nao.purchase.tokens[i].quantity) {
                nao.purchase.redeemed = "0"
                break
              }
            }
            return {
              adminOrder: nao
            }
          })
        }
      }
    }

    API.callDarwinAPI("POST", "redeemPurchase/" + this.state.adminOrder.purchase.id, data, (result) => {
      if ("error" in result) {
        console.log("Error Redeem Purchase", result)
        this.setState({
          error: "Could not redeem the purchase.",
        })
        return
      }
      console.log("redeemed", result)
    })
  }

  /**
  Redeems the provided order or unredeems if possible.
  */
  adminRedeemOrderFromQueue(order, event) {
    console.log(order, event)
    if (event.stopPropagation) {
      event.stopPropagation();   // W3C model
    }
    this.setState({
      adminOrder: order
    }, () => {
      this.adminRedeemOrderToken(false)
    })
  }

  /**
  Refund this customer's purchase then reload the data.
  */
  adminRefundPurchase() {
    //show confirmation box.
    this.showConfirmation("Refund Customer", "Are you sure you want to refund this customer. This action is not undoable.", "Refund", "Cancel", (refund) => {
      if (refund) {
        //Refund the purchase - then reload the order.
        this.setState({
          adminOrderLoading: true
        }, () => {
          API.callDarwinAPI("POST", "refundPurchase/" + this.state.adminOrder.purchase.id, {}, (result) => {
            if ("error" in result) {
              console.log("Error Refund Purchase", result)
              this.setState({
                adminOrderLoading: false
              })
              if (result.error === "already paid out") {
                this.showError("Could not refund the purchase", "You have already been paid out for this user. Please reach out to support@geoji.com for details on how to resolve this issue.")
              } else {
                this.showError("Could not refund the purchase", "Please check your internet connection and try again. Reach out to support@geoji.com if this problem continues to happen.")
              }
              return
            }
            console.log("refund", result)
            this.loadOrderDetails()
          })
        })
      }
    })
  }

  /**
  Refund this customer's purchase then reload the data.
  */
  adminTextCustomer() {
    //Open text link.
    if (this.state.adminOrder.phoneNumber && this.state.adminOrder.phoneNumber.length > 0) {
      let link = "sms://" + this.state.adminOrder.phoneNumber
      window.location.href = link
    } else if (this.state.adminOrder.tempPhoneNumber && this.state.adminOrder.tempPhoneNumber.length > 0) {
      let link = "sms://" + this.state.adminOrder.tempPhoneNumber
      window.location.href = link
    }
  }

  /**
  Refund this customer's purchase then reload the data.
  */
  adminCallCustomer() {
    //Open call link.
    if (this.state.adminOrder.phoneNumber && this.state.adminOrder.phoneNumber.length > 0) {
      let link = "tel://" + this.state.adminOrder.phoneNumber
      window.location.href = link
    } else if (this.state.adminOrder.tempPhoneNumber && this.state.adminOrder.tempPhoneNumber.length > 0) {
      let link = "tel://" + this.state.adminOrder.tempPhoneNumber
      window.location.href = link
    }
  }

  /*
  * Shows a confirmation dialog with an optional callback passing true or false
  * to whether they have accepted the action
  */
  showConfirmation(title, description, accept, deny, action) {
    this.setState({
      confirmation: true,
      confirmationTitle: title,
      confirmationDescription: description,
      confirmationAccept: accept,
      confirmationDeny: deny,
      confirmationAction: (result) => {
        this.setState({
          confirmation: false
        })
        action(result)
      }
    })
  }

  /*
  Shows an error overlay that can be dismissed.
  */
  showError(title, description) {
    this.setState({
      showError: true,
      showErrorTitle: title,
      showErrorDescription: description,
      showErrorAction: (result) => {
        this.setState({
          showError: false
        })
      }
    })
  }

  /**
  Create and download a csv file for this user.
  */
  adminDownloadCustomerCSV() {
    if (this.state.downloadingCSVFileLink && this.state.downloadingCSVFileLink.length > 0) {
      let link = document.createElement('a');
      link.target = "_blank"
      link.href = this.state.downloadingCSVFileLink;
      link.download = 'CustomerList.csv'
      link.click();
      return
    }
    this.setState({
      downloadingCSVFile: true,
    }, () => {
      API.callDarwinAPI("GET", "adminCustomerCSV/" + this.state.geoji.geojiID, {}, (result) => {
        if ("error" in result) {
          this.setState({
            downloadingCSVFile: false
          })
          console.log("Error Generating CSV File", result)
          this.showError("Could not generate CSV", "Something went wrong. Please try again later.")
          return
        }
        console.log("adminCustomerCSV response:" + this.state.geoji.geojiID, result)
        let newLink = result.data.fileLink
        this.setState({
          downloadingCSVFile: false,
          downloadingCSVFileLink: newLink,
        })
        //now that we have the link - we need to open it.
        let link = document.createElement('a');
        link.target = "_blank"
        link.href = newLink;
        link.download = 'CustomerList.csv'
        link.click();
      })
    })
  }

  /**
  Create and download a csv file of the daily happenings.
  */
  adminDownloadDailyReport() {
    if (this.state.downloadingDailyReportLink && this.state.downloadingDailyReportLink.length > 0) {
      let link = document.createElement('a');
      link.target = "_blank"
      link.href = this.state.downloadingDailyReportLink;
      link.download = 'CustomerList.csv'
      link.click();

      this.setState({
        downloadingDailyReportLink: false
      })
      return
    }

    //Make sure a date is selected
    if (!this.state.reportDateValue || !Array.isArray(this.state.reportDateValue) || this.state.reportDateValue.length === 0) {
      this.showError("Select a date", "Select a date first to download a daily report.")
      return
    }

    let firstDate = this.state.reportDateValue[0]
    let lastDate = this.state.reportDateValue[0]
    if (this.state.reportDateValue.length > 1) {
      lastDate = this.state.reportDateValue[1]
    }

    this.setState({
      downloadingDailyReport: true,
    }, () => {
      let ddparams = {
        "firstDate": firstDate,
        "lastDate": lastDate
      }
      if (firstDate.format === undefined) {
        let cdf = new DateObject({
          year: firstDate.getFullYear(),
          month: firstDate.getMonth() + 1,
          day: firstDate.getDate(),
        })
        ddparams["firstDate"] = cdf.format("YYYY-MM-DD")
      } else {
        ddparams["firstDate"] = firstDate.format("YYYY-MM-DD")
      }
      if (lastDate.format === undefined) {
        let cdf = new DateObject({
          year: lastDate.getFullYear(),
          month: lastDate.getMonth() + 1,
          day: lastDate.getDate(),
        })
        ddparams["lastDate"] = cdf.format("YYYY-MM-DD")
      } else {
        ddparams["lastDate"] = lastDate.format("YYYY-MM-DD")
      }
      console.log(ddparams)

      API.callDarwinAPI("GET", "adminDailyReportCSV/" + this.state.geoji.geojiID, ddparams, (result) => {
        if ("error" in result) {
          this.setState({
            downloadingDailyReport: false
          })
          console.log("Error Generating CSV File", result)
          this.showError("Could not generate CSV", "Something went wrong. Please try again later.")
          return
        }
        console.log("adminCustomerCSV response:" + this.state.geoji.geojiID, result)
        let newLink = result.data.fileLink
        this.setState({
          downloadingDailyReport: false,
          downloadingDailyReportLink: newLink,
        })
        //now that we have the link - we need to open it.
        let link = document.createElement('a');
        link.target = "_blank"
        link.href = newLink;
        link.download = 'CustomerList.csv'
        link.click();

        this.setState({
          downloadingDailyReportLink: false
        })
      })
    })
  }

  /**
  Shows the view to send free tokens.
  */
  showSendFreeTokensView() {
    this.setState((old) => {
      let newGeoji = old.geoji
      for (let i = 0; i < newGeoji.tokens.length; i = i + 1) {
        newGeoji.tokens[i].cart = 0
      }

      return {
        subview: "SendFreeTokens",
        geoji: newGeoji
      }
    }, () => {
      //scroll to the top of the screen.
      window.scrollTo(0, 0)

      let link = "/g/" + this.props.geojiLink + "/dashboard_sft"
      if (this.props.geojiAccess === "private") {
        link = "/p/" + this.props.geojiLink + "/dashboard_sft"
      }
      Helpers.updateWindowPath(link)
    })
  }

  /**
  Shows the view to invite a team member.
  */
  showInviteTeamMember() {
    this.setState((old) => {
      return {
        subview: "InviteTeamMember",
        data: {},
      }
    }, () => {
      //scroll to the top of the screen.
      window.scrollTo(0, 0)

      let link = "/g/" + this.props.geojiLink + "/dashboard_invite"
      if (this.props.geojiAccess === "private") {
        link = "/p/" + this.props.geojiLink + "/dashboard_invite"
      }
      Helpers.updateWindowPath(link)
    })
  }

  /**
  Invites a team member to this Geoji by name & phone number.
  */
  inviteTeamMember(data) {
    this.setState({
      subloading: true
    }, () => {
      let newPhoneNumber = data.phoneNumber
      if (newPhoneNumber.length === 10) {
        newPhoneNumber = "1" + newPhoneNumber
      }

      let newData = {
        "phoneNumber": newPhoneNumber,
        "role": "Creator",
        "name": data.name,
      }
      API.callDarwinAPI("POST", "geojiInvite/" + this.state.geoji.geojiID, newData, (result) => {
        if ("error" in result) {
          this.setState({
            subloading: false
          })
          console.log("Error Inviting Team Member", result)
          this.showError("Could not invite this team member.", "Something went wrong. Please try again later.")
          return
        }
        console.log("invited team member", newData, result)
        this.setState({
          subloading: false,
        }, () => {
          //go back to the dashboard.
          this.props.updatePurchaseID("dashboard")
        })
      })
    })
  }

  /**
  Shows the view to manage promo codes.
  */
  openPCodes() {
    this.setState((oldData) => {
      return {
        subview: "PromoCodes",
        pcodesEdit: false,
      }
    }, () => {
      window.scrollTo(0, 0)
      let link = "/g/" + this.props.geojiLink + "/dashboard_pc"
      if (this.props.geojiAccess === "private") {
        link = "/p/" + this.props.geojiLink + "/dashboard_pc"
      }
      Helpers.updateWindowPath(link)

      //now load the promo codes
      this.loadPCodes()
    })
  }

  /**
  Loads Promo Codes.
  */
  loadPCodes() {
    this.setState({
      subloading: true
    }, () => {
      API.callDarwinAPI("GET", "promoCodes/" + this.state.geoji.geojiID, {}, (result) => {
        if ("error" in result) {
          this.setState({
            subloading: false
          })
          console.log("Error getting promo codes", result)
          this.showError("Could not get promo codes", "Something went wrong. Please try again later.")
          return
        }
        let fullCodes = result.data.codes
        let codes = []
        console.log("codes", fullCodes)
        for (let i = 0; i < fullCodes.length; i = i + 1) {
          fullCodes[i].discount = parseFloat(fullCodes[i].discount)
          fullCodes[i].deleted = parseInt(fullCodes[i].deleted)
          fullCodes[i].uses = parseInt(fullCodes[i].uses)
          fullCodes[i].maxUses = parseInt(fullCodes[i].maxUses)
          //only add in the code if it is not deleted.
          if (fullCodes[i].deleted === 0) {
            codes.push(fullCodes[i])
          }
        }
        //sort the codes
        codes.sort((a, b) => {
          if (a.code < b.code) {
            return -1
          } else if (a.code > b.code) {
            return 1
          } else {
            return 0
          }
        })
        fullCodes.sort((a, b) => {
          if (a.deleted === b.deleted) {
            if (a.code < b.code) {
              return -1
            } else if (a.code > b.code) {
              return 1
            } else {
              return 0
            }
          } else if (a.deleted === 0) {
            return -1
          } else {
            return 1
          }
        })
        console.log("promoCodes", codes, fullCodes)
        this.setState({
          subloading: false,
          pcodes: codes,
          pcodesFull: fullCodes
        })
      })
    })
  }

  /**
  The Promo Code was tapped, copy the code.
  */
  pcodeTapped(code) {
    console.log("promo code tapped", code)
    if (code.deleted === 1) {
      //nothing to do as this code was deleted
      return
    }

    let newIndi = 0
    //copy the link to the clipboard
    let linkURL = code.code
    Helpers.copyToClipboard(linkURL)

    //update the state to show the copied link.
    this.setState((old) => {
      newIndi = old.pcodesIndi + 1
      return {
        pcodesIndi: newIndi,
        pcodesSelected: code.id,
      }
    }, () => {
      setTimeout(() => {
        //make sure still the same indi
        if (this.state.pcodesIndi === newIndi) {
          this.setState({
            pcodesSelected: ""
          })
        }
      }, 1000)
    })
  }

  /**
  Show the view to create/edit a promoter link.
  */
  pcodeShowCreate(editCode, e) {
    e.stopPropagation()
    console.log("Creat/Edit PromoCode", editCode)

    if (editCode !== "new" && editCode.deleted === 1) {
      //nothing to do as this has been deleted.
      return
    }

    this.setState((old) => {
      //the old promo code.
      old.data = {}
      if (editCode !== "new") {
        old.data["pcodeName"] = {
          value: editCode.code,
          valid: true
        }
        old.data["pcodeDescription"] = {
          value: editCode.codeDescription,
          valid: true
        }
        old.data["pcodeDiscount"] = {
          value: editCode.discount,
          valid: true
        }
        old.data["pcodeMaxUses"] = {
          value: editCode.maxUses,
          valid: true
        }
      }

      return {
        pcodesEdit: editCode
      }
    })
  }

  /**
  Back button pressed on the Promo Codes page. Should we dismiss the editor or go back to the dashboard.
  */
  pcodeBack() {
    if (this.state.pcodesEdit === false) {
      console.log("pcback 1")
      this.props.updatePurchaseID("dashboard")
    } else {
      console.log("pcback 2")
      this.setState({
        pcodesEdit: false
      })
    }
  }

  /**
  Saves or creates the promoter link.
  */
  savePCode(data) {
    console.log(data)
    this.setState({
      subloading: true
    }, () => {
      let method = "POST"
      let dd = {
        code: data.pcodeName,
        codeDescription: data.pcodeDescription,
        discount: data.pcodeDiscount,
        maxUses: data.pcodeMaxUses ? data.pcodeMaxUses : 0,
      }
      if (this.state.pcodesEdit !== "new") {
        //edit an existing the promo code.
        method = "PUT"
        dd.codeID = this.state.pcodesEdit.id
      }
      console.log(dd)
      API.callDarwinAPI(method, "promoCode/" + this.state.geoji.geojiID, dd, (result) => {
        if ("error" in result) {
          this.setState({
            subloading: false
          })
          console.log("Error saving Promo Code", result)
          this.showError("Could not save Promo Code", "Something went wrong. Please try again later.")
          return
        }

        this.setState({
          pcodesEdit: false,
        }, () => {
          //load the promo codes
          this.loadPCodes()
        })
      })
    })
  }

  /**
  Toggles showing the deleted promo codes.
  */
  togglePCodeDeleted() {
    this.setState((old) => {
      return {
        pcodesShowDeleted: !old.pcodesShowDeleted
      }
    })
  }

  /**
  Prompts to delete the selected promo code.
  */
  pcodeDelete() {
    this.showConfirmation("Delete Promo Code", "This action is not undoable", "Delete", "Cancel", (del) => {
      if (del) {
        this.setState({
          subloading: true
        }, () => {
          let dd = {
            codeID: this.state.pcodesEdit.id
          }
          API.callDarwinAPI("DELETE", "promoCode/" + this.state.geoji.geojiID, dd, (result) => {
            if ("error" in result) {
              this.setState({
                subloading: false
              })
              console.log("Error deleting Promo Code", result)
              this.showError("Could not delete Promo Code", "Something went wrong. Please try again later.")
              return
            }

            this.setState({
              pcodesEdit: false,
            }, () => {
              //load the promo codes
              this.loadPCodes()
            })
          })
        })
      }
    })
  }

  /**
  Shows the view to manage promoter links.
  */
  openPromoterLinks() {
    this.setState((oldData) => {
      return {
        subview: "PromoterLinks",
        promoterLinkEdit: false,
      }
    }, () => {
      window.scrollTo(0, 0)
      let link = "/g/" + this.props.geojiLink + "/dashboard_pl"
      if (this.props.geojiAccess === "private") {
        link = "/p/" + this.props.geojiLink + "/dashboard_pl"
      }
      Helpers.updateWindowPath(link)

      //now load the promoter links
      this.loadPromoterLinks()
    })
  }

  /**
  Loads Promoter Links.
  */
  loadPromoterLinks() {
    this.setState({
      subloading: true
    }, () => {
      API.callDarwinAPI("GET", "promoterLinks/" + this.state.geoji.geojiID, {}, (result) => {
        if ("error" in result) {
          this.setState({
            subloading: false
          })
          console.log("Error getting promoter links", result)
          this.showError("Could not get promoter links", "Something went wrong. Please try again later.")
          return
        }
        let links = result.data.links
        for (let i = 0; i < links.length; i = i + 1) {
          links[i].money = parseInt(links[i].money)
          links[i].sales = parseInt(links[i].sales)
        }
        //sort the links
        links.sort((a, b) => {
          if (a.money === b.money) {
            if (a.name < b.name) {
              return -1
            } else if (a.name > b.name) {
              return 1
            }
            return 0
          } else if (a.money > b.money) {
            return -1
          } else {
            return 1
          }
        })
        console.log("promoterLinks", links)
        this.setState({
          subloading: false,
          promoterLinks: links,
        })
      })
    })
  }

  /**
  The Promoter Link was tapped, copy the link.
  */
  promoterLinkTapped(link) {
    console.log("promoter link tapped", link)
    let newIndi = 0

    //copy the link to the clipboard
    let glink = this.props.geojiLink
    glink = glink.replace(/ /g, "-");
    glink = glink.replace(/[^0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-]/g, '');
    while (true) {
      let newString = glink.replace(/--/g, "-");
      if (newString === glink) {
        break
      }
      glink = newString
    }

    let linkURL = "https://geoji.com/g/" + glink + "?r=" + link.id
    if (this.props.geojiAccess === "private") {
      linkURL = "https://geoji.com/p/" + glink + "?r=" + link.id
    }
    Helpers.copyToClipboard(linkURL)

    //update the state to show the copied link.
    this.setState((old) => {
      newIndi = old.promoterLinkIndi + 1
      return {
        promoterLinkIndi: newIndi,
        promoterLinkSelected: link.id,
      }
    }, () => {
      setTimeout(() => {
        //make sure still the same indi
        if (this.state.promoterLinkIndi === newIndi) {
          this.setState({
            promoterLinkSelected: ""
          })
        }
      }, 1000)
    })
  }

  /**
  Show the view to create/edit a promoter link.
  */
  promoterLinkShowCreate(editLink, e) {
    e.stopPropagation()
    console.log("Creat/Edit PromoterLink", editLink)
    this.setState((old) => {
      //the old promoter link.
      old.data = {}
      if (editLink !== "new") {
        old.data["promoterLinkName"] = {
          value: editLink.name,
          valid: true
        }
      }

      return {
        promoterLinkEdit: editLink
      }
    })
  }

  /**
  Back button pressed on the Promoter Links page. Should we dismiss the editor or go back to the dashboard.
  */
  promoterLinkBack() {
    if (this.state.promoterLinkEdit === false) {
      console.log("plback 1")
      this.props.updatePurchaseID("dashboard")
    } else {
      console.log("plback 2")
      this.setState({
        promoterLinkEdit: false
      })
    }
  }

  /**
  Saves or creates the promoter link.
  */
  savePromoterLink(data) {
    console.log(data)
    this.setState({
      subloading: true
    }, () => {
      let method = "POST"
      let dd = {
        name: data.promoterLinkName
      }
      if (this.state.promoterLinkEdit !== "new") {
        //edit an existing the promoter link.
        method = "PUT"
        dd.linkID = this.state.promoterLinkEdit.id
      }
      console.log(dd)
      API.callDarwinAPI(method, "promoterLink/" + this.state.geoji.geojiID, dd, (result) => {
        if ("error" in result) {
          this.setState({
            subloading: false
          })
          console.log("Error saving Promoter Link", result)
          this.showError("Could not save Promoter Link", "Something went wrong. Please try again later.")
          return
        }

        this.setState({
          promoterLinkEdit: false,
        }, () => {
          //load the promoter links.
          this.loadPromoterLinks()
        })
      })
    })
  }

  /**
  Switches between sending free tokens to single people and multiple people.
  */
  toggleAdminSendFreeTokensMultiple() {
    this.setState((old) => {
      return {
        adminSendFreeTokensMultiple: !old.adminSendFreeTokensMultiple
      }
    })
  }

  /**
  Switches between sending receipt by email and text.
  */
  toggleAdminSendReciptByText() {
    this.setState((old) => {
      return {
        adminSendReceiptByText: !old.adminSendReceiptByText
      }
    })
  }

  /**
  Sends free tokens with the provided list of data to send to the API.
  */
  sendFreeTokens(passedInData = false) {
    let data = {}
    //1) Make sure the cart is not empty
    let empty = true
    for (let i = 0; i < this.state.geoji.tokens.length; i = i + 1) {
      if (this.state.geoji.tokens[i].cart > 0) {
        empty = false
        break
      }
    }
    if (empty) {
      this.showError("Cart Empty", "Please select which tokens you want to send for free.")
      return
    }
    //add the cart as data
    data.cart = Helpers.getTokenJSONwithCart(this.state.geoji)

    if (!this.state.adminSendFreeTokensMultiple) {
      //2) On single - make sure the data is valid.
      if (passedInData === false) {
        this.submitForm()
        return
      }
      data.people = []
      data.people.push({
        name: passedInData.freeTokenName,
        phoneNumber: passedInData.freeTokenPhone ? passedInData.freeTokenPhone : "",
        email: passedInData.freeTokenEmail ? passedInData.freeTokenEmail : "",
        multiplier: 1
      })
    } else {
      //3) On multiple - make sure adminSendFreeTokensCSVPeople is an array and has a length > 0.
      if (!Array.isArray(this.state.adminSendFreeTokensCSVPeople) || this.state.adminSendFreeTokensCSVPeople.length === 0) {
        this.showError("Upload CSV File", "You need to upload your csv file first.")
        return
      }
      data.people = this.state.adminSendFreeTokensCSVPeople

      // Make sure there are no datetime or map entries.
      if (Helpers.cartIncompatibleWithMultipleBuys(this.state.geoji)) {
        this.showError("Can't Send to Multiple People", "You can't send these tickets to multiple people. You must send them one at a time.")
        return
      }
    }

    console.log("ready to call API", data.cart, data.people)

    data.cart = JSON.stringify(data.cart)
    data.people = JSON.stringify(data.people)

    //4) Show loading.
    this.setState({
      loadingSendFreeTokens: true
    }, () => {
      //5) Upload the data.
      API.callDarwinAPI("POST", "adminSendFreeTokens/" + this.state.geoji.geojiID, data, (result) => {
        if ("error" in result) {
          this.setState({
            loadingSendFreeTokens: false
          })
          console.log("Error Sending Free Tokens", result)
          if ("issue" in result.error) {
            this.showError("Could not send free tokens", result.error.issue)
            //refresh this page.
            this.refreshGeoji()
          } else {
            this.showError("Could not send free tokens", "Something went wrong. Please try again later.")
          }
          return
        }
        console.log("adminSendFreeTokens response", result)
        //6) Show success.
        this.setState((old) => {
          let newData = old.data
          delete newData.freeTokenName
          delete newData.freeTokenEmail
          delete newData.freeTokenPhone

          return {
            data: newData,
            loadingSendFreeTokens: false,
            sendingFreeTokensSuccess: true,
            adminSendFreeTokensCSVPeople: [],
          }
        })
      })
    })
  }

  /**
  A CSV file was selected for upload, parse the data and turn it into a list of people.
  */
  csvFileSelected() {
    console.log("csv file selected", this.csvFilePicker.current)

    if (this.csvFilePicker.current.files.length === 0) {
      //no file selected
      return
    }
    let file = this.csvFilePicker.current.files[0]
    //file has been selected
    //console.log("file selected", file)

    let reader = new FileReader();
		reader.addEventListener('load', (e) => {
      let text = e.target.result;
      //console.log(text)

      //check the format.
      var lines = text.split(/\r\n|\n/);
      var columns = lines[0].split(',');

      console.log("columns", columns)
      if (columns.length !== 4) {
        //error - undefined columns - return an error.
        this.showError("Invalid Format", "Please use the format in the downloaded CSV template. You should have 4 columns of data.")
        return
      }

      //now parse the data into people.
      let people = []
      for (let i = 1; i < lines.length; i = i + 1) {
        let parts = lines[i].split(',')
        //How to detect if it is a comma or an escaped comma!
        if (parts.length !== 4) {
          if (parts.length === 1) {
            //empty line - skip
            continue;
          }
          //invalid format.
          console.log(parts)
          this.showError("Invalid Format", "This CSV file contains commas in the data. Please remove all commas from your data. For instance: replace '1,234' with '1234'.")
          return
        }
        let mp = parts[3].trim()
        if (mp.length === 0) {
          mp = "1"
        }
        mp = parseInt(mp)
        if (isNaN(mp)) {
          mp = 1
        }
        mp = Math.max(mp, 1)

        let name = parts[0].trim()
        let phone = parts[1].trim()
        let email = parts[2].trim()

        if (name.length === 0) {
          //empty name - skip this row.
          continue;
        }

        people.push({
          name: name,
          phoneNumber: phone,
          email: email,
          multiplier: mp,
        })
      }

      console.log(people)
      //update the onscreen data.
      this.setState({
        adminSendFreeTokensCSVPeople: people,
      })
		})
		reader.readAsText(file);
  }

  /**
  The user and geoji environments don't match
  */
  sandboxMismatch() {
    if (this.state.geoji.sandbox === "1") {
      this.showError("Can't Buy from a Test Geoji", "This is a test Geoji and is for showing off how the app works. You can't buy anything on this Geoji.")
    } else {
      this.showError("Test Account", "This is a live Geoji. You can't purchase anything on this Geoji with a test account.")
    }
  }

  /*
  Called when a real time update has occurred to update the data.
  */
  realTimeUpdate(data) {
    console.log("RTS Update", data)
    if (this.state.subview === "Dashboard") {
      this.refreshGeoji()
    } else if (this.state.subview === "AZRedemptions") {
      if (!this.state.loadingAdminOrders) {
        let searchVal = ""
        if (this.state.data && this.state.data.adminSearch && this.state.data.adminSearch.value) {
          searchVal = this.state.data.adminSearch.value
        }
        this.loadOrders("name", searchVal, 0)
      }
    } else if (this.state.subview === "QueueRedemptions") {
      if (!this.state.loadingAdminOrders) {
        let searchVal = ""
        if (this.state.data && this.state.data.adminSearch && this.state.data.adminSearch.value) {
          searchVal = this.state.data.adminSearch.value
        }
        this.loadOrders("queue", searchVal, 0)
      }
    }
  }

  viewAdminTokenDetails(token) {
    console.log("View Admin Token", token.theme, token)
    this.setState((oldData) => {

      //set chart dates to show the last 7 days.
      let newChartDataValue = [
        new Date(),
        new Date(),
      ]
      newChartDataValue[0].setDate(newChartDataValue[0].getDate() - 6)

      return {
        subview: "TokenDetails",
        adminToken: token,
        tokenTimeFilter: "1W",
        chartDateValue: newChartDataValue,
        tokenDetailsChart: token.theme === "datetime" ? "datetime" : "default",
      }
    }, () => {
      window.scrollTo(0, 0)
      //load the token details
      this.loadAdminTokenDetails()

      let link = "/g/" + this.props.geojiLink + "/dashboard_token_" + token.id
      if (this.props.geojiAccess === "private") {
        link = "/p/" + this.props.geojiLink + "/dashboard_token_" + token.id
      }
      Helpers.updateWindowPath(link)
    })
  }

  selectAdminTokenFilter(filter) {

    let newChartDataValue = [
      new Date(),
      new Date(),
    ]
    switch (filter) {
      case "1D":
        break;
      case "1W":
        newChartDataValue[0].setDate(newChartDataValue[0].getDate() - 6)
        break;
      case "1M":
        newChartDataValue[0].setDate(newChartDataValue[0].getDate() - 29)
        break;
      case "3M":
        newChartDataValue[0].setDate(newChartDataValue[0].getDate() - 89)
        break;
      case "1Y":
        newChartDataValue[0].setDate(newChartDataValue[0].getDate() - 364)
        break;
      default:
        break;
    }


    this.setState({
      tokenTimeFilter: filter,
      chartDateValue: newChartDataValue,
    }, () => {
      this.loadAdminTokenDetails()
    })
  }

  updateAdminTokenRange(dates) {
    console.log("New Dates", dates)
    this.setState({
      chartDateValue: dates,
      tokenTimeFilter: "custom",
    }, () => {
      this.loadAdminTokenDetails()
    })
  }

  updateAdminReportDateRange(dates) {
    console.log("Passed In Dates", dates)
    let newDates = []
    if (Array.isArray(dates)) {
      if (dates.length === 0) {
        newDates = []
      } else if (dates.length === 1) {
        newDates = [dates[dates.length - 1]]
      } else {
        newDates = dates
      }
    } else {
      newDates = [dates]
    }
    console.log("New Dates", newDates)
    this.setState({
      reportDateValue: newDates,
    })
  }

  loadAdminTokenDetails() {

    this.setState({
      loadingAdminTokenDetails: true,
      showingTokenDate: false,
    }, () => {
      if (this.state.tokenDetailsChart === "datetime") {
        //sales by the date & time that the sale is for. Used for tickets for a specific date in the future.
        let dd = {}
        API.callDarwinAPI("GET", "tokenCalendarSales/" + this.state.geoji.geojiID + "/" + this.state.adminToken.id, dd, (result) => {
          if ("error" in result) {
            this.setState({
              loadingAdminTokenDetails: false
            })
            console.log("Error getting chart", result)
            this.showError("Could not get sales data", "Something went wrong. Please try again later.")
            return
          }
          console.log("tokenCalendarSales", dd, result)
          this.setState((old) => {
            let newToken = old.adminToken
            newToken.tokenLabels = result.data.labels
            newToken.tokenSales = result.data.sales
            newToken.tokenMoney = result.data.money
            newToken.tokenCash = result.data.cash
            newToken.tokenQuantity = result.data.quantity
            newToken.tokenTime = result.data.time
            newToken.tokenDate = result.data.date

            //figure out the total money and sales for the period.
            newToken.displayMoney = result.data.totalMoney
            newToken.displaySales = result.data.totalSales
            if (result.data.totalCash === 0) {
              newToken.displayCash = "-"
            } else {
              newToken.displayCash = result.data.totalCash
            }

            return {
              loadingAdminTokenDetails: false,
              adminToken: newToken,
            }
          })
        })
      } else {
        //sales by date & time that the sale actually took place
        let dd = {
          startDate: "1990-01-11",
          endDate: "1991-03-31",
        }
        if (this.state.chartDateValue && Array.isArray(this.state.chartDateValue)) {
          if (this.state.chartDateValue.length > 0) {
            //set the start & end date
            let date = this.state.chartDateValue[0]
            if (date.format === undefined) {
              let cdf = new DateObject({
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
              })
              dd.startDate = cdf.format("YYYY-MM-DD")
              dd.endDate = cdf.format("YYYY-MM-DD")
            } else {
              dd.startDate = date.format("YYYY-MM-DD")
              dd.endDate = date.format("YYYY-MM-DD")
            }
          }
          if (this.state.chartDateValue.length > 1) {
            //set the end date
            let date = this.state.chartDateValue[1]
            if (date.format === undefined) {
              let cdf = new DateObject({
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
              })
              dd.endDate = cdf.format("YYYY-MM-DD")
            } else {
              dd.endDate = date.format("YYYY-MM-DD")
            }
          }
        }
        API.callDarwinAPI("GET", "tokenSales/" + this.state.geoji.geojiID + "/" + this.state.adminToken.id + "/" + this.state.tokenTimeFilter, dd, (result) => {
          if ("error" in result) {
            this.setState({
              loadingAdminTokenDetails: false
            })
            console.log("Error getting chart", result)
            this.showError("Could not get sales data", "Something went wrong. Please try again later.")
            return
          }
          console.log("tokenSales/" + this.state.tokenTimeFilter, dd, result)
          this.setState((old) => {
            let newToken = old.adminToken
            newToken.tokenLabels = result.data.labels
            newToken.tokenSales = result.data.sales
            newToken.tokenMoney = result.data.money
            newToken.tokenCash = result.data.cash

            //figure out the total money and sales for the period.
            newToken.displayMoney = result.data.totalMoney
            newToken.displaySales = result.data.totalSales
            if (result.data.totalCash === 0) {
              newToken.displayCash = "-"
            } else {
              newToken.displayCash = result.data.totalCash
            }

            return {
              loadingAdminTokenDetails: false,
              adminToken: newToken,
            }
          })
        })
      }
    })
  }

  loadAdminTokenDateSales(date) {
    if (this.state.tokenDetailsChart !== "datetime") {
      console.log("not a date time chart - don't break it down.")
      return
    }
    this.setState({
      loadingAdminTokenDetails: true,
      showingTokenDate: true,
      adminTokenDate: date,
    }, () => {
      //sales by the date & time that the sale is for. Used for tickets for a specific date in the future.
      let dd = {}
      API.callDarwinAPI("GET", "tokenDailySales/" + this.state.geoji.geojiID + "/" + this.state.adminToken.id + "/" + this.state.adminTokenDate, dd, (result) => {
        if ("error" in result) {
          this.setState({
            loadingAdminTokenDetails: false
          })
          console.log("Error getting chart", result)
          this.showError("Could not get sales data", "Something went wrong. Please try again later.")
          return
        }
        console.log("tokenDailySales", dd, result)
        this.setState((old) => {
          let newToken = old.adminToken
          newToken.tokenLabels = result.data.labels
          newToken.tokenSales = result.data.sales
          newToken.tokenMoney = result.data.money
          newToken.tokenCash = result.data.cash
          newToken.tokenQuantity = result.data.quantity
          newToken.tokenTime = result.data.time
          newToken.tokenDate = result.data.date

          //figure out the total money and sales for the period.
          newToken.displayMoney = result.data.totalMoney
          newToken.displaySales = result.data.totalSales
          if (result.data.totalCash === 0) {
            newToken.displayCash = "-"
          } else {
            newToken.displayCash = result.data.totalCash
          }

          return {
            loadingAdminTokenDetails: false,
            adminToken: newToken,
          }
        })
      })
    })
  }

  showAdminQRCode() {
    this.setState((oldData) => {
      return {
        subview: "QRCode"
      }
    }, () => {
      window.scrollTo(0, 0)
      let link = "/g/" + this.props.geojiLink + "/dashboard_qrcode"
      if (this.props.geojiAccess === "private") {
        link = "/p/" + this.props.geojiLink + "/dashboard_qrcode"
      }
      Helpers.updateWindowPath(link)
    })
  }

  printQRCode() {
    console.log("Print QR Code")

    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    let drawnCallback = () => {
      //turn the image into a url
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    }

    let wraptext = (context, text, x, y, maxWidth, maxHeight, fontSize) => {
      let yy = y
      let words = text.split(' ');
      let line = '';
      let lineHeight = fontSize + 2;

      let lines = []

      for (let n = 0; n < words.length; n = n + 1) {
        let testLine = line + words[n] + ' ';
        let metrics = context.measureText(testLine);
        if (metrics.width > maxWidth) {
          let m2 = context.measureText(line)
          let offsetX = (maxWidth - m2.width) / 2
          lines.push({
            "line": line,
            "x": x + offsetX,
            "y": yy
          })
          line = words[n] + ' ';
          yy += lineHeight;
          if (yy > maxHeight) {
            line = line + "..."
            break;
          }
        } else {
          line = testLine;
        }
      }
      let m2 = context.measureText(line)
      let offsetX = (maxWidth - m2.width) / 2
      if (line.trim().length > 0) {
        lines.push({
          "line": line,
          "x": x + offsetX,
          "y": yy
        })
      }

      let totalHeight = lineHeight * lines.length
      let yOffset = (maxHeight - y - totalHeight) / 2
      console.log("yOffset", yOffset, totalHeight, maxHeight, y)

      for (let i = 0; i < lines.length; i = i + 1) {
        console.log("line", lines[i])
        context.fillText(lines[i].line, lines[i].x, lines[i].y + yOffset);
      }
    }

    const img = new Image();
    img.onload = () => {
      //1) Draw the background image
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height);

      const img2 = new Image()
      img2.onload = () => {
        //2) Draw the QR Code
        let qrSize = 0.4 * canvas.width
        ctx.drawImage(img2, 0, 0, img2.width, img2.height, (canvas.width - qrSize) / 2, canvas.height * 0.26, qrSize, qrSize)

        //3) Draw the title of this Geoji
        ctx.font = '128px Karla, Helvetica, sans-serif'
        ctx.fillStyle = 'black'
        ctx.textAlign = 'start'
        wraptext(ctx, this.state.geoji.title, (canvas.width - qrSize) / 2, canvas.height * 0.6347, qrSize, canvas.height * 0.7798, 128)

        //4) Generate and download the image
        drawnCallback()
      }
      img2.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };
    img.src = ImageQRCodeBackground;
  }

  promoCodeChanged(token, name, value, valid) {
    //console.log(token, name, value, valid)

    this.setState((old) => {
      let gg = old.geoji
      for (let i = 0; i < gg.tokens.length; i = i + 1) {
        if (gg.tokens[i].id === token.id) {
          gg.tokens[i].promoCode = value
        }
      }
      return {
        geoji: gg,
        tokenIncrement: old.tokenIncrement + 1
      }
    })
  }

  promoCodeSubmit(token) {
    let pc = token.promoCode ? token.promoCode : ""
    //console.log(pc)
    //submit the promo code for review.
    this.setState((old) => {
      let gg = old.geoji
      for (let i = 0; i < gg.tokens.length; i = i + 1) {
        if (gg.tokens[i].id === token.id) {
          gg.tokens[i].promoCodeLoading = true
        }
      }
      return {
        geoji: gg,
        tokenIncrement: old.tokenIncrement + 1
      }
    }, () => {
      API.callDarwinAPI("GET", "promoCode/" + this.state.geoji.geojiID + "/" + pc, {}, (result) => {
        if ("error" in result) {
          this.setState((old) => {
            let gg = old.geoji
            for (let i = 0; i < gg.tokens.length; i = i + 1) {
              if (gg.tokens[i].id === token.id) {
                gg.tokens[i].promoCodeLoading = false
                delete gg.tokens[i].promoCodeData
              }
            }
            return {
              geoji: gg,
              tokenIncrement: old.tokenIncrement + 1
            }
          })
          console.log("Error getting promo code", result)
          this.showError("Invalid Promo Code", "This code is invalid or has expired.")
          return
        }
        console.log("promoCode", result)
        this.setState((old) => {
          let gg = old.geoji
          for (let i = 0; i < gg.tokens.length; i = i + 1) {
            if (gg.tokens[i].id === token.id) {
              gg.tokens[i].promoCodeLoading = false
              gg.tokens[i].promoCodeData = result.data.promoCode
            }
          }
          return {
            geoji: gg,
            tokenIncrement: old.tokenIncrement + 1
          }
        })
      })
    })
  }

  tokenDateTimeSelected(token, dates) {
    let before = Helpers.tokenDateTimeAvailableDates(token)
    //1) Remove any additions.
    let tapped = false
    for (let i = 0; i < dates.length; i = i + 1) {
      let found = false
      for (let j = 0; j < before.length; j = j + 1) {
        if (before[j].year === dates[i].year && before[j].month.number === dates[i].month.number && before[j].day === dates[i].day) {
          //found
          found = true
          break
        }
      }
      if (!found) {
        tapped = dates[i]
        // console.log("Added", dates[i].year, dates[i].month.number, dates[i].day)
      }
    }

    //2) Remove any subtractions.
    for (let j = 0; j < before.length; j = j + 1) {
      let found = false
      for (let i = 0; i < dates.length; i = i + 1) {
        if (before[j].year === dates[i].year && before[j].month.number === dates[i].month.number && before[j].day === dates[i].day) {
          //found
          found = true
          break
        }
      }
      if (!found) {
        tapped = before[j]
        // console.log("Removed", before[j].year, before[j].month.number, before[j].day)
      }
    }

    if (tapped === false) {
      //couldn't find the date time in our list of available dates and times.
      // console.log("Tapped is false")
      return
    }

    this.setState((old) => {
      let gg = old.geoji

      for (let i = 0; i < gg.tokens.length; i = i + 1) {
        if (gg.tokens[i].id === token.id) {
          //found the token
          let found = 0
          for (let j = 0; j < gg.tokens[i].datetimes.length; j = j + 1) {
            let parsedDate = Helpers.parseSQLDate(gg.tokens[i].datetimes[j].date)
            let date = new DateObject({
              year: parsedDate.getFullYear(),
              month: parsedDate.getMonth() + 1,
              day: parsedDate.getDate(),
            })
            if (date.year === tapped.year && date.month.number === tapped.month.number && date.day === tapped.day) {
              found += 1
            }

            //unselect all
            gg.tokens[i].datetimes[j].selected = false
          }
          if (found > 0) {
            //make sure the date is in the future
            let yesterday = new Date()
            yesterday.setTime(yesterday.getTime() - (24 * 60 * 60 * 1000))
            console.log("selectedDate", tapped, yesterday, tapped < yesterday)
            if (tapped < yesterday) {
              //date is in the past - nothing to select
              console.log("date is in the past - nothing to select")
              break
            }

            //select the date
            gg.tokens[i].datetimeselected = tapped

            //if only one available datetime, select it
            if (found === 1) {
              for (let j = 0; j < gg.tokens[i].datetimes.length; j = j + 1) {
                let parsedDate = Helpers.parseSQLDate(gg.tokens[i].datetimes[j].date)
                let date = new DateObject({
                  year: parsedDate.getFullYear(),
                  month: parsedDate.getMonth() + 1,
                  day: parsedDate.getDate(),
                })
                if (date.year === tapped.year && date.month.number === tapped.month.number && date.day === tapped.day) {
                  gg.tokens[i].datetimes[j].selected = true
                }
              }
            }
          }
          break
        }
      }

      return {
        geoji: gg
      }
    })
  }

  tokenDateTimeTimeSelected(token, time) {
    if (parseInt(time.tokensSold) >= parseInt(time.quantity) && parseInt(time.quantity) !== 0) {
      //sold out - can't select this.
      return
    }

    this.setState((old) => {
      let gg = old.geoji

      for (let i = 0; i < gg.tokens.length; i = i + 1) {
        if (gg.tokens[i].id === token.id) {
          //found the token
          for (let j = 0; j < gg.tokens[i].datetimes.length; j = j + 1) {
            if (gg.tokens[i].datetimes[j].id === time.id) {
              gg.tokens[i].datetimes[j].selected = true

              let newVal = gg.tokens[i].cart
              //find the selected datetime if there is one.
              if (gg.tokens[i].datetimes[j].quantity > 0) {
                newVal = Math.min(gg.tokens[i].datetimes[j].quantity - gg.tokens[i].datetimes[j].tokensSold, newVal)
              }
              gg.tokens[i].cart = newVal
            } else {
              //unselect all
              gg.tokens[i].datetimes[j].selected = false
            }
          }
          break
        }
      }

      return {
        geoji: gg,
        tokenIncrement: old.tokenIncrement + 1
      }
    })
  }

  showEditGeoji() {
    this.props.changeView("Dashboard", "create", this.state.geoji.geojiID)
  }

  /**
  One of the settings has been toggled.
  */
  onSettingsToggle(setting) {
    this.setState((old) => {
      let newSettings = Object.assign({}, old.geojiSettings)
      if (newSettings.hasOwnProperty(setting)) {
        //opposite the toggle.
        newSettings[setting] = !newSettings[setting]
      } else {
        //toggle it on.
        newSettings[setting] = true
      }
      return {
        geojiSettings: newSettings
      }
    }, () => {
      this.saveGeojiSettings()
    })
  }

  /**
  Shows the view to manage Settings.
  */
  openSettings() {
    this.setState((oldData) => {
      return {
        subview: "Settings",
      }
    }, () => {
      window.scrollTo(0, 0)
      let link = "/g/" + this.props.geojiLink + "/dashboard_settings"
      if (this.props.geojiAccess === "private") {
        link = "/p/" + this.props.geojiLink + "/dashboard_settings"
      }
      Helpers.updateWindowPath(link)
    })
  }

  /**
  Shows the view to manage Reports
  */
  openReports() {
    this.setState((oldData) => {
      return {
        subview: "Reports",
      }
    }, () => {
      window.scrollTo(0, 0)
      let link = "/g/" + this.props.geojiLink + "/dashboard_reports"
      if (this.props.geojiAccess === "private") {
        link = "/p/" + this.props.geojiLink + "/dashboard_reports"
      }
      Helpers.updateWindowPath(link)
    })
  }

  geojiSettingsChanged(name, val) {
    console.log("geojiSettingsChanged", name, val)

    this.setState((old) => {
      let newVal = val
      if (typeof val === 'string') {
        if (val.includes(".")) {
          //float
          newVal = val
          //newVal = parseFloat(val)
        } else {
          //int
          newVal = parseInt(val)
        }
        //check for NaN
        if (isNaN(newVal)) {
          newVal = 0
        }
      } else {
        newVal = 0
      }
      let newSettings = Object.assign({}, old.geojiSettings)
      newSettings[name] = newVal
      return {
        geojiSettings: newSettings
      }
    })
  }

  saveGeojiSettings() {
    this.setState({
      geojiSettingsSaving: 0
    }, () => {
      let dd = {
        purchaseNotification: this.state.geojiSettings.purchaseNotifications ? 1 : 0,
        showCashNumbers: this.state.geojiSettings.acceptCash ? 1 : 0,
        hideRevenue: this.state.geojiSettings.hideRevenue ? 1 : 0,
        captureEmail: this.state.geojiSettings.captureEmail ? 1 : 0,
        showInstagram: this.state.geojiSettings.showInstagram ? 1 : 0,
        showInstagramAlways: this.state.geojiSettings.showInstagramAlways ? 1 : 0,
        hideLocation: this.state.geojiSettings.hideLocation ? 1 : 0,
        inviteOnly: this.state.geojiSettings.inviteOnly ? 1 : 0,
        pointOfSale: this.state.geojiSettings.pointOfSale ? 1 : 0,
        processingFeeCents: this.state.geojiSettings.processingFeeCents,
        processingFeePercent: this.state.geojiSettings.processingFeePercent,
      }
      API.callDarwinAPI("PUT", "geojiSettings/" + this.state.geoji.geojiID, dd, (result) => {
        if ("error" in result) {
          console.log("Error saving settings", result)
          this.showError("Couldn't save settings", "Please try again.")
          return
        }
        console.log("saved settings", result, dd)
        this.setState({
          geojiSettingsSaving: 1
        })
      })
    })
  }

  showDownload() {
    this.props.changeView("Download")
  }

  viewWhosGoing() {
    if (Helpers.canTapInWhosGoing(this.props.userInfo && this.props.userInfo.user, this.state.geoji)) {
      //show who is going.
      this.setState({
        view: "WhosGoing",
        subview: "Home",
        profiles: [],
        profilesPage: 0,
        profilesMoreToLoad: true,
        subloading: false,
      }, () => {
        //load the first page of profiles.
        this.loadTopProfiles(0)

        //setup observer.
        if (this.profileListEnd.current) {
          //setup the observer if possible
          if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries) => {
              let entry = entries[0]
              if (entry.isIntersecting) {
                //load more orders
                if (this.state.profilesMoreToLoad) {
                  console.log("Load More Profiles!")
                  this.loadTopProfiles(this.state.profilesPage + 1)
                }
              }
            }, {
              root: null,
              rootMargin: "0px",
              threshold: 1.0,
            })
            observer.observe(this.profileListEnd.current)
          } else {
            console.log("No IntersectionObserver found")
          }
        }
      })
    }
  }

  stopViewingWhosGoing() {
    this.setState({
      view: "Home",
      subview: "Home",
      subloading: false,
    })
  }

  viewKickback() {
    //Make sure we are logged in.
    //Make sure the user is logged in.
    if (API.hasLoggedIn()) {
      //Logged in.
      this.setState({
        view: "Kickback",
        subview: "Home",
        subloading: false,
        kickbackLink: false,
      }, () => {
        //load the kickback - publicPromoLink for this user.
        this.loadKickback()
      })
    } else {
      //Not logged in. Make the user do that first.
      this.goToLogin("viewKickback")
    }
  }

  stopViewingKickback() {
    this.setState({
      view: "Home",
      subview: "Home",
      subloading: false,
    })
  }

  loadKickback() {
    //Load the kickback link for this user.
    if (this.state.subloading) {
      //already loading.
      return
    }
    this.setState((old) => {
      return {
        subloading: true,
        kickbackLink: false,
      }
    }, () => {
      //load the kickback
      API.callDarwinAPI("GET", "publicPromoLink/" + this.state.geoji.geojiID, {}, (result) => {
        if ("error" in result) {
          console.log("Error getting public promo link", result)
          this.showError("Couldn't get promoter link", "Please try again.")
          this.stopViewingKickback()
          return
        }
        console.log("publicPromoLink/" + this.state.geoji.geojiID, result)
        this.setState({
          kickbackLink: result.data,
          subloading: false,
        })
      })
    })
  }

  copyKickbackLink() {
    let copyLink = "https://geoji.com/g/" + this.state.geoji.geojiID + "?r=" + this.state.kickbackLink.id
    Helpers.copyToClipboard(copyLink)
    this.setState({
      copiedKickback: true
    }, () => {
      setTimeout(() => {
        this.setState({
          copiedKickback: false
        })
      }, 1000)
    })
  }

  loadTopProfiles(page = 0) {
    //Load the profiles.
    if (this.state.subloading) {
      //already loading.
      return
    }
    this.setState((old) => {
      return {
        subloading: true,
        profilesPage: page,
      }
    }, () => {
      //load the profiles.
      let callback = (result) => {
        if ("error" in result) {
          console.log("Error saving settings", result)
          this.showError("Couldn't get profiles", "Please try again.")
          this.setState({
            subloading: false
          })
          return
        }
        console.log("geojiTopProfiles", result.data.profiles)
        let newMore = false
        let newProfiles = result.data.profiles
        if (newProfiles.length === this.state.profilesLimit) {
          //there is another page to load
          newMore = true
        } else {
          newMore = false
        }

        let resultProfiles = []
        if (page === 0) {
          resultProfiles = newProfiles
        } else {
          resultProfiles = [...this.state.profiles, ...newProfiles]
        }

        this.setState({
          profilesMoreToLoad: newMore,
          profiles: resultProfiles,
          subloading: false
        })
      }

      let dd = {
        page: page,
        limit: this.state.profilesLimit
      }
      if (!API.hasLoggedIn()) {
        API.callDarwinAPIUnsecured("GET", "ugeojiTopProfiles/" + this.state.geoji.geojiID, dd, callback)
      } else {
        API.callDarwinAPI("GET", "geojiTopProfiles/" + this.state.geoji.geojiID, dd, callback)
      }
    })
  }

  viewInstagramProfile(profile) {
    if (profile.instagramUsername && profile.instagramUsername.length > 0) {
      window.open("https://instagram.com/" + profile.instagramUsername, '_blank');
    }
  }

  viewRequestAccess() {
    //Make sure the user is logged in.
    if (API.hasLoggedIn()) {
      //Logged in.
      this.setState((old) => {
        let newData = [];
        if (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.name) {
          newData["name"] = {
            value: this.props.userInfo.user.name,
            valid: true,
          }
        } else {
          newData["name"] = {
            value: "",
            valid: false,
          }
        }
        newData["instagramUsername"] = {
          value: (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.instagramUsername) ? this.props.userInfo.user.instagramUsername : "",
          valid: true,
        }

        return {
          view: "RequestAccess",
          subview: "Home",
          subloading: false,
          data: newData,
        }
      })
    } else {
      //Not logged in. Make the user do that first.
      this.goToLogin("viewRequestAccess")
    }
  }

  requestAccess(data) {
    //Actually request access to this event.

    this.setState({
      subloading: true
    }, () => {
      let dd = {
        name: data.name,
      }
      if (data.instagramUsername && data.instagramUsername.length > 0) {
        if (data.instagramUsername.startsWith("@")) {
          dd["instagramUsername"] = data.instagramUsername.substr(1)
        } else {
          dd["instagramUsername"] = data.instagramUsername
        }
      }
      console.log("geojiRequestInviteAccess", dd, data)
      API.callDarwinAPI("POST", "geojiRequestInviteAccess/" + this.state.geoji.geojiID, dd, (result) => {
        if ("error" in result) {
          console.log("Error requesting access", result)
          this.showError("Couldn't request access", "Please try again.")
          this.setState({
            subloading: false
          })
          return
        }
        console.log("Access Requested", result)

        //update our invite only status.
        this.setState((old) => {
          let newGeoji = old.geoji
          newGeoji.inviteOnlyAccess = "Requested"
          newGeoji.inviteOnlyNumber = 1

          return {
            subloading: false,
            geoji: newGeoji,
            view: "Home",
            subview: "Home",
          }
        })
      })
    })
  }

  qrCodesScrolled() {

    if (this.qrCodeList.current) {
      let itemWidth = (this.qrCodeList.current.clientWidth - 64 + 16)
      let indi = this.qrCodeList.current.scrollLeft / itemWidth
      indi = Math.min(this.state.purchase.qrCodes.length - 1, Math.max(0, Math.round(indi)))
      if (indi !== this.state.qrCodeScrollIndex) {
        this.setState({
          qrCodeScrollIndex: indi
        })
      }
      if (this.qrCodeScrollTimer !== -1) {
        clearTimeout(this.qrCodeScrollTimer)
      }
      this.qrCodeScrollTimer = window.setTimeout(() => {
        //scroll to the specified index
        if (this.qrCodeList.current) {
          this.qrCodeList.current.scrollTo({
            top: 0,
            left: indi * itemWidth,
            behavior: 'smooth'
          })
        }
      }, 150)
    }
  }

  onToggleTerms() {
    this.setState((old) => {
      return {
        acceptTerms: !old.acceptTerms
      }
    })
  }

  showAdminChangeTicketDate() {
    this.setState((old) => {
      let ct = false
      let ctid = false
      for (let i = 0; i < this.state.adminOrder.purchase.tokens.length; i = i + 1) {
        if (this.state.adminOrder.purchase.tokens[i].theme === "datetime") {
          ctid = this.state.adminOrder.purchase.tokens[i].tokenID
        }
      }
      for (let i = 0; i < old.geoji.tokens.length; i = i + 1) {
        if (old.geoji.tokens[i].id === ctid) {
          ct = old.geoji.tokens[i]
        }
      }
      console.log("found", ctid, ct)

      return {
        changingTicketDate: !old.changingTicketDate,
        changeToken: ct,
      }
    })
  }

  adminShowEditNotes() {
    this.setState((old) => {
      let newData = Object.assign({}, old.data)
      //grab the purchaser's notes.
      console.log("adminOrder", old.adminOrder)
      if (old.adminOrder.purchase.notes && old.adminOrder.purchase.notes.length > 0) {
        newData.editNotesNotes = {
          valid: true,
          value: old.adminOrder.purchase.notes
        }
      } else {
        delete newData.editNotesNotes
      }
      return {
        adminShowingEditNotes: !old.adminShowingEditNotes,
        data: newData,
      }
    })
  }

  adminSubmitEditNotes(data) {
    this.setState({
      subloading: true,
    }, () => {
      console.log("adminEditNotes", data, this.state.adminOrder)
      let newData = {
        purchaseID: this.state.adminOrder.purchase.id,
        notes: (data.editNotesNotes && data.editNotesNotes.length > 0) ? data.editNotesNotes : "",
      }
      console.log("send data", newData)
      API.callDarwinAPI("POST", "terminalReceipt/" + this.state.adminOrder.purchase.geojiID, newData, (result) => {
        if ("error" in result) {
          this.setState({
            subloading: false
          })
          console.log("Error editing notes", result)
          this.showError("Could not edit notes", "Something went wrong. Please try again later.")
          return
        }
        console.log("sendPurchaseReceipt", newData, result)
        this.setState((old) => {
          let newAdminOrder = Object.assign({}, old.adminOrder)
          newAdminOrder.purchase.notes = newData.notes

          return {
            subloading: false,
            adminShowingEditNotes: false,
            adminOrder: newAdminOrder,
          }
        })
      })
    })
  }

  adminShowSendReceipt() {
    this.setState((old) => {
      let newData = Object.assign({}, old.data)
      //grab the purchaser's phone number and email.
      console.log("adminOrder", old.adminOrder)
      if (old.adminOrder.email && old.adminOrder.email.length > 0) {
        newData.sendReceiptEmail = {
          valid: true,
          value: old.adminOrder.email,
        }
      } else if (newData.sendReceiptEmail) {
        delete newData.sendReceiptEmail
      }
      if (old.adminOrder.phoneNumber && old.adminOrder.phoneNumber.length > 0) {
        newData.sendReceiptPhoneNumber = {
          valid: true,
          value: old.adminOrder.phoneNumber,
        }
      } else if (newData.sendReceiptPhoneNumber) {
        delete newData.sendReceiptPhoneNumber
      }

      return {
        adminShowingSendReceipt: !old.adminShowingSendReceipt,
        data: newData,
        adminSendReceiptByText: false,
      }
    })
  }

  adminSendReceipt(data) {
    if (this.state.adminSendReceiptByText && !data.sendReceiptPhoneNumber) {
      //show an error
      this.showError("Please fill out phone number", "The phone number must be at least 10 digits")
      return
    } else if (!this.state.adminSendReceiptByText && !data.sendReceiptEmail) {
      //show an error
      this.showError("Please fill out email", "The email must be a valid email")
      return
    }
    this.setState({
      subloading: true,
    }, () => {
      console.log("adminSendReceiptByText", this.state.adminSendReciptByText, data, this.state.adminOrder)
      let newData = {
        purchaseID: this.state.adminOrder.purchase.id,
        method: this.state.adminSendReceiptByText ? "text" : "email",
      }
      if (this.state.adminSendReceiptByText) {
        newData.phoneNumber = data.sendReceiptPhoneNumber
        if (newData.phoneNumber.length === 10) {
          newData.phoneNumber = "1" + newData.phoneNumber
        }
      } else {
        newData.email = data.sendReceiptEmail
      }
      console.log("send data", newData)
      API.callDarwinAPI("POST", "sendPurchaseReceipt", newData, (result) => {
        if ("error" in result) {
          this.setState({
            subloading: false
          })
          console.log("Error sending purchase receipt", result)
          this.showError("Could not send receipt", "Something went wrong. Please try again later.")
          return
        }
        console.log("sendPurchaseReceipt", newData, result)
        this.setState({
          subloading: false,
          adminShowingSendReceipt: false,
        })
      })
    })
  }

  updateTicketDate() {
    let sdt = ""
    for (let i = 0; i < this.state.geoji.tokens.length; i = i + 1) {
      if (this.state.geoji.tokens[i].id === this.state.changeToken.id) {
        //found the token
        let tt = this.state.geoji.tokens[i]
        for (let st = 0; st < tt.datetimes.length; st = st + 1) {
          if (tt.datetimes[st].selected) {
            sdt = tt.datetimes[st].id
          }
        }
        break
      }
    }
    let purchaseTokenID = ""
    for (let i = 0; i < this.state.adminOrder.purchase.tokens.length; i = i + 1) {
      if (this.state.adminOrder.purchase.tokens[i].tokenID === this.state.changeToken.id) {
        //found it
        purchaseTokenID = this.state.adminOrder.purchase.tokens[i].id
        break
      }
    }
    if (sdt === "" || purchaseTokenID === "") {
      this.showError("Select a Date & Time", "Please select both a date and time that you would like to switch these tickets to.")
      return
    }
    console.log("switching to GeojiTokenDT", sdt, "from purchaseTokenID", purchaseTokenID)

    this.setState({
      adminOrderLoading: true,
      changingTicketDate: false,
    }, () => {
      //1) Call the API to switch the ticket dates.
      let dd = {
        purchaseTokenID: purchaseTokenID,
        geojiTokenDT: sdt,
      }
      API.callDarwinAPI("POST", "adminChangeTicketDate", dd, (result) => {
        if ("error" in result) {
          this.setState({
            adminOrderLoading: false
          })
          console.log("Error switching admin change ticket date", result)
          this.showError("Could not switch admin ticket date", "Something went wrong. Please try again later.")
          return
        }
        console.log("adminChangeTicketDate", dd, result)
        //2) Reload the view.
        this.loadOrderDetails()
      })
    })
  }

  creatorActionContact(creator) {
    console.log("contact creator", creator)
    window.location.href = "sms:+" + creator.phoneNumber
  }

  /**
   * Changes the role for the provided creator on this Geoji
   * @param {Object} creator a creator object under this.state.geoji.creators
   * @param {String} newRole one of Owner, Creator, or Remove
   */
  creatorActionChangeRole(creator, newRole) {
    console.log("change creator role", creator, newRole)
    //Owner, Creator, Remove
    if (newRole === "Owner" || newRole === "Creator") {
      //Change the role
      let dd = {
        phoneNumber: creator.phoneNumber,
        role: newRole,
      }
      API.callDarwinAPI("PUT", "geojiInvite/" + this.state.geoji.geojiID, dd, (result) => {
        if ("error" in result) {
          console.log("Couldn't change role of team member", result)
          if (result.error === "There must be at least one owner.") {
            this.showError("There must be at least one owner", "Cannot downgrade this owner as there must be at least one owner of this Geoji. First upgrade another Creator to be an Owner, then try again.")
          } else {
            this.showError("Could not remove team member", "Something went wrong. Please try again later.")
          }
          return
        }
        //2) Change the role for this creator.
        this.setState((old) => {
          let newGeoji = old.geoji
          if (newGeoji.creators) {
            for (let i = 0; i < newGeoji.creators.length; i = i + 1) {
              if (newGeoji.creators[i].id === creator.id) {
                newGeoji.creators[i].role = newRole
                newGeoji.creators[i].profitCut = 0 //reset ownership to 0 if necessary.
              }
            }
          }

          return {
            geoji: newGeoji
          }
        }, () => {
          //reload the Geoji
          this.loadGeojiAuthed()
        })
      })
    } else if (newRole === "Remove") {
      //Remove from the team
      let dd = {
        phoneNumber: creator.phoneNumber,
      }
      API.callDarwinAPI("DELETE", "geojiInvite/" + this.state.geoji.geojiID, dd, (result) => {
        if ("error" in result) {
          console.log("Error removing team member", result)
          this.showError("Could not remove team member", "Something went wrong. Please try again later.")
          return
        }
        //2) Remove this creator from the list.
        this.setState((old) => {
          let newGeoji = old.geoji
          newGeoji.creators = newGeoji.creators.filter((cc) => {
            return cc.id !== creator.id
          })
          return {
            geoji: newGeoji
          }
        })
      })
    }
  }

  /**
   * Shows the editing
   * @param {Object} creator a creator object under this.state.geoji.creators
   */
  creatorActionEditOwnership(creator) {
    console.log("edit creator ownership", creator)
    let numberOfOwners = 0
    if (this.state.geoji.creators) {
      for (let i = 0; i < this.state.geoji.creators.length; i = i + 1) {
        if (this.state.geoji.creators[i].role === "Owner") {
          numberOfOwners += 1
        }
      }
    }
    if (numberOfOwners < 2) {
      this.showError("You need multiple owners to split revenue.", "You can split revenue once you have upgraded another Creator to an Owner.")
      return
    }
    this.setState((old) => {
      let newGeoji = old.geoji
      if (newGeoji.creators) {
        for (let i = 0; i < newGeoji.creators.length; i = i + 1) {
          if (newGeoji.creators[i].id === creator.id) {
            newGeoji.creators[i].editingOwnership = true
            newGeoji.creators[i].editingPercent = parseFloat(newGeoji.creators[i].profitCut)
          } else {
            newGeoji.creators[i].editingOwnership = false
          }
        }
      }
      return {
        geoji: newGeoji
      }
    })
  }

  creatorContextSaveOwnership(creator) {
    console.log("save creator ownership", creator)
    let dd = {
      theirID: creator.id,
      newCut: creator.editingPercent,
    }
    API.callDarwinAPI("PUT", "geojiRevenueShare/" + this.state.geoji.geojiID, dd, (result) => {
      if ("error" in result) {
        console.log("Couldn't update revenue share", result, dd)
        if (result.error === "Invalid Cut") {
          this.showError("Invalid Cut", "This cut would go over 100%. Please decrease the ownership from other team members first.")
        } else {
          this.showError("Error", "Couldn't update revenue share. Please try again.")
        }
        return
      }
      //2) Stop editing the revenue share of this creator.
      this.setState((old) => {
        let newGeoji = old.geoji
        if (newGeoji.creators) {
          for (let i = 0; i < newGeoji.creators.length; i = i + 1) {
            if (newGeoji.creators[i].id === creator.id) {
              newGeoji.creators[i].profitCut = "" + creator.editingPercent
              newGeoji.creators[i].editingOwnership = false
            }
          }
        }

        return {
          geoji: newGeoji
        }
      }, () => {
        //reload the Geoji
        this.loadGeojiAuthed()
      })
    })
  }

  /**
   * Updates the ownership of the creator by adding or subtracting 1%
   * @param {Object} creator a creator object under this.state.geoji.creators
   * @param {Boolean} increment true if adding 1% and false if subtracting 1%
   */
  creatorContextUpdateOwnership(creator, increment) {
    console.log("update creator ownership", creator)
    this.setState((old) => {
      let newGeoji = old.geoji
      if (newGeoji.creators) {
        for (let i = 0; i < newGeoji.creators.length; i = i + 1) {
          if (newGeoji.creators[i].id === creator.id) {
            let adder = 0.01
            if (!increment) {
              adder = -0.01
            }
            newGeoji.creators[i].editingPercent = Math.max(0, Math.min(1.0, newGeoji.creators[i].editingPercent + adder))
            break
          }
        }
      }
      return {
        geoji: newGeoji
      }
    })
  }

  creatorContextOptions(creator) {
    //1) Find our role
    let ourRole = ""
    if (this.props.userInfo.user.accountPermissions === "Admin") {
      ourRole = "Owner"
    } else {
      for (let i = 0; i < this.state.geoji.creators; i = i + 1) {
        if (this.props.userInfo.user.id === this.state.geoji.creators[i]) {
          ourRole = this.state.geoji.creators[i].role
        }
      }
    }

    if (ourRole === "") {
      //return empty options
      return (
        <span>
          <ContextMenuItem>
            <Components.DualImage className="defaultImage" image={ImageCancel} />
            <span>Cancel</span>
          </ContextMenuItem>
        </span>
      )
    } else if (creator.role === "Invited") {
      //Invited this user
      return (
        <span>
          <ContextMenuItem onClick={this.creatorActionContact.bind(this, creator)}>
            <Components.DualImage className="defaultImage" image={ImageMessagesGreen} />
            <span>Contact</span>
          </ContextMenuItem>
          <ContextMenuItem onClick={this.creatorActionChangeRole.bind(this, creator, "Remove")}>
            <Components.DualImage className="defaultImage" image={ImageRemove} />
            <span>Remove from Team</span>
          </ContextMenuItem>
        </span>
      )
    } else if (creator.role === "Creator") {
      //Creator
      return (
        <span>
          {ourRole === "Owner" &&
            <ContextMenuItem onClick={this.creatorActionChangeRole.bind(this, creator, "Owner")}>
              <Components.DualImage className="defaultImage" image={ImageArrowUp} />
              <span>Upgrade to Owner</span>
            </ContextMenuItem>
          }
          <ContextMenuItem onClick={this.creatorActionContact.bind(this, creator)}>
            <Components.DualImage className="defaultImage" image={ImageMessagesGreen} />
            <span>Contact</span>
          </ContextMenuItem>
          <ContextMenuItem onClick={this.creatorActionChangeRole.bind(this, creator, "Remove")}>
            <Components.DualImage className="defaultImage" image={ImageRemove} />
            <span>Remove from Team</span>
          </ContextMenuItem>
        </span>
      )
    } else {
      //Owner
      return (
        <span>
          {ourRole === "Owner" &&
            <ContextMenuItem onClick={this.creatorActionEditOwnership.bind(this, creator)}>
              <Components.DualImage className="defaultImage" image={ImagePercent} />
              <span>Change Revenue Share</span>
            </ContextMenuItem>
          }
          {ourRole === "Owner" &&
            <ContextMenuItem onClick={this.creatorActionChangeRole.bind(this, creator, "Creator")}>
              <Components.DualImage className="defaultImage" image={ImageArrowDown} />
              <span>Downgrade to Creator</span>
            </ContextMenuItem>
          }
          <ContextMenuItem onClick={this.creatorActionContact.bind(this, creator)}>
            <Components.DualImage className="defaultImage" image={ImageMessagesGreen} />
            <span>Contact</span>
          </ContextMenuItem>
        </span>
      )
    }
  }

  render() {
    let confirmationProps = {
      main: "Confirmation",
      title: this.state.confirmationTitle,
      description: this.state.confirmationDescription,
      accept: this.state.confirmationAccept,
      deny: this.state.confirmationDeny,
      action: this.state.confirmationAction
    }
    let showErrorProps = {
      main: "Error",
      title: this.state.showErrorTitle,
      description: this.state.showErrorDescription,
      dismiss: this.state.showErrorAction
    }

    return (
      <div className="Geoji">
        {/* Confirmation */}
        { this.state.confirmation &&
          <Components.Confirmation {...confirmationProps} />
        }
        {/* Error Popopver */}
        { this.state.showError &&
          <Components.FullScreenAlert {...showErrorProps} />
        }
        {/* Loading Indicator */}
        { this.state.loading &&
          <div className="GeojiLoading">
            <img src={logo} className="GeojiLoadingLogo" alt="logo" />
          </div>
        }
        {/* Error Handling */}
        { !this.state.loading && this.state.error !== false &&
          <Components.GeojiError error={this.state.error} action={this.goHome.bind(this)} />
        }
        {/* Geoji View */}
        { !this.state.loading && this.state.error === false &&
          <div className={"GeojiCn" + (this.state.geoji.purchases ? " GeojiCnNone" : "")}>
            { ["Home", "Purchase"].includes(this.state.view) &&
              <GeojiBackgroundHeader theme="standard" userInfo={this.props.userInfo} />
            }
            { ["Admin"].includes(this.state.view) &&
              <GeojiBackgroundHeader theme="wide" userInfo={this.props.userInfo} />
            }
            { this.state.view === "Home" &&
              <Elements stripe={this.stripePromise}>
                { this.state.theme.includes("dark") &&
                  <BackgroundTheme theme="darkTheme" />
                }
                <div className={"GeojiCnInner " + (this.state.theme.includes("dark") ? "GeojiCnInnerDarkTheme " : "") + (this.state.theme.includes("center") ? "GeojiCnInnerCenterTheme " : "")}>
                  { this.state.embedded === false &&
                    <div className="GeojiCnInnerWhite">
                      {/* Emoji and Top Bar */}
                      <div className="GeojiCnTopBar">
                        { this.state.geoji.photoURL && this.state.geoji.photoURL.length > 0 && !this.state.theme.includes("tall") &&
                          <div className="GeojiCnTopBarEmoji">
                            {this.state.geoji.emoji}
                          </div>
                        }
                        { !(this.state.geoji.photoURL && this.state.geoji.photoURL.length > 0 && !this.state.theme.includes("tall")) &&
                          <div className="GeojiCnTopBarEmoji">
                            &nbsp;
                          </div>
                        }
                      </div>
                      {/* Image or Large Emoji */}
                      { this.state.theme.includes("tall") &&
                        <div className="GeojiCnImageViewTall">
                          {/*<div className="GeojiCnImageViewTallBackground">
                          </div>*/}
                          {/* Image if found */}
                          { this.state.geoji.photoURL && this.state.geoji.photoURL.length > 0 &&
                            <img className="GeojiCnImageViewImage" src={this.state.geoji.photoURL} alt={this.state.geoji.title} />
                          }
                          { !(this.state.geoji.photoURL && this.state.geoji.photoURL.length > 0) &&
                            <div className="GeojiCnImageViewEmoji">
                              {this.state.geoji.emoji}
                            </div>
                          }
                        </div>
                      }
                      { !this.state.theme.includes("tall") &&
                        <div className="GeojiCnImageView">
                          {/* Image if found */}
                          { this.state.geoji.photoURL &&
                            <img className="GeojiCnImageViewImage" src={this.state.geoji.photoURL} alt={this.state.geoji.title} />
                          }
                          { !this.state.geoji.photoURL &&
                            <div className="GeojiCnImageViewEmoji">
                              {this.state.geoji.emoji}
                            </div>
                          }
                        </div>
                      }
                      <h1 className={"GeojiCnTitle" + (this.state.theme.includes("tall") ? " GeojiCnTitleTall" : "")}>
                        {this.state.geoji.title}
                      </h1>
                      { this.state.geoji.infinite &&
                        <div className="GeojiCnDateInfinite">
                          <img src={this.state.theme.includes("dark") ? ImageInfinityWhite : ImageInfinity} alt="Infinity" className="GeojiCnDateInfiniteImage" />
                        </div>
                      }
                      { !this.state.geoji.infinite &&
                        <div className="GeojiCnDate">
                          {Helpers.geojiGetNextEventDateString(this.state.geoji)}
                        </div>
                      }
                      { this.state.geoji.when && this.state.geoji.when.length > 0 &&
                        <h2 className="GeojiCnTime">
                          {this.state.geoji.when}
                        </h2>
                      }
                      {/* Active Now */}
                      { this.state.geoji.activeNow === "1" && this.state.geoji.activeNowTitle.length > 0 &&
                        <div className="GeojiCnActivation">
                          <div className="GeojiCnActivationText">
                            {this.state.geoji.activeNowTitle}
                          </div>
                        </div>
                      }
                      {/* Admin Button */}
                      { this.props.userInfo && this.props.userInfo.user && Helpers.hasAdminAccess(this.state.geoji, this.props.userInfo.user) &&
                        <div className="GeojiCnAdminButton" onClick={this.props.updatePurchaseID.bind(this, "dashboard")}>
                          <img src={ImageDashboard} alt="Dashboard" />
                          <span>Dashboard</span>
                        </div>
                      }
                      {/* Promote and get paid */}
                      { !(this.props.userInfo && this.props.userInfo.user && Helpers.hasAdminAccess(this.state.geoji, this.props.userInfo.user)) && this.state.geoji.kickbacks !== null &&
                        <div className="GeojiCnMakeMoneyButton" onClick={this.viewKickback.bind(this)}>
                          {/* <img src={ImageMoneyWhite} alt="Money" /> */}
                          <span>Promote and get paid</span>
                        </div>
                      }
                      {/* Create your own Geoji */}
                      { !(this.props.userInfo && this.props.userInfo.user && Helpers.hasAdminAccess(this.state.geoji, this.props.userInfo.user)) && this.state.geoji.kickbacks === null &&
                        <div className="GeojiCnMakeMoneyButton" onClick={this.showDownload.bind(this)}>
                          {/* <img src={ImageMoneyWhite} alt="Money" /> */}
                          <span>Create your own Geoji</span>
                        </div>
                      }
                    </div>
                  }
                  <div className="GeojiCnInnerGray">
                    {/* Who's Going */}
                    { this.state.embedded === false && Helpers.showWhosGoingSection(this.state.geoji) &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div className="GeojiCnInnerGrayGroupTitle">
                          Who's Going?
                        </div>
                        <div className="GeojiCnInnerGrayGroupWhoGoing" onClick={this.viewWhosGoing.bind(this)}>
                          { [0, 1, 2, 3, 4, 5, 6].map((iter) => (
                            <div key={"who_" + iter} className="GeojiCnInnerGrayGroupWhoGoingProfile" style={{left: (iter * -10)+ "px", zIndex: 10 - iter}}>
                              <Components.URLImage src={(this.state.geoji.topProfiles && iter < this.state.geoji.topProfiles.length && this.state.geoji.topProfiles[iter].profilePicture) ? this.state.geoji.topProfiles[iter].profilePicture : "https://galaxy.darwincloud.com/Apps/fs/19/Resources/Images/profiles/BlurFace" + (iter + 1) + ".jpg"} alt="Blurred Face" />
                            </div>
                          ))}
                        </div>
                        { Helpers.canTapInWhosGoing(this.props.userInfo && this.props.userInfo.user, this.state.geoji) &&
                          <div className="GeojiCnInnerGrayGroupWhoGoingBody">
                            Tap in to see who's going
                          </div>
                        }
                        { !Helpers.canTapInWhosGoing(this.props.userInfo && this.props.userInfo.user, this.state.geoji) &&
                          <div className="GeojiCnInnerGrayGroupWhoGoingBody">
                            Purchase tickets to see who's going
                          </div>
                        }
                      </div>
                    }
                    {/* Purchases */}
                    { this.state.embedded === false && this.state.geoji.purchases && this.state.geoji.purchases.length > 0 &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div className="GeojiCnInnerGrayGroupTitle">
                          Purchases
                        </div>
                        <div className="GeojiCnInnerGrayGroupPurchases">
                          { this.state.geoji.purchases.slice(0, this.state.showAllPurchases ? this.state.geoji.purchases.length : 5).map((purchase, i) => (
                            <div className="GeojiCnInnerGrayGroupPurchase" key={"purchase_" + purchase.id} onClick={this.props.updatePurchaseID.bind(this, purchase.id)}>
                              <div className="GeojiCnInnerGrayGroupPurchaseLeft">
                                {this.state.geoji.emoji}
                              </div>
                              <div className="GeojiCnInnerGrayGroupPurchaseMiddle">
                                <div className="GeojiCnInnerGrayGroupPurchaseMiddleDate">
                                  {Helpers.formatDateObjectShort(purchase.purchaseTimestamp)}
                                </div>
                                <div className="GeojiCnInnerGrayGroupPurchaseMiddleDescription">
                                  {this.state.geoji.title}
                                </div>
                              </div>
                              <div className="GeojiCnInnerGrayGroupPurchaseRight">
                                {purchase.priceCents === 0 ? "Free" : "$" + Helpers.formatNumberDecimals(parseInt(purchase.totalCents) / 100.0, 2)}
                              </div>
                            </div>
                          ))}
                        </div>
                        { !this.state.showAllPurchases && this.state.geoji.purchases.length > 5 &&
                          <div className="GeojiCnInnerGrayGroupPurchasesShowMore" onClick={this.showAllPurchases.bind(this)}>
                            Show More Purchases
                          </div>
                        }
                      </div>
                    }
                    {/* Tokens */}
                    { this.state.geoji.tokens && Helpers.geojiActiveTokens(this.state.geoji, this.state.focusToken).length > 0 && (this.state.geoji.inviteOnly === "0" || this.state.geoji.inviteOnlyNumber === 2) &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div id="GeojiTokenHeader">
                        </div>
                        { Helpers.geojiActiveTokens(this.state.geoji, this.state.focusToken)[0].theme !== "section" &&
                          <div className="GeojiCnInnerGrayGroupTitle" id="GeojiTokenHeader2">
                            Get Your Tickets
                          </div>
                        }
                        <div className="GeojiCnInnerGrayGroupTokens">
                          { Helpers.geojiActiveTokens(this.state.geoji, this.state.focusToken).map((token, ii) => (
                            <GeojiToken key={"token_" + token.id}
                              token={token} geoji={this.state.geoji}
                              theme={this.state.theme}
                              editing={false} onEdit={() => {}} onCopy={() => {}}
                              decrementToken={this.decrementToken.bind(this)}
                              incrementToken={this.incrementToken.bind(this)}
                              tokenChanged={this.tokenChanged.bind(this)}
                              selectedTokenAutoTip={this.selectedTokenAutoTip.bind(this)}
                              promoCodeChanged={this.promoCodeChanged.bind(this)}
                              promoCodeSubmit={this.promoCodeSubmit.bind(this)}
                              selectedTokenMapSeat={this.selectedTokenMapSeat.bind(this)}
                              tokenDateTimeSelected={this.tokenDateTimeSelected.bind(this)}
                              tokenDateTimeTimeSelected={this.tokenDateTimeTimeSelected.bind(this)}
                            />
                          ))}
                        </div>
                        {/* Checkbox selected for the refund policy */}
                        <div className="GeojiCnInnerGrayGroupAccept">
                          <div className="GeojiCnInnerGrayGroupAcceptBox">
                            <Components.SettingsToggle name="" name2="" value={this.state.acceptTerms === true}
                              onToggle={this.onToggleTerms.bind(this)} />
                          </div>
                          <div className="GeojiCnInnerGrayGroupAcceptTitle">
                            All sales are final. Refunds are at the discretion of the creator of this event. Geoji's ticketing fee is non-refundable.
                            You accept the <a href="/terms">refund and cancellation policy</a>.
                          </div>
                        </div>
                        { this.state.focusToken && this.state.focusToken.length > 0 &&
                          <div className="GeojiCnInnerGrayGroupPurchasesShowMore" onClick={this.showAllTokens.bind(this)}>
                            Show All Tickets
                          </div>
                        }
                      </div>
                    }
                    {/* Request Access Button */}
                    { this.state.geoji.inviteOnly === "1" && this.state.geoji.inviteOnlyNumber !== 1 && this.state.geoji.inviteOnlyNumber !== 2 &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div id="GeojiTokenHeader">
                        </div>
                        <div className="GeojiCnInnerGrayGroupTitle" id="GeojiTokenHeader2">
                          Tickets - Invite Only
                        </div>
                        <div className="GeojiCnInnerGrayGroupRequestAccessBody">
                          This is an exclusive event. Request access to purchase tickets.
                        </div>
                        <div className="GeojiCnInnerGrayGroupRequestAccessButton" onClick={this.viewRequestAccess.bind(this)}>
                          <img src={ImagePersonQuestionMark} alt="Request Access" />
                          Request Access
                        </div>
                      </div>
                    }
                    { this.state.geoji.inviteOnly === "1" && this.state.geoji.inviteOnlyNumber === 1 &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div id="GeojiTokenHeader">
                        </div>
                        <div className="GeojiCnInnerGrayGroupTitle" id="GeojiTokenHeader2">
                          Tickets - Invite Only
                        </div>
                        <div className="GeojiCnInnerGrayGroupRequestAccessBody">
                          Access requested. Once the creator of this event has granted you access, you'll be able to get your tickets.
                        </div>
                      </div>
                    }
                    {/* Details */}
                    { this.state.embedded === false && this.state.geoji.description && this.state.geoji.description.length > 0 &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div className="GeojiCnInnerGrayGroupTitle">
                          Details
                        </div>
                        <h2 className="GeojiCnInnerGrayGroupDescription">
                          { this.state.geoji.descriptionParts.map((part, i) => (
                            <span className="GeojiCnInnerGrayGroupDescriptionPart" key={"part_" + i}>
                              { part.type === "text" &&
                                <span>
                                  {part.value}
                                </span>
                              }
                              { part.type === "bold" &&
                                <span className="GeojiCnInnerGrayGroupDescriptionPartBold">
                                  {part.value}
                                </span>
                              }
                              { part.type === "link" &&
                                <a href={part.value} target="_blank" rel="noopener noreferrer" className="GeojiCnInnerGrayGroupDescriptionPartLink">
                                  {part.display}
                                </a>
                              }
                            </span>
                          ))}
                        </h2>
                      </div>
                    }
                    {/* Event Dates */}
                    { this.state.embedded === false && this.state.geoji.eventDates.length > 0 && !Helpers.hasDateTimeToken(this.state.geoji) &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div className="GeojiCnInnerGrayGroupTitle">
                          Event Dates
                        </div>
                        <div className="GeojiCnInnerGrayGroupEventDates">
                          <Calendar
                            calendar="gregorian"
                            locale="en"
                            multiple={true}
                            value={this.state.geoji.eventDates}
                            currentDate={this.state.geoji.calendarFocusDate}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    }
                    {/* Location */}
                    { this.state.embedded === false && this.state.geoji.where && this.state.geoji.where.length > 0 &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div className="GeojiCnInnerGrayGroupTitle">
                          Location
                        </div>
                        <div className="GeojiCnInnerGrayGroupBody">
                          <img className="GeojiCnInnerGrayGroupBodyIcon" src={ImageLocation} alt="Location" />
                          { Helpers.showLocation(this.state.userInfo && this.state.userInfo.user && this.state.userInfo.user.id, this.state.geoji) &&
                            <a className="GeojiCnInnerGrayGroupBodyText" target="_blank" rel="noopener noreferrer" href={"https://www.google.com/maps/search/?api=1&&query=" + this.state.geoji.geojiLatitude + "," + this.state.geoji.geojiLongitude}>
                              {this.state.geoji.where}
                            </a>
                          }
                          { !Helpers.showLocation(this.state.userInfo && this.state.userInfo.user && this.state.userInfo.user.id, this.state.geoji) &&
                            <div className="GeojiCnInnerGrayGroupBodyText BreakWord">
                              Location revealed after you get your tickets.
                            </div>
                          }
                        </div>
                      </div>
                    }
                    {/* Website */}
                    { this.state.embedded === false && this.state.geoji.websiteURL &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div className="GeojiCnInnerGrayGroupTitle">
                          Website
                        </div>
                        <div className="GeojiCnInnerGrayGroupBody">
                          <img className="GeojiCnInnerGrayGroupBodyIcon" src={ImageWebsite} alt="Location" />
                          <a className="GeojiCnInnerGrayGroupBodyText" target="_blank" rel="noopener noreferrer"
                            href={!this.state.geoji.websiteURL.includes("://") ? "http://" + this.state.geoji.websiteURL : this.state.geoji.websiteURL}>
                            {this.state.geoji.displayWebsiteURL}
                          </a>
                        </div>
                      </div>
                    }
                    {/* Contact Creator */}
                    { this.state.embedded === false && this.state.geoji.contactInfo && this.state.geoji.contactInfo.length > 0 &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div className="GeojiCnInnerGrayGroupTitle">
                          Contact Creator
                        </div>
                        <div className="GeojiCnInnerGrayGroupBody">
                          <img className="GeojiCnInnerGrayGroupBodyIcon" src={ImageMessage} alt="Location" />
                          <a className="GeojiCnInnerGrayGroupBodyText" target="_blank" rel="noopener noreferrer"
                            href={this.state.geoji.contactInfoPhoneNumber ? ("tel:" + (this.state.geoji.contactInfo.length === 10 ? "1" : "") + this.state.geoji.contactInfo) : (this.state.geoji.contactInfoEmail ? "mailto:" + this.state.geoji.contactInfo : "https://instagram.com/" + this.state.geoji.contactInfo)}>
                            {this.state.geoji.contactInfoDisplay}
                          </a>
                        </div>
                      </div>
                    }
                    {/* Login Button */}
                    { !API.hasLoggedIn() &&
                      <div className="GeojiCnInnerGrayGroup">
                        <div className="GeojiCnInnerGrayGroupButton" onClick={this.goToLogin.bind(this)}>
                          Login
                        </div>
                      </div>
                    }
                  </div>
                  {/* Bottom Bar */}
                  <ElementsConsumer>
                    {({stripe, elements}) => (
                      <GeojiBottomBar stripe={stripe} elements={elements}
                        userInfo={this.props.userInfo}
                        sandboxMismatch={this.sandboxMismatch.bind(this)}
                        geoji={this.state.geoji} cartTapped={this.cartTapped.bind(this)}
                        showCreditCardScreen={this.showCreditCardScreen.bind(this)}
                        tokenIncrement={this.state.tokenIncrement}
                        success={this.goToSuccessPage.bind(this)}
                        type="Home"
                        subloading={this.state.subloading} suberror={this.state.suberror}
                        updateSubloading={this.updateSubloading.bind(this)}
                        failed={this.showCreditCardScreen.bind(this)}
                        showError={this.showError.bind(this)}
                        theme={this.state.theme}
                        acceptTerms={this.state.acceptTerms}
                        acceptTermsAction={this.onToggleTerms.bind(this)}
                      />
                    )}
                  </ElementsConsumer>
                </div>
              </Elements>
            }
            { this.state.view === "Login" &&
              <div className={"GeojiCnInner " + (this.state.theme.includes("dark") ? "GeojiCnInnerDarkTheme" : "")}>
                { this.state.theme.includes("dark") &&
                  <BackgroundTheme theme="darkTheme" />
                }
                { this.state.subview === "Phone" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar">
                      { !this.state.viewPurchase &&
                        <img src={this.state.theme.includes("dark") ? ImageBackWhite : ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.backHome.bind(this)} />
                      }
                    </div>
                    <div className="GeojiCnInnerPromptEmoji">
                      <span role="img" aria-label="phone">📱</span>
                    </div>
                    <div className="GeojiCnInnerPromptTitle">
                      Enter your phone number
                    </div>
                    { this.state.subloading &&
                      <div className="GeojiCnInnerPromptLoading">
                        <img src={logo} className="GeojiCnInnerPromptLoadingLogo" alt="logo" />
                      </div>
                    }
                    { !this.state.subloading &&
                      <div>
                        <div className="GeojiCnInnerPromptField">
                          <div className="InputDiv">
                            <Components.InputBottomLine type="text" name="phoneNumber" placeholder="phone number" validation="phoneNumber10" required="true" validateTyping={false} autoComplete="tel"
                              tabIndex="1"
                              value={this.state.data.phoneNumber ? this.state.data.phoneNumber.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          </div>
                        </div>
                        { this.state.suberror !== false &&
                          <div className="GeojiCnInnerPromptError">
                            {this.state.suberror}
                          </div>
                        }
                        <div className="GeojiCnInnerPromptDescription">
                          By continuing, you are agreeing to Geoji's
                          <br/>
                          <div className="GeojiCnInnerPromptDescriptionLink" onClick={this.openTerms.bind(this)}>
                            Terms of Service
                          </div>
                          &nbsp;&&nbsp;
                          <div className="GeojiCnInnerPromptDescriptionLink" onClick={this.openPrivacy.bind(this)}>
                            Privacy Policy
                          </div>
                        </div>
                        <div className="GeojiCnInnerPromptButton" onClick={this.submitForm}>
                          Submit
                        </div>
                      </div>
                    }
                  </div>
                }
                { this.state.subview === "Code" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar">
                      <img src={this.state.theme.includes("dark") ? ImageBackWhite : ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.backToPhoneNumber.bind(this)} />
                    </div>
                    <div className="GeojiCnInnerPromptEmoji">
                      <span role="img" aria-label="key">🔑</span>
                    </div>
                    <div className="GeojiCnInnerPromptTitle">
                      Enter 6-digit verification code
                    </div>
                    { this.state.subloading &&
                      <div className="GeojiCnInnerPromptLoading">
                        <img src={logo} className="GeojiCnInnerPromptLoadingLogo" alt="logo" />
                      </div>
                    }
                    { !this.state.subloading &&
                      <div>
                        <div className="GeojiCnInnerPromptField">
                          <div className="InputDiv">
                            <Components.InputBottomLine type="text" name="code" placeholder="verification code" validation="code" required="true" validateTyping={false} autoComplete="one-time-code"
                              tabIndex="1"
                              value={this.state.data.code ? this.state.data.code.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          </div>
                        </div>
                        { this.state.suberror !== false &&
                          <div className="GeojiCnInnerPromptError">
                            {this.state.suberror}
                          </div>
                        }
                        <div className="GeojiCnInnerPromptDescription">
                          A verification code was sent to mobile number
                          <br/>
                          {this.state.data.phoneNumber && Helpers.parsePhoneNumber(this.state.data.phoneNumber.value)}
                          <br/>
                          Didn't receive a code?&nbsp;
                          <div className="GeojiCnInnerPromptDescriptionLink" onClick={this.resendCode.bind(this)}>
                            Resend Code
                          </div>
                        </div>
                        <div className="GeojiCnInnerPromptButton" onClick={this.submitForm}>
                          Submit
                        </div>
                      </div>
                    }
                  </div>
                }
                { this.state.subview === "Name" &&
                  <Elements stripe={this.stripePromise}>
                    <div className="GeojiCnInnerPrompt">
                      <div className="GeojiCnInnerPromptTopBar">
                        <img src={this.state.theme.includes("dark") ? ImageBackWhite : ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.backHome.bind(this)} />
                      </div>
                      <div className="GeojiCnInnerPromptEmoji">
                        <span role="img" aria-label="token">🎟</span>
                      </div>
                      <div className="GeojiCnInnerPromptTitle">
                        Your Name
                      </div>
                      <ElementsConsumer>
                        {({stripe, elements}) => (
                          <GeojiBottomBar stripe={stripe} elements={elements}
                            userInfo={this.props.userInfo} sandboxMismatch={this.sandboxMismatch.bind(this)}
                            geoji={this.state.geoji} cartTapped={this.cartTapped.bind(this)}
                            showCreditCardScreen={this.showCreditCardScreen.bind(this)}
                            tokenIncrement={this.state.tokenIncrement}
                            success={this.goToSuccessPage.bind(this)}
                            type="Name" submitForm={this.submitForm.bind(this)}
                            data={this.state.data} formChanged={this.formChanged.bind(this)} forceCheck={this.state.forceCheck}
                            subloading={this.state.subloading} suberror={this.state.suberror}
                            updateSubloading={this.updateSubloading.bind(this)}
                            failed={this.showCreditCardScreen.bind(this)}
                            acceptTerms={this.state.acceptTerms}
                            acceptTermsAction={this.onToggleTerms.bind(this)}
                          />
                        )}
                      </ElementsConsumer>
                    </div>
                  </Elements>
                }
              </div>
            }
            { this.state.view === "Checkout" &&
              <Elements stripe={this.stripePromise}>
                { this.state.theme.includes("dark") &&
                  <BackgroundTheme theme="darkTheme" />
                }
                <div className={"GeojiCnInner " + (this.state.theme.includes("dark") ? "GeojiCnInnerDarkTheme" : "")}>
                  { this.state.subview === "Home" &&
                    <div className="GeojiCnCheckout">
                      { this.state.purchaseLoading &&
                        <div className="GeojiCnCheckoutCover">
                          <div className="GeojiCnCheckoutCoverLoading">
                            <img src={logoColored} className="GeojiLoadingLogo" alt="logo" />
                          </div>
                        </div>
                      }
                      <div className="GeojiCnCheckoutTopBar">
                        <div className="GeojiCnCheckoutTopBarBack" onClick={this.backHome.bind(this)}>
                          <img src={this.state.theme.includes("dark") ? ImageBackWhite : ImageBack} alt="back" className="GeojiCnCheckoutTopBarBackArrow" />
                        </div>
                        <div className="GeojiCnCheckoutTopBarTitle">
                          Checkout
                        </div>
                        <div className={"GeojiCnCheckoutTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnCheckoutTopBarSandboxVisible" : "")}>
                          Sandbox
                        </div>
                      </div>
                      <div className="GeojiCnCheckoutTitle">
                        Payment Method
                      </div>
                      <div id="AmazonPayHiddenBox">
                      </div>

                      {/* Show Loading Buttons */}
                      { this.state.subloading && this.state.geoji.checkoutMethods !== "C" &&
                        <div className="PaymentMethodsLoading">
                          { this.state.geoji.checkoutMethods.includes("Z") &&
                            <div className="PaymentBox PayBoxLoading">
                            </div>
                          }
                          { this.state.geoji.checkoutMethods.includes("P") &&
                            <div className="PaymentBox PayBoxLoading">
                            </div>
                          }
                        </div>
                      }
                      {/* Show the payment options */}
                      { !this.state.subloading &&
                        <div className="PaymentMethodsContainer">
                          {/* Amazon Pay */}
                          { this.state.geoji.checkoutMethods.includes("Z") && this.state.amazonPayButtonSettings !== undefined &&
                            <div id="AmazonPayBox" className={"PaymentBox PayBoxLoading " + (this.state.subloading ? "PayBoxLoading" : "")}
                              onClick={this.checkoutWithMethod.bind(this, "AmazonPay")}>
                              <img src={ImageAmazonPay} alt="Amazon Pay" className="AmazonPayBoxImage" />
                            </div>
                          }
                          { this.state.geoji.checkoutMethods.includes("Z") && this.state.amazonPayButtonSettings !== undefined &&
                            <div id="AmazonPayBoxBottomText">
                              Use your Amazon Account
                            </div>
                          }

                          {/* PayPal */}
                          { this.state.geoji.checkoutMethods.includes("P") &&
                            <PayPalScriptProvider options={{
                              clientId: this.state.geoji.sandbox === "1" ? "AWgVVSRItUJUqTRGzOCFDVLXsXXMtRoH7w30mF3B0RK3jEh0xPQJFIVWOk_n1ckonMYMX986Ocd1GeHA" : "ARwmqhlQh_ZFhtcv77E5KJvKnDfKd6BMU7wuMkMiFGrOIAKsuViHW61QVDQDNoPuiTT1_eRs91wPI67C",
                              currency: "USD",
                              intent: "capture",
                              enableFunding: "venmo",
                              components: "buttons",
                              }}>
                              <PayPalButtons
                                style={{
                                  layout: 'horizontal',
                                  color: 'gold',
                                  shape: 'rect',
                                  label: 'paypal',
                                  height: 55,
                                  tagline: false,
                                }}
                                createOrder={(data, actions) => this.payPalCreateOrder(data, actions)}
                                onApprove={(data, actions) => this.payPalOnApprove(data, actions)}
                              />
                            </PayPalScriptProvider>
                          }

                          {/* Apple Pay */}
                          { this.state.geoji.checkoutMethods.includes("A") &&
                            <div id="OSPayBoxApple" className={"PaymentBox " + (this.state.subloading ? "PayBoxLoading" : "")}
                              onClick={this.checkoutWithMethod.bind(this, "ApplePay")}>
                              <div className="OSPayBoxText">
                                Buy with
                              </div>
                              <img src={ImageApplePay} alt="Apple Pay" className="OSPayBoxImage" />
                            </div>
                          }
                          
                          {/* Google Pay */}
                          { this.state.geoji.checkoutMethods.includes("G") &&
                            <div id="OSPayBoxGoogle" className={"PaymentBox " + (this.state.subloading ? "PayBoxLoading" : "")}
                              onClick={this.checkoutWithMethod.bind(this, "GooglePay")}>
                              <div className="OSPayBoxText">
                                Buy with
                              </div>
                              <img src={ImageGooglePay} alt="Google Pay" className="OSPayBoxImage" />
                            </div>
                          }
                        </div>
                      }

                      {/* 'or' text - spacer */}
                      { this.state.geoji.checkoutMethods !== "C" &&
                        <div className="PaymentBoxOr">
                          or
                        </div>
                      }
                      { this.state.geoji.checkoutMethods === "C" &&
                        <div className="PaymentBoxSpacer">
                        </div>
                      }

                      {/* Credit Card */}
                      <div className="GeojiCnCheckoutPaymentMethod">
                        <img src={ImageCreditCard} alt="Credit Card" className="GeojiCnCheckoutPaymentMethodIconImage" />
                        <div className="GeojiCnCheckoutPaymentMethodLabel">
                          Credit Card
                        </div>
                      </div>
                      <ElementsConsumer>
                        {({stripe, elements}) => (
                          <CheckoutForm stripe={stripe} elements={elements} theme={this.state.theme}
                            clientSecret={this.state.paymentIntent && this.state.paymentIntent.client_secret} geoji={this.state.geoji}
                            subloading={this.state.subloading} suberror={this.state.suberror} updateSubloading={this.updateSubloading.bind(this)}
                            card={this.state.card} editCard={this.editCard.bind(this)}
                            goToSuccessPage={this.goToSuccessPage.bind(this)}/>
                        )}
                      </ElementsConsumer>
                    </div>
                  }
                </div>
              </Elements>
            }
            { this.state.view === "Success" &&
              <div className={"GeojiCnInner " + (this.state.theme.includes("dark") ? "GeojiCnInnerDarkTheme" : "")}>
                { this.state.theme.includes("dark") &&
                  <BackgroundTheme theme="darkTheme" />
                }
                { this.state.subview === "Home" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptEmoji">
                      <span role="img" aria-label="token">🎉</span>
                    </div>
                    <div className="GeojiCnInnerPromptTitle">
                      Success
                    </div>
                    { this.state.subloading &&
                      <div className="GeojiCnInnerPromptLoading">
                        <img src={logo} className="GeojiCnInnerPromptLoadingLogo" alt="logo" />
                      </div>
                    }
                    { !this.state.subloading &&
                      <div>
                        {/* No QR Codes */}
                        <div className="GeojiCnInnerPurchaseTale" style={{marginBottom:"64px"}}>
                          Your purchase was successful.
                          <div className="GeojiCnInnerPurchaseTaleBold">
                            SIMPLY STATE YOUR NAME TO REDEEM!
                          </div>
                          You are now on the list.
                        </div>

                        <div className="GeojiCnInnerPromptButton GeojiCnInnerPromptButton2" onClick={this.viewPurchase.bind(this, this.state.purchase)}>
                          ok
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            }
            { this.state.view === "Purchase" &&
              <div className={"GeojiCnInner"}>
                { this.state.subview === "Home" && this.state.suberror !== "No Access" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar">
                      <img src={ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.props.updatePurchaseID.bind(this, false)} />
                      <div className="GeojiCnInnerPromptTopBarTitle">
                        {this.state.geoji.title}
                      </div>
                      { this.state.geoji.sandbox === "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandbox">
                          Sandbox
                        </div>
                      }
                      { this.state.geoji.sandbox !== "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandboxEmpty" />
                      }
                    </div>
                    <div className="GeojiCnInnerPurchase">

                      {/* Mass Admission Wristbands */}
                      { this.state.subloading &&
                        <div className="GeojiCnInnerPurchaseMassLoading">
                        </div>
                      }
                      { !this.state.subloading && Helpers.getWristbands(this.state.geoji, this.state.purchase).length > 0 &&
                        <div className="GeojiCnInnerPurchaseMassContainer">
                          { Helpers.getWristbands(this.state.geoji, this.state.purchase).map((wristband, i) => (
                            <div className="GeojiCnInnerPurchaseMassAd" key={"wristband_" + i}>
                              <GeojiWristband wristband={wristband} />

                              { Helpers.getWristbands(this.state.geoji, this.state.purchase)[Helpers.getWristbands(this.state.geoji, this.state.purchase).length - 1].tokenID === wristband.tokenID &&
                                <div className="GeojiCnInnerPurchaseMassAdBandText">
                                  <img src={ImageUpArrow} className="GeojiCnInnerPurchaseMassAdBandTextL" alt="Up Arrow" />
                                  <div className="GeojiCnInnerPurchaseMassAdBandTextM">
                                    <div className="GeojiCnInnerPurchaseMassAdBandTextMT">
                                      Digital Wristband
                                    </div>
                                    <div className="GeojiCnInnerPurchaseMassAdBandTextMB">
                                      Show this at door for entry
                                    </div>
                                  </div>
                                  <img src={ImageUpArrow} className="GeojiCnInnerPurchaseMassAdBandTextR" alt="Up Arrow" />
                                </div>
                              }

                              <div className="GeojiCnInnerPurchaseMassAdNumber">
                                {wristband.text}
                              </div>
                            </div>
                          ))}

                          <div className="GeojiCnInnerPurchaseMassBottom">
                            No QR Codes or Printed Tickets.
                            <br/>
                            Hold your phone out, and walk in.
                            <br/>
                            It's that simple.
                          </div>
                        </div>
                      }

                      {/* QR Codes */}
                      { !this.state.subloading && Helpers.getQRCodes(this.state.purchase).length > 0 &&
                        <div className="GeojiCnInnerPurchaseQRCodesContainer">
                          {/* List of QR Codes horizontally */}
                          <div className="GeojiCnInnerPurchaseQRCodesList" onScroll={this.qrCodesScrolled.bind(this)} ref={this.qrCodeList}>
                            { Helpers.getQRCodes(this.state.purchase).map((code, i) => (
                              <div key={"code_" + i} className="GeojiCnInnerPurchaseQRCode">
                                <div className="GeojiCnInnerPurchaseQRCodeInner">
                                  <div className="GeojiCnInnerPurchaseQRCodeInnerTop">
                                    <img src={ImagePoweredByGeoji} alt="Powered by Geoji" />
                                  </div>
                                  <div className="GeojiCnInnerPurchaseQRCodeInnerImage">
                                    <QRCodeSVG value={code.id} style={{width: "100%", height: "100%"}} />
                                  </div>
                                  <div className="GeojiCnInnerPurchaseQRCodeLabel">
                                    {Helpers.getQRCodeLabel(this.state.geoji, code)}
                                  </div>
                                  <div className="GeojiCnInnerPurchaseQRCodeDetailsLabel">
                                    {Helpers.getQRCodeDetailsLabel(code)}
                                  </div>
                                  <div className="GeojiCnInnerPurchaseQRCodeQuantity">
                                    {code.quantity}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>

                          {/* Dots for sliding */}
                          <div className="GeojiCnInnerPurchaseQRCodesDots">
                            { Helpers.getQRCodes(this.state.purchase).map((code, i) => (
                              <div key={"dot_" + i} className={"GeojiCnInnerPurchaseQRCodesDot " + (i === this.state.qrCodeScrollIndex ? "GeojiCnInnerPurchaseQRCodesDotSelected" : "")}>
                              </div>
                            ))}
                          </div>
                        </div>
                      }

                      {/* No QR Codes */}
                      { !this.state.subloading && Helpers.getWristbands(this.state.geoji, this.state.purchase).length === 0 && Helpers.getQRCodes(this.state.purchase).length === 0 &&
                        <div className="GeojiCnInnerPurchaseTale">
                          No QR Codes or Printed Tickets.
                          <div className="GeojiCnInnerPurchaseTaleBold">
                            SIMPLY STATE YOUR NAME TO REDEEM!
                          </div>
                          You are now on the list.
                        </div>
                      }

                      {/* Ticket Bar */}
                      <div className="GeojiCnInnerPurchaseTicketBar">
                        <div className="GeojiCnInnerPurchaseTicketBarLeft">
                        </div>
                        <div className="GeojiCnInnerPurchaseTicketBarMiddle">
                        </div>
                        <div className="GeojiCnInnerPurchaseTicketBarRight">
                        </div>
                      </div>

                      {/* TODO: Edit Profile Picture - Contact Information */}
                      <div className="GeojiCnInnerPurchaseProfile">
                        <div className="GeojiCnInnerPurchaseProfileImageView">
                          <img className={(this.props.userInfo.user && this.props.userInfo.user.profilePicture) ? "GeojiCnInnerPurchaseProfileImageViewImage" : "GeojiCnInnerPurchaseProfileImageViewImageDefault"} src={(this.props.userInfo.user && this.props.userInfo.user.profilePicture) ? this.props.userInfo.user.profilePicture : ImagePersonGray} alt="Profile" />
                          {/*<img className="GeojiCnInnerPurchaseProfileImageViewEdit" src={ImagePencilEdit} alt="Edit Profile" />*/}
                        </div>
                        <div className="GeojiCnInnerPurchaseProfileRight">
                          <div className="GeojiCnInnerPurchaseField">
                            <div className="InputDiv">
                              <Components.InputBottomLine type="text" name="name" placeholder="Felix Fox" validation="text" required="true" validateTyping={false} autoComplete="name"
                                title="Full Name" tabIndex="1"
                                saving={this.state.nameSavedCount > 0}
                                value={this.state.data.name ? this.state.data.name.value : ""} onEnter={this.submitForm} onChange={this.formChanged} onBlur={this.submitForm} forceCheck={this.state.forceCheck} />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Notes */}
                      <div className="GeojiCnInnerPurchaseNotes">
                        <div className="GeojiCnInnerPurchaseField">
                          { this.state.subloading &&
                            <div className="GeojiCnInnerPurchaseNotesLoading" />
                          }
                          { !this.state.subloading &&
                            <div className="InputDiv">
                              <Components.InputBottomLine type="text" name="notes" validation="text" required="false" validateTyping={false}
                                title={(this.state.geoji.notesTitle && this.state.geoji.notesTitle.length > 0) ? this.state.geoji.notesTitle : "Notes"}
                                placeholder={(this.state.geoji.notesPlaceholder && this.state.geoji.notesPlaceholder.length > 0) ? this.state.geoji.notesPlaceholder : "Table #, guests under your name, ..."}
                                description={(this.state.geoji.notesDescription && this.state.geoji.notesDescription.length > 0) ? this.state.geoji.notesDescription : "add special instructions"}
                                tabIndex="2" saving={this.state.notesSavedCount > 0}
                                value={this.state.data.notes ? this.state.data.notes.value : ""} onEnter={this.submitForm} onChange={this.formChanged} onBlur={this.submitForm} forceCheck={this.state.forceCheck} />
                            </div>
                          }
                        </div>
                      </div>

                      {/* Location of the event */}
                      { this.state.geoji.where && this.state.geoji.where.length > 0 &&
                        <div className="GeojiCnInnerPurchaseLocation">
                          <div className="GeojiCnInnerPurchaseLocationTitle">
                            Location
                          </div>
                          <div className="GeojiCnInnerPurchaseLocationBody">
                            <img className="GeojiCnInnerPurchaseLocationBodyIcon" src={ImageLocation} alt="Location" />
                            <a className="GeojiCnInnerPurchaseLocationBodyText" target="_blank" rel="noopener noreferrer" href={"https://www.google.com/maps/search/?api=1&&query=" + this.state.geoji.geojiLatitude + "," + this.state.geoji.geojiLongitude}>
                              {this.state.geoji.where}
                            </a>
                          </div>
                        </div>
                      }

                      {/* Purchase Bar */}
                      { (this.state.subloading || (this.state.purchase.tokens && this.state.purchase.tokens.length > 0)) &&
                        <div className="GeojiCnInnerPurchaseBar">
                          <div className="GeojiCnInnerPurchaseBarEmoji">
                            {this.state.geoji.emoji}
                          </div>
                          <div className="GeojiCnInnerPurchaseBarList">
                            <div className="GeojiCnInnerPurchaseBarDate">
                              { (!this.state.subloading && this.state.purchase.purchaseTimestamp) ? "Purchased " + Helpers.formatDateObjectShort(this.state.purchase.purchaseTimestamp) : ""}
                              <div className="GeojiCnInnerPurchaseBarDateTime">
                                { (!this.state.subloading && this.state.purchase.purchaseTimestamp) ? Helpers.formatDateObjectTime(this.state.purchase.purchaseTimestamp) : ""}
                              </div>
                            </div>
                            {/* List of Purchase Items */}
                            { this.state.subloading &&
                              <div className="GeojiCnInnerPurchaseBarLoading" />
                            }
                            { !this.state.subloading && this.state.purchase.tokens && this.state.purchase.tokens.map((item, i) => (
                              <div className="GeojiCnInnerPurchaseBarItem" key={"token_" + item.id + "_" + i}>
                                <div className="GeojiCnInnerPurchaseBarItemQuantity">
                                  {item.quantity} x
                                </div>
                                <div className="GeojiCnInnerPurchaseBarItemTitle">
                                  {Helpers.geojiTokenTitle(this.state.geoji, item.tokenID)}
                                </div>
                                <div className="GeojiCnInnerPurchaseBarItemPrice">
                                  {item.totalPriceCents === "0" ? "Free" :
                                    (parseInt(item.totalPriceCents) - (Math.floor(parseFloat(item.totalPriceCents) / 100.0) * 100) > 0) ?
                                    "$" + Helpers.formatNumberDecimals(parseFloat(item.totalPriceCents) / 100.0, 2) :
                                    "$" + Helpers.formatNumberDecimals(parseFloat(item.totalPriceCents) / 100.0, 0)
                                  }
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      }

                      {/* Map of seats - if necessary */}
                      { !this.state.subloading && Helpers.getMaps(this.state.geoji, this.state.purchase).length > 0 &&
                        <div className="GeojiCnInnerPurchaseMapContainer">
                          { Helpers.getMaps(this.state.geoji, this.state.purchase).map((map, i) => (
                            <div className="GeojiCnInnerGrayGroupTokenMap" key={"map_" + i}>
                              {/* Map */}
                              { map.map && map.map.mapURL &&
                                <div className="GeojiCnInnerGrayGroupTokenMapGraphic">
                                  <img src={map.map.mapURL} alt="Seating Map" className="GeojiCnInnerGrayGroupTokenMapGraphicImage" />
                                  { map.map.seats && map.map.seats.map((seat, index) => (
                                    <div key={"seat_" + seat.name + "_" + index} className={"GeojiCnInnerGrayGroupTokenMapGraphicSeat" + (map.seats.includes(seat.name) ? " GeojiCnInnerGrayGroupTokenMapGraphicSeatSelected" : "") + " GeojiCnInnerGrayGroupTokenMapGraphicSeatShape" + seat.shape}
                                      style={{left:"calc(" + seat.position[0] * 100 + "% - 15px)", top:"calc(" + seat.position[1] * 100 + "% - 15px)"}}
                                      >
                                      <div className="GeojiCnInnerGrayGroupTokenMapGraphicSeatText">
                                        {seat.name}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              }

                              {/* About Description */}
                              { map.token.about && map.token.about.length > 0 &&
                                <div className="GeojiCnInnerGrayGroupTokenMapAbout">
                                  {map.token.about}
                                </div>
                              }
                            </div>
                          ))}
                        </div>
                      }

                      {/* Purchase Total */}
                      { (this.state.subloading || (this.state.purchase.tokens && this.state.purchase.tokens.length > 0)) &&
                        <div className="GeojiCnInnerPurchaseTotal">
                          <div className="GeojiCnInnerPurchaseTotalTitle">
                            Total
                          </div>
                          { !this.state.subloading && this.state.purchase.totalCents === 0 &&
                            <div className="GeojiCnInnerPurchaseTotalValue">
                              Free
                            </div>
                          }
                          { !this.state.subloading && this.state.purchase.totalCents !== 0 &&
                            <div className="GeojiCnInnerPurchaseTotalValue">
                              {this.state.purchase.totalCents ? "$" + Helpers.formatNumberDecimals(this.state.purchase.totalCents / 100.0, 2) : ""}
                            </div>
                          }
                        </div>
                      }

                      {/* Waivers */}
                      { this.state.purchase && this.state.purchase.signedWaivers && this.state.purchase.signedWaivers.length > 0 &&
                        <div className="GeojiCnInnerPurchaseWaivers">
                          <div className="GeojiCnInnerPurchaseWaiversTitle">
                            Waivers
                          </div>
                          { this.state.purchase.signedWaivers.map((waiver, ii) => (
                            <div key={"waiver_" + waiver.id} className="GeojiCnInnerPurchaseWaiversW" onClick={this.viewWaiver.bind(this, waiver)}>
                              <div className="GeojiCnInnerPurchaseWaiversWLeft">
                                <div className="GeojiCnInnerPurchaseWaiversWLeftTitle">
                                  {Helpers.getWaiverTitle(waiver, this.state.purchase.waivers)}
                                </div>
                                <div className="GeojiCnInnerPurchaseWaiversWLeftBody">
                                  <div className="GeojiCnInnerPurchaseWaiversWLeftBodySignature">
                                    <img src={ImageSignature} alt="Signature" />
                                  </div>
                                  <div className="GeojiCnInnerPurchaseWaiversWLeftBodyName">
                                    {waiver.name}
                                  </div>
                                </div>
                              </div>
                              <div className="GeojiCnInnerPurchaseWaiversWRight">
                                <img src={ImageRightArrowGray} alt="Right Arrow" />
                              </div>
                            </div>
                          ))}
                        </div>
                      }

                      {/* Ticket Notes */}
                      { this.state.geoji.ticketNotes && this.state.geoji.ticketNotes.length > 0 &&
                        <div className="GeojiCnInnerPurchaseTicketNotes">
                          {this.state.geoji.ticketNotes}
                        </div>
                      }

                      {/* Download our app prompt */}
                      <div className="GeojiCnInnerPurchaseKeepYourTickets">
                        Download our app to keep your tickets on your phone.
                      </div>

                      {/* App Store Badge */}
                      <a className="AppStoreBadge" href="https://apps.apple.com/us/app/geoji/id1502351343?itsct=apps_box_badge&amp;itscg=30200">
                        <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1583971200&h=97da12fbd57d1fd38cacda489373cc2c"
                          alt="Download on the App Store" />
                      </a>

                      <Components.DualImage image={ImageGreenOctopus} className="GeojiCnInnerPurchaseTicketImage">
                      </Components.DualImage>
                    </div>
                  </div>
                }
                { this.state.subview === "Home" && this.state.suberror === "No Access" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar">
                      <img src={ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.backHome.bind(this)} />
                      <div className="GeojiCnInnerPromptTopBarTitle">
                        {this.state.geoji.title}
                      </div>
                      { this.state.geoji.sandbox === "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandbox">
                          Sandbox
                        </div>
                      }
                      { this.state.geoji.sandbox !== "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandboxEmpty" />
                      }
                    </div>

                    <div className="GeojiCnInnerPurchase">
                      <div className="GeojiCnInnerPurchaseNoAccessTitle">
                        Not Found
                      </div>
                      <div className="GeojiCnInnerPurchaseNoAccessBody">
                        You do not have access to this purchase. If you think this is an error, please reach out to
                        <br/>
                        <b>support@geoji.com</b>
                      </div>
                      <div className="GeojiCnInnerPurchaseNoAccessID">
                        PurchaseID: {this.props.geojiLink} - {this.props.purchaseID}
                      </div>
                    </div>
                  </div>
                }
                { this.state.subview === "Waiver" &&
                  <div className="GeojiCnInnerPrompt GeojiCnInnerPromptWaiver">
                    <div className="GeojiCnInnerPromptTopBar">
                      <img src={ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.backHome.bind(this)} />
                      <div className="GeojiCnInnerPromptTopBarTitle">
                        Waivers
                      </div>
                      { this.state.geoji.sandbox === "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandbox">
                          Sandbox
                        </div>
                      }
                      { this.state.geoji.sandbox !== "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandboxEmpty" />
                      }
                    </div>
                    <div className="GeojiCnInnerWaiver">
                      <div className="GeojiCnInnerWaiverTitle">
                        {this.state.waiver.waiverTitle}
                      </div>
                      <div className="GeojiCnInnerWaiverBody">
                        {this.state.waiver.waiverText}
                        <br/><br/>
                        By clicking 'I Agree' below, you agree that you have read and agree with the terms of the waiver and that the information you provided is accurate. You furthermore agree that your submission of this form, via the 'I Agree' button, shall constitute the execution of this document in exactly the same manner as if you had signed, by hand, a paper version of this agreement.
                      </div>
                    </div>
                    <div className="GeojiCnInnerWaiverBottom">
                      <div className="GeojiCnInnerWaiverBottomTitle">
                        {this.state.waiverParticipant ? this.state.waiverParticipant : "Participant"}
                      </div>
                      <div className="GeojiCnInnerPromptField">
                        <div className="InputDiv">
                          <Components.InputBottomLine type="text" name="name" placeholder="full name" validation="text" required="true" validateTyping={false} autoComplete="name"
                            tabIndex="1"
                            value={this.state.data.name ? this.state.data.name.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        </div>
                      </div>
                      { this.state.suberror !== false &&
                        <div className="GeojiCnInnerWaiverBottomTextError">
                          {this.state.suberror}
                        </div>
                      }
                      { this.state.subloading &&
                        <div className="GeojiCnInnerPromptButtonLoading" />
                      }
                      { !this.state.subloading &&
                        <div className={"GeojiCnInnerPromptButton" + (this.state.shakeButton ? " AnimationShake" : "")} onClick={this.waiverAgree.bind(this)}>
                          I Agree
                        </div>
                      }
                    </div>
                  </div>
                }
                { this.state.subview === "ViewWaiver" &&
                  <div className="GeojiCnInnerPrompt GeojiCnInnerPromptWaiver">
                    <div className="GeojiCnInnerPromptTopBar">
                      <img src={ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.dismissWaiver.bind(this)} />
                      <div className="GeojiCnInnerPromptTopBarTitle">
                        Waivers
                      </div>
                      { this.state.geoji.sandbox === "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandbox">
                          Sandbox
                        </div>
                      }
                      { this.state.geoji.sandbox !== "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandboxEmpty" />
                      }
                    </div>
                    <div className="GeojiCnInnerWaiver">
                      <div className="GeojiCnInnerWaiverTitle">
                        {this.state.waiver.waiverTitle}
                      </div>
                      <div className="GeojiCnInnerWaiverBody">
                        {this.state.waiver.waiverText}
                        <br/><br/>
                        By clicking 'I Agree' below, you agree that you have read and agree with the terms of the waiver and that the information you provided is accurate. You furthermore agree that your submission of this form, via the 'I Agree' button, shall constitute the execution of this document in exactly the same manner as if you had signed, by hand, a paper version of this agreement.
                      </div>
                      <div className="GeojiCnInnerWaiverSignage">
                        <div className="GeojiCnInnerWaiverSignageTop">
                          <div className="GeojiCnInnerWaiverSignageTopDate">
                            {Helpers.formatDateObjectShort(this.state.signedWaiver.creationTimestamp)}
                          </div>
                          <div className="GeojiCnInnerWaiverSignageTopTime">
                            {Helpers.formatDateObjectTime(this.state.signedWaiver.creationTimestamp)}
                          </div>
                        </div>
                        <div className="GeojiCnInnerWaiverSignageBottom">
                          <div className="GeojiCnInnerWaiverSignageBottomSignature">
                            <img src={ImageSignature} alt="Signature" />
                          </div>
                          <div className="GeojiCnInnerWaiverSignageBottomName">
                            {this.state.signedWaiver.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                { this.state.subview === "Email" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar">
                      <div className="GeojiCnInnerPromptTopBarTitle">
                        Finish Purchase
                      </div>
                    </div>

                    <div className="GeojiCnInnerPurchaseEmail">
                      {/* Require Email */}
                      { (!this.props.userInfo.user.email || this.props.userInfo.user.email.length === 0) &&
                        <div className="GeojiCnInnerPurchaseField">
                          <div className="InputDiv">
                            <Components.InputBottomLine type="text" name="email" validation="email" required="true" validateTyping={false}
                              title={"Email"}
                              placeholder="email@address.com"
                              tabIndex="1"
                              saving={this.state.emailSavedCount > 0}
                              value={this.state.data.email ? this.state.data.email.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          </div>
                        </div>
                      }

                      {/* Ask for Instagram */}
                      { this.state.geoji.showInstagram === "1" && this.state.purchase.skipInstagram === "0" && (!this.props.userInfo.user.instagramUsername || this.props.userInfo.user.instagramUsername.length === 0) &&
                        <div className="GeojiCnInnerPurchaseField">
                          <div className="InputDiv">
                            <Components.InputBottomLine type="text" name="instagramUsername" validation="text" required="false" validateTyping={false}
                              title={"Instagram Username"}
                              placeholder="@instagram"
                              tabIndex="2"
                              saving={this.state.emailSavedCount > 0}
                              value={this.state.data.instagramUsername ? this.state.data.instagramUsername.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          </div>
                        </div>
                      }

                      {/* Require Notes */}
                      { this.state.geoji.notesRequired === "1" &&
                        <div className="GeojiCnInnerPurchaseField">
                          <div className="InputDiv">
                            <Components.InputBottomLine type="text" name="notes" validation="text" required="true" validateTyping={false}
                              title={this.state.geoji.notesTitle ? this.state.geoji.notesTitle : "Notes"}
                              placeholder={this.state.geoji.notesPlaceholder ? this.state.geoji.notesPlaceholder : "Table #, guests under your name, ..."}
                              description={this.state.geoji.notesDescription ? this.state.geoji.notesDescription : "add special instructions"}
                              tabIndex="3"
                              saving={this.state.emailSavedCount > 0}
                              value={this.state.data.notes ? this.state.data.notes.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          </div>
                        </div>
                      }

                      {/* Submit Button */}
                      { this.state.subloading &&
                        <div className="GeojiCnInnerPurchaseEmailButtonLoading">
                        </div>
                      }
                      { !this.state.subloading &&
                        <div className={"GeojiCnInnerPurchaseEmailButton" + (this.state.shakeButton ? " AnimationShake" : "")} onClick={this.submitForm}>
                          Finish Purchase
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            }
            { this.state.view === "Admin" &&
              <div className="GeojiCnInner GeojiCnInnerExpanded">
                {/* Dashboard */}
                { this.state.subview === "Dashboard" &&
                  <div className="GeojiCnAdminDashboard">
                    <RealTimeSocket channel={"RTS_3_" + this.state.geoji.geojiID} callback={this.realTimeUpdate.bind(this)} />
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.props.updatePurchaseID.bind(this, false)}>
                        <img src={ImageBackWhite} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        Dashboard
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Revenue & QR Code */}
                    <div className="GeojiCnAdminRevenue">
                      <div className="GeojiCnAdminRevenueLeft">
                        <div className="GeojiCnAdminRevenueTitle">
                          { Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji, true) ? "Revenue" : "For Walk-ups" }
                        </div>
                        {/* Update dollars and cents. */}
                        <div className="GeojiCnAdminRevenueTotal">
                          <div className="GeojiCnAdminRevenueTotalDollar">
                            {Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji, true) ? Helpers.revenueToDollars(Helpers.totalRevenue(this.state.geoji)) : "Tap QR Code"}
                          </div>
                          <div className="GeojiCnAdminRevenueTotalCents">
                            {Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji, true) ? Helpers.revenueToCents(Helpers.totalRevenue(this.state.geoji)) : ""}
                          </div>
                        </div>
                        <div className="GeojiCnAdminRevenueToday">
                          <div className="GeojiCnAdminRevenueTodayDollar">
                            {Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji, true) ? "Today " + Helpers.revenueToDollars(Helpers.todayRevenue(this.state.geoji)) : ""}
                          </div>
                          <div className="GeojiCnAdminRevenueTodayCents">
                            {Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji, true) ? Helpers.revenueToCents(Helpers.todayRevenue(this.state.geoji)) : ""}
                          </div>
                        </div>
                      </div>
                      {/* Edit Button */}
                      { Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) &&
                        <div className="GeojiCnAdminRevenueEdit" onClick={this.showEditGeoji.bind(this)}>
                          Edit
                        </div>
                      }
                    </div>

                    {/* White View */}
                    <div className="GeojiCnAdminWhite">
                      {/* Orders */}
                      <div className="GeojiCnAdminWhiteC">
                        <div className="GeojiCnAdminWhiteCHeader">
                          Orders
                        </div>

                        <div className="GeojiCnAdminWhiteRedemptions">
                          {/* AZ */}
                          <div className="GeojiCnAdminWhiteRedemption GeojiCnAdminWhiteRedemptionAZ" onClick={this.props.updatePurchaseID.bind(this, "dashboard_az")}>
                            <div className="GeojiCnAdminWhiteRedemptionTop">
                              <div className="GeojiCnAdminWhiteRedemptionName">
                                Search<br/>A to Z
                              </div>
                              <img src={ImageAZ} alt="AZ Redemption" />
                            </div>
                            <div className="GeojiCnAdminWhiteRedemptionBody">
                              Orders listed by name
                            </div>
                          </div>

                          {/* Queue */}
                          <div className="GeojiCnAdminWhiteRedemption GeojiCnAdminWhiteRedemptionQueue" onClick={this.props.updatePurchaseID.bind(this, "dashboard_queue")}>
                            <div className="GeojiCnAdminWhiteRedemptionTop">
                              <div className="GeojiCnAdminWhiteRedemptionName">
                                Queue<br/>&nbsp;
                              </div>
                              <img src={ImageQueue} alt="Queue Redemption" />
                            </div>
                            <div className="GeojiCnAdminWhiteRedemptionBody">
                              Orders listed by time
                            </div>
                          </div>

                          {/* Virtual Terminal */}
                          <div className="GeojiCnAdminWhiteRedemption GeojiCnAdminWhiteRedemptionVirtualTerminal" onClick={this.showConfirmation.bind(this, "Download our app to use the Virtual Terminal", "Our app allows you to scan credit cards faster than cash, chipping, or swipping. Download Geoji on the App Store to use this feature.", "Download App", "Cancel", (action) => { if (action) { this.props.changeView("Download") }})}>
                            <div className="GeojiCnAdminWhiteRedemptionTop">
                              <div className="GeojiCnAdminWhiteRedemptionName">
                                Virtual Terminal
                              </div>
                              <img src={ImageVirtualTerminal} alt="Virtual Terminal" />
                            </div>
                            <div className="GeojiCnAdminWhiteRedemptionBody">
                              Scan & process cards easily
                            </div>
                          </div>

                          {/* Fire Marshal */}
                          <div className="GeojiCnAdminWhiteRedemption GeojiCnAdminWhiteRedemptionFireMarshal">
                            <div className="GeojiCnAdminWhiteRedemptionTop">
                              <div className="GeojiCnAdminWhiteRedemptionName">
                                Fire Marshal
                              </div>
                              <img src={ImageFireMarshal} alt="Body Count" />
                            </div>
                            <div className="GeojiCnAdminWhiteRedemptionBody">
                              {Helpers.formatNumberDecimals(this.state.geoji.fireMarshalCount, 0)} Guests in attendance
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* List of Tokens */}
                      { this.state.geoji.tokens && this.state.geoji.tokens.length > 0 && Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) &&
                        <div className="GeojiCnAdminWhiteC">
                          <div className="GeojiCnAdminWhiteCHeader">
                            Sales
                          </div>
                          <div className="GeojiCnAdminWhiteTokens">
                            {/* All Sales */}
                            <div className="GeojiCnAdminWhiteToken GeojiCnAdminWhiteTokenAll"
                              onClick={
                                Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) ?
                                this.props.updatePurchaseID.bind(this, "dashboard_token_all") :
                                undefined
                              }>
                              <div className="GeojiCnAdminWhiteTokenTop">
                                <img className="GeojiCnAdminWhiteTokenTopBubbleFull" src={ImageGeojiCoinShape} alt="Geoji Coin" />
                              </div>
                              
                              <div className="GeojiCnAdminWhiteTokenTitle">
                                <span>🛍</span> All Sales <span>🛍</span>
                              </div>

                              <div className="GeojiCnAdminWhiteTokenLine">
                              </div>

                              <div className="GeojiCnAdminWhiteTokenSoldTitle">
                                Tap for Charts
                              </div>
                            </div>

                            {/* Individual Tokens */}
                            { Helpers.geojiFreshTokens(this.state.geoji).map((token, indi) => (
                              <div className="GeojiCnAdminWhiteToken" key={"token_" + token.id}
                                onClick={
                                  Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) ?
                                  this.props.updatePurchaseID.bind(this, "dashboard_token_" + token.id) :
                                  undefined
                                }
                                >
                                {/* style={{"background": "#" + Helpers.tokenColor(indi)}} */}
                                <div className="GeojiCnAdminWhiteTokenTop">
                                  { token.quantity !== 0 &&
                                    <div className="GeojiCnAdminWhiteTokenTopBubble" style={{"background": "#" + Helpers.tokenColorTransparent(indi)}}>
                                      <CircularProgressbarWithChildren min={0} max={100} value={Math.max(1, 100 * Math.min(token.tokensSold / token.quantity, 1))} strokeWidth={4}
                                        styles={buildStyles({
                                          strokeLinecap: "butt",
                                          pathColor: "#" + Helpers.tokenColor(indi),
                                          trailColor: "transparent",
                                        })}>
                                        <img className="GeojiCnAdminWhiteTokenTopBubbleImage" src={Helpers.tokenShapeImage(indi)} alt="Geoji Coin" />
                                      </CircularProgressbarWithChildren>
                                    </div>
                                  }
                                  { token.quantity !== 0 &&
                                    <div className="GeojiCnAdminWhiteTokenTopPercent">
                                      {Helpers.tokenPercentSoldString(token)}
                                    </div>
                                  }
                                  { token.quantity === 0 &&
                                    <img className="GeojiCnAdminWhiteTokenTopBubbleFull" src={Helpers.tokenImage(indi)} alt="Geoji Coin" />
                                  }
                                </div>

                                <div className="GeojiCnAdminWhiteTokenTitle">
                                  {token.name}
                                </div>

                                {/* Set data */}
                                <div className="GeojiCnAdminWhiteTokenRevenue">
                                  <div className="GeojiCnAdminWhiteTokenRevenueDollars">
                                    {Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji, true) ? Helpers.revenueToDollars(token.revenueCents) : ""}
                                  </div>
                                  <div className="GeojiCnAdminWhiteTokenRevenueCents">
                                    {Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji, true) ? Helpers.revenueToCents(token.revenueCents) : ""}
                                  </div>
                                </div>

                                <div className="GeojiCnAdminWhiteTokenLine">
                                </div>

                                <div className="GeojiCnAdminWhiteTokenSoldTitle">
                                  Tokens Sold
                                </div>

                                {/* Format numbers with commas */}
                                { token.quantity === 0 &&
                                  <div className="GeojiCnAdminWhiteTokenSoldValue">
                                    {token.tokensSold.toLocaleString("en-US")}
                                  </div>
                                }
                                { token.quantity !== 0 &&
                                  <div className="GeojiCnAdminWhiteTokenSoldValue">
                                    {token.tokensSold.toLocaleString("en-US")} / {token.quantity.toLocaleString("en-US")}
                                  </div>
                                }
                              </div>
                            ))}
                          </div>
                        </div>
                      }

                      {/* Tips */}
                      {/* <AdminTips tipTapped={this.tipTapped.bind(this)} /> */}

                      {/* Actions */}
                      <div className="GeojiCnAdminWhiteC">
                        <div className="GeojiCnAdminWhiteCHeader">
                          Actions
                        </div>

                        {/* Send Free Tokens */}
                        { Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) && Helpers.geojiSendableTokens(this.state.geoji).length > 0 &&
                          <div className="GeojiCnAdminWhiteAction GeojiCnAdminWhiteActionFreeTokens" onClick={this.props.updatePurchaseID.bind(this, "dashboard_sft")}>
                            <img src={ImagePlane} alt="Send" />
                            <div className="GeojiCnAdminWhiteActionRight">
                              <div className="GeojiCnAdminWhiteActionRightTitle">
                                Send Free Tokens
                              </div>
                              <div className="GeojiCnAdminWhiteActionRightBody">
                                Invite special guests and influencers
                              </div>
                            </div>
                          </div>
                        }

                        {/* Copy Link */}
                        <div className={"GeojiCnAdminWhiteAction " + (this.state.copyingAdminLink ? "GeojiCnAdminWhiteActionCopying" : "GeojiCnAdminWhiteActionCopy")} onClick={this.copyAdminLink.bind(this)}>
                          <img src={this.state.copyingAdminLink ? ImageCheckmark : ImageCopy} alt="Copy" />
                          <div className="GeojiCnAdminWhiteActionRight">
                            <div className="GeojiCnAdminWhiteActionRightTitle">
                              { this.state.copyingAdminLink &&
                                <span>Beep Boop</span>
                              }
                              { !this.state.copyingAdminLink && this.state.copyAdminLink &&
                                <span>Tap Again to Copy</span>
                              }
                              { !this.state.copyingAdminLink && !this.state.copyAdminLink &&
                                <span>Copy Link</span>
                              }
                            </div>
                            <div className="GeojiCnAdminWhiteActionRightBody">
                              { this.state.copyingAdminLink &&
                                <span>Link copied and ready to paste</span>
                              }
                              { !this.state.copyingAdminLink && this.state.copyAdminLink &&
                                <span>Private link generated</span>
                              }
                              { !this.state.copyingAdminLink && !this.state.copyAdminLink &&
                                <span>Paste and share everywhere</span>
                              }
                            </div>
                          </div>
                        </div>

                        {/* Promoter Links - Track sales by link */}
                        { Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) &&
                          <div className={"GeojiCnAdminWhiteAction GeojiCnAdminWhiteActionPromo"} onClick={this.props.updatePurchaseID.bind(this, "dashboard_pl")}>
                            <img src={ImagePromoterLinks} alt="Promoter Links" />
                            <div className="GeojiCnAdminWhiteActionRight">
                              <div className="GeojiCnAdminWhiteActionRightTitle">
                                Promoter Links
                              </div>
                              <div className="GeojiCnAdminWhiteActionRightBody">
                                Track sales by link
                              </div>
                            </div>
                          </div>
                        }

                        {/* Promo Codes - Discounts on purchases */}
                        { Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) &&
                          <div className={"GeojiCnAdminWhiteAction GeojiCnAdminWhiteActionPromo"} onClick={this.props.updatePurchaseID.bind(this, "dashboard_pc")}>
                            <img src={ImagePromoCodes} alt="Promo Codes" />
                            <div className="GeojiCnAdminWhiteActionRight">
                              <div className="GeojiCnAdminWhiteActionRightTitle">
                                Promo Codes
                              </div>
                              <div className="GeojiCnAdminWhiteActionRightBody">
                                Discounts on purchases
                              </div>
                            </div>
                          </div>
                        }

                        {/* Edit Geoji */}
                        { Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) &&
                          <div className={"GeojiCnAdminWhiteAction GeojiCnAdminWhiteActionEditGeoji"} onClick={this.showEditGeoji.bind(this)}>
                            <img src={ImagePencilWhite} alt="Edit" />
                            <div className="GeojiCnAdminWhiteActionRight">
                              <div className="GeojiCnAdminWhiteActionRightTitle">
                                Edit Geoji
                              </div>
                              <div className="GeojiCnAdminWhiteActionRightBody">
                                Update location, details, tokens, ...
                              </div>
                            </div>
                          </div>
                        }

                        {/* Reports */}
                        { Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) &&
                          <div className={"GeojiCnAdminWhiteAction GeojiCnAdminWhiteActionDownload"} onClick={this.props.updatePurchaseID.bind(this, "dashboard_reports")}>
                            <img src={ImageChart} alt="Reports" />
                            <div className="GeojiCnAdminWhiteActionRight">
                              <div className="GeojiCnAdminWhiteActionRightTitle">
                                Reports
                              </div>
                              <div className="GeojiCnAdminWhiteActionRightBody">
                                Stats, data, & more
                              </div>
                            </div>
                          </div>
                        }

                        {/* Settings - Notifications, cash settings, ... */}
                        { Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) &&
                          <div className={"GeojiCnAdminWhiteAction GeojiCnAdminWhiteActionSettings"} onClick={this.props.updatePurchaseID.bind(this, "dashboard_settings")}>
                            <img src={ImageSettings} alt="Settings Wheel" />
                            <div className="GeojiCnAdminWhiteActionRight">
                              <div className="GeojiCnAdminWhiteActionRightTitle">
                                Settings
                              </div>
                              <div className="GeojiCnAdminWhiteActionRightBody">
                                Notifications, cash settings, ...
                              </div>
                            </div>
                          </div>
                        }

                        {/* QR Code - Only use if customer insists on Apple Pay */}
                        <div className={"GeojiCnAdminWhiteAction GeojiCnAdminWhiteActionQRCode"} onClick={this.props.updatePurchaseID.bind(this, "dashboard_qrcode")}>
                          <img src={ImageQRCode} alt="QR Code" />
                          <div className="GeojiCnAdminWhiteActionRight">
                            <div className="GeojiCnAdminWhiteActionRightTitle">
                              QR Code
                            </div>
                            <div className="GeojiCnAdminWhiteActionRightBody">
                              Print a QR Code for customers to scan and pay on their own
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Team */}
                      <div className="GeojiCnAdminWhiteC">
                        <div className="GeojiCnAdminWhiteCHeader">
                          Team
                        </div>
                        <div className="GeojiCnAdminWhiteTeamMembers">
                          {/* Actions to edit the team member's role, profit share, etc. */}

                          { this.state.geoji.creators.map((creator, index) => (
                            <div className="GeojiCnAdminWhiteTeamMember" key={"creator_" + creator.id}>
                              <div className="GeojiCnAdminWhiteTeamMemberInner">
                                <div className="GeojiCnAdminWhiteTeamMemberImage">
                                  { creator.profilePicture && creator.profilePicture.length > 0 &&
                                    <Components.DualImage image={creator.profilePicture} fallback={ImagePersonGray} />
                                  }
                                  { (!creator.profilePicture || creator.profilePicture.length === 0) &&
                                    <Components.DualImage className="defaultImage" image={ImagePersonGray} />
                                  }
                                </div>
                                <div className="GeojiCnAdminWhiteTeamMemberContent">
                                  <div className="GeojiCnAdminWhiteTeamMemberContentLeft">
                                    <div className="GeojiCnAdminWhiteTeamMemberName">
                                      {Helpers.displayableName(creator)}
                                    </div>
                                    <div className="GeojiCnAdminWhiteTeamMemberRole">
                                      { !creator.editingOwnership &&
                                        <div className="GeojiCnAdminWhiteTeamMemberRoleLeft">
                                          {Helpers.displayableRole(creator)}
                                        </div>
                                      }
                                      <div className="GeojiCnAdminWhiteTeamMemberRoleRight">
                                        {Helpers.displayableOwnership(creator)}
                                      </div>
                                      { creator.editingOwnership &&
                                        <div className="GeojiCnAdminWhiteTeamMemberRoleEdit">
                                            <div className="GeojiCnAdminWhiteTeamMemberRoleEditMinus" onClick={this.creatorContextUpdateOwnership.bind(this, creator, false)}>—</div>
                                            <div className="GeojiCnAdminWhiteTeamMemberRoleEditLine"></div>
                                            <div className="GeojiCnAdminWhiteTeamMemberRoleEditPlus" onClick={this.creatorContextUpdateOwnership.bind(this, creator, true)}>+</div>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                  <div className="GeojiCnAdminWhiteTeamMemberContentRight">
                                    { !creator.editingOwnership &&
                                      <ContextMenuTrigger id={"creator_context_menu_" + creator.id} leftClickEnabled={true}>
                                        <Components.DualImage className="defaultImage" image={ImageEllipse} />
                                      </ContextMenuTrigger>
                                    }
                                    { creator.editingOwnership &&
                                      <Components.DualImage className="defaultImage" image={ImageCheckmarkBlack} onClick={this.creatorContextSaveOwnership.bind(this, creator)} />
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="GeojiCnAdminWhiteTeamMemberLine">
                              </div>
                            </div>
                          ))}
                          { this.state.geoji.creators.map((creator, index) => (
                            <ContextMenu key={"creator_menu_" + creator.id} id={"creator_context_menu_" + creator.id}>
                              {/* Show the options for this creator */}
                              {this.creatorContextOptions(creator)}
                            </ContextMenu>
                          ))}

                          <div className="GeojiCnAdminWhiteTeamMembersInvite" onClick={this.props.updatePurchaseID.bind(this, "dashboard_invite")}>
                            <img src={ImageInvitePerson} alt="Invite" />
                            Invite Team Member
                          </div>
                        </div>
                      </div>

                      {/* TODO - Task List */}
                      {/*<div className="GeojiCnAdminWhiteC">
                        <div className="GeojiCnAdminWhiteCHeader">
                          Task List
                        </div>
                      </div>*/}
                    </div>
                  </div>
                }

                {/* QR Code */}
                { this.state.subview === "QRCode" &&
                  <div className="GeojiCnAdminQRCode">
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.props.updatePurchaseID.bind(this, "dashboard")}>
                        <img src={ImageBackWhite} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        QR Code
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Scan Me */}
                    <div className="GeojiCnAdminQRCodeTitle">
                      Scan Me
                    </div>

                    {/* QR Code */}
                    <div className="GeojiCnAdminQRCodeImage">
                      <div className="GeojiCnAdminQRCodeImageInner">
                        <QRCodeSVG id="QRCode" value={this.props.geojiAccess === "private" ? ("https://geoji.com/p/" + this.props.geojiLink) : ("https://geoji.com/g/" + this.props.geojiLink)} style={{width: "100%", height: "100%"}} />
                      </div>
                    </div>

                    {/* Export Options */}
                    <div className="GeojiCnAdminQRCodeActions">
                      <div className="GeojiCnAdminQRCodeAction" onClick={this.printQRCode.bind(this)}>
                        <img src={ImagePrint} className="GeojiCnAdminQRCodeActionImage" alt="share" />
                      </div>
                    </div>
                  </div>
                }

                {/* AZ Redemptions */}
                { this.state.subview === "AZRedemptions" &&
                  <div className="GeojiCnAdminAZRedemptions">
                    <RealTimeSocket channel={"RTS_3_" + this.state.geoji.geojiID} callback={this.realTimeUpdate.bind(this)} />
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.props.updatePurchaseID.bind(this, "dashboard")}>
                        <img src={ImageBack} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        Order List
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Wristbands if necessary */}
                    { this.state.geoji.wristbands && this.state.geoji.wristbands.length > 0 &&
                      <div className="GeojiCnAdminAZWristbands">
                        { this.state.geoji.wristbands.map((band, i) => (
                          <div className="GeojiCnAdminAZWristband" key={"band_" + i}>
                            <GeojiWristband wristband={band} />

                            <div className="GeojiCnAdminAZWristbandNames">
                              { band.tokenName.map((name, jj) => (
                                <div className="GeojiCnAdminAZWristbandName" key={"name_" + jj}>
                                  ‣ {name}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    }

                    {/* Search Bar */}
                    <div className="GeojiCnAdminAZSearchBar">
                      <div className="InputDiv">
                        <Components.InputBottomLine type="text" name="adminSearch" placeholder="Search"
                          validation="text" required="false" validateTyping={false} tabIndex="1"
                          value={this.state.data.adminSearch ? this.state.data.adminSearch.value : ""} onEnter={this.submitForm}
                          onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                    </div>

                    {/* Loading */}
                    { this.state.loadingAdminOrders && this.state.adminPurchases.length === 0 &&
                      <div className="GeojiCnAdminRLoading">
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                      </div>
                    }
                    {/* No Orders */}
                    { !this.state.loadingAdminOrders && this.state.adminPurchases.length === 0 &&
                      <div className="GeojiCnAdminRNone">
                        No orders found.
                      </div>
                    }
                    {/* Orders */}
                    { this.state.adminPurchases.length > 0 &&
                      <div className="GeojiCnAdminOrdersList">
                        { this.state.adminPurchases.map((order, indi) => (
                          <div className={"GeojiCnAdminOrder " + ((order.purchase.redeemed === "1" || order.purchase.status === "Refunded") ? "GeojiCnAdminOrderTransparent" : "")}
                            key={"order_" + order.purchase.id + "_" + indi} onClick={this.props.updatePurchaseID.bind(this, "dashboard_" + order.purchase.id)}>
                            { order.purchase.notes && order.purchase.notes.length > 0 &&
                              <div className="GeojiCnAdminOrderNotes">
                                <img src={ImageNotes} alt="Notes" />
                              </div>
                            }
                            { order.purchase.giveaway === "1" &&
                              <div className="GeojiCnAdminOrderGiveaway">
                                <img src={ImageGuest} alt="Guest" />
                              </div>
                            }
                            { parseInt(order.purchase.waiversSigned) >= parseInt(order.purchase.waiversRequired) && parseInt(order.purchase.waiversRequired) > 0 &&
                              <div className="GeojiCnAdminOrderWaiverSuccess">
                                <img src={ImageWaiverSuccess} alt="Waiver Success" />
                              </div>
                            }
                            { parseInt(order.purchase.waiversSigned) < parseInt(order.purchase.waiversRequired) && parseInt(order.purchase.waiversRequired) > 0 &&
                              <div className="GeojiCnAdminOrderWaiverFailed">
                                <img src={ImageWaiverFailed} alt="Waiver Failed" />
                              </div>
                            }
                            <div className="GeojiCnAdminOrderInner">
                              <div className="GeojiCnAdminOrderButton" onClick={this.adminRedeemOrderFromQueue.bind(this, order)}>
                                <img src={(order.purchase.redeemed === "1" || order.purchase.status === "Refunded") ? ImageCheckmarkBlack : ImageEmptyCheckmark} alt="Checkmark" />
                              </div>
                              <div className="GeojiCnAdminOrderImage">
                                { order.profilePicture && order.profilePicture.length > 0 &&
                                  <Components.DualImage image={order.profilePicture} fallback={ImagePersonGray} />
                                }
                                { (!order.profilePicture || order.profilePicture.length === 0) &&
                                  <div className="GeojiCnAdminOrderImageInitials">
                                    {Helpers.getInitialsFromName(order.name)}
                                  </div>
                                }
                              </div>
                              <div className="GeojiCnAdminOrderContent">
                                <div className="GeojiCnAdminOrderContentName">
                                  {order.name}
                                </div>
                                <div className="GeojiCnAdminOrderContentBody">
                                  {order.purchase.description}
                                </div>
                              </div>
                            </div>
                            <div className="GeojiCnAdminOrderLine">
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                    <div className="GeojiCnAdminListEnd" ref={this.adminListAZEnd}>
                    </div>
                  </div>
                }

                {/* Queue Redemptions */}
                { this.state.subview === "QueueRedemptions" &&
                  <div className="GeojiCnAdminQueueRedemptions">
                    <RealTimeSocket channel={"RTS_3_" + this.state.geoji.geojiID} callback={this.realTimeUpdate.bind(this)} />
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.props.updatePurchaseID.bind(this, "dashboard")}>
                        <img src={ImageBack} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        Order Queue
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Loading */}
                    { this.state.loadingAdminOrders && this.state.adminPurchases.length === 0 &&
                      <div className="GeojiCnAdminRLoading">
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                      </div>
                    }
                    {/* No Orders */}
                    { !this.state.loadingAdminOrders && this.state.adminPurchases.length === 0 &&
                      <div className="GeojiCnAdminRNone">
                        No orders found.
                      </div>
                    }
                    {/* Orders */}
                    { this.state.adminPurchases.length > 0 &&
                      <div className="GeojiCnAdminOrdersList">
                        { this.state.adminPurchases.map((order, indi) => (
                          <div className={"GeojiCnAdminOrder " + ((order.purchase.redeemed === "1" || order.purchase.status === "Refunded") ? "GeojiCnAdminOrderTransparent" : "")}
                            key={"order_" + order.purchase.id + "_" + indi} onClick={this.props.updatePurchaseID.bind(this, "dashboard_q" + order.purchase.id)}>
                            <div className="GeojiCnAdminOrderInner">
                              <div className="GeojiCnAdminOrderImage">
                                { order.profilePicture && order.profilePicture.length > 0 &&
                                  <Components.DualImage image={order.profilePicture} fallback={ImagePersonGray} />
                                }
                                { (!order.profilePicture || order.profilePicture.length === 0) &&
                                  <Components.DualImage className="defaultImage" image={ImagePersonGray} />
                                }
                              </div>
                              <div className="GeojiCnAdminOrderContent">
                                <div className="GeojiCnAdminOrderContentName">
                                  {order.name}
                                </div>
                                <div className="GeojiCnAdminOrderContentBody">
                                  {order.purchase.description}
                                </div>
                                { order.purchase.notes && order.purchase.notes.length > 0 &&
                                  <div className="GeojiCnAdminOrderContentNotes">
                                    {order.purchase.notes}
                                  </div>
                                }
                              </div>
                              <div className="GeojiCnAdminOrderButton" onClick={this.adminRedeemOrderFromQueue.bind(this, order)}>
                                <img src={(order.purchase.redeemed === "1" || order.purchase.status === "Refunded") ? ImageCheckmarkBlack : ImageEmptyCheckmark} alt="Checkmark" />
                              </div>
                            </div>
                            <div className="GeojiCnAdminOrderLine">
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                    <div className="GeojiCnAdminListEnd" ref={this.adminListQueueEnd}>
                    </div>
                  </div>
                }

                {/* Order Details */}
                { this.state.subview === "OrderDetails" &&
                  <div className="GeojiCnAdminOrderDetails">
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.backFromOrderDetails.bind(this)}>
                        <img src={ImageBack} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        Order Details
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Image, Name, Status */}
                    <div className="GeojiCnAdminOrderDetailsInner">
                      <div className="GeojiCnAdminOrderDetailsImage">
                        { this.state.adminOrder.profilePicture && this.state.adminOrder.profilePicture.length > 0 &&
                          <Components.DualImage image={this.state.adminOrder.profilePicture} fallback={ImagePersonGray} />
                        }
                        { (!this.state.adminOrder.profilePicture || this.state.adminOrder.profilePicture.length === 0) &&
                          <Components.DualImage className="defaultImage" image={ImagePersonGray} />
                        }
                      </div>
                      <div className="GeojiCnAdminOrderDetailsContent">
                        <div className="GeojiCnAdminOrderDetailsContentName">
                          {this.state.adminOrder.name}
                        </div>
                        {/* Status */}
                        <div className="GeojiCnAdminOrderDetailsContentStatus" style={{color: Helpers.orderStatusColor(this.state.adminOrder.purchase)}}>
                          {Helpers.displayableOrderStatus(this.state.adminOrder.purchase)}
                        </div>
                      </div>
                    </div>

                    {/* Purchase Timestamp */}
                    <div className="GeojiCnAdminOrderDetailsPurchaseTimestamp">
                      Purchased on {Helpers.displayableOrderPurchaseDate(this.state.adminOrder.purchase)}
                    </div>

                    {/* Notes */}
                    { this.state.adminOrder.purchase.notes && this.state.adminOrder.purchase.notes.length > 0 &&
                      <div className="GeojiCnAdminOrderDetailsNotesSection">
                        <div className="GeojiCnAdminOrderDetailsNotesTitle">
                          Notes
                        </div>
                        <div className="GeojiCnAdminOrderDetailsNotesBody">
                          {this.state.adminOrder.purchase.notes}
                        </div>
                      </div>
                    }

                    {/* Loading Status */}
                    { this.state.adminOrderLoading &&
                      <div className="GeojiCnAdminRLoading" style={{paddingTop:"16px"}}>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                      </div>
                    }
                    {/* Redeem Tokens */}
                    { !this.state.adminOrderLoading &&
                      <div className="GeojiCnAdminOrderDetailsTokens">
                        <div className="GeojiCnAdminOrderDetailsAllTokens" onClick={this.adminRedeemOrderToken.bind(this, false)}>
                          <img src={this.state.adminOrder.purchase.redeemed === "1" ? ImageCheckmarkBlack : ImageEmptyCheckmark} alt="Checkmark" />
                          <div className="GeojiCnAdminOrderDetailsAllTokensTitle">
                            Tokens (redeem all)
                          </div>
                        </div>
                        { Helpers.orderTokensArray(this.state.adminOrder.purchase).map((token, index) => (
                          <div className="GeojiCnAdminOrderDetailsIndiToken" key={"token_" + token.id + "_" + index}
                            onClick={this.adminRedeemOrderToken.bind(this, token)}>
                            <img src={token.checked ? ImageCheckmarkBlack : ImageEmptyCheckmark} alt="Checkmark" />
                            <div className="GeojiCnAdminOrderDetailsIndiTokenTitle">
                              {Helpers.formatTokenSpecialName(token)}<span>{(Helpers.formatTokenSpecialText(token))}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    }

                    {/* -- Actions -- */}

                    {/* Changing the Ticket Date */}
                    { this.state.changingTicketDate &&
                      <div className="GeojiCnAdminOrderDetailsChangeDate">
                        {/* Header */}
                        <div className="GeojiCnAdminOrderDetailsActionsHeader">
                          Change Ticket Date
                        </div>

                        {/* Show the Calendar */}
                        <div className="GeojiCnInnerGrayGroupTokenElement">
                          <div className={"GeojiCnInnerGrayGroupTokenDateTime " + (this.state.changeToken.soldOut ? "GeojiCnInnerGrayGroupTokenSoldOut" : "")}>
                            <div className="GeojiCnInnerGrayGroupTokenDateTimeTitle">
                              {Helpers.getDateTimeTitleText(this.state.changeToken)}
                            </div>
                            <div className="GeojiCnInnerGrayGroupTokenDateTimeCalendar">
                              <Calendar
                                calendar={gregorian}
                                locale={gregorian_en}
                                multiple={true}
                                value={Helpers.tokenDateTimeAvailableDates(this.state.changeToken)}
                                currentDate={this.state.geoji.calendarFocusDate}
                                plugins={[colors({ defaultColor: "blue", colors: ["blue", "green"] })]}
                                onChange={this.tokenDateTimeSelected.bind(this, this.state.changeToken)}
                                readOnly={false}
                                weekStartDayIndex={(this.state.changeToken.mapData && this.state.changeToken.mapData.firstWeekday) ? this.state.changeToken.mapData.firstWeekday : 0}
                              />
                            </div>
                            { this.state.changeToken.datetimeselected &&
                              <div className="GeojiCnInnerGrayGroupTokenDateTimeTimes">
                                {/*<div className="GeojiCnInnerGrayGroupTokenDateTimeTimesName">
                                  Select a time
                                </div>*/}
                                { Helpers.tokenDateTimeAllTimes(this.state.changeToken).map((time, i) => (
                                  <div key={"time_" + time.id + "_" + i} className={"GeojiCnInnerGrayGroupTokenDateTimeTime " + ((parseInt(time.tokensSold) >= parseInt(time.quantity) && parseInt(time.quantity) !== 0) ? "GeojiCnInnerGrayGroupTokenDateTimeTimeSoldOut" : "")}
                                    style={{background: time.selected ? "#8AC349" : time.color}}
                                    onClick={this.tokenDateTimeTimeSelected.bind(this, this.state.changeToken, time)}>
                                    {time.time}
                                  </div>
                                ))}
                              </div>
                            }
                          </div>
                        </div>

                        {/* Actions */}
                        <div className="GeojiCnAdminOrderDetailsAction" onClick={this.updateTicketDate.bind(this)}>
                          <div className="GeojiCnAdminOrderDetailsActionHeader">
                            Change Date
                          </div>
                        </div>
                        <div className="GeojiCnAdminOrderDetailsAction" onClick={this.showAdminChangeTicketDate.bind(this)}>
                          <div className="GeojiCnAdminOrderDetailsActionHeader">
                            Cancel
                          </div>
                        </div>
                      </div>
                    }

                    {/* Sending Ticket Receipt */}
                    { this.state.adminShowingSendReceipt &&
                      <div className="GeojiCnAdminOrderDetailsSendReceipt">
                          {/* Header */}
                          <div className="GeojiCnAdminOrderDetailsActionsHeader">
                            Send Receipt
                          </div>

                          { this.state.subloading &&
                            <div className="GeojiCnAdminOrderDetailsSendReceiptLoading">
                              <div className="GeojiCnAdminOrderDetailsSendReceiptLoadingBar">
                              </div>
                              <div className="GeojiCnAdminOrderDetailsSendReceiptLoadingBar">
                              </div>
                              <div className="GeojiCnAdminOrderDetailsSendReceiptLoadingBar">
                              </div>
                            </div>
                          }
                          { !this.state.subloading &&
                            <span>
                              {/* Toggle between email and text */}
                              <div className="GeojiCnAdminOrderDetailsSendReceiptToggle">
                                <div className="GeojiCnAdminOrderDetailsSendReceiptToggleText">
                                  Email
                                </div>
                                <Toggle defaultChecked={this.state.adminSendReciptByText} onChange={this.toggleAdminSendReciptByText.bind(this)}
                                  icons={false} />
                                <div className="GeojiCnAdminOrderDetailsSendReceiptToggleText">
                                  Text
                                </div>
                              </div>

                              {/* Email Input */}
                              { this.state.adminSendReceiptByText &&
                                <div className="InputDiv">
                                  <Components.InputBottomLine type="text" name="sendReceiptPhoneNumber" placeholder="1 (512) 333 - 4444" validation="phoneNumber" required="true" validateTyping={false}
                                    tabIndex="1" title="Phone Number"
                                    value={this.state.data.sendReceiptPhoneNumber ? this.state.data.sendReceiptPhoneNumber.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                                </div>
                              }
                              { !this.state.adminSendReceiptByText &&
                                <div className="InputDiv">
                                  <Components.InputBottomLine type="text" name="sendReceiptEmail" placeholder="address@email.com" validation="email" required="true" validateTyping={false}
                                    tabIndex="2" title="Email"
                                    value={this.state.data.sendReceiptEmail ? this.state.data.sendReceiptEmail.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                                </div>
                              }

                              {/* Actions */}
                              <div className="GeojiCnAdminOrderDetailsAction" onClick={this.submitForm}>
                                <div className="GeojiCnAdminOrderDetailsActionHeader">
                                  Send Receipt
                                </div>
                              </div>
                              <div className="GeojiCnAdminOrderDetailsAction" onClick={this.adminShowSendReceipt.bind(this)}>
                                <div className="GeojiCnAdminOrderDetailsActionHeader">
                                  Cancel
                                </div>
                              </div>
                            </span>
                          }
                      </div>
                    }
                    
                    {/* Edit Notes */}
                    { this.state.adminShowingEditNotes &&
                      <div className="GeojiCnAdminOrderDetailsEditNotes">
                        {/* Header */}
                        <div className="GeojiCnAdminOrderDetailsActionsHeader">
                          Edit Notes
                        </div>

                        { this.state.subloading &&
                          <div className="GeojiCnAdminOrderDetailsEditNotesLoading">
                            <div className="GeojiCnAdminOrderDetailsEditNotesLoadingBar">
                            </div>
                            <div className="GeojiCnAdminOrderDetailsEditNotesLoadingBar">
                            </div>
                            <div className="GeojiCnAdminOrderDetailsEditNotesLoadingBar">
                            </div>
                          </div>
                        }
                        { !this.state.subloading &&
                          <span>
                            {/* Notes Input */}
                            <div className="InputDiv">
                              <Components.InputBottomLine type="text" name="editNotesNotes" placeholder="Custom Notes, Address, ..." validation="text" required="false" validateTyping={false}
                                tabIndex="1" title="Notes"
                                value={this.state.data.editNotesNotes ? this.state.data.editNotesNotes.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                            </div>

                            {/* Actions */}
                            <div className="GeojiCnAdminOrderDetailsAction" onClick={this.submitForm}>
                              <div className="GeojiCnAdminOrderDetailsActionHeader">
                                Save Notes
                              </div>
                            </div>
                            <div className="GeojiCnAdminOrderDetailsAction" onClick={this.adminShowEditNotes.bind(this)}>
                              <div className="GeojiCnAdminOrderDetailsActionHeader">
                                Cancel
                              </div>
                            </div>
                          </span>
                        }
                      </div>
                    }

                    {/* Action Buttons */}
                    { !this.state.changingTicketDate && !this.state.adminShowingSendReceipt && !this.state.adminShowingEditNotes &&
                      <div className="GeojiCnAdminOrderDetailsActions">
                        <div className="GeojiCnAdminOrderDetailsActionsHeader">
                          Actions
                        </div>
                        <div className="GeojiCnAdminOrderDetailsAction" onClick={this.adminShowSendReceipt.bind(this)}>
                          <img src={ImageNotesBlack} alt="Send Receipt" />
                          <div className="GeojiCnAdminOrderDetailsActionHeader">
                            Send Receipt
                          </div>
                        </div>
                        <div className="GeojiCnAdminOrderDetailsAction" onClick={this.adminShowEditNotes.bind(this)}>
                          <img src={ImagePencilBlack} alt="Edit Notes" />
                          <div className="GeojiCnAdminOrderDetailsActionHeader">
                            Edit Notes
                          </div>
                        </div>
                        { Helpers.canChangeTicketDate(this.state.adminOrder.purchase) &&
                          <div className="GeojiCnAdminOrderDetailsAction" onClick={this.showAdminChangeTicketDate.bind(this)}>
                            <img src={ImageCalendarDark} alt="Change Ticket Date" />
                            <div className="GeojiCnAdminOrderDetailsActionHeader">
                              Change Ticket Date
                            </div>
                          </div>
                        }
                        { ((this.state.adminOrder.phoneNumber && this.state.adminOrder.phoneNumber.length > 0) || (this.state.adminOrder.tempPhoneNumber && this.state.adminOrder.tempPhoneNumber.length > 0)) &&
                          <div>
                            <div className="GeojiCnAdminOrderDetailsAction" onClick={this.adminTextCustomer.bind(this)}>
                              <img src={ImageText} alt="Text" />
                              <div className="GeojiCnAdminOrderDetailsActionHeader">
                                Text Customer
                              </div>
                            </div>
                            <div className="GeojiCnAdminOrderDetailsAction" onClick={this.adminCallCustomer.bind(this)}>
                              <img src={ImagePhone} alt="Call" />
                              <div className="GeojiCnAdminOrderDetailsActionHeader">
                                Call Customer
                              </div>
                            </div>
                          </div>
                        }
                        { this.state.adminOrder.purchase.status !== "Refunded" &&
                          <div className="GeojiCnAdminOrderDetailsAction" onClick={this.adminRefundPurchase.bind(this)}>
                            <img src={ImageRefund} alt="Refund" />
                            <div className="GeojiCnAdminOrderDetailsActionHeader">
                              Refund Purchase
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }

                {/* Send Free Tokens */}
                { this.state.subview === "SendFreeTokens" &&
                  <div className="GeojiCnAdminSendFreeTokens">
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.props.updatePurchaseID.bind(this, "dashboard")}>
                        <img src={ImageBack} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        Send Free Tokens
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    { Helpers.geojiSendableTokens(this.state.geoji).length === 0 &&
                      <div className="GeojiCnAdminSendFreeTokensEmpty">
                        There is nothing to send. You need to create a token first before you can send it for free.
                      </div>
                    }
                    { Helpers.geojiSendableTokens(this.state.geoji).length > 0 &&
                      <span>
                        {/* List of Tokens - only show the default kind. */}
                        <div className="GeojiCnAdminSendFreeTokensHeader">
                          1) Select Tokens
                        </div>
                        <div className="GeojiCnAdminSendFreeTokensList">
                          { Helpers.geojiSendableTokens(this.state.geoji).map((token, ii) => (
                            <GeojiToken key={"token_" + token.id}
                              token={token} geoji={this.state.geoji}
                              editing={false} onEdit={() => {}} onCopy={() => {}}
                              decrementToken={this.decrementToken.bind(this)}
                              incrementToken={this.incrementToken.bind(this)}
                              tokenChanged={this.tokenChanged.bind(this)}
                              selectedTokenAutoTip={this.selectedTokenAutoTip.bind(this)}
                              promoCodeChanged={this.promoCodeChanged.bind(this)}
                              promoCodeSubmit={this.promoCodeSubmit.bind(this)}
                              selectedTokenMapSeat={this.selectedTokenMapSeat.bind(this)}
                              tokenDateTimeSelected={this.tokenDateTimeSelected.bind(this)}
                              tokenDateTimeTimeSelected={this.tokenDateTimeTimeSelected.bind(this)}
                            />
                          ))}
                        </div>

                        {/* Who */}
                        <div className="GeojiCnAdminSendFreeTokensHeader">
                          2) Who
                        </div>
                        <div className="GeojiCnAdminSendFreeTokensWho">
                          {/* Toggle */}
                          <div className="GeojiCnAdminSendFreeTokensToggle">
                            <div className="GeojiCnAdminSendFreeTokensToggleText">
                              Single Person
                            </div>
                            <Toggle defaultChecked={this.state.adminSendFreeTokensMultiple} onChange={this.toggleAdminSendFreeTokensMultiple.bind(this)}
                              icons={false} />
                            <div className="GeojiCnAdminSendFreeTokensToggleText">
                              Multiple People
                            </div>
                          </div>

                          {/* Indi Form */}
                          { !this.state.sendingFreeTokensSuccess && !this.state.adminSendFreeTokensMultiple &&
                            <div className="GeojiCnAdminSendFreeTokensForm">
                              <div className="InputDiv">
                                <Components.InputBottomLine type="text" name="freeTokenName" placeholder="First and Last name" validation="text" required="true" validateTyping={false}
                                  tabIndex="1" title="Name"
                                  value={this.state.data.freeTokenName ? this.state.data.freeTokenName.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                              </div>
                              <div className="InputDiv">
                                <Components.InputBottomLine type="text" name="freeTokenPhone" placeholder="1 (512) 333 - 4444" validation="phoneNumber10" required="false" validateTyping={false}
                                  tabIndex="2" title="(Phone Number) - optional"
                                  value={this.state.data.freeTokenPhone ? this.state.data.freeTokenPhone.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                              </div>
                              <div className="InputDiv">
                                <Components.InputBottomLine type="text" name="freeTokenEmail" placeholder="address@email.com" validation="email" required="false" validateTyping={false}
                                  tabIndex="3" title="(Email) - optional"
                                  value={this.state.data.freeTokenEmail ? this.state.data.freeTokenEmail.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                              </div>
                            </div>
                          }

                          {/* CSV Info */}
                          { !this.state.sendingFreeTokensSuccess && this.state.adminSendFreeTokensMultiple &&
                            <div className="GeojiCnAdminSendFreeTokensMultiple">
                              <div className="GeojiCnAdminSendFreeTokensMultipleDescription">
                                Download the template CSV file and add the people you want to send free tokens. Then, upload that file, review the data, and hit Send below.
                              </div>

                              {/* Template CSV File */}
                              <a href="https://galaxy.darwincloud.com/Apps/fs/19/Resources/Templates/GeojiFreeTokenTemplate.csv" target="_blank" rel="noreferrer noopener" download>
                                <div className="GeojiCnAdminSendFreeTokensMultipleButton">
                                  <img src={ImageTableBlack} alt="CSV" />
                                  <div>Download CSV Template</div>
                                </div>
                              </a>

                              {/* Upload CSV Data */}
                              <div className="FileSelectorDiv">
                                <input id={"FileSelectorInputCSV"} className="FileSelectorUploadInput" type="file" ref={this.csvFilePicker} onChange={this.csvFileSelected.bind(this)} accept={"text/csv"} />
                                <label htmlFor="FileSelectorInputCSV" className="FileSelectorUploadInputLabel">
                                  <div className="GeojiCnAdminSendFreeTokensMultipleButton GeojiCnAdminSendFreeTokensMultipleButtonBlack">
                                    <img src={ImageTable} alt="CSV" />
                                    <div>Upload CSV</div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          }

                          {/* CSV Uploaded List */}
                          { !this.state.sendingFreeTokensSuccess && this.state.adminSendFreeTokensMultiple && this.state.adminSendFreeTokensCSVPeople && this.state.adminSendFreeTokensCSVPeople.length > 0 &&
                            <div className="GeojiCnAdminSendFreeTokensPeople">
                              <div className="GeojiCnAdminSendFreeTokensHeader">
                                Uploaded List
                              </div>
                              <div className="GeojiCnAdminSendFreeTokensPeopleList">
                                { this.state.adminSendFreeTokensCSVPeople.map((person, indi) => (
                                  <div className="GeojiCnAdminSendFreeTokensPerson" key={"person_" + indi}>
                                    <div className="GeojiCnAdminSendFreeTokensPersonInner">
                                      <div className="GeojiCnAdminSendFreeTokensPersonImage">
                                        x{person.multiplier}
                                      </div>
                                      <div className="GeojiCnAdminSendFreeTokensPersonContent">
                                        <div className="GeojiCnAdminSendFreeTokensPersonContentName">
                                          {person.name}
                                        </div>
                                        { person.phoneNumber && person.phoneNumber.length > 0 &&
                                          <div className="GeojiCnAdminSendFreeTokensPersonContentPhone">
                                            {Helpers.parsePhoneNumber(person.phoneNumber)}
                                          </div>
                                        }
                                        { person.email && person.email.length > 0 &&
                                          <div className="GeojiCnAdminSendFreeTokensPersonContentEmail">
                                            {person.email}
                                          </div>
                                        }
                                      </div>
                                    </div>
                                    <div className="GeojiCnAdminSendFreeTokensPersonLine">
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }

                          {/* Success Dialog */}
                          { this.state.sendingFreeTokensSuccess &&
                            <div className="GeojiCnAdminSendFreeTokensSuccess">
                              <div className="GeojiCnAdminSendFreeTokensSuccessHeader">
                                Success
                              </div>
                              <div className="GeojiCnAdminSendFreeTokensSuccessBody">
                                Your people are on the list. All they need to do is walkup, say their name, and have a great time!
                              </div>
                              <div className="GeojiCnAdminSendFreeTokensSuccessButton" onClick={() => {this.setState({sendingFreeTokensSuccess: false})}}>
                                Ok
                              </div>
                            </div>
                          }

                          {/* Send Button */}
                          { !this.state.sendingFreeTokensSuccess && this.state.loadingSendFreeTokens &&
                            <div className="GeojiCnAdminSendFreeTokensSendButtonLoading">
                            </div>
                          }
                          { !this.state.sendingFreeTokensSuccess && !this.state.loadingSendFreeTokens &&
                            <div className="GeojiCnAdminSendFreeTokensSendButton" onClick={this.sendFreeTokens.bind(this, false)}>
                              Send
                            </div>
                          }
                        </div>
                      </span>
                    }
                  </div>
                }

                {/* Charts / Token Details */}
                { this.state.subview === "TokenDetails" &&
                  <div className="GeojiCnAdminTokenDetails">
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={() => {
                          if (this.state.showingTokenDate) {
                            this.loadAdminTokenDetails()
                          } else {
                            this.props.updatePurchaseID("dashboard")
                          }
                        }}>
                        <img src={ImageBackWhite} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        Token Sales
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Revenue and toggles */}
                    <div className="GeojiCnAdminTokenDetailsTop">
                      <div className="GeojiCnAdminTokenDetailsTopContent">
                        {/* Revenue */}
                        <div className="GeojiCnAdminTokenDetailsTopLeft">
                          <div className="GeojiCnAdminTokenDetailsTopName">
                            {this.state.adminToken.name ?? "All Sales"}
                          </div>
                          <div className="GeojiCnAdminTokenDetailsTopRMoney">
                            {Helpers.revenueToDollars(this.state.adminToken.displayMoney)}
                            <span>
                              {Helpers.revenueToCents(this.state.adminToken.displayMoney)}
                            </span>
                          </div>
                          <div className="GeojiCnAdminTokenDetailsTopRSales">
                            {this.state.adminToken.displaySales.toLocaleString("en-US")} Sales
                          </div>
                        </div>
                        {/* Cash Sales */}
                        { this.state.adminToken.displayCash !== "-" &&
                          <div className="GeojiCnAdminTokenDetailsTopRight">
                            <div className="GeojiCnAdminTokenDetailsTopName">
                              Cash Sales
                            </div>
                            <div className="GeojiCnAdminTokenDetailsTopRMoney">
                              {Helpers.revenueToDollars(this.state.adminToken.displayCash)}
                              <span>
                                {Helpers.revenueToCents(this.state.adminToken.displayCash)}
                              </span>
                            </div>
                          </div>
                        }
                      </div>

                      {/* Date Filter */}
                      { this.state.tokenDetailsChart === "default" &&
                        <DatePicker
                          value={this.state.chartDateValue}
                          onChange={this.updateAdminTokenRange.bind(this)}
                          multiple={false}
                          range={true}
                          format="MMM D, YYYY"
                          className="teal"
                          mustShowDates={false}
                          minDate={Helpers.subtractDaysFromDate(Helpers.parseSQLDate(this.state.geoji.creationTimestamp), 1)}
                          maxDate={Helpers.addDaysToDate(new Date(), 1)}
                          render={(value, openCalendar) => {
                            return (
                              <div className="GeojiCnAdminTokenDetailsTopDateSelector" onClick={openCalendar}>
                                <img src={ImageCalendar} alt="Calendar" className="GeojiCnAdminTokenDetailsTopDateSelectorImg1"/>
                                { value && Array.isArray(value) && value.length > 1 &&
                                  <div className="GeojiCnAdminTokenDetailsTopDateSelectorLabel">
                                    {value[0]} - {value[1]}
                                  </div>
                                }
                                { value && Array.isArray(value) && value.length === 1 &&
                                  <div className="GeojiCnAdminTokenDetailsTopDateSelectorLabel">
                                    {value[0]}
                                  </div>
                                }
                                { value && Array.isArray(value) && value.length === 0 &&
                                  <div className="GeojiCnAdminTokenDetailsTopDateSelectorLabel">
                                    Last Week
                                  </div>
                                }
                                <img src={ImageChevronDown} alt="Down Arrow" className="GeojiCnAdminTokenDetailsTopDateSelectorImg2"/>
                              </div>
                            )
                          }} />
                      }

                      {/* Filters - 1D, 1W, 1M, 3M, 1Y */}
                      { this.state.tokenDetailsChart === "default" &&
                        <div className="GeojiCnAdminTokenDetailsTopFilters">
                          { ["1D", "1W", "1M", "3M", "1Y"].map((time, i) => (
                            <div className={"GeojiCnAdminTokenDetailsTopFilter " + (time === this.state.tokenTimeFilter ? "GeojiCnAdminTokenDetailsTopFilterSelected" : "")} key={"filter_" + i}
                              onClick={this.selectAdminTokenFilter.bind(this, time)}>
                              {time}
                            </div>
                          ))}
                        </div>
                      }

                      {/* DateTime chart explanation */}
                      { this.state.tokenDetailsChart === "datetime" &&
                        <div className="GeojiCnAdminTokenDetailsTopDateTime">
                          Showing the sales for each day of your event.
                          <br/>
                          Hover to see the counts. Click to view details.
                        </div>
                      }
                    </div>

                    {/* The Bar Chart */}
                    <div className="GeojiCnAdminTokenDetailsChart">
                      <Bar height={250} data={{
                          labels: this.state.adminToken.tokenLabels,
                          datasets: ((this.state.adminToken.priceCents === 0 && this.state.adminToken.theme !== "promocode") || this.state.tokenDetailsChart === "datetime") ? [
                            {
                              label: 'Sales',
                              data: this.state.adminToken.tokenSales,
                              backgroundColor: '#6D64F2',
                              hoverBackgroundColor: '#FFFFFF',
                            }
                          ] : [
                            {
                              label: 'Cash',
                              data: this.state.adminToken.tokenCash,
                              backgroundColor: '#8AC349',
                              hoverBackgroundColor: '#FFFFFF',
                            },
                            {
                              label: 'Revenue',
                              data: this.state.adminToken.tokenMoney,
                              backgroundColor: '#6D64F2',
                              hoverBackgroundColor: '#FFFFFF',
                            }
                        ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          grouped: false,
                          scales: {
                            y: {
                              display: false,
                              grid: {
                                display: false,
                              },
                              ticks: {
                                beginAtZero: true,
                              },
                            },
                            x: {
                              display: true,
                              grid: {
                                display: false,
                                drawBorder: false,
                              },
                              ticks: {
                                beginAtZero: true,
                                color: '#9E9CE5',
                                font: {
                                  size: 12,
                                  weight: 'bold',
                                  family: 'Karla',
                                }
                              },
                            }
                          },
                          plugins: {
                            legend: {
                              display: false,
                            },
                            tooltip: {
                              backgroundColor: "#000000",
                              titleColor: "#FFFFFF",
                              displayColors: false,
                              callbacks: {
                                title: (context) => {
                                  let cindi = context[0].dataIndex
                                  if (this.state.tokenDetailsChart === "datetime" && this.state.adminToken.tokenTime && cindi < this.state.adminToken.tokenTime.length) {
                                    return context[0].label + ", " + this.state.adminToken.tokenTime[cindi]
                                  }
                                  return context[0].label
                                },
                                label: (context) => {
                                  let label = (context.dataset.label || '')
                                  if (label === "Revenue" || label === "Cash") {
                                    //format the value using $x,xxx.xx
                                    let money = Helpers.revenueToDollars(context.raw * 100) + Helpers.revenueToCents(context.raw * 100)
                                    return label + ": " + money
                                  } else {
                                    return label + ": " + context.formattedValue
                                  }
                                }
                              }
                            }
                          },
                          elements: {
                            bar: {
                              borderRadius: 12,
                            }
                          },
                          responsive: true,
                          onClick: (e, elements) => {
                            if (elements.length > 0 && this.state.tokenDetailsChart === "datetime" && !this.state.showingTokenDate) {
                              let indi = elements[0].index
                              //now find it in the data
                              let date = this.state.adminToken.tokenDate[indi]
                              this.loadAdminTokenDateSales(date)
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                }

                {/* Promoter Links */}
                { this.state.subview === "PromoterLinks" &&
                  <div className="GeojiCnAdminPromoterLinks">
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.promoterLinkBack.bind(this)}>
                        <img src={ImageBack} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        Promoter Links
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Loading */}
                    { this.state.subloading &&
                      <div className="GeojiCnAdminRLoading">
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                      </div>
                    }
                    {/* No Promoter Links */}
                    { !this.state.subloading && this.state.promoterLinks.length === 0 && this.state.promoterLinkEdit === false &&
                      <div className="GeojiCnAdminPromoterLinksNone">
                        <div className="GeojiCnAdminPromoterLinksNoneEmoji">
                          <span role="img" aria-label="track sales">🛍</span>
                        </div>
                        <div className="GeojiCnAdminPromoterLinksNoneTitle">
                          Track Where Sales are Coming From
                        </div>
                        <div className="GeojiCnAdminPromoterLinksNoneBody">
                          Create a link for each of your ads or promoters, then send them out!
                          It's that simple. Watch as your sales are tracked and see what marketing channels are working the best.
                          Tap the button below to create your first link.
                        </div>
                        <div className="GeojiCnAdminPromoterLinksCreateButton" onClick={this.promoterLinkShowCreate.bind(this, "new")}>
                          Create New Link
                        </div>
                      </div>
                    }
                    {/* Promoter Links */}
                    { !this.state.subloading && this.state.promoterLinks.length > 0 && this.state.promoterLinkEdit === false &&
                      <div className="GeojiCnAdminPromoterLinksList">
                        <div className="GeojiCnAdminPromoterLinksCreateButton" onClick={this.promoterLinkShowCreate.bind(this, "new")}>
                          Create New Link
                        </div>
                        <div className="GeojiCnAdminPromoterLinksListTapToCopy">
                          Tap on link to copy
                        </div>
                        { this.state.promoterLinks.map((link, indi) => (
                          <div className="GeojiCnAdminPromoterLink" key={"plink_" + link.id} onClick={this.promoterLinkTapped.bind(this, link)}>
                            { this.state.promoterLinkSelected === link.id &&
                              <div className="GeojiCnAdminPromoterLinkCopied">
                                <div className="GeojiCnAdminPromoterLinkTop">
                                  Beep Boop
                                </div>
                                <div className="GeojiCnAdminPromoterLinkBottom">
                                  Link Copied and ready to paste
                                </div>
                              </div>
                            }
                            { this.state.promoterLinkSelected !== link.id &&
                              <div className="GeojiCnAdminPromoterLinkContent">
                                <div className="GeojiCnAdminPromoterLinkTop">
                                  <div className="GeojiCnAdminPromoterLinkTopText">
                                    {link.name}
                                  </div>
                                  <div className="GeojiCnAdminPromoterLinkTopEdit" onClick={this.promoterLinkShowCreate.bind(this, link)}>
                                    EDIT
                                  </div>
                                </div>
                                <div className="GeojiCnAdminPromoterLinkBottom">
                                  {"$ " + Helpers.formatNumberDecimals(link.money / 100.0, 2)} Revenue - {Helpers.formatNumberDecimals(link.sales, 0)} Sales
                                </div>
                              </div>
                            }
                          </div>
                        ))}
                      </div>
                    }
                    {/* Create/Edit Promoter Link */}
                    { !this.state.subloading && this.state.promoterLinkEdit !== false &&
                      <div className="GeojiCnAdminPromoterLinksEdit">
                        <div className="InputDiv">
                          <Components.InputBottomLine type="text" name="promoterLinkName" placeholder="Promoter name, Campaign Name, etc." validation="text" required="true" validateTyping={false}
                            tabIndex="1" title="Link Name"
                            value={this.state.data.promoterLinkName ? this.state.data.promoterLinkName.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        </div>
                        <div className="GeojiCnAdminPromoterLinksEditButton" onClick={this.submitForm}>
                          {this.state.promoterLinkEdit === "new" ? "Create Link" : "Save Changes"}
                        </div>
                      </div>
                    }
                  </div>
                }

                {/* Promo Codes */}
                { this.state.subview === "PromoCodes" &&
                  <div className="GeojiCnAdminPromoCodes">
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.pcodeBack.bind(this)}>
                        <img src={ImageBack} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        Promo Codes
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Loading */}
                    { this.state.subloading &&
                      <div className="GeojiCnAdminRLoading">
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                      </div>
                    }
                    {/* No Promo Codes */}
                    { !this.state.subloading && (this.state.pcodesShowDeleted ? this.state.pcodesFull.length === 0 : this.state.pcodes.length === 0) && this.state.pcodesEdit === false &&
                      <div className="GeojiCnAdminPromoCodesNone">
                        <Components.SettingsToggle name="" name2="Show Deleted Codes" value={this.state.pcodesShowDeleted === true} onToggle={this.togglePCodeDeleted.bind(this)} />
                        <div className="GeojiCnAdminPromoCodesNoneEmoji">
                          <span role="img" aria-label="promo code">🎁</span>
                        </div>
                        <div className="GeojiCnAdminPromoCodesNoneTitle">
                          Give Promo Codes to Influencers and Friends
                        </div>
                        <div className="GeojiCnAdminPromoCodesNoneBody">
                          Set the discount, choose the code, send it to your peeps, and track your sales!
                          It's that simple.
                          Tap the button below to create your first code.
                        </div>
                        <div className="GeojiCnAdminPromoCodesCreateButton" onClick={this.pcodeShowCreate.bind(this, "new")}>
                          Create New Code
                        </div>
                      </div>
                    }
                    {/* Promo Codes */}
                    { !this.state.subloading && (this.state.pcodesShowDeleted ? this.state.pcodesFull.length > 0 : this.state.pcodes.length > 0) && this.state.pcodesEdit === false &&
                      <div className="GeojiCnAdminPromoCodesList">
                        <div className="GeojiCnAdminPromoCodesCreateButton" onClick={this.pcodeShowCreate.bind(this, "new")}>
                          Create New Code
                        </div>
                        <Components.SettingsToggle name="" name2="Show Deleted Codes" value={this.state.pcodesShowDeleted === true} onToggle={this.togglePCodeDeleted.bind(this)} />
                        <div className="GeojiCnAdminPromoCodesListTapToCopy">
                          Tap on code to copy
                        </div>
                        { (this.state.pcodesShowDeleted ? this.state.pcodesFull : this.state.pcodes).map((code, indi) => (
                          <div className={"GeojiCnAdminPromoCode " + (code.deleted === 1 ? "GeojiCnAdminPromoCodeDeleted" : "")} key={"pcode_" + code.id} onClick={this.pcodeTapped.bind(this, code)}>
                            { this.state.pcodesSelected === code.id &&
                              <div className="GeojiCnAdminPromoCodeCopied">
                                <div className="GeojiCnAdminPromoCodeCopiedTop">
                                  Beep Boop
                                </div>
                                <div className="GeojiCnAdminPromoCodeCopiedBottom">
                                  Code Copied and ready to paste
                                </div>
                              </div>
                            }
                            { this.state.pcodesSelected !== code.id &&
                              <div className="GeojiCnAdminPromoCodeContent">
                                <div className="GeojiCnAdminPromoCodeTop">
                                  <div className="GeojiCnAdminPromoCodeTopCode">
                                    {code.code}
                                  </div>
                                  <div className="GeojiCnAdminPromoCodeTopDiscount">
                                    { code.discount <= 1.0 ? Math.round(code.discount * 100) + "% Off" : "$ " + Helpers.formatNumberDecimals(code.discount, 0) + " Off" }
                                  </div>
                                </div>
                                <div className="GeojiCnAdminPromoCodeDescription">
                                  {code.codeDescription}
                                </div>
                                <div className="GeojiCnAdminPromoCodeBottom">
                                  <div className="GeojiCnAdminPromoCodeBottomUsed">
                                    {"Used " + Helpers.formatNumberDecimals(code.uses, 0) + (code.maxUses === 0 ? "" : "/" + Helpers.formatNumberDecimals(code.maxUses, 0))}
                                  </div>
                                  <div className="GeojiCnAdminPromoCodeBottomEdit" onClick={this.pcodeShowCreate.bind(this, code)}>
                                    EDIT
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        ))}
                      </div>
                    }
                    {/* Create/Edit Promo Code */}
                    { !this.state.subloading && this.state.pcodesEdit !== false &&
                      <div className="GeojiCnAdminPromoCodesEdit">
                        <div className="InputDiv">
                          <Components.InputBottomLine type="text" name="pcodeName" placeholder="Code user enters to get discount" validation="text" required="true" validateTyping={false}
                            tabIndex="1" title="Code Name"
                            value={this.state.data.pcodeName ? this.state.data.pcodeName.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        </div>
                        <div className="InputDiv">
                          <Components.InputBottomLine type="text" name="pcodeDescription" placeholder="10% off all items" validation="text" required="true" validateTyping={false}
                            tabIndex="2" title="Code Description"
                            value={this.state.data.pcodeDescription ? this.state.data.pcodeDescription.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        </div>
                        <div className="InputDiv">
                          <Components.InputBottomLine type="text" name="pcodeDiscount" placeholder="0.10 for 10% off, 0.50 for 50% off, 20 for $20 off" validation="decimal" required="true" validateTyping={true}
                            tabIndex="3" title="Discount" minimum={0.01}
                            value={this.state.data.pcodeDiscount ? this.state.data.pcodeDiscount.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        </div>
                        <div className="InputDiv">
                          <Components.InputBottomLine type="text" name="pcodeMaxUses" placeholder="3, leave blank for unlimited uses" validation="positiveNumber" required="false" validateTyping={true}
                            tabIndex="4" title="Max Uses" minimum={0}
                            value={this.state.data.pcodeMaxUses ? this.state.data.pcodeMaxUses.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        </div>
                        { this.state.pcodesEdit !== "new" &&
                          <div className="GeojiCnAdminPromoCodesDeleteButton" onClick={this.pcodeDelete.bind(this)}>
                            Delete Code
                          </div>
                        }
                        <div className="GeojiCnAdminPromoCodesEditButton" onClick={this.submitForm}>
                          {this.state.pcodesEdit === "new" ? "Create Promo Code" : "Save Changes"}
                        </div>
                      </div>
                    }
                  </div>
                }

                {/* Reports */}
                { this.state.subview === "Reports" &&
                  <div className="GeojiCnAdminReports">
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.props.updatePurchaseID.bind(this, "dashboard")}>
                        <img src={ImageBack} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        <div className="GeojiCnAdminTopBarTitleEmpty">
                        </div>
                        Reports
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Loading */}
                    { this.state.subloading &&
                      <div className="GeojiCnAdminRLoading">
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                      </div>
                    }

                    {/* List of Reports */}
                    { !this.state.subloading && Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) &&
                      <div className="GeojiCnAdminReportsContent">
                        {/* Download CSV */}
                        <div className={"GeojiCnAdminWhiteAction " + (this.state.downloadingCSVFile ? "GeojiCnAdminWhiteActionDownloading" : "GeojiCnAdminWhiteActionDownload")} onClick={this.adminDownloadCustomerCSV.bind(this)}>
                          <img src={ImageTable} alt="CSV" />
                          <div className="GeojiCnAdminWhiteActionRight">
                            <div className="GeojiCnAdminWhiteActionRightTitle">
                              { this.state.downloadingCSVFile &&
                                <span>Beep Boop</span>
                              }
                              { !this.state.downloadingCSVFile &&
                                <span>Download User Data</span>
                              }
                            </div>
                            <div className="GeojiCnAdminWhiteActionRightBody">
                              { this.state.downloadingCSVFile &&
                                <span>CSV generated</span>
                              }
                              { !this.state.downloadingCSVFile &&
                                <span>Name, #, Email, ... as a CSV</span>
                              }
                            </div>
                          </div>
                        </div>

                        <hr/>

                        {/* Show a Calendar here where you can select a single date. */}
                        <div className="GeojiCnAdminReportsContentCalendar">
                          <DatePicker
                            value={this.state.reportDateValue}
                            onChange={this.updateAdminReportDateRange.bind(this)}
                            multiple={false}
                            range={true}
                            format="MMM D, YYYY"
                            className="teal"
                            mustShowDates={false}
                            render={(value, openCalendar) => {
                              return (
                                <div className="GeojiCnAdminReportsContentCalendarSelector" onClick={openCalendar}>
                                  <img src={ImageCalendar} alt="Calendar" className="GeojiCnAdminReportsContentCalendarSelectorImg1"/>
                                  { value && Array.isArray(value) && value.length > 1 &&
                                    <div className="GeojiCnAdminReportsContentCalendarSelectorLabel">
                                      {value[0]} - {value[1]}
                                    </div>
                                  }
                                  { value && Array.isArray(value) && value.length === 1 &&
                                    <div className="GeojiCnAdminReportsContentCalendarSelectorLabel">
                                      {value[0]}
                                    </div>
                                  }
                                  { value && Array.isArray(value) && value.length === 0 &&
                                    <div className="GeojiCnAdminReportsContentCalendarSelectorLabel">
                                      Select a Date
                                    </div>
                                  }
                                  <img src={ImageChevronDown} alt="Down Arrow" className="GeojiCnAdminReportsContentCalendarSelectorImg2"/>
                                </div>
                              )
                            }} />
                        </div>

                        {/* Sales Report */}
                        <div className={"GeojiCnAdminWhiteAction " + (this.state.downloadingDailyReport ? "GeojiCnAdminWhiteActionDownloading" : "GeojiCnAdminWhiteActionDownload")} onClick={this.adminDownloadDailyReport.bind(this)}>
                          <img src={ImageChart} alt="Report" />
                          <div className="GeojiCnAdminWhiteActionRight">
                            <div className="GeojiCnAdminWhiteActionRightTitle">
                              { this.state.downloadingDailyReport &&
                                <span>Beep Boop</span>
                              }
                              { !this.state.downloadingDailyReport &&
                                <span>Download Sales Report</span>
                              }
                            </div>
                            <div className="GeojiCnAdminWhiteActionRightBody">
                              { this.state.downloadingDailyReport &&
                                <span>CSV generated</span>
                              }
                              { !this.state.downloadingDailyReport &&
                                <span>Breakdown of all items sold for over a period of time</span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }

                {/* Settings */}
                { this.state.subview === "Settings" &&
                  <div className="GeojiCnAdminSettings">
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.props.updatePurchaseID.bind(this, "dashboard")}>
                        <img src={ImageBack} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        <div className="GeojiCnAdminTopBarTitleEmpty">
                        </div>
                        Settings
                        <span role="img" aria-label="check" className={this.state.geojiSettingsSaving > 0 ? "GeojiCnAdminTopBarTitleSaving" : "GeojiCnAdminTopBarTitleSaving GeojiCnAdminTopBarTitleSavingHidden"}>
                          ✔️
                        </span>
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Loading */}
                    { this.state.subloading &&
                      <div className="GeojiCnAdminRLoading">
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                      </div>
                    }

                    {/* Settings */}
                    { !this.state.subloading &&
                      <div className="GeojiCnAdminSettingsContent">
                        {/* Purchase Notifications */}
                        <GeojiSettingBar title="Purchase Notifications" image={ImagePurchaseNotifications}
                          value={this.state.geojiSettings.purchaseNotifications}
                          onToggle={this.onSettingsToggle.bind(this, "purchaseNotifications")}
                          description="You will receive a push notification everytime a sale has been made."
                          />

                        {/* Accept Cash */}
                        <GeojiSettingBar title="Accept Cash" image={ImageAcceptCash}
                          value={this.state.geojiSettings.acceptCash}
                          onToggle={this.onSettingsToggle.bind(this, "acceptCash")}
                          description="Accept cash transactions in Virtual Terminal and include them in the revenue shown on the dashboard."
                          />

                        {/* Hide Revenue */}
                        { Helpers.userHasRevenueAccess(this.props.userInfo.user, this.state.geoji) &&
                          <GeojiSettingBar title="Hide Revenue" image={ImageHideLocation}
                            value={this.state.geojiSettings.hideRevenue}
                            onToggle={this.onSettingsToggle.bind(this, "hideRevenue")}
                            description="Don't show the revenue in the dashboard - use when working the door to hide from customers."
                            />
                        }

                        {/* Capture Email */}
                        <GeojiSettingBar title="Capture Email" image={ImageRequireEmail}
                          value={this.state.geojiSettings.captureEmail}
                          onToggle={this.onSettingsToggle.bind(this, "captureEmail")}
                          description="Capture the customer's email when they checkout."
                          />

                        {/* Show Who's Going */}
                        <GeojiSettingBar title="Show Who's Going" image={ImageShowWhosGoing}
                          value={this.state.geojiSettings.showInstagram}
                          onToggle={this.onSettingsToggle.bind(this, "showInstagram")}
                          description="Show the Instagram profiles of who is going to your event. Only people who are going can see everyone in the list."
                          />

                        {/* Always Show Who's Going */}
                        { this.state.geojiSettings.showInstagram &&
                          <GeojiSettingBar title="Always Show Who's Going" image={ImageShowWhosGoingAlways}
                            value={this.state.geojiSettings.showInstagramAlways}
                            onToggle={this.onSettingsToggle.bind(this, "showInstagramAlways")}
                            description="Allow people to see who is going without purchasing first."
                            />
                        }

                        {/* Hide Location */}
                        <GeojiSettingBar title="Hide Location" image={ImageHideLocation}
                          value={this.state.geojiSettings.hideLocation}
                          onToggle={this.onSettingsToggle.bind(this, "hideLocation")}
                          description="Hide the location of the event. People must purchase something to see the location."
                          />

                        {/* Invite Only */}
                        <GeojiSettingBar title="Invite Only" image={ImageInviteOnly}
                          value={this.state.geojiSettings.inviteOnly}
                          onToggle={this.onSettingsToggle.bind(this, "inviteOnly")}
                          description="People must request access and be approved before they can buy a ticket."
                          />

                        {/* Point of Sale */}
                        <GeojiSettingBar title="Point of Sale" image={ImagePointOfSale}
                          value={this.state.geojiSettings.pointOfSale}
                          onToggle={this.onSettingsToggle.bind(this, "pointOfSale")}
                          description="Enable Point of Sale features like scanning in products and taking inventory."
                          />

                        {/* Admin Settings Only */}
                        { this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.accountPermissions === "Admin" &&
                          <span>
                            <div className="GeojiCnAdminSettingsSection">
                              Admin Settings
                            </div>
                            {/* Processing Fee Cents */}
                            <div className="InputDiv">
                              <Components.InputBottomLine type="text" inputType="tel" name={"processingFeeCents"} placeholder="0" validation="positiveNumber" required="false"
                                title="Processing Fee Cents"
                                description="Each transaction has a base fee of this many cents. Use 30 for $0.30"
                                value={this.state.geojiSettings.processingFeeCents}
                                onChange={this.geojiSettingsChanged.bind(this)} onEnter={this.saveGeojiSettings.bind(this)}
                                onBlur={this.saveGeojiSettings.bind(this)} />
                            </div>

                            {/* Processing Fee Percent */}
                            <div className="InputDiv">
                              <Components.InputBottomLine type="text" inputType="text" name={"processingFeePercent"} placeholder="0.0825" validation="decimal" required="false"
                                title="Processing Fee Percent"
                                description="Each transaction has a percentage fee of this. Use 0.0825 for 8.25%"
                                value={this.state.geojiSettings.processingFeePercent}
                                onChange={this.geojiSettingsChanged.bind(this)} onEnter={this.saveGeojiSettings.bind(this)}
                                onBlur={this.saveGeojiSettings.bind(this)} />
                            </div>
                          </span>
                        }
                      </div>
                    }
                  </div>
                }

                {/* Invite Team Member */}
                { this.state.subview === "InviteTeamMember" &&
                  <div className="GeojiCnAdminInvite">
                    {/* Top Bar */}
                    <div className="GeojiCnAdminTopBar">
                      <div className="GeojiCnAdminTopBarBack" onClick={this.props.updatePurchaseID.bind(this, "dashboard")}>
                        <img src={ImageBack} alt="back" className="GeojiCnAdminTopBarBackArrow" />
                      </div>
                      <div className="GeojiCnAdminTopBarTitle">
                        Invite Team Member
                      </div>
                      <div className={"GeojiCnAdminTopBarSandbox " + (this.state.geoji.sandbox === "1" ? "GeojiCnAdminTopBarSandboxVisible" : "")}>
                        Sandbox
                      </div>
                    </div>

                    {/* Loading */}
                    { this.state.subloading &&
                      <div className="GeojiCnAdminRLoading">
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                        <div className="GeojiCnAdminRLoadingBar">
                        </div>
                      </div>
                    }

                    {/* Settings */}
                    { !this.state.subloading &&
                      <div className="GeojiCnAdminInviteContent">
                        {/* Name */}
                        <div className="InputDiv">
                          <Components.InputBottomLine type="text" name="name" placeholder="Felix Fox" validation="text" required="true" validateTyping={false}
                            title="Full Name" tabIndex="1"
                            value={this.state.data.name ? this.state.data.name.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        </div>

                        {/* Phone Number */}
                        <div className="InputDiv">
                          <Components.InputBottomLine type="text" name="phoneNumber" placeholder="(313) 007 - 4499" validation="phoneNumber10" required="true" validateTyping={false}
                            title="Phone Number" tabIndex="2"
                            value={this.state.data.phoneNumber ? this.state.data.phoneNumber.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                        </div>

                        {/* Submit Button */}
                        <div className="GeojiCnAdminInviteContentButton" onClick={this.submitForm}>
                          Invite
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            }
            { this.state.view === "WhosGoing" &&
              <div className={"GeojiCnInner " + (this.state.theme.includes("dark") ? "GeojiCnInnerDarkTheme" : "")}>
                { this.state.theme.includes("dark") &&
                  <BackgroundTheme theme="darkTheme" />
                }
                { this.state.subview === "Home" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar">
                      <img src={this.state.theme.includes("dark") ? ImageBackWhite : ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.stopViewingWhosGoing.bind(this)} />
                      <div className="GeojiCnInnerPromptTopBarTitle">
                        Who's Going?
                      </div>
                      { this.state.geoji.sandbox === "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandbox">
                          Sandbox
                        </div>
                      }
                      { this.state.geoji.sandbox !== "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandboxEmpty" />
                      }
                    </div>
                    <div className="GeojiCnInnerWhosGoing">

                      {/* No Profiles */}
                      { !this.state.subloading && (!this.state.profiles || this.state.profiles.length === 0) &&
                        <div className="GeojiCnInnerWhosGoingEmpty">
                          <div className="GeojiCnInnerWhosGoingEmptyTop">
                            <span role="img" aria-label="empty">📭</span>
                          </div>
                          <div className="GeojiCnInnerWhosGoingEmptyMiddle">
                            No Profiles
                          </div>
                          <div className="GeojiCnInnerWhosGoingEmptyBottom">
                            Nobody who has purchased has linked their Instagram account yet. Check back tomorrow to see who is going.
                          </div>
                        </div>
                      }
                      {/* List of Who is Going to this Geoji. */}
                      { this.state.profiles && this.state.profiles.length > 0 &&
                        <div className="GeojiCnInnerWhosGoingList">
                          { this.state.profiles.map((profile, i) => (
                            <div key={"profile_" + i} className="GeojiCnInnerWhosGoingProfile" onClick={this.viewInstagramProfile.bind(this, profile)}>
                              { profile.profilePicture === null &&
                                <div className="GeojiCnInnerWhosGoingProfileInitials">
                                  {Helpers.getInitialsFromName(profile.name)}
                                </div>
                              }
                              { profile.profilePicture !== null &&
                                <Components.URLImage src={profile.profilePicture} alt="Profile" className="GeojiCnInnerWhosGoingProfileImage" />
                              }
                              <div className="GeojiCnInnerWhosGoingProfileBody">
                                <div className="GeojiCnInnerWhosGoingProfileBodyName">
                                  {profile.name}
                                </div>
                                <div className="GeojiCnInnerWhosGoingProfileBodyInsta">
                                  {profile.instagramUsername && profile.instagramUsername.length > 0 ? "@" + profile.instagramUsername : ""}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                      {/* Loading */}
                      { this.state.subloading &&
                        <div className="GeojiCnInnerWhosGoingLoading">
                          <div className="GeojiCnInnerWhosGoingLoadingBar">
                          </div>
                          <div className="GeojiCnInnerWhosGoingLoadingBar">
                          </div>
                          <div className="GeojiCnInnerWhosGoingLoadingBar">
                          </div>
                        </div>
                      }
                      <div className="GeojiCnInnerWhosGoingListMoreToLoad" ref={this.profileListEnd}>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
            { this.state.view === "RequestAccess" &&
              <div className={"GeojiCnInner " + (this.state.theme.includes("dark") ? "GeojiCnInnerDarkTheme" : "")}>
                { this.state.theme.includes("dark") &&
                  <BackgroundTheme theme="darkTheme" />
                }
                { this.state.subview === "Home" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar">
                      <img src={this.state.theme.includes("dark") ? ImageBackWhite : ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.stopViewingWhosGoing.bind(this)} />
                      <div className="GeojiCnInnerPromptTopBarTitle">
                        Request Access
                      </div>
                      { this.state.geoji.sandbox === "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandbox">
                          Sandbox
                        </div>
                      }
                      { this.state.geoji.sandbox !== "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandboxEmpty" />
                      }
                    </div>
                    <div className="GeojiCnInnerRequestAccess">
                      {/* Loading */}
                      { this.state.subloading &&
                        <div className="GeojiCnInnerRequestAccessLoading">
                          <div className="GeojiCnInnerRequestAccessLoadingBar">
                          </div>
                          <div className="GeojiCnInnerRequestAccessLoadingBar">
                          </div>
                          <div className="GeojiCnInnerRequestAccessLoadingBar">
                          </div>
                        </div>
                      }
                      { !this.state.subloading &&
                        <div className="GeojiCnInnerRequestAccess">
                          {/* Show the user's name input */}
                          <div className="InputDiv">
                            <Components.InputBottomLine type="text" name="name" placeholder="Felix Fox" validation="text" required="true" validateTyping={false} autoComplete="name"
                              title="Full Name" tabIndex="1"
                              value={this.state.data.name ? this.state.data.name.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          </div>

                          {/* Show the user's instagram profile input */}
                          <div className="InputDiv">
                            <Components.InputBottomLine type="text" name="instagramUsername" placeholder="@insta" validation="text" required="false" validateTyping={false}
                              title="Instagram Username (optional)" tabIndex="2"
                              value={this.state.data.instagramUsername ? this.state.data.instagramUsername.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          </div>

                          {/* Request Access */}
                          <div className="GeojiCnInnerRequestAccessButton" onClick={this.submitForm.bind(this)}>
                            <img src={ImagePersonQuestionMark} alt="Request Access" />
                            Request Access
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            }
            { this.state.view === "Kickback" &&
              <div className={"GeojiCnInner " + (this.state.theme.includes("dark") ? "GeojiCnInnerDarkTheme" : "")}>
                { this.state.theme.includes("dark") &&
                  <BackgroundTheme theme="darkTheme" />
                }
                { this.state.subview === "Home" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar">
                      <img src={this.state.theme.includes("dark") ? ImageBackWhite : ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.stopViewingKickback.bind(this)} />
                      <div className="GeojiCnInnerPromptTopBarTitle">
                        Promote
                      </div>
                      { this.state.geoji.sandbox === "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandbox">
                          Sandbox
                        </div>
                      }
                      { this.state.geoji.sandbox !== "1" &&
                        <div className="GeojiCnInnerPromptTopBarSandboxEmpty" />
                      }
                    </div>
                    <div className="GeojiCnInnerKickback">

                      <div className="GeojiCnInnerKickbackTitle">
                        Promote and Get Paid
                      </div>
                      <div className="GeojiCnInnerKickbackBody">
                        Share the link below and receive a {(this.state.kickbackLink && this.state.kickbackLink.kickback > 0) ? this.state.kickbackLink.kickback + "% " : "" }kickback for everything you sell.
                        You can come back here to track your sales or view the money flow into your Geoji Balance in your profile.
                      </div>
                      {/* Loading */}
                      { this.state.subloading &&
                        <div className="GeojiCnInnerKickbackLoading">
                          <div className="GeojiCnInnerKickbackLoadingBar">
                          </div>
                        </div>
                      }
                      { !this.state.subloading &&
                        <div className={"GeojiCnMakeMoneyButton " + (this.state.copiedKickback ? "GeojiCnMakeMoneyButtonCopied" : "")} onClick={this.copyKickbackLink.bind(this)}>
                          <span>{this.state.copiedKickback ? "Copied" : "Copy Link"}</span>
                        </div>
                      }
                      { !this.state.subloading && this.state.kickbackLink &&
                        <div className="GeojiCnInnerKickbackStat">
                          Sales: {Helpers.formatNumberOnlyInts(this.state.kickbackLink.sales)}
                        </div>
                      }
                      { !this.state.subloading && this.state.kickbackLink &&
                        <div className="GeojiCnInnerKickbackStat">
                          Your Money: ${Helpers.formatNumberDecimals(this.state.kickbackLink.money / 100.0)}
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            }
            {/* Footer */}
            <Components.GeojiFooter />
          </div>
        }
      </div>
    )
  }
}

//Adds the bodyWhite class to the body div which will
//turn the background to be a white color. When this
//component is removed the class will be removed.
export class BackgroundTheme extends React.Component {

  componentDidMount() {
    document.documentElement.className = this.props.theme
  }

  componentWillUnmount() {
    document.documentElement.className = ""
  }

  render() {
    return (
      <span className="BackgroundSetSpecialClass">
      </span>
    )
  }
}

export class GeojiToken extends React.Component {

  render() {
    return (
      <div className="GeojiCnInnerGrayGroupTokenElement"
        draggable={this.props.draggable}
        onDragOver={this.props.onDragOver}
        onDragStart={this.props.onDragStart}
        onDragEnd={this.props.onDragEnd}
        onTouchStart={this.props.onTouchStart}
        onTouchMove={this.props.onTouchMove}
        onTouchEnd={this.props.onTouchEnd}
        data-id={this.props['data-id']}

        id={(this.props.token.index === 0 && this.props.token.theme === "section") ? "GeojiTokenHeader2" : ("GeojiTokenIndex" + this.props.token.index)}>
        { this.props.editing === true &&
          <div className={"GeojiCnInnerGrayGroupToken " + (this.props.token.soldOut ? "GeojiCnInnerGrayGroupTokenSoldOut" : "")}>
            <div className="GeojiCnInnerGrayGroupTokenTop">
              <div className="GeojiCnInnerGrayGroupTokenLeft">
                <div className="GeojiCnInnerGrayGroupTokenLeftTitle">
                  {this.props.token.name}
                </div>
                <div className="GeojiCnInnerGrayGroupTokenLeftPrice">
                  {Helpers.geojiTokenPriceString(this.props.token)}
                </div>
              </div>
              { this.props.token.theme === "default" &&
                <div className="GeojiCnInnerGrayGroupTokenRight">
                  <div className="GeojiCnInnerGrayGroupTokenRightEdit" onClick={this.props.onEdit.bind(this, this.props.token)}>
                    EDIT
                  </div>
                  <div className="GeojiCnInnerGrayGroupTokenRightDuplicate" onClick={this.props.onCopy.bind(this, this.props.token)}>
                    CLONE
                  </div>
                </div>
              }
            </div>
            {/* aboutImage & about */}
            { this.props.token.aboutImage && this.props.token.aboutImage.length > 0 &&
              <div className="GeojiCnInnerGrayGroupTokenBottomAboutImage">
                <img src={this.props.token.aboutImage} alt={this.props.token.name} />
              </div>
            }
            { this.props.token.about && this.props.token.about.length > 0 &&
              <div className="GeojiCnInnerGrayGroupTokenBottomAbout">
                {this.props.token.about}
              </div>
            }
          </div>
        }
        { !this.props.editing && this.props.token.theme === "default" &&
          <div className={"GeojiCnInnerGrayGroupToken " + (this.props.token.soldOut ? "GeojiCnInnerGrayGroupTokenSoldOut" : "")}>
            <div className="GeojiCnInnerGrayGroupTokenTop">
              <div className="GeojiCnInnerGrayGroupTokenLeft">
                <div className="GeojiCnInnerGrayGroupTokenLeftTitle">
                  {this.props.token.name}
                </div>
                <div className="GeojiCnInnerGrayGroupTokenLeftPrice">
                  {Helpers.geojiTokenPriceString(this.props.token)}
                </div>
              </div>
              { this.props.token.soldOut &&
                <div className="GeojiCnInnerGrayGroupTokenRight">
                  <div className="GeojiCnInnerGrayGroupTokenRightSoldOut">
                    Sold Out
                  </div>
                </div>
              }
              { !this.props.token.soldOut &&
                <div className="GeojiCnInnerGrayGroupTokenRight">
                  <div className="GeojiCnInnerGrayGroupTokenRightMinus" onClick={this.props.decrementToken.bind(this, this.props.token)}>
                    –
                  </div>
                  <div className={"GeojiCnInnerGrayGroupTokenRightLabel " + (this.props.token.cart > 0 ? "GeojiCnInnerGrayGroupTokenRightLabelActive" : "")}>
                    {this.props.token.cart}
                    {/*<div className="InputDiv">
                      <Components.InputBottomLine type="text" inputType="tel" name={"token_" + token.id} placeholder="0" validation="digits" required="false"
                        value={token.cart} onChange={this.tokenChanged.bind(this, token)} style={{width: 10 + (token.cart.toString().length * 12) + "px"}} />
                    </div>*/}
                  </div>
                  <div className="GeojiCnInnerGrayGroupTokenRightPlus" onClick={this.props.incrementToken.bind(this, this.props.token)}>
                    +
                  </div>
                </div>
              }
            </div>
            {/* aboutImage & about */}
            { this.props.token.aboutImage && this.props.token.aboutImage.length > 0 &&
              <div className="GeojiCnInnerGrayGroupTokenBottomAboutImage">
                <img src={this.props.token.aboutImage} alt={this.props.token.name} />
              </div>
            }
            { this.props.token.about && this.props.token.about.length > 0 &&
              <div className="GeojiCnInnerGrayGroupTokenBottomAbout">
                {this.props.token.about}
              </div>
            }
          </div>
        }
        { !this.props.editing && this.props.token.theme === "tipjar" &&
          <div className="GeojiCnInnerGrayGroupTokenTipJar">
            <div className="GeojiCnInnerGrayGroupTokenTipJarLeft" onClick={this.props.decrementToken.bind(this, this.props.token)}>
              –
            </div>
            <div className="GeojiCnInnerGrayGroupTokenTipJarCenter">
              <div className="InputDiv">
                <Components.InputBottomLine type="text" inputType="tel" name={"token_" + this.props.token.id} placeholder="0" validation="money" required="false"
                  value={this.props.token.cart} onChange={this.props.tokenChanged.bind(this, this.props.token)}
                  style={{width: 10 + (this.props.token.cart.toString().length * 14) + "px"}} />
              </div>
              <div className="GeojiCnInnerGrayGroupTokenTipJarCenterTitle">
                {this.props.token.name}
              </div>
            </div>
            <div className="GeojiCnInnerGrayGroupTokenTipJarRight" onClick={this.props.incrementToken.bind(this, this.props.token)}>
              +
            </div>
          </div>
        }
        { !this.props.editing && this.props.token.theme === "autotip" &&
          <div className="GeojiCnInnerGrayGroupTokenAutoTip">
            <div className="GeojiCnInnerGrayGroupTokenAutoTipTitle">
              {this.props.token.name}
            </div>
            <div className="GeojiCnInnerGrayGroupTokenAutoTipOptions">
              { this.props.token.mapData.tips.map((tip, ti) => (
                <div className={"GeojiCnInnerGrayGroupTokenAutoTipOption GeojiCnInnerGrayGroupTokenAutoTipOption" + tip.status } key={"tip_" + ti}
                  onClick={this.props.selectedTokenAutoTip.bind(this, this.props.token, tip)}>
                  <div className="GeojiCnInnerGrayGroupTokenAutoTipOptionName">
                    {tip.name}
                  </div>
                </div>
              ))}
            </div>
            { this.props.token.about && this.props.token.about.length > 0 &&
              <div className="GeojiCnInnerGrayGroupTokenAutoTipAbout">
                {this.props.token.about}
              </div>
            }
          </div>
        }
        { !this.props.editing && this.props.token.theme === "promocode" &&
          <div className="GeojiCnInnerGrayGroupTokenPromoCode">
            <div className="GeojiCnInnerGrayGroupTokenPromoCodeInner">
              <div className="InputDiv">
                <Components.InputBottomLine type="text" inputType="text" name={"token_" + this.props.token.id} placeholder="Promo Code"
                  validation="text" required="false"
                  value={this.props.token.promoCode ? this.props.token.promoCode : ""} onChange={this.props.promoCodeChanged.bind(this, this.props.token)} onEnter={this.props.promoCodeSubmit.bind(this, this.props.token)} />
              </div>
              <div className={"GeojiCnInnerGrayGroupTokenPromoCodeInnerSubmit " + ((this.props.token.promoCodeData && this.props.token.promoCodeData.codeDescription) ? "GeojiCnInnerGrayGroupTokenPromoCodeInnerSubmitGreen" : "")}
                onClick={this.props.promoCodeSubmit.bind(this, this.props.token)}>
                <img alt="Submit" src={(this.props.token.promoCodeData && this.props.token.promoCodeData.codeDescription) ? ImageCheckmarkWhite : (this.props.theme.includes("dark") ? ImageRightArrowWhite : ImageRightArrow)} />
              </div>
            </div>
            { this.props.token.promoCodeData && this.props.token.promoCodeData.codeDescription &&
              <div className="GeojiCnInnerGrayGroupTokenPromoCodeDescription">
                {this.props.token.promoCodeData.codeDescription}
              </div>
            }
          </div>
        }
        { !this.props.editing && this.props.token.theme === "map" &&
          <div className={"GeojiCnInnerGrayGroupTokenMap " + (this.props.token.soldOut ? "GeojiCnInnerGrayGroupTokenMapSoldOut" : "")}>
            {/* Title - usually Select your Seat Below */}
            { this.props.token.mapData && this.props.token.mapData.title &&
              <div className="GeojiCnInnerGrayGroupTokenMapTitle">
                {this.props.token.mapData.title}
              </div>
            }

            {/* Map Key */}
            <div className="GeojiCnInnerGrayGroupTokenMapKey">
              <div className="GeojiCnInnerGrayGroupTokenMapKeyElement">
                <div className="GeojiCnInnerGrayGroupTokenMapKeyElementColor">
                </div>
                <div className="GeojiCnInnerGrayGroupTokenMapKeyElementName">
                  Available
                </div>
              </div>
              <div className="GeojiCnInnerGrayGroupTokenMapKeyElement">
                <div className="GeojiCnInnerGrayGroupTokenMapKeyElementColor GeojiCnInnerGrayGroupTokenMapKeyElementColorGreen">
                </div>
                <div className="GeojiCnInnerGrayGroupTokenMapKeyElementName">
                  Selected
                </div>
              </div>
              <div className="GeojiCnInnerGrayGroupTokenMapKeyElement">
                <div className="GeojiCnInnerGrayGroupTokenMapKeyElementColor GeojiCnInnerGrayGroupTokenMapKeyElementColorGray">
                </div>
                <div className="GeojiCnInnerGrayGroupTokenMapKeyElementName">
                  Unavailable
                </div>
              </div>
            </div>

            {/* Map */}
            { this.props.token.mapData && this.props.token.mapData.mapURL &&
              <div className="GeojiCnInnerGrayGroupTokenMapGraphic">
                <img src={this.props.token.mapData.mapURL} alt="Seating Map" className="GeojiCnInnerGrayGroupTokenMapGraphicImage" />
                { this.props.token.mapData.seats && this.props.token.mapData.seats.map((seat, index) => (
                  <div key={"seat_" + seat.name + "_" + index} className={"GeojiCnInnerGrayGroupTokenMapGraphicSeat GeojiCnInnerGrayGroupTokenMapGraphicSeat" + seat.status + " GeojiCnInnerGrayGroupTokenMapGraphicSeatShape" + seat.shape}
                    style={{
                      left:"calc(" + (seat.position[0] * 100 - (seat.size ? seat.size[0] * 50 : 0)) + "%" + ((seat.size) ? ")" : " - 15px)"),
                      top:"calc(" + (seat.position[1] * 100 - (seat.size ? seat.size[1] * 50 : 0)) + "%" + ((seat.size) ? ")" : " - 15px)"),
                      width: seat.size ? seat.size[0] * 100 + "%" : "30px",
                      height: seat.size ? seat.size[1] * 100 + "%" : "30px",
                    }} onClick={this.props.selectedTokenMapSeat.bind(this, this.props.token, seat)}>
                    { seat.status === "Sold" &&
                      <div className="GeojiCnInnerGrayGroupTokenMapGraphicSeatText">
                        x
                      </div>
                    }
                    { seat.status !== "Sold" &&
                      <div className="GeojiCnInnerGrayGroupTokenMapGraphicSeatText">
                        {seat.name}
                      </div>
                    }
                  </div>
                ))}
              </div>
            }

            {/* Name and Quantity */}
            <div className="GeojiCnInnerGrayGroupTokenMapText">
              <div className="GeojiCnInnerGrayGroupTokenMapLeft">
                <div className="GeojiCnInnerGrayGroupTokenMapLeftPrice">
                  { Helpers.geojiTokenPriceString(this.props.token)}
                </div>
              </div>
              {/*<div className="GeojiCnInnerGrayGroupTokenMapRight">
                <div className={"GeojiCnInnerGrayGroupTokenMapRightLabel " + (this.props.token.cart > 0 ? "GeojiCnInnerGrayGroupTokenMapRightLabelActive" : "")}>
                  {this.props.token.cart}
                </div>
              </div>*/}
            </div>

            {/* About Description */}
            { this.props.token.about && this.props.token.about.length > 0 &&
              <div className="GeojiCnInnerGrayGroupTokenMapAbout">
                {Helpers.geojiTokenAbout(this.props.token)}
              </div>
            }
          </div>
        }
        { !this.props.editing && this.props.token.theme === "datetime" &&
          <div className={"GeojiCnInnerGrayGroupTokenDateTime " + (this.props.token.soldOut ? "GeojiCnInnerGrayGroupTokenSoldOut" : "")}>
            <div className="GeojiCnInnerGrayGroupTokenDateTimeTitle">
              {Helpers.getDateTimeTitleText(this.props.token)}
            </div>
            <div className="GeojiCnInnerGrayGroupTokenDateTimeCalendar">
              <Calendar
                calendar={gregorian}
                locale={gregorian_en}
                multiple={true}
                value={Helpers.tokenDateTimeAvailableDates(this.props.token)}
                currentDate={this.props.geoji.calendarFocusDate}
                plugins={[colors({ defaultColor: "blue", colors: ["blue", "green"] })]}
                onChange={this.props.tokenDateTimeSelected.bind(this, this.props.token)}
                readOnly={false}
                weekStartDayIndex={(this.props.token.mapData && this.props.token.mapData.firstWeekday) ? this.props.token.mapData.firstWeekday : 0}
                showOtherDays={true}
              />
            </div>
            { this.props.token.datetimeselected &&
              <div className="GeojiCnInnerGrayGroupTokenDateTimeTimes">
                {/*<div className="GeojiCnInnerGrayGroupTokenDateTimeTimesName">
                  Select a time
                </div>*/}
                { Helpers.tokenDateTimeAllTimes(this.props.token).map((time, i) => (
                  <div key={"time_" + time.id + "_" + i} className={"GeojiCnInnerGrayGroupTokenDateTimeTime " + ((parseInt(time.tokensSold) >= parseInt(time.quantity) && parseInt(time.quantity) !== 0) ? "GeojiCnInnerGrayGroupTokenDateTimeTimeSoldOut" : "")}
                    style={{background: time.selected ? "#8AC349" : time.color}}
                    onClick={this.props.tokenDateTimeTimeSelected.bind(this, this.props.token, time)}>
                    {time.time}
                  </div>
                ))}
              </div>
            }

            <div className="GeojiCnInnerGrayGroupTokenDateTimeBottom">
              <div className="GeojiCnInnerGrayGroupTokenLeft">
                <div className="GeojiCnInnerGrayGroupTokenLeftTitle">
                  {this.props.token.name}
                </div>
                <div className="GeojiCnInnerGrayGroupTokenLeftPrice">
                  {Helpers.geojiTokenPriceString(this.props.token)}
                </div>
              </div>
              { this.props.token.soldOut &&
                <div className="GeojiCnInnerGrayGroupTokenRight">
                  <div className="GeojiCnInnerGrayGroupTokenRightSoldOut">
                    Sold Out
                  </div>
                </div>
              }
              { !this.props.token.soldOut &&
                <div className="GeojiCnInnerGrayGroupTokenRight">
                  <div className="GeojiCnInnerGrayGroupTokenRightMinus" onClick={this.props.decrementToken.bind(this, this.props.token)}>
                    –
                  </div>
                  <div className={"GeojiCnInnerGrayGroupTokenRightLabel " + (this.props.token.cart > 0 ? "GeojiCnInnerGrayGroupTokenRightLabelActive" : "")}>
                    {this.props.token.cart}
                  </div>
                  <div className="GeojiCnInnerGrayGroupTokenRightPlus" onClick={this.props.incrementToken.bind(this, this.props.token)}>
                    +
                  </div>
                </div>
              }
            </div>

            {/* aboutImage & about */}
            { this.props.token.aboutImage && this.props.token.aboutImage.length > 0 &&
              <div className="GeojiCnInnerGrayGroupTokenBottomAboutImage">
                <img src={this.props.token.aboutImage} alt={this.props.token.name} />
              </div>
            }
            { this.props.token.about && this.props.token.about.length > 0 &&
              <div className="GeojiCnInnerGrayGroupTokenBottomAbout">
                {this.props.token.about}
              </div>
            }
          </div>
        }
        { !this.props.editing && this.props.token.theme === "section" &&
          <div className="GeojiCnInnerGrayGroupSection">
            <div className="GeojiCnInnerGrayGroupSectionTitle">
              {this.props.token.name}
            </div>
            {/* about */}
            { this.props.token.about && this.props.token.about.length > 0 &&
              <div className="GeojiCnInnerGrayGroupSectionAbout">
                {this.props.token.about}
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export var SortableGeojiToken = sortable(GeojiToken);

export class GeojiBackgroundHeader extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className={"GeojiCnBackgroundHeader " + (this.props.theme === "wide" ? "GeojiCnBackgroundHeaderWide" : "")}>
        {/* Sign In Here */}
        <HomePage.HomeHeaderProfile props={this.props} />
      </div>
    )
  }
}

export class GeojiBottomBar extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      paymentRequest: false,
      walletAvailable: undefined,
    }

    //console.log("Geoji Bottom Bar", this.props)
  }

  componentDidMount() {
    this.checkProps()
  }

  componentDidUpdate(prevProps) {
    if (this.props.stripe !== prevProps.stripe || this.props.elements !== prevProps.elements || this.props.tokenIncrement !== prevProps.tokenIncrement) {
      this.checkProps()
    }
  }

  checkProps() {
    //console.log("checkProps", this.props)
    if (this.props.stripe !== null && this.props.elements !== null) {
      //Create the payment request
      let priceCents = Helpers.geojiCartPrice(this.props.geoji)
      //console.log("priceCents", priceCents)
      let pr = this.props.stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: "Geoji, Inc.",
          amount: priceCents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })
      pr.on('paymentmethod', async (ev) => {
        console.log("payment method", ev)

        //1) Create Payment Intent with the API & get the clientSecret.
        let priceCents = Helpers.geojiCartPrice(this.props.geoji)
        let shoppingCart = Helpers.getTokenJSONwithCart(this.props.geoji)
        let dd = {
          amountCents: priceCents,
          type: "Apple Pay",
          tokens: JSON.stringify(shoppingCart),
          device: "website",
          redirect: window.location.hostname === "localhost" ? "localhost" : (window.location.hostname === "www.geoji.com" ? "www" : "geoji"),
        }
        let pls = API.getCookie("promoterLinks")
        try {
          pls = JSON.parse(pls)
        } catch (e) {
          pls = {}
        }
        if (pls[this.props.geoji.geojiID]) {
          dd["promoterLinkID"] = pls[this.props.geoji.geojiID]
        }

        API.callDarwinAPI("POST", "stripePaymentIntent/" + this.props.geoji.geojiID, dd, async (result) => {
          console.log("stripePaymentIntent", result)
          if ("error" in result) {
            console.log("Couldn't get payment intent", result)
            //log error
            API.logIssue("Stripe Payment Intent", {
              "result": result,
              "data": dd,
              "geojiID": this.props.geoji.geojiID
            })
            //show error
            ev.complete('fail')
            this.props.failed && this.props.failed()
            return
          }

          let clientSecret = result.data.client_secret

          //2) Confirm the PaymentIntent without handling potential next actions (yet).
          const {paymentIntent, error: confirmError} = await this.props.stripe.confirmCardPayment(
            clientSecret,
            {payment_method: ev.paymentMethod.id},
            {handleActions: false}
          );

          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');
            this.props.failed && this.props.failed()
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow.
            if (paymentIntent.status === "requires_action") {
              // Let Stripe.js handle the rest of the payment flow.
              const {error} = await this.props.stripe.confirmCardPayment(clientSecret);
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
                this.props.failed && this.props.failed()
              } else {
                // The payment has succeeded.
                this.props.success(result.data.purchaseID)
              }
            } else {
              // The payment has succeeded.
              this.props.success(result.data.purchaseID)
            }
          }
        })
      })
      pr.on('cancel', () => {
        console.log("payment method cancelled")
        //Go to the checkout page
        this.props.showCreditCardScreen()
      })
      //now check which payment methods can be used with this.
      pr.canMakePayment().then(result => {
        //console.log("Can Make Payments to:", result)
        if (result) {
          //we can make a wallet payment.
          this.setState({
            walletAvailable: true,
            paymentRequest: pr,
          })
        } else {
          //we can not make a wallet payment.
          this.setState({
            walletAvailable: false,
            paymentRequest: pr,
          })
        }
      })
    }
  }

  cartTapped() {
    if (this.props.subloading) {
      //prevents double checkouts!
      return
    }
    //1) Determine if checking out or scrolling
    let scrolling = Helpers.geojiCartEmpty(this.props.geoji)
    if (scrolling) {
      //2) Scroll to the token header
      let dd = document.querySelector("#GeojiTokenHeader2")
      if (dd) {
        dd.scrollIntoView({behavior: 'smooth'})
      }
    } else {
      //3) Checking out
      //3.0) Make sure it is valid.
      let valid = Helpers.cartValid(this.props.geoji)
      console.log("Cart Valid", valid)
      if (valid[0] === false) {
        this.props.showError && this.props.showError("Wait", valid[1])
        return
      }

      //3.1) Make sure terms are accepted.
      if (!this.props.acceptTerms) {
        console.log("Terms not accepted")
        this.props.showError && this.props.showError("Accept Refund Terms", "All sales are final. Refunds are at the discretion of the creator of this event. Geoji's ticketing fee is non-refundable. You must accept the refund and cancellation terms to checkout.")
        //change the acceptTerms to be accepted.
        this.props.acceptTermsAction()
        return
      }

      //3.2) If not logged in -> enter phone number and verify to create an account / login.
      if (!API.hasLoggedIn()) {
        this.props.cartTapped()
      } else {
        //3.3) If logged in - attempt payment.
        console.log("Logged In!")
        if (this.props.userInfo && this.props.userInfo.user) {
          if (this.props.geoji.sandbox !== this.props.userInfo.user.sandbox) {
            //sandbox mismatch
            console.log("Sandbox Mismatch")
            this.props.sandboxMismatch()
            return
          }
        }
        this.attemptPayment()
      }
    }
  }

  attemptPayment() {
    let priceCents = Helpers.geojiCartPrice(this.props.geoji)
    if (priceCents === 0) {
      //Free Purchase - just check out.
      this.props.updateSubloading(true, false)

      let shoppingCart = Helpers.getTokenJSONwithCart(this.props.geoji)
      let dd = {
        amountCents: priceCents,
        type: "Free",
        tokens: JSON.stringify(shoppingCart),
        device: "website",
        redirect: window.location.hostname === "localhost" ? "localhost" : (window.location.hostname === "www.geoji.com" ? "www" : "geoji"),
      }
      let pls = API.getCookie("promoterLinks")
      try {
        pls = JSON.parse(pls)
      } catch (e) {
        pls = {}
      }
      if (pls[this.props.geoji.geojiID]) {
        dd["promoterLinkID"] = pls[this.props.geoji.geojiID]
      }

      API.callDarwinAPI("POST", "stripePaymentIntent/" + this.props.geoji.geojiID, dd, (result) => {
        if ("error" in result) {
          console.log("Couldn't get stripe payment intent attemptPayment", result)
          //log the error
          API.logIssue("Free Stripe Payment Intent", {
            "result": result,
            "data": dd,
            "geojiID": this.props.geoji.geojiID
          })
          //show error
          let ee = (result.error.issue && result.error.issue.length > 0) ? result.error.issue : "Couldn't complete checkout"
          let ed = (result.error.tokenName && result.error.tokenName.length > 0) ? result.error.tokenName : false
          this.props.updateSubloading(false, ee, ed)
          return
        }
        console.log("Free Payment Complete", result)
        //What to do once we have completed payment!
        this.props.success(result.data.purchaseID)
      })
    } else if (this.state.walletAvailable && this.state.paymentRequest !== false) {
      //Use Apple or Google Pay
      console.log("Wallet Available - attempt payment!")
      this.state.paymentRequest.show()
    } else {
      //Go to the Credit Card Screen to checkout.
      this.props.showCreditCardScreen()
    }
  }

  checkoutName() {
    //1) Update the name props if we can.
    this.props.submitForm && this.props.submitForm()
    //2) Now checkout.
    this.cartTapped()
  }

  render() {
    if (this.props.type === "Home") {
      return (
        <div className="GeojiCnBottomBar">
          {/*<div className="GeojiCnBottomBarPoweredBy">
            <img src={this.props.theme.includes("dark") ? ImagePoweredByGeojiWhite : ImagePoweredByGeoji} alt="Powered By Geoji" />
          </div>*/}
          <div className={"GeojiCnBottomBarButtonCart " + (!Helpers.geojiCartEmpty(this.props.geoji) ? "GeojiCnBottomBarButtonCartCheckout" : "")} onClick={this.cartTapped.bind(this)}>
            { this.props.subloading &&
              <div className="GeojiCnBottomBarButtonCartLoading">
              </div>
            }
            <img src={ImageShoppingCart} alt="Shopping Cart" className="GeojiCnBottomBarButtonCartImage" />
            { !Helpers.geojiCartEmpty(this.props.geoji) &&
              <div className="GeojiCnBottomBarButtonCartContent">
                <div className="GeojiCnBottomBarButtonCartTitle">
                  {this.props.geoji.sandbox === "1" ? "Sandbox" : "Checkout"}
                </div>
                <div className="GeojiCnBottomBarButtonCartPrice" key={this.props.tokenIncrement}>
                  {Helpers.geojiCartPriceText(this.props.geoji, this.props.tokenIncrement)}
                </div>
              </div>
            }
          </div>
        </div>
      )
    } else if (this.props.type === "Name") {
      return (
        <span>
          { this.props.subloading &&
            <div className="GeojiCnInnerPromptLoading">
              <img src={logo} className="GeojiCnInnerPromptLoadingLogo" alt="logo" />
            </div>
          }
          { !this.props.subloading &&
            <div>
              <div className="GeojiCnInnerPromptField">
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="name" placeholder="full name" validation="text" required="true" validateTyping={false} autoComplete="name"
                    tabIndex="1"
                    value={this.props.data.name ? this.props.data.name.value : ""} onEnter={this.checkoutName.bind(this)} onChange={this.props.formChanged} forceCheck={this.props.forceCheck} />
                </div>
              </div>
              { this.props.suberror !== false &&
                <div className="GeojiCnInnerPromptError">
                  {this.props.suberror}
                </div>
              }
              <div className="GeojiCnInnerPromptDescription">
                You will say this to the vendor or event to redeem your tokens.
              </div>
              <div className="GeojiCnInnerPromptButton GeojiCnInnerPromptButton2" onClick={this.checkoutName.bind(this)}>
                Checkout
              </div>
            </div>
          }
        </span>
      )
    }
    return (<div>Unknown Type</div>)
  }
}

export class GeojiWristband extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      code: 921029,
      bcolor: "#FF0000",
      bshapeColor: "#00FFFF",
      bsides: 3,
    }

    this.calculateCode = this.calculateCode.bind(this)
    this.onTimer = this.onTimer.bind(this)

    this.shapeSize = 68
    this.codeDuration = 15.0
    this.timer = false
  }

  componentDidMount() {
    //calculate the code
    this.calculateCode()
    //setup the timer
    this.timer = setInterval(this.onTimer, 250)

    this.focusListener = window.addEventListener("focus", this.calculateCode)
  }

  componentWillUnmount() {
    if (this.timer !== false) {
      clearInterval(this.timer)
    }
    window.removeEventListener("focus", this.calculateCode)
  }

  calculateCode() {
    let code = Helpers.generateOTP(this.props.wristband.secret)
    console.log("calculated code: ", code)

    //console.log("mass colors", MassColors.colors)

    //convert the code into a color - use all digits
    let bcolor = MassColors.colors[code % MassColors.colors.length]
    //invert the color
    let hexVal = bcolor.match(/.{1,2}/g)
    let rgb = [
      parseInt(hexVal[0], 16),
      parseInt(hexVal[1], 16),
      parseInt(hexVal[2], 16)
    ]
    let inverted = [Number(255 - rgb[0]).toString(16), Number(255 - rgb[1]).toString(16), Number(255 - rgb[2]).toString(16)]
    let bshapeColor = ""
    for (let i = 0; i < inverted.length; i = i + 1) {
      let vv = inverted[i]
      if (vv.length === 0) {
        bshapeColor += "00"
      } else if (vv.length === 1) {
        bshapeColor += "0" + vv
      } else {
        bshapeColor += vv
      }
    }

    //change the bsides that is displayed - first 2 digits
    let bsides = Math.floor(3 + ((code / 10000) % 5))

    this.setState({
      code: code,
      bcolor: bcolor,
      bshapeColor: bshapeColor,
      bsides: bsides,
    })
  }

  onTimer() {
    let ss = new Date()
    let seconds = ss / 1000
    let remainder = seconds % this.codeDuration
    //console.log("onTimer", remainder)
    if (remainder < 1.0) {
      this.calculateCode()
    }
  }

  generateSVGShape(size = 68) {
    //hypotenuse
    let h = size / 2.0
    //center
    let c = [size / 2.0, size / 2.0]

    let path = []

    let extra = 0

    for (let i = 0; i < this.state.bsides + extra; i = i + 1) {
      let angle = (i * (360.0 / this.state.bsides)) * Math.PI / 180.0

      //calculate vertex position
      let pt = [c[0] + (Math.cos(angle) * h), c[1] + (Math.sin(angle) * h)]

      path.push(pt)
    }

    let retString = ""
    for (let i = 0; i < path.length; i = i + 1) {
      retString = retString + path[i][0] + "," + path[i][1] + " "
    }
    return retString
  }

  /* eslint-disable no-dupe-keys */
  render() {
    return (
      <div className="GeojiCnInnerPurchaseMassAdBand"
        style={{"background":"#" + this.state.bcolor,
                "background": "-moz-linear-gradient(90deg, #" + this.state.bcolor + " 0%, #" + this.state.bcolor + "80 100%)",
                "background": "-webkit-linear-gradient(90deg, #" + this.state.bcolor + " 0%, #" + this.state.bcolor + "80 100%)",
                "background": "linear-gradient(90deg, #" + this.state.bcolor + " 0%, #" + this.state.bcolor + "80 100%)",
              }}>
        <div className="GeojiCnInnerPurchaseMassAdBandShapeBox">
          { [1, 2, 3, 4, 5].map((shape) => (
            <div className={"GeojiCnInnerPurchaseMassAdBandShape GeojiCnInnerPurchaseMassAdBandShape" + shape} key={"shape_" + shape}>
              <svg width={this.shapeSize} height={this.shapeSize}>
                <defs>
                  <linearGradient id={"wristbandGrad" + this.props.wristband.tokenID} x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{"stopColor":"#" + this.state.bshapeColor, "stopOpacity":"0.5"}} />
                    <stop offset="100%" style={{"stopColor":"#" + this.state.bshapeColor, "stopOpacity":"1.0"}} />
                  </linearGradient>
                </defs>
                <polygon points={this.generateSVGShape(this.shapeSize)}
                  fill={"url(#wristbandGrad" + this.props.wristband.tokenID + ")"} />
              </svg>
            </div>
          ))}
        </div>

        {this.state.code}
        <br/>
        {this.state.bcolor}
        <br/>
        {this.state.bshapeColor}
        <br/>
        {this.state.bsides}
      </div>
    )
  }
  /* eslint-enable no-dupe-keys */
}

export class CheckoutForm extends React.Component {

  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const {stripe, elements} = this.props

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    this.props.updateSubloading(true, false)

    //Determine if we are using the default card on file or not?
    let paymentMethod = false
    if (this.props.card !== false) {
      //Use the card on file.
      paymentMethod = this.props.card.stripeID
    } else {
      //Use the entered card details.
      paymentMethod = {
        card: elements.getElement(CardElement),
      }
    }
    const result = await stripe.confirmCardPayment(this.props.clientSecret, {
      payment_method: paymentMethod
    });
    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
      this.props.updateSubloading(false, result.error.message)
    } else {
      this.props.updateSubloading(false, false)
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        console.log("Success!")
        this.props.goToSuccessPage()
      }
    }
  };

  render() {
    return (
      <div>
        <div className="GeojiCnCheckoutStripeContainer">
          { this.props.subloading &&
            <div className="GeojiCnCheckoutLoadingTop" />
          }
          { this.props.card !== false &&
            <div className="GeojiCnCheckoutStripeContainerCard">
              <div className="GeojiCnCheckoutStripeContainerCardLeft">
                <div className="GeojiCnCheckoutStripeContainerCardLeftTitle">
                  NUMBER
                </div>
                <div className="GeojiCnCheckoutStripeContainerCardLeftNumber">
                  **** **** **** {this.props.card.last4}
                </div>
              </div>
              <div className="GeojiCnCheckoutStripeContainerCardRight">
                <div className="GeojiCnCheckoutStripeContainerCardRightTitle">
                  EXP DATE
                </div>
                <div className="GeojiCnCheckoutStripeContainerCardRightDate">
                  {this.props.card.exp_month} / {this.props.card.exp_year}
                </div>
              </div>
            </div>
          }
          { this.props.card === false &&
            <CardElement options={{style: {
                base: {
                  color: this.props.theme.includes("dark") ? "#FFFFFF" : "#000000",
                  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                  fontSmoothing: "antialiased",
                  fontSize: "17px",
                  "::placeholder": {
                    color: "#8E8E93",
                  },
                },
                invalid: {
                  color: "#FF3B30",
                  iconColor: "#FF3B30",
                }
              }}}
            />
          }
        </div>
        { this.props.card !== false &&
          <div className="GeojiCnCheckoutEdit" onClick={this.props.editCard}>
            Edit Card
          </div>
        }
        { this.props.suberror &&
          <div className="GeojiCnCheckoutError">
            {this.props.suberror}
          </div>
        }
        <div className={"GeojiCnCheckoutTotal " + (this.props.card !== false ? "GeojiCnCheckoutTotalShort" : "")}>
          <div className="GeojiCnCheckoutTotalLabel">
            Total
          </div>
          <div className="GeojiCnCheckoutTotalValue">
            {Helpers.geojiCartPriceTotalText(this.props.geoji)}
          </div>
        </div>
        { this.props.subloading &&
          <div className="GeojiCnCheckoutLoading" />
        }
        { !this.props.subloading &&
          <div className="GeojiCnCheckoutButton" onClick={this.handleSubmit.bind(this)}>
            Pay
          </div>
        }
      </div>
    )
  }
}

export class AdminTips extends React.Component {

  tipTitle(tip) {
    switch (tip) {
      case 1:
        return "Build your Team"
      case 2:
        return "Organization"
      case 3:
        return "Promote"
      case 4:
        return "QR Codes"
      case 5:
        return "Redemption"
      case 6:
        return "Experience"
      case 7:
        return "Experience"
      default:
        return "Unknown"
    }
  }

  tipBody(tip) {
    switch (tip) {
      case 1:
        return "Add other creators & event workers so they can see the dashboard."
      case 2:
        return "Make a task list of everything that needs to be done before & during the event."
      case 3:
        return "Send free tokens to your Instagram friends with the most followers & have them put the link in their bio & promote on their story. Have everyone working your event do this as well."
      case 4:
        return "Print off QR Codes & hang them at the entrance (preferably one, the more entrances the more confusion)."
      case 5:
        return "When people walk-up, ask them for their name & use A-Z to search & check them off your virtual clipboard. Otherwise, have them scan the QR code to purchase."
      case 6:
        return "Connect to WiFi for stable internet."
      case 7:
        return "If you're working the door, put a smile on your face. You set the tone! This isn't airport security. Your customers are here to have fun!"
      default:
        return "Unknown"
    }
  }

  tipColor(tip) {
    switch (tip) {
      case 1:
        return "#101338"
      case 2:
        return "#67AFEE"
      case 3:
        return "#9767EE"
      case 4:
        return "#8FBEA7"
      case 5:
        return "#EE9267"
      case 6:
        return "#EE677E"
      case 7:
        return "#6DC887"
      default:
        return "#101338"
    }
  }

  render() {
    return (
      <div className="GeojiCnAdminWhiteC">
        <div className="GeojiCnAdminWhiteCHeader">
          Tips
        </div>

        <div className="GeojiCnAdminWhiteTips">
          { [1, 2, 3, 4, 5, 6, 7].map((tip) => (
            <div className="GeojiCnAdminWhiteTip" key={"tip_" + tip} style={{background: this.tipColor(tip)}} onClick={this.props.tipTapped.bind(this, tip)}>
              <div className="GeojiCnAdminWhiteTipTR">
                {tip}
              </div>
              <div className="GeojiCnAdminWhiteTipBL">
                {tip}
              </div>
              <div className="GeojiCnAdminWhiteTipTitle">
                {this.tipTitle(tip)}
              </div>
              <div className="GeojiCnAdminWhiteTipBody">
                {this.tipBody(tip)}
              </div>
              <div className="GeojiCnAdminWhiteTipBottom">
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export class RealTimeSocket extends React.Component {

  constructor(props) {
    super(props)

    this.socket = false
    this.channelCallbackFunction = this.channelCallbackFunction.bind(this)
  }

  componentDidMount() {
    //console.log("mount socket")
    //1) Setup the socket.
    //for testing, use localhost 3001. We would need to startup the RealTimeContainer to get that to work though.
    //var socket = io.connect("localhost:3001", {path: "/rts/socket.io"});
    var socket = io.connect("https://rts.darwincloud.com");
    let channel = this.props.channel
    socket.on('connect', () => {
      console.log("socket connected")
      //now try to subscribe to the passed in channel.
      socket.emit('subscribe', channel, API.getAccessToken(), API.getCSRFToken())
      //socket.emit('chat message subscribe', true)
    })
    socket.on('connect_error', (e, j) => {
      //console.log("socket connection error", e, j)
    })
    socket.on('disconnect', (reason) => {
      console.log("socket disconnected", reason)
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });

    socket.on('subscribed', (success, message) => {
      if (!success) {
        console.log("socket subscription error: ", message)
        if (message === "Auth Error") {
          console.log("we need to try refreshing the tokens, then we can try connecting again.")
          API.refreshTokens(() => {
            //now that the tokens have refreshed, try to subscribe again.
            socket.emit('subscribe', channel, API.getAccessToken(), API.getCSRFToken())
          })
        }
      } else {
        //console.log("socket subscription result: ", message)
      }
    })
    socket.on(channel, this.channelCallbackFunction)
    this.socket = socket
  }

  componentWillUnmount() {
    //Disconnect the socket
    //console.log("unmount socket")
    if (this.socket !== false && this.socket !== undefined) {
      console.log("Disconnecting the socket")
      this.socket.close()
    }
  }

  channelCallbackFunction(data) {
    this.props.callback(data)
  }

  render() {
    return (
      <span>
      </span>
    )
  }
}

export class GeojiSettingBar extends React.Component {

  render() {
    return (
      <div className="GeojiSettingsBar">
        <div className="GeojiSettingsBarTop">
          <div className="GeojiSettingsBarTopImage">
            <img src={this.props.image} alt={this.props.title} />
          </div>
          <div className="GeojiSettingsBarTopTitle">
            {this.props.title}
          </div>
          <div className="GeojiSettingsBarTopGrow">
          </div>
          <Components.SettingsToggle name="" name2="" value={this.props.value === true}
            onToggle={this.props.onToggle.bind(this)} />
        </div>
        <div className="GeojiSettingsBarDescription">
          {this.props.description}
        </div>
      </div>
    )
  }
}
