import React from 'react';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

// import the Darwin API classes
import API from '../API.js';
import logo from '../images/GeojiLogoGreenBlue.svg';
import ImageBack from '../images/Back.svg';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export class AmazonPay extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: true,
      data: data,
      forceCheck: false,
      shakeButton: false,
      error: false,
      params: {},
      session: {},

      view: "Checkout",
      subview: "Home",
    }

    this.submitForm = this.submitForm.bind(this)
    this.formChanged = this.formChanged.bind(this)
    this.shakeTheButton = this.shakeTheButton.bind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  calculateView() {
    //get the query parameters.
    let foundParams = API.getAllUrlParams()
    console.log(foundParams)
    this.setState({
      params: foundParams
    })

    if (foundParams.amazonCheckoutSessionId !== undefined) {
      //handle the amazon checkout session
      //call the API to get information about this checkout.
      let dd = {
        amazonCheckoutSessionId: foundParams.amazonCheckoutSessionId
      }
      this.setState({
        loading: true,
        error: false,
      }, () => {
        API.callDarwinAPI("POST", "amazonPaymentIntent/" + this.props.purchaseID, dd, (result) => {
          if ("error" in result) {
            console.log("Error POST amazonPaymentIntent", result)
            this.setState({
              loading: false,
              error: "Couldn't complete the purchase.",
            })
            return
          }
          console.log("POST amazonPaymentIntent/" + this.props.purchaseID, result.data)
          console.log(result.data.session)

          //Handle the payment intent and go to the Geoji View to checkout.
          let geo = Helpers.formatGeoji(result.data.geoji)
          console.log("Geoji", geo, Helpers.geojiCartPrice(geo))

          //change the view to see the Geoji Purchase by purchaseID.
          this.props.changeView("Geoji", geo.geojiID, this.props.purchaseID)
        })
      })
    } else {
      //route to the home page.
      this.props.changeView("Home")
    }
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Purchase":
        requiredFields = ["name"]
        optionals = ["notes"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Purchase":
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    let shouldSubmit = false
    this.setState((prevState) => {
      let d = prevState.data
      let newRet = {
        data: d,
        forceCheck: false
      }
      if (name === "phoneNumber" && prevState.subview === "Phone") {
        if (((d[name] && d[name].value && d[name].value.length === 0) || !d[name] || !d[name].value) && value.length >= 10) {
          //should submit
          shouldSubmit = true
        }
      }
      if (name === "code" && prevState.subview === "Code") {
        if (value.length >= 6) {
          //should submit
          shouldSubmit = true
        }
      }
      if (prevState.view === "Purchase" && name === "name" && value !== this.props.userInfo.user.name) {
        newRet.nameSavedCount = 0
      }
      if (prevState.view === "Purchase" && name === "notes" && value !== (this.state.purchase.notes || "")) {
        newRet.notesSavedCount = 0
      }
      d[name] = {
        value: value,
        valid: valid
      }
      newRet.data = d
      return newRet
    }, () => {
      if (shouldSubmit) {
        this.submitForm()
      }
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  backHome() {
    console.log("Back Home")
  }

  payWithAmazon() {
    if (this.state.session && this.state.session.webCheckoutDetails && this.state.session.webCheckoutDetails.amazonPayRedirectUrl) {
      let uu = this.state.session.webCheckoutDetails.amazonPayRedirectUrl
      console.log("Amazon Pay URL", uu)
      //redirect to this link.
      window.location.href = uu
    }
  }

  render() {

    return (
      <div className="Geoji">
        {/* Loading Indicator */}
        { this.state.loading &&
          <div className="GeojiLoading">
            <img src={logo} className="GeojiLoadingLogo" alt="logo" />
          </div>
        }
        {/* Error Handling */}
        { !this.state.loading && this.state.error !== false &&
          <Components.GeojiError error={this.state.error} action={this.props.changeView.bind(this, "Home")} />
        }
        {/* Geoji View */}
        { !this.state.loading && this.state.error === false &&
            <div className="GeojiCn">
              { this.state.view === "Checkout" &&
                <div className="GeojiCnInner">
                  { this.state.subview === "Home" &&
                    <div className="GeojiCnCheckout">
                      <div className="GeojiCnCheckoutTopBar">
                        <div className="GeojiCnCheckoutTopBarBack" onClick={this.backHome.bind(this)}>
                          <img src={ImageBack} alt="back" className="GeojiCnCheckoutTopBarBackArrow" />
                        </div>
                        <div className="GeojiCnCheckoutTopBarTitle">
                          Checkout
                        </div>
                        <div className={"GeojiCnCheckoutTopBarSandbox " + ((this.state.session && this.state.session.releaseEnvironment === "Sandbox") ? "GeojiCnCheckoutTopBarSandboxVisible" : "")}>
                          Sandbox
                        </div>
                      </div>
                      <div className="GeojiCnCheckoutTitle">
                        Amazon Pay
                      </div>

                      <div className="GeojiCnCheckoutTotal">
                        <div className="GeojiCnCheckoutTotalLabel">
                          Total
                        </div>
                        <div className="GeojiCnCheckoutTotalValue">
                          {Helpers.geojiCartPriceTotalText(this.state.geoji)}
                        </div>
                      </div>
                      <div className="GeojiCnCheckoutButton" onClick={this.payWithAmazon.bind(this)}>
                        Pay
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
        }
      </div>
    )
  }
}
