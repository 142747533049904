import React from 'react';
import ReactPlayer from "react-player"
//import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';
//import * as Gradient from '../Gradient.js';

import ImageLogoText from '../images/LogoText.svg';
//import ImageGraph1 from '../images/shots/Graph1.png';
//import ImageGraph2 from '../images/shots/Graph2.png';
import ImageGraphicTop from '../images/shots/TwoPhone.jpg'

// import ImageCompanyAmazon from '../images/companies/AmazonGray.svg';
// import ImageCompanyApple from '../images/companies/AppleGray.svg';
// import ImageCompanyGoogle from '../images/companies/GoogleGray.svg';
// import ImageCompanyPayPal from '../images/companies/PayPalGray.svg';
// import ImageCompanyVenmo from '../images/companies/VenmoGray.svg';
// import ImageCompanyBank from '../images/Bank.svg';
import ImageCompanyAllGray from '../images/CompaniesGray.svg';
// import ImageCompanyLogos from '../images/CompanyLogosGray.svg';

// import ImagePhoneNotifications from '../images/PhoneNotifications.svg';
// import ImageEmail from '../images/Email.svg';
// import ImageMessages from '../images/Messages.svg';

// import ImageAdMap from '../images/shots/AdMap.png';
// import ImageAdMap2 from '../images/shots/AdMap3.png';
import ImageAdMapHorizontal from '../images/shots/AdMapHorizontal.jpg';

import ImageNetworkTwoNetworth from '../images/shots/NetworkTwoNetworth.jpg';
import ImageSellAnything from '../images/shots/SellAnything.jpg';
import ImageCreditCardScanner from '../images/shots/CreditCardScanner.jpg';
import ImagePaidFast from '../images/shots/PaidFast.jpg';
import ImageAddDoorGuy from '../images/shots/AddDoorGuy.jpg';

// import ImageCheckList from '../images/shots/CheckList.svg';
// import ImageGetPaid from '../images/shots/GetPaid.svg';
// import ImageVirtualTerminal from '../images/shots/VirtualTerminal.svg';
// import ImageTeamAccess from '../images/shots/TeamAccess.svg';
// import ImageYourPhone from '../images/shots/YourPhone.svg';
// import ImageEvangelize from '../images/shots/Evangelize.svg';

import ImageProfilePicture from '../images/PersonGray.svg';
import ImageMenuBar from '../images/MenuBar.svg';
import ImageXButton from '../images/XButton.svg';
import ImageInfinity from '../images/Infinity.svg';
// import ImageFestivalMap from '../images/shots/FestivalMap.svg';
// import ImageKevinCircle from '../images/KevinCircle.png';
import ImageIceSkate from '../images/IceSkate.png';
// import ImagePumpkin from '../images/Pumpkin.svg';

//import the Darwin API classes
import API from '../API.js';

/**
Header for the Home Page and other generic content pages.
*/
export class HomeHeader extends React.Component {

  render() {
    return (
      <div className={"HomeHeader"}>
        <a href="/" className="HomeHeaderLogo">
          <img src={ImageLogoText} alt="Logo" className="HomeHeaderLogoImage" />
        </a>

        { this.props.selected !== "dashboard" &&
          <div className="HomeHeaderLinks">
            <a href="/home" className={"HomeHeaderLink " + (this.props.selected === "features" ? "HomeHeaderLinkSelected" : "")}>
              Home
            </a>
            <div className="HomeHeaderLink HomeHeaderLinkPricing">
              Pricing
              <div className="HomeHeaderLinkPricingFree">
                FREE
              </div>
            </div>
            <a href="/about" className={"HomeHeaderLink " + (this.props.selected === "about" ? "HomeHeaderLinkSelected" : "")}>
              Our Why
            </a>
            <a href="/contact" className={"HomeHeaderLink " + (this.props.selected === "contact" ? "HomeHeaderLinkSelected" : "")}>
              Contact
            </a>
          </div>
        }

        {/* Sign In Here */}
        <HomeHeaderProfile props={this.props} />

        <div className="HomeHeaderIcon" onClick={this.props.openCloseMenu.bind(this, true)}>
          <img src={ImageMenuBar} alt="Menu" className="HomeHeaderIconImage" />
        </div>
      </div>
    )
  }
}

/**
 * Profile button that shows if you are logged in or not.
 */
export class HomeHeaderProfile extends React.Component {
  render() {
    //get the profilePicture and the user's name if we have it
    let profilePicture = null
    let userName = null
    if (API.hasLoggedIn()) {
      if (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.profilePicture !== null && this.props.userInfo.user.profilePicture !== "") {
        profilePicture = this.props.userInfo.user.profilePicture
      } else {
        let ccd = API.getCookie("profilePicture")
        if (ccd !== null && ccd !== "") {
          profilePicture = ccd
        }
      }
      if (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.name !== null && this.props.userInfo.user.name !== "") {
        userName = this.props.userInfo.user.name
      } else {
        let ccd = API.getCookie("name")
        if (ccd !== null && ccd !== "") {
          userName = ccd
        }
      }
    }

    return (
      <div className="HomeHeaderSignIn">
        <a href="/dashboard" className={"HomeHeaderSignInLink " + (this.props.selected === "dashboard" ? "HomeHeaderSignInLinkSelected" : "")}>
          { API.hasLoggedIn() &&
            <div className="HomeHeaderSignInProfile">
              { userName === null &&
                <img src={ImageMenuBar} alt="Menu" className="HomeHeaderSignInProfileHamburger" />
              }
              { profilePicture === null &&
                <span className="URLImage">
                  <img src={ImageProfilePicture} alt="Profile" className="HomeHeaderSignInProfilePhoto" />
                </span>
              }
              { profilePicture !== null &&
                <Components.URLImage src={profilePicture} alt="Profile" className="HomeHeaderSignInProfilePhoto" />
              }
              { userName !== null &&
                <div className="HomeHeaderSignInProfileName">
                    {userName}
                </div>
              }
            </div>
          }
          { !API.hasLoggedIn() &&
            <div className="HomeHeaderSignInButton">
              Sign In
            </div>
          }
        </a>
      </div>
    )
  }
}

/**
Header for the Home Page and other generic content pages.
*/
export class HomeHeaderOpen extends React.Component {
  render() {
    
    //get the profilePicture and the user's name if we have it
    let profilePicture = null
    let userName = null
    if (API.hasLoggedIn()) {
      if (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.profilePicture !== null && this.props.userInfo.user.profilePicture !== "") {
        profilePicture = this.props.userInfo.user.profilePicture
      } else {
        let ccd = API.getCookie("profilePicture")
        if (ccd !== null && ccd !== "") {
          profilePicture = ccd
        }
      }
      if (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.name !== null && this.props.userInfo.user.name !== "") {
        userName = this.props.userInfo.user.name
      } else {
        let ccd = API.getCookie("name")
        if (ccd !== null && ccd !== "") {
          userName = ccd
        }
      }
    }

    return (
      <div className="HomeHeaderOpen">
        <div className="HomeHeaderOpenContent">
          <div className="HomeHeaderOpenContentTop">
            <a href="/" className="HomeHeaderLogo">
              <img src={ImageLogoText} alt="Logo" className="HomeHeaderLogoImage" />
            </a>

            <div className="HomeHeaderIcon" onClick={this.props.openCloseMenu.bind(this, false)}>
              <img src={ImageXButton} alt="Menu" className="HomeHeaderIconImage" />
            </div>
          </div>

          <div className="HomeHeaderLinks">
            <a href="/home" className={"HomeHeaderLink " + (this.props.selected === "features" ? "HomeHeaderLinkSelected" : "")}>
              Home
            </a>
            <div className="HomeHeaderLink HomeHeaderLinkPricing">
              Pricing
              <div className="HomeHeaderLinkPricingFree">
                FREE
              </div>
            </div>
            <a href="/about" className={"HomeHeaderLink " + (this.props.selected === "about" ? "HomeHeaderLinkSelected" : "")}>
              Our Why
            </a>
            <a href="/contact" className={"HomeHeaderLink " + (this.props.selected === "contact" ? "HomeHeaderLinkSelected" : "")}>
              Contact
            </a>
            
            {/* My Account Content */}
            <div className="HomeHeaderLinksBreak">
            </div>
            <div className="HomeHeaderSignIn2">
              <a href="/dashboard" className={"HomeHeaderSignInLink " + (this.props.selected === "dashboard" ? "HomeHeaderSignInLinkSelected" : "")}>
                { API.hasLoggedIn() &&
                  <div className="HomeHeaderSignInProfile">
                    { userName === null &&
                      <img src={ImageMenuBar} alt="Menu" className="HomeHeaderSignInProfileHamburger" />
                    }
                    { profilePicture === null &&
                      <span className="URLImage">
                        <img src={ImageProfilePicture} alt="Profile" className="HomeHeaderSignInProfilePhoto" />
                      </span>
                    }
                    { profilePicture !== null &&
                      <Components.URLImage src={profilePicture} alt="Profile" className="HomeHeaderSignInProfilePhoto" />
                    }
                    { userName !== null &&
                      <div className="HomeHeaderSignInProfileName">
                          {userName}
                      </div>
                    }
                  </div>
                }
                { !API.hasLoggedIn() &&
                  <div className="HomeHeaderSignInButton">
                    Sign In
                  </div>
                }
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class ResponsivePlayer extends React.Component {
  render () {
    return (
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url='https://www.youtube.com/watch?v=zI3JxYMeQWg'
          controls={true} pip={true}
          width='100%'
          height='100%'
        />
      </div>
    )
  }
}

class HomeFeaturedEventsListItem extends React.Component {

  render() {
    return (
      <div className="HomeFeaturedEventsListItem" onClick={this.props.onClick}>
        <div className="HomeFeaturedEventsListItemTop">
          { this.props.geoji.photoURL && this.props.geoji.photoURL.length > 0 &&
            <img src={this.props.geoji.photoURL} className="HomeFeaturedEventsListItemTopImage" alt={this.props.geoji.title} />
          }
          { !(this.props.geoji.photoURL && this.props.geoji.photoURL.length > 0) &&
            <div className="HomeFeaturedEventsListItemTopEmoji">
              {this.props.geoji.emoji}
            </div>
          }
        </div>
        <div className="HomeFeaturedEventsListItemTitle">
          {this.props.geoji.title}
        </div>
        <div className="HomeFeaturedEventsListItemDate">
          { this.props.geoji.infinite &&
            <img src={ImageInfinity} alt="Infinity"/>
          }
          { !this.props.geoji.infinite &&
            <span>{Helpers.geojiGetNextEventDateString(this.props.geoji, "short")}</span>
          }
        </div>
      </div>
    )
  }
}

export class PageHeader extends React.Component {

  beforeDate(dc) {
    let dd = new Date()
    if (dd <= new Date(dc)) {
      return true
    }
    return false
  }

  afterDate(dc) {
    let dd = new Date()
    if (dd >= new Date(dc)) {
      return true
    }
    return false
  }

  render() {
    return (
      <div className="HomePagePageHeader">
        {/* Start Engine Header */}
        {/* <div className="StartEngineHeaderStretch">
          <div className="StartEngineHeader">
            <img className="StartEngineHeaderImage" src={ImageKevinCircle} alt="Kevin O'Leary Invest in Geoji" />
            <div className="StartEngineHeaderBody">
              <p className="StartEngineHeaderBodyTitle">
                Invest in Geoji
              </p>
              <p className="StartEngineHeaderBodyDescription">
                Now you have the chance to invest with Kevin O'Leary
              </p>
            </div>
            <a href="https://www.startengine.com/geoji" target="_blank" rel="noopener noreferrer"><div className="StartEngineHeaderButton">
              Invest now via StartEngine
            </div></a>
          </div>
        </div> */}

        {/* Ice Skating Header */}
        { this.afterDate('2023-12-01') && this.beforeDate('2023-12-31') &&
          <div className="IceSkatingHeaderStretch">
            <div className="IceSkatingHeader">
              <img className="IceSkatingHeaderImage" src={ImageIceSkate} alt="Ice Skate" />
              <div className="IceSkatingHeaderBody">
                <p className="IceSkatingHeaderBodyTitle">
                  Ice Rink at the Long Center
                </p>
                <p className="IceSkatingHeaderBodyDescription">
                  Dec 1 - Dec 31, tickets start at $15
                </p>
              </div>
              <a href="https://geoji.com/g/4099?r=2948" target="_blank" rel="noopener noreferrer">
                <div className="IceSkatingHeaderButton">
                  Get Tickets
                </div>
              </a>
            </div>
          </div>
        }

        {/* <div className="IceSkatingHeaderStretch IceSkatingHeaderStretch2">
          <div className="IceSkatingHeader">
            <img className="IceSkatingHeaderImage" src={ImageIceSkate} alt="Ice Skate" />
            <div className="IceSkatingHeaderBody">
              <p className="IceSkatingHeaderBodyTitle">
                San Antonio Ice Rink
              </p>
              <p className="IceSkatingHeaderBodyDescription">
                Nov 23 - Dec 31, tickets start at $15
              </p>
            </div>
            <a href="https://geoji.com/g/4104?r=2951" target="_blank" rel="noopener noreferrer">
              <div className="IceSkatingHeaderButton">
                Get Tickets
              </div>
            </a>
          </div>
        </div>
        <div className="IceSkatingHeaderStretch">
          <div className="IceSkatingHeader">
            <img className="IceSkatingHeaderImage" src={ImageIceSkate} alt="Ice Skate" />
            <div className="IceSkatingHeaderBody">
              <p className="IceSkatingHeaderBodyTitle">
                Boerne Ice Rink
              </p>
              <p className="IceSkatingHeaderBodyDescription">
                Nov 23 - Dec 31, tickets start at $15
              </p>
            </div>
            <a href="https://geoji.com/g/4105?r=2950" target="_blank" rel="noopener noreferrer">
              <div className="IceSkatingHeaderButton">
                Get Tickets
              </div>
            </a>
          </div>
        </div> */}

        {/* San Antonio Pumpkin Festival Header */}
        {/* <div className="PumpkinHeaderStretch">
          <div className="PumpkinHeader">
            <img className="PumpkinHeaderImage" src={ImagePumpkin} alt="Pumpkin" />
            <div className="PumpkinHeaderBody">
              <p className="PumpkinHeaderBodyTitle">
                San Antonio Pumpkin Festival
              </p>
              <p className="PumpkinHeaderBodyDescription">
                Sept 28 - Oct 29, tickets start at $12
              </p>
            </div>
            <a href="https://geoji.com/g/4020?r=1502" target="_blank" rel="noopener noreferrer">
              <div className="PumpkinHeaderButton">
                Get Tickets
              </div>
            </a>
          </div>
        </div> */}
      </div>
    )
  }
}

/**
Main Home Page for people to land on when going to Geoji.com
*/
export class HomePage extends React.Component {

  constructor(props) {
    super(props)

    let data = {
      eventType: {
        value: "Festival",
        valid: true,
      },
      calculatorPeople: {
        value: "9837",
        valid: true
      },
      calculatorTicket: {
        value: "120",
        valid: true
      },
      calculatorMerchandise: {
        value: "0",
        valid: true
      },
      calculatorFood: {
        value: "0",
        valid: true
      }
    }

    this.state = {
      loading: false,
      error: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",

      handle: undefined,
      events: [],
      businesses: [],
    }

    this.actionText = Helpers.isMobile() ? "Download App" : "Get Started"
    this.actionLink = "https://link.geoji.com/download"

    this.formChanged = this.formChanged.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    this.calculateView()

    //load the featured Geojis.
    //this.loadHandle()

    //console.log("Gradient", Gradient)
    //var gradient = new Gradient.Gradient()
    //gradient.initGradient('#gradient-canvas');
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    /*setTimeout(() => {
      const scrollHeight = document.body.scrollHeight;
      // scroll to the bottom of webpage
      window.scrollTo(0, scrollHeight);
    }, 1000)*/
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        switch (this.state.subview) {
          case "Home":
            requiredFields = []
            optionals = ["calculatorPeople", "calculatorTicket", "calculatorMerchandise", "calculatorFood"]
            requiredIf = []
            requiredSometimes = []
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Home":
          switch (this.state.subview) {
            case "Home":
              console.log("submitting calculator form", data)
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    let shouldSubmit = false
    this.setState((prevState) => {
      let d = prevState.data
      let newRet = {
        data: d,
        forceCheck: false
      }
      d[name] = {
        value: value,
        valid: valid
      }
      //recalculate the calculator numbers if the changed value was eventType
      if (name === "eventType") {
        switch (value) {
          case "Festival":
            d["calculatorPeople"] = {
              value: "9837",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "120",
              valid: true
            }
            break;
          case "Venue":
            d["calculatorPeople"] = {
              value: "423",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "60",
              valid: true
            }
            break;
          case "Concert":
            d["calculatorPeople"] = {
              value: "217",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "40",
              valid: true
            }
            break;
          case "Event":
            d["calculatorPeople"] = {
              value: "185",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "30",
              valid: true
            }
            break;
          case "Popup":
            d["calculatorPeople"] = {
              value: "69",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "20",
              valid: true
            }
            break;
          case "Experience":
            d["calculatorPeople"] = {
              value: "30",
              valid: true
            }
            d["calculatorTicket"] = {
              value: "80",
              valid: true
            }
            break;
          default:
            break;
        }
      }

      newRet.data = d
      return newRet
    }, () => {
      if (shouldSubmit) {
        this.submitForm()
      }
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  loadHandle() {
    this.setState({
      loading: true,
      error: false,
    }, () => {
      API.callDarwinAPIUnsecured("GET", "handle/geoji", {}, (result) => {
        console.log("result", result)
        if ("error" in result) {
          console.log("Error GET Handle", result)
          this.setState({
            loading: false,
            error: "Invalid link.",
          })
          return
        }

        let events = []
        let businesses = []
        for (let i = 0; i < result.data.events.length; i = i + 1) {
          events.push(Helpers.formatGeoji(result.data.events[i]))
        }
        for (let i = 0; i < result.data.businesses.length; i = i + 1) {
          businesses.push(Helpers.formatGeoji(result.data.businesses[i]))
        }

        this.setState({
          handle: result.data.handle,
          events: events,
          businesses: businesses,
          loading: false,
          error: false,
        })
      })
    })
  }

  geojiSelected(geoji) {
    this.props.changeView("Geoji", geoji.geojiID)
  }

  render() {
    return (
      <div className="HomePage">

        <PageHeader />

        {/* Header */}
        <HomeHeader selected="features" openCloseMenu={this.props.openCloseMenu} userInfo={this.props.userInfo} />

        {/* Turn your passion into a discoverable business */}
        <div className="HomeTurningPassions">
          <div className="HomePageContent">
            <div className="HomePageContentLeft">
              <h1 className="HomePageContentLeftTitle">
                Turn your passion into a discoverable business
              </h1>
              <p className="HomePageContentLeftSubtitle">
                Finally — <b>FREE ticketing</b> & <b>POS</b> that actually gets you <b>discovered</b>
              </p>
              <a href={this.actionLink}>
                <div className="HomePageContentLeftButton HomeSellTicketsButton">
                  {this.actionText}
                </div>
              </a>
            </div>
            <div className="HomePageContentRight">
              <img src={ImageGraphicTop} className="HomeSellTicketsGraphic" alt="Graph" />
            </div>
          </div>
        </div>

        {/* Company Logos */}
        <div className="HomeCompanies">
          <img src={ImageCompanyAllGray} alt="Amazon, PayPal, Venmo, Apple, Google" className="HomeCompany"/>
        </div>

        {/* Advertising Map */}
        <div className="HomeShotSection">
          <h2 className="HomeShotSectionTitle">
            Free advertising on our map of emojis
          </h2>
          <div className="HomeMapImage">
            <img src={ImageAdMapHorizontal} alt="Map of Emojis" />
          </div>
          <div className="HomeShotSectionBody">
            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Modern hieroglyphics
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                It's primitive and everyone just gets it.
              </p>
            </div>

            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Bat signal to the city
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                Notify everyone nearby when you drop a pin on the map.
              </p>
            </div>

            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Crowd sourcing
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                Your local community is your best demographic.
              </p>
            </div>
          </div>
        </div>

        {/* Turn your network into your networth */}
        <div className="HomeNetworkSection">
          <div className="HomeNetworkSectionContent">
            <h2 className="HomeNetworkSectionContentTitle">
              Turn your network<br/>into your networth
            </h2>
            <p className="HomeNetworkSectionContentDescription">
              Free SMS Text Blaster
            </p>
          </div>
          <div className="HomeNetworkSectionBackground">
            <img src={ImageNetworkTwoNetworth} alt="Network to Networth"/>
          </div>
        </div>

        {/* Sell Anything */}
        <div className="HomeShotSection">
          <h2 className="HomeShotSectionTitle">
            Sell Anything
          </h2>
          <div className="HomeShotSectionImage">
            <img src={ImageSellAnything} alt="Sell Anything with Geoji" />
          </div>
          <div className="HomeShotSectionBody">
            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Tickets & Presales
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                Fastest checkout in the industry.
              </p>
            </div>

            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Food, Drink, Merchandise, ...
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                Customers are notified when their order is ready.
              </p>
            </div>

            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Tables, Bookings, Auctions, ...
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                Sell the whole experience all in one place.
              </p>
            </div>
          </div>
        </div>

        {/* Accept credit cards for walkups */}
        <div className="HomeShotSection">
          <h2 className="HomeShotSectionTitle">
            Accept credit cards for walkups
          </h2>
          <div className="HomeShotSectionImage">
            <img src={ImageCreditCardScanner} alt="Geoji Proprietary Credit Card Scanner" />
          </div>
          <div className="HomeShotSectionBody">
            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Proprietary
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                You've never seen anything like this before.
              </p>
            </div>

            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                No hardware required
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                Customers are notified when their order is ready.
              </p>
            </div>

            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Faster than cash
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                Scans in 0.1 seconds and is faster than chipping or swiping.
              </p>
            </div>
          </div>
        </div>

        {/* Easily add door guys, cashiers, and staff */}
        <div className="HomeSplitSection">
          <div className="HomeSplitSectionLeft">
            <img src={ImageAddDoorGuy} alt="Geoji Add Door Guy to Team" />
          </div>
          <div className="HomeSplitSectionRight">
            <p className="HomeSplitSectionRightTitle">
              Easily add door guys, cashiers, and staff
            </p>
            <p className="HomeSplitSectionRightDescription">
              So easy your grandma could do it. True story. She has.
            </p>
          </div>
        </div>

        {/* Get paid fast & run payroll */}
        <div className="HomeShotSection">
          <h2 className="HomeShotSectionTitle">
            Get paid fast & run payroll
          </h2>
          <div className="HomeShotSectionImage">
            <img src={ImagePaidFast} alt="Geoji Proprietary Credit Card Scanner" />
          </div>
          <div className="HomeShotSectionBody">
            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Quick payouts
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                Get paid out in 2-3 days with PayPal, Venmo, & Bank Transfer
              </p>
            </div>

            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Split profits
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                Running an event with your friends? Split up the profits transparently without any hassle.
              </p>
            </div>

            <div className="HomeShotSectionBodyPart">
              <p className="HomeShotSectionBodyPartTitle">
                Payroll
              </p>
              <p className="HomeShotSectionBodyPartDescription">
                Pay your help straight from your balance.
              </p>
            </div>
          </div>
        </div>

        {/* Featured Events */}
        {(this.state.events.length > 0 || this.state.businesses.length > 0) &&
          <div className="HomeFeaturedEvents">
            <div className="HomeFeaturedEventsTitle">
              Featured
            </div>
            <div className="HomeFeaturedEventsList">
              { this.state.events.map((geoji, i) => (
                <HomeFeaturedEventsListItem key={"event_" + geoji.geojiID + "_" + i} geoji={geoji} onClick={this.geojiSelected.bind(this, geoji)} />
              ))}
              { this.state.businesses.map((geoji, i) => (
                <HomeFeaturedEventsListItem key={"business_" + geoji.geojiID + "_" + i} geoji={geoji} onClick={this.geojiSelected.bind(this, geoji)} />
              ))}
            </div>
          </div>
        }

        {/* Testimonial */}
        <div className="HomeTestimonial">
          <div className="HomeTestimonialContent">
            <h2 className="HomeTestimonialTitle">
              Trust our Customers
            </h2>
            <div className="HomeTestimonialVideo">
              <ResponsivePlayer />
            </div>
            <p className="HomeTestimonialBody">
              Sammy from <a href="https://wanderlustwine.com" target="_blank" rel="noopener noreferrer">Wanderlust Wine</a> used Geoji for one festival.
              Now, he has transformed his entire business around Geoji selling memberships,
              rides to Wineries, experiences, charcuterie boards, tip jars, etc.
            </p>
            <a href={this.actionLink}>
              <div className="HomeTestimonialButton">
                {this.actionText}
              </div>
            </a>
          </div>
        </div>

        {/* Footer */}
        <Components.GeojiFooter nohide={true} />
      </div>
    )
  }
}
