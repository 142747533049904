//import the Darwin API classes

//import StatViewsCircle from './images/Icons/StatViewsCircle.svg'
//import StatTapsCircle from './images/Icons/StatTapsCircle.svg'
//import StatFavoritesCircle from './images/Icons/StatFavoritesCircle.svg'
//import StatUsesCircle from './images/Icons/StatUsesCircle.svg'
//import StatSignedUpCircle from './images/Icons/StatSignedUpCircle.svg'
//import StatPayingCircle from './images/Icons/StatPayingCircle.svg'

import ImageGeojiCoin0 from './images/GeojiCoinOrange.svg';
import ImageGeojiCoin1 from './images/GeojiCoinRed.svg';
import ImageGeojiCoin2 from './images/GeojiCoinBlue.svg';
import ImageGeojiCoin3 from './images/GeojiCoinPink.svg';
import ImageGeojiCoin4 from './images/GeojiCoinGreen.svg';

import ImageGeojiCoinShape0 from './images/GeojiCoinShapeOrange.svg';
import ImageGeojiCoinShape1 from './images/GeojiCoinShapeRed.svg';
import ImageGeojiCoinShape2 from './images/GeojiCoinShapeBlue.svg';
import ImageGeojiCoinShape3 from './images/GeojiCoinShapePink.svg';
import ImageGeojiCoinShape4 from './images/GeojiCoinShapeGreen.svg';

import { version, apiVersion } from '../../package.json'
import API from './API.js';
import request from 'request';
import { DateObject } from "react-multi-date-picker"

var pluralize = require('pluralize')
let emojis = require('./data/emojis2-parsed.json')
let stopwords = require('./data/stopwords_en.js').words

//Returns the components of the url.
//For instance, /abc/def/yoyo would return ['abc', 'def', 'yoyo']
//A blank url would return ['']
export function getWindowPathComponents(props = false) {
  let path = ""
  if (props !== false && props.location !== undefined) {
    //split the location into components.
    let url = new URL(props.location)
    path = url.pathname
  } else if (typeof(window) !== "undefined") {
    path = window.location.pathname
  }
  let pathComponents = path.split("/")
  if (pathComponents[0] === "" && pathComponents.length > 1) {
    //remove the first element as it is worthless.
    pathComponents.splice(0, 1)
  }
  return pathComponents
}

//Changes the url in the browser for help with deep linking.
export function updateWindowPath(newPath) {
  //if the current path is different from newPath then add newPath to the history
  let pathComponents = getWindowPathComponents()
  if ("/" + pathComponents.join("/") !== newPath) {
    console.log("window history push state", newPath)
    //window.history.replaceState({}, "", newPath)
    window.history.pushState({}, "", newPath)
  }
}

/**
* Returns the url parameters as an object that you can access.
* Code taken from https://www.sitepoint.com/get-url-parameters-with-javascript/
*/
export function getAllUrlParams(url = false) {
  if (typeof(window) !== "undefined") {
    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      var arr = queryString.split('&');

      let funcv = (v) => {
        paramNum = v.slice(1,-1);
        return '';
      }

      for (var i=0; i<arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');

        // in case params look like: list[]=thing1&list[]=thing2
        var paramNum = undefined;
        var paramName = a[0].replace(/\[\d*\]/, (v) => {
          return funcv(v)
        });

        // set parameter value (use 'true' if empty)
        var paramValue = typeof(a[1])==='undefined' ? true : a[1];

        // if parameter name already exists
        if (obj[paramName]) {
          // convert value to array (if still string)
          if (typeof obj[paramName] === 'string') {
            obj[paramName] = [obj[paramName]];
          }
          // if no array index number specified...
          if (typeof paramNum === 'undefined') {
            // put the value on the end of the array
            obj[paramName].push(paramValue);
          }
          // if array index number specified...
          else {
            // put the value at that index number
            obj[paramName][paramNum] = paramValue;
          }
        }
        // if param name doesn't exist yet, set it
        else {
          obj[paramName] = paramValue;
        }
      }
    }

    //now decode the uri
    let keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i = i + 1) {
      obj[keys[i]] = decodeURIComponent(obj[keys[i]])
    }
    return obj;
  }
  return {}
}

//Searches and returns the topX emojis (if found) for the provided search.
export function searchEmojis(search, topX = 10) {
  //1) Remove all non-alphanumeric characters.
  let combined = search.replace(/[^0-9a-z]/gi, ' ')

  //2) Split into words
  let words = combined.split(' ')
  //2.1) lowercase the words and singularize
  words = words.map((word) => {
    return [pluralize(word.toLowerCase(), 1), 1]
  })
  //2.2) remove empty words & stopwords
  words = words.filter((word) => {
    if (word[0].length === 0 || stopwords.includes(word[0])) {
      return false
    }
    return true
  })
  //2.3) combine words into word counts.
  words = words.reduce((acc, word) => {
    acc["-" + word[0]] = (acc["-" + word[0]] || 0) + 1
    return acc
  }, [])

  let wordsList = Object.keys(words)

  //3) Search over the emojis
  let emojiCounts = []
  for (let i = 0; i < emojis.length; i = i + 1) {
    let keywords = emojis[i]["keywords"].split(" ")
    //loop over the keywords
    for (let j = 0; j < keywords.length; j = j + 1) {
      if (wordsList.includes("-" + keywords[j])) {
        //we have found a keyword for this emoji.
        let value = words["-" + keywords[j]]
        emojiCounts[emojis[i]["symbol"]] = (emojiCounts[emojis[i]["symbol"]] || 0) + value
      }
    }
  }

  let top10 = Object.keys(emojiCounts).map(function(key) {
    return [key, emojiCounts[key]];
  });
  top10.sort(function(first, second) {
    return second[1] - first[1];
  });

  return top10.slice(0, topX)
}

//Searches for similar emojis to this emoji.
export function searchLikeEmojis(search, topX = 10) {
  //1) Get the keywords for this emoji.
  let keywords = ""
  for (let i = 0; i < emojis.length; i = i + 1) {
    if (emojis[i]["symbol"] === search) {
      keywords = emojis[i]["keywords"]
    }
  }
  //2) Search for the emojis
  if (keywords === "") {
    //couldn't find this emoji, search with the original search phrase.
    return searchEmojis(search, topX)
  } else {
    return searchEmojis(keywords, topX)
  }
}

//Returns if we are in development or production
export function isDevelopment() {
  if (process && process.env && process.env.REACT_APP_STAGE === 'dev') {
    return true
  }
  return false
}

export function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy).then(() => {
          console.log(`"${textToCopy}" was copied to clipboard.`);
        })
        .catch((err) => {
          console.error(`Error copying text to clipboard: ${err}`);
        });;;
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res() : rej();
          textArea.remove();
        }).then(() => {
          console.log(`"${textToCopy}" was copied to clipboard 2.`);
        }).catch((err) => {
          console.error(`Error copying text to clipboard 2: ${err}`);
        });
    }
}

//Returns a random string of the provided length of alphanumeric characters
export function randomString(len) {
  var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return [...Array(len)].reduce(a=>a+p[~~(Math.random()*p.length)],'');
}

//Returns the initials or - from the provided name
export function getInitialsFromName(name) {
  let nn = name
  if (typeof nn !== "string") {
    nn = ""
  }
  let parts = nn.trim()
  parts = parts.split(/\s+/)
  let res = ""
  for (let i = 0; i < Math.min(parts.length, 2); i = i + 1) {
    res += parts[i].substring(0, 1)
  }
  if (res === "") {
    res = "--"
  }
  return res.toUpperCase()
}

// Subtracts years from a date
export function subtractYears(date, years) {
  let d = date
  d.setFullYear(date.getFullYear() - years)
  return d
}

//Returns the account options.
export function getAccountOptions(accountType) {
  if (accountType === "Personal") {
    return [
      {
        value: "",
        label: "Select an Account Type"
      },
      {
        value: "Personal",
        label: "Personal Account"
      },
    ]
  } else {
    return [
      {
        value: "",
        label: "Select an Account Type"
      },
      {
        value: "Corporation",
        label: "Corporation"
      },
      {
        value: "LLC",
        label: "LLC"
      },
      {
        value: "Partnership",
        label: "Partnership, LP, or LLP"
      },
    ]
  }
}

export function getTransferFailures() {
  return [
    {
      label: "Special Case",
      value: ""
    },
    {
      label: "Insufficient Funds",
      value: "R01"
    },
    {
      label: "Insufficient Funds - Late",
      value: "R01-late"
    },
    /*{
      label: "Bank Account Closed (n, y)",
      value: "R02"
    },*/
    {
      label: "Unable to Locate Account - Remove Bank",
      value: "R03"
    },
    {
      label: "Unable to Locate Account - Late - Remove Bank",
      value: "R03-late"
    },
    /*{
      label: "Invalid Bank Account Number Structure (n, y)",
      value: "R04"
    },
    {
      label: "Unauthorized debit to consumer account (y, y)",
      value: "R05"
    },
    {
      label: "Returned per ODFI's Request (n, n)",
      value: "R06"
    },
    {
      label: "Authorization Revoked by Customer (y, y)",
      value: "R07"
    },
    {
      label: "Payment Stopped (y, y)",
      value: "R08"
    },
    {
      label: "Uncollected Funds (n, n)",
      value: "R09"
    },
    {
      label: "Customer Advises Not Authorized, Improper, or Ineligible (y, y)",
      value: "R10"
    },
    {
      label: "Bank Account Frozen (y, y)",
      value: "R16"
    },
    {
      label: "Non-Transaction Account (y, y)",
      value: "R20"
    },
    {
      label: "Credit Entry Refused by Receiver (n, y)",
      value: "R23"
    },
    {
      label: "Corporate Customer Advises Not Authorized (y, y)",
      value: "R29"
    },*/
    {
      label: "Unlimited Funds - No Balance Checking",
      value: "NBC"
    },
  ]
}

//Returns Business or Personal options.
export function getBusinessOrPersonal() {
  return [
    {
      value: "",
      label: "Select an Account Type"
    },
    {
      value: "Personal",
      label: "Personal"
    },
    {
      value: "Business",
      label: "Business"
    },
  ]
}

//Returns Home Page Festival options.
export function getHomePageOptions() {
  return [
    {
      value: "Festival",
      label: "Festival"
    },
    {
      value: "Venue",
      label: "Venue"
    },
    {
      value: "Concert",
      label: "Concert"
    },
    {
      value: "Event",
      label: "Event"
    },
    {
      value: "Popup",
      label: "Popup"
    },
    {
      value: "Experience",
      label: "Experience"
    },
  ]
}

export function mapTypeToSubType(type) {
  switch (type) {
    case "Personal":
      return "personal"
    case "Sole Proprietorship":
      return "soleProprietorship"
    case "Unincorporated Association":
      return "soleProprietorship"
    case "Trust":
      return "soleProprietorship"
    case "Corporation":
      return "corporation"
    case "Publicly Traded Corporation":
      return "corporation"
    case "LLC":
      return "llc"
    case "Partnership":
      return "partnership"
    default:
      return "unknown"
  }
}

//Returns the business type options
export function getBusinessTypeOptions() {
  return [
    {
      value: "",
      label: "Select an Account Type"
    },
    {
      value: "corporation",
      label: "Corporation"
    },
    {
      value: "llc",
      label: "LLC"
    },
  ]
}

//Returns table info for displaying request information.
export function getRequestTableInfo() {
  return [
    {
      header: "ID",
      mapping: "display_id",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Client",
      mapping: "client_name",
      classes: "tdHideable2",
      headerClasses: "tdHideable2",
    },
    {
      header: "Amount",
      mapping: "amount",
      classes: "tdMoney",
      headerClasses: "",
    },
    {
      header: "Status",
      mapping: "status",
      classes: "tdStatus",
      headerClasses: "",
    },
    {
      header: "Due Date",
      mapping: "display_due_date",
      classes: "tdHideable",
      headerClasses: "tdHideable",
    },
    {
      header: "Note",
      mapping: "description",
      classes: "tdHideable tdNote",
      headerClasses: "tdHideable",
    }
  ]
}

//Returns table info for displaying internal user information.
export function getUserTableInfo() {
  return [
    {
      header: "First Name",
      mapping: "short_name",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Last Name",
      mapping: "last_name",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Email",
      mapping: "email",
      classes: "tuHideable",
      headerClasses: "tuHideable",
    },
    {
      header: "Status",
      mapping: "dwolla_status",
      classes: "tdStatus",
      headerClasses: "",
    },
    {
      header: "Last Use",
      mapping: "display_last_use",
      classes: "tuHideable",
      headerClasses: "tuHideable",
    },
    {
      header: "Business",
      mapping: "business_name",
      classes: "tuHideable2",
      headerClasses: "tuHideable2",
    }
  ]
}

//Returns table info for displaying internal transactions information.
export function getTransactionsTableInfo() {
  return [
    {
      header: "Vendor",
      mapping: "name",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Client",
      mapping: "client_name",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Amount",
      mapping: "amount",
      classes: "tuHideable2",
      headerClasses: "tuHideable2",
    },
    {
      header: "Status",
      mapping: "status",
      classes: "tdStatus",
      headerClasses: "",
    },
    {
      header: "Last Action",
      mapping: "last_action",
      classes: "tuHideable",
      headerClasses: "tuHideable",
      format: (value) => {
        return formatDateObjectLong(parseSQLDate(value))
      }
    },
    {
      header: "Description",
      mapping: "description",
      classes: "tuHideable",
      headerClasses: "tuHideable",
    }
  ]
}

//Returns table info for displaying security sessions.
export function getSecuritySessionsTableInfo() {
  return [
    {
      header: "Device",
      mapping: "operating_system",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Browser",
      mapping: "browser",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Location",
      mapping: "location",
      classes: "tsHideable",
      headerClasses: "tsHideable",
    },
    {
      header: "Time",
      mapping: "time",
      classes: "tsHideable2",
      headerClasses: "tsHideable2",
      format: (value) => {
        return formatDateObjectLong(parseSQLDate(value))
      }
    },
  ]
}

//Returns table info for sending request information.
export function getSendingTableInfo() {
  return [
    {
      header: "ID",
      mapping: "display_id",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Vendor",
      mapping: "vendorName",
      classes: "tdHideable2",
      headerClasses: "tdHideable2",
    },
    {
      header: "Amount",
      mapping: "amount",
      classes: "tdMoney",
      headerClasses: "",
    },
    {
      header: "Status",
      mapping: "status",
      classes: "tdStatus",
      headerClasses: "",
    },
    {
      header: "Due Date",
      mapping: "display_due_date",
      classes: "tdHideable",
      headerClasses: "tdHideable",
    },
    {
      header: "Note",
      mapping: "description",
      classes: "tdHideable tdNote",
      headerClasses: "tdHideable",
    }
  ]
}

//Appends and saves the requestID to the list of requests to link for this user when they login.
export function appendRequest(requestID) {
  let requests = API.getCookie("ZochaRequests")
  if (requests === "") {
    requests = []
  } else {
    try {
      requests = JSON.parse(requests)
    } catch (e) {
      requests = []
    }
  }
  if (!requests.includes(requestID)) {
    requests.push(requestID)
  }
  API.setCookie("ZochaRequests", JSON.stringify(requests))
}

//Returns the requests for the user that they have linked via email.
export function getRequests() {
  let requests = API.getCookie("ZochaRequests")
  if (requests === "") {
    requests = []
  } else {
    try {
      requests = JSON.parse(requests)
    } catch (e) {
      requests = []
    }
  }
  return requests
}

//Clears out the requests.
export function clearRequests() {
  API.deleteCookie("ZochaRequests")
}

//Returns the document types for the provided accountType and who the document is for.
//documentFor can be personal, controller, or business
export function getDocumentTypes(accountType, documentFor) {
  let data = [
    {
      value: "",
      label: "Select a Document Type"
    }
  ]
  if (documentFor === "personal" || documentFor === "controller") {
    data.push({
      value: "passport",
      label: "Passport"
    })
    data.push({
      value: "license",
      label: "License"
    })
    data.push({
      value: "idCard",
      label: "ID Card"
    })
    return data
  }
  //business document
  switch (accountType) {
    case "Sole Proprietorship":
    case "Unincorporated Association":
    case "Trust":
      data.push({
        value: "other_1",
        label: "EIN Letter from IRS"
      })
      data.push({
        value: "other_2",
        label: "Business License"
      })
      data.push({
        value: "other_3",
        label: "Sales/Use Tax License"
      })
      data.push({
        value: "other_4",
        label: "Registration of Trade Name"
      })
      data.push({
        value: "other_5",
        label: "Filed and stamped Articles of Organization/Incorporation"
      })
      data.push({
        value: "other_6",
        label: "Certificate of Good Standing"
      })
      data.push({
        value: "other_7",
        label: "Other US Government Issued Document"
      })
      break;
    case "Corporation":
    case "Publicly Traded Company":
    case "Non-Profit":
    case "LLC":
    case "Partnership":
      data.push({
        value: "other_1",
        label: "EIN Letter from IRS"
      })
      data.push({
        value: "other_7",
        label: "Other US Government Issued Document"
      })
      break;
    default:
      break;
  }
  return data
}

//Returns the list of states for a form.
export function getStates() {
  return [
    { value: "", label: "Select a State"},
    { value: 'AL', label: 'Alabama'},
    { value: 'AK', label: 'Alaska'},
    { value: 'AS', label: 'American Samoa'},
    { value: 'AZ', label: 'Arizona'},
    { value: 'AR', label: 'Arkansas'},
    { value: 'CA', label: 'California'},
    { value: 'CO', label: 'Colorado'},
    { value: 'CT', label: 'Connecticut'},
    { value: 'DE', label: 'Delaware'},
    { value: 'DC', label: 'District of Columbia'},
    { value: 'FM', label: 'Federated States of Micronesia'},
    { value: 'FL', label: 'Florida'},
    { value: 'GA', label: 'Georgia'},
    { value: 'GU', label: 'Guam'},
    { value: 'HI', label: 'Hawaii'},
    { value: 'ID', label: 'Idaho'},
    { value: 'IL', label: 'Illinois'},
    { value: 'IN', label: 'Indiana'},
    { value: 'IA', label: 'Iowa'},
    { value: 'KS', label: 'Kansas'},
    { value: 'KY', label: 'Kentucky'},
    { value: 'LA', label: 'Louisiana'},
    { value: 'ME', label: 'Maine'},
    { value: 'MH', label: 'Marshall Islands'},
    { value: 'MD', label: 'Maryland'},
    { value: 'MA', label: 'Massachusetts'},
    { value: 'MI', label: 'Michigan'},
    { value: 'MN', label: 'Minnesota'},
    { value: 'MS', label: 'Mississippi'},
    { value: 'MO', label: 'Missouri'},
    { value: 'MT', label: 'Montana'},
    { value: 'NE', label: 'Nebraska'},
    { value: 'NV', label: 'Nevada'},
    { value: 'NH', label: 'New Hampshire'},
    { value: 'NJ', label: 'New Jersey'},
    { value: 'NM', label: 'New Mexico'},
    { value: 'NY', label: 'New York'},
    { value: 'NC', label: 'North Carolina'},
    { value: 'ND', label: 'North Dakota'},
    { value: 'MP', label: 'Northern Mariana Islands'},
    { value: 'OH', label: 'Ohio'},
    { value: 'OK', label: 'Oklahoma'},
    { value: 'OR', label: 'Oregon'},
    { value: 'PW', label: 'Palau'},
    { value: 'PA', label: 'Pennsylvania'},
    { value: 'PR', label: 'Puerto Rico'},
    { value: 'RI', label: 'Rhode Island'},
    { value: 'SC', label: 'South Carolina'},
    { value: 'SD', label: 'South Dakota'},
    { value: 'TN', label: 'Tennessee'},
    { value: 'TX', label: 'Texas'},
    { value: 'UT', label: 'Utah'},
    { value: 'VT', label: 'Vermont'},
    { value: 'VI', label: 'Virgin Islands'},
    { value: 'VA', label: 'Virginia'},
    { value: 'WA', label: 'Washington'},
    { value: 'WV', label: 'West Virginia'},
    { value: 'WI', label: 'Wisconsin'},
    { value: 'WY', label: 'Wyoming'},
    { value: 'AE', label: 'Armed Forces Europe, the Middle East, and Canada'},
    { value: 'AP', label: 'Armed Forces Pacific'},
    { value: 'AA', label: 'Armed Forces Americas (except Canada)'}
  ]
}

//Returns the list of states for a form.
export function getCountries(excludeUS = false) {
  let data = [
    { value: "", label: "Select a Country"},
    { value: 'US', label: 'United States of America'},
    { value: 'AD', label: 'Andorra'},
    { value: 'AE', label: 'United Arab Emirates'},
    { value: 'AF', label: 'Afghanistan'},
    { value: 'AG', label: 'Antigua and Barbuda'},
    { value: 'AI', label: 'Anguilla'},
    { value: 'AL', label: 'Albania'},
    { value: 'AM', label: 'Armenia'},
    { value: 'AO', label: 'Angola'},
    { value: 'AQ', label: 'Antarctica'},
    { value: 'AR', label: 'Argentina'},
    { value: 'AS', label: 'American Samoa'},
    { value: 'AT', label: 'Austria'},
    { value: 'AU', label: 'Australia'},
    { value: 'AW', label: 'Aruba'},
    { value: 'AX', label: 'Åland Islands'},
    { value: 'AZ', label: 'Azerbaijan'},
    { value: 'BA', label: 'Bosnia and Herzegovina'},
    { value: 'BB', label: 'Barbados'},
    { value: 'BD', label: 'Bangladesh'},
    { value: 'BE', label: 'Belgium'},
    { value: 'BF', label: 'Burkina Faso'},
    { value: 'BG', label: 'Bulgaria'},
    { value: 'BH', label: 'Bahrain'},
    { value: 'BI', label: 'Burundi'},
    { value: 'BJ', label: 'Benin'},
    { value: 'BL', label: 'Saint Barthélemy'},
    { value: 'BM', label: 'Bermuda'},
    { value: 'BN', label: 'Brunei Darussalam'},
    { value: 'BO', label: 'Bolivia (Plurinational State of)'},
    { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba'},
    { value: 'BR', label: 'Brazil'},
    { value: 'BS', label: 'Bahamas'},
    { value: 'BT', label: 'Bhutan'},
    { value: 'BV', label: 'Bouvet Island'},
    { value: 'BW', label: 'Botswana'},
    { value: 'BY', label: 'Belarus'},
    { value: 'BZ', label: 'Belize'},
    { value: 'CA', label: 'Canada'},
    { value: 'CC', label: 'Cocos (Keeling) Islands'},
    { value: 'CD', label: 'Congo, Democratic Republic of the'},
    { value: 'CF', label: 'Central African Republic'},
    { value: 'CG', label: 'Congo'},
    { value: 'CH', label: 'Switzerland'},
    { value: 'CI', label: "Côte d'Ivoire"},
    { value: 'CK', label: 'Cook Islands'},
    { value: 'CL', label: 'Chile'},
    { value: 'CM', label: 'Cameroon'},
    { value: 'CN', label: 'China'},
    { value: 'CO', label: 'Colombia'},
    { value: 'CR', label: 'Costa Rica'},
    { value: 'CU', label: 'Cuba'},
    { value: 'CV', label: 'Cabo Verde'},
    { value: 'CW', label: 'Curaçao'},
    { value: 'CX', label: 'Christmas Island'},
    { value: 'CY', label: 'Cyprus'},
    { value: 'CZ', label: 'Czechia'},
    { value: 'DE', label: 'Germany'},
    { value: 'DJ', label: 'Djibouti'},
    { value: 'DK', label: 'Denmark'},
    { value: 'DM', label: 'Dominica'},
    { value: 'DO', label: 'Dominican Republic'},
    { value: 'DZ', label: 'Algeria'},
    { value: 'EC', label: 'Ecuador'},
    { value: 'EE', label: 'Estonia'},
    { value: 'EG', label: 'Egypt'},
    { value: 'EH', label: 'Western Sahara'},
    { value: 'ER', label: 'Eritrea'},
    { value: 'ES', label: 'Spain'},
    { value: 'ET', label: 'Ethiopia'},
    { value: 'FI', label: 'Finland'},
    { value: 'FJ', label: 'Fiji'},
    { value: 'FK', label: 'Falkland Islands (Malvinas)'},
    { value: 'FM', label: 'Micronesia (Federated States of)'},
    { value: 'FO', label: 'Faroe Islands'},
    { value: 'FR', label: 'France'},
    { value: 'GA', label: 'Gabon'},
    { value: 'GB', label: 'United Kingdom of Great Britain and Northern Ireland'},
    { value: 'GD', label: 'Grenada'},
    { value: 'GE', label: 'Georgia'},
    { value: 'GF', label: 'French Guiana'},
    { value: 'GG', label: 'Guernsey'},
    { value: 'GH', label: 'Ghana'},
    { value: 'GI', label: 'Gibraltar'},
    { value: 'GL', label: 'Greenland'},
    { value: 'GM', label: 'Gambia'},
    { value: 'GN', label: 'Guinea'},
    { value: 'GP', label: 'Guadeloupe'},
    { value: 'GQ', label: 'Equatorial Guinea'},
    { value: 'GR', label: 'Greece'},
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands'},
    { value: 'GT', label: 'Guatemala'},
    { value: 'GU', label: 'Guam'},
    { value: 'GW', label: 'Guinea-Bissau'},
    { value: 'GY', label: 'Guyana'},
    { value: 'HK', label: 'Hong Kong'},
    { value: 'HM', label: 'Heard Island and McDonald Islands'},
    { value: 'HN', label: 'Honduras'},
    { value: 'HR', label: 'Croatia'},
    { value: 'HT', label: 'Haiti'},
    { value: 'HU', label: 'Hungary'},
    { value: 'ID', label: 'Indonesia'},
    { value: 'IE', label: 'Ireland'},
    { value: 'IL', label: 'Israel'},
    { value: 'IM', label: 'Isle of Man'},
    { value: 'IN', label: 'India'},
    { value: 'IO', label: 'British Indian Ocean Territory'},
    { value: 'IQ', label: 'Iraq'},
    { value: 'IR', label: 'Iran (Islamic Republic of)'},
    { value: 'IS', label: 'Iceland'},
    { value: 'IT', label: 'Italy'},
    { value: 'JE', label: 'Jersey'},
    { value: 'JM', label: 'Jamaica'},
    { value: 'JO', label: 'Jordan'},
    { value: 'JP', label: 'Japan'},
    { value: 'KE', label: 'Kenya'},
    { value: 'KG', label: 'Kyrgyzstan'},
    { value: 'KH', label: 'Cambodia'},
    { value: 'KI', label: 'Kiribati'},
    { value: 'KM', label: 'Comoros'},
    { value: 'KN', label: 'Saint Kitts and Nevis'},
    { value: 'KP', label: "Korea (Democratic People's Republic of)"},
    { value: 'KR', label: 'Korea, Republic of'},
    { value: 'KW', label: 'Kuwait'},
    { value: 'KY', label: 'Cayman Islands'},
    { value: 'KZ', label: 'Kazakhstan'},
    { value: 'LA', label: "Lao People's Democratic Republic"},
    { value: 'LB', label: 'Lebanon'},
    { value: 'LC', label: 'Saint Lucia'},
    { value: 'LI', label: 'Liechtenstein'},
    { value: 'LK', label: 'Sri Lanka'},
    { value: 'LR', label: 'Liberia'},
    { value: 'LS', label: 'Lesotho'},
    { value: 'LT', label: 'Lithuania'},
    { value: 'LU', label: 'Luxembourg'},
    { value: 'LV', label: 'Latvia'},
    { value: 'LY', label: 'Libya'},
    { value: 'MA', label: 'Morocco'},
    { value: 'MC', label: 'Monaco'},
    { value: 'MD', label: 'Moldova, Republic of'},
    { value: 'ME', label: 'Montenegro'},
    { value: 'MF', label: 'Saint Martin (French part)'},
    { value: 'MG', label: 'Madagascar'},
    { value: 'MH', label: 'Marshall Islands'},
    { value: 'MK', label: 'North Macedonia'},
    { value: 'ML', label: 'Mali'},
    { value: 'MM', label: 'Myanmar'},
    { value: 'MN', label: 'Mongolia'},
    { value: 'MO', label: 'Macao'},
    { value: 'MP', label: 'Northern Mariana Islands'},
    { value: 'MQ', label: 'Martinique'},
    { value: 'MR', label: 'Mauritania'},
    { value: 'MS', label: 'Montserrat'},
    { value: 'MT', label: 'Malta'},
    { value: 'MU', label: 'Mauritius'},
    { value: 'MV', label: 'Maldives'},
    { value: 'MW', label: 'Malawi'},
    { value: 'MX', label: 'Mexico'},
    { value: 'MY', label: 'Malaysia'},
    { value: 'MZ', label: 'Mozambique'},
    { value: 'NA', label: 'Namibia'},
    { value: 'NC', label: 'New Caledonia'},
    { value: 'NE', label: 'Niger'},
    { value: 'NF', label: 'Norfolk Island'},
    { value: 'NG', label: 'Nigeria'},
    { value: 'NI', label: 'Nicaragua'},
    { value: 'NL', label: 'Netherlands'},
    { value: 'NO', label: 'Norway'},
    { value: 'NP', label: 'Nepal'},
    { value: 'NR', label: 'Nauru'},
    { value: 'NU', label: 'Niue'},
    { value: 'NZ', label: 'New Zealand'},
    { value: 'OM', label: 'Oman'},
    { value: 'PA', label: 'Panama'},
    { value: 'PE', label: 'Peru'},
    { value: 'PF', label: 'French Polynesia'},
    { value: 'PG', label: 'Papua New Guinea'},
    { value: 'PH', label: 'Philippines'},
    { value: 'PK', label: 'Pakistan'},
    { value: 'PL', label: 'Poland'},
    { value: 'PM', label: 'Saint Pierre and Miquelon'},
    { value: 'PN', label: 'Pitcairn'},
    { value: 'PR', label: 'Puerto Rico'},
    { value: 'PS', label: 'Palestine, State of'},
    { value: 'PT', label: 'Portugal'},
    { value: 'PW', label: 'Palau'},
    { value: 'PY', label: 'Paraguay'},
    { value: 'QA', label: 'Qatar'},
    { value: 'RE', label: 'Réunion'},
    { value: 'RO', label: 'Romania'},
    { value: 'RS', label: 'Serbia'},
    { value: 'RU', label: 'Russian Federation'},
    { value: 'RW', label: 'Rwanda'},
    { value: 'SA', label: 'Saudi Arabia'},
    { value: 'SB', label: 'Solomon Islands'},
    { value: 'SC', label: 'Seychelles'},
    { value: 'SD', label: 'Sudan'},
    { value: 'SE', label: 'Sweden'},
    { value: 'SG', label: 'Singapore'},
    { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha'},
    { value: 'SI', label: 'Slovenia'},
    { value: 'SJ', label: 'Svalbard and Jan Mayen'},
    { value: 'SK', label: 'Slovakia'},
    { value: 'SL', label: 'Sierra Leone'},
    { value: 'SM', label: 'San Marino'},
    { value: 'SN', label: 'Senegal'},
    { value: 'SO', label: 'Somalia'},
    { value: 'SR', label: 'Suriname'},
    { value: 'SS', label: 'South Sudan'},
    { value: 'ST', label: 'Sao Tome and Principe'},
    { value: 'SV', label: 'El Salvador'},
    { value: 'SX', label: 'Sint Maarten (Dutch part)'},
    { value: 'SY', label: 'Syrian Arab Republic'},
    { value: 'SZ', label: 'Eswatini'},
    { value: 'TC', label: 'Turks and Caicos Islands'},
    { value: 'TD', label: 'Chad'},
    { value: 'TF', label: 'French Southern Territories'},
    { value: 'TG', label: 'Togo'},
    { value: 'TH', label: 'Thailand'},
    { value: 'TJ', label: 'Tajikistan'},
    { value: 'TK', label: 'Tokelau'},
    { value: 'TL', label: 'Timor-Leste'},
    { value: 'TM', label: 'Turkmenistan'},
    { value: 'TN', label: 'Tunisia'},
    { value: 'TO', label: 'Tonga'},
    { value: 'TR', label: 'Turkey'},
    { value: 'TT', label: 'Trinidad and Tobago'},
    { value: 'TV', label: 'Tuvalu'},
    { value: 'TW', label: 'Taiwan, Province of China'},
    { value: 'TZ', label: 'Tanzania, United Republic of'},
    { value: 'UA', label: 'Ukraine'},
    { value: 'UG', label: 'Uganda'},
    { value: 'UM', label: 'United States Minor Outlying Islands'},
    { value: 'UY', label: 'Uruguay'},
    { value: 'UZ', label: 'Uzbekistan'},
    { value: 'VA', label: 'Holy See'},
    { value: 'VC', label: 'Saint Vincent and the Grenadines'},
    { value: 'VE', label: 'Venezuela (Bolivarian Republic of)'},
    { value: 'VG', label: 'Virgin Islands (British)'},
    { value: 'VI', label: 'Virgin Islands (U.S.)'},
    { value: 'VN', label: 'Viet Nam'},
    { value: 'VU', label: 'Vanuatu'},
    { value: 'WF', label: 'Wallis and Futuna'},
    { value: 'WS', label: 'Samoa'},
    { value: 'YE', label: 'Yemen'},
    { value: 'YT', label: 'Mayotte'},
    { value: 'ZA', label: 'South Africa'},
    { value: 'ZM', label: 'Zambia'},
    { value: 'ZW', label: 'Zimbabwe'},
  ]
  if (excludeUS) {
    data.splice(1,1)
  }
  return data
}

//Returns true or false if there is a valid bank account for the user
export function bankAccountValid(sources) {
  for (let i = 0; i < sources.length; i = i + 1) {
    let source = sources[i]
    if (source.type === "bank" && source.status === "verified" && !source.removed && source.zocha && source.zocha.status === "default" && source.zocha.verification_status === "verified") {
      return true
    }
  }
  return false
}

//Returns true or false if you need to open Plaid to edit your bank account
export function bankAccountEditing(sources) {
  for (let i = 0; i < sources.length; i = i + 1) {
    let source = sources[i]
    if (source.zocha && source.zocha.status === "default" && source.zocha.verification_status === "pending_manual_verification") {
      return true
    }
  }
  return false
}

//Returns true or false if you are waiting on automatic verification
export function bankAccountWaiting(sources) {
  for (let i = 0; i < sources.length; i = i + 1) {
    let source = sources[i]
    if (source.zocha && source.zocha.status === "default" && source.zocha.verification_status === "pending_automatic_verification") {
      return true
    }
  }
  return false
}

//Finds and returns the default bank account or returns false
export function getDefaultBankAccount(sources) {
  for (let i = 0; i < sources.length; i = i + 1) {
    let source = sources[i]
    if (source.type === "bank" && source.status === "verified" && !source.removed && source.zocha && source.zocha.status === "default" && source.zocha.verification_status === "verified") {
      return source
    }
  }
  return false
}

//Returns a display name for the default bank account.
export function getDefaultBankAccountName(sources) {
  let defaultBankAccount = getDefaultBankAccount(sources)
  if (defaultBankAccount === false) {
    return "****"
  }
  return ((defaultBankAccount.zocha.institution_name ? (defaultBankAccount.zocha.institution_name + "  - ") : "") + defaultBankAccount.zocha.account_name)
}

//Returns if this user's account has been upgraded, is in the process of upgrading, or has not been upgraded.
//Returns one of "upgraded", "upgrading", "basic"
export function accountUpgraded(userInfo) {
  if (userInfo.dwollaAccount.status === "unverified") {
    //hasn't started the process.
    if (userInfo.user.customer_form === null) {
      return "basic"
    } else {
      //the form is set, we are waiting on review
      return "upgrading"
    }
  } else {
    //Check status
    if (userInfo.dwollaAccount.status === "suspended" || userInfo.dwollaAccount.status === "deactivated") {
      //The account has been suspended.
      return "basic"
    } else if (userInfo.dwollaAccount.status === "retry") {
      //Retry sending in the account information.
      return "upgrading"
    } else if (userInfo.dwollaAccount.status === "document") {
      //check if the documents property is set or we are waiting/rejected/need another doc.
      return "upgrading"
    } else if (userInfo.dwollaAccount._links["certify-beneficial-ownership"]) {
      //Customer is Dwolla verified, now working on beneficial owners and the need to certify.
      return "upgrading"
    } else {
      //account has been upgraded
      return "upgraded"
    }
  }
}

export function getStatHelpDescription(type) {
  let helpDescription = ""
  switch (type) {
    case "Views":
      helpDescription = "How many times users have swiped over and seen your deal."
      break;
    case "Taps":
      helpDescription = "How many times users have tapped on your deal to expand its details."
      break;
    case "Favorites":
      helpDescription = "How many times users have favorited your deal."
      break;
    case "Uses":
      helpDescription = "How many times users have used your deal."
      break;
    case "Signed Up":
      helpDescription = "How many shops or accounts this Ambassador has signed up."
      break;
    case "Paying":
      helpDescription = "How many of the shops or accounts that this Ambassador has signed up are paying."
      break;
    default:
      break
  }
  return helpDescription
}

// Parse string like '2019-09-12 09:52:52.992823+00'
// to a JS Date
// Assumes string is always +00
export function parseSQLDate(s) {
  if (s === false || s === null || s === undefined) {
    return new Date();
  }
  let b = s.split(/\D/);
  --b[1];                  // Adjust month number
  //b[6] = b[6].substr(0,3); // Microseconds to milliseconds
  let dd = new Date(Date.UTC(...b));
  if (b.length === 3) {
    //no time so we need to add in the timezone offset.
    dd.setTime(dd.getTime() + (dd.getTimezoneOffset() * 1000 * 60))
  }
  return dd
}

//Subtracts the number of days from the date.
export function subtractDaysFromDate(date, days) {
  date.setTime(date.getTime() - (24*60*60*1000*days))
  return date
}

//Adds the number of days to the date.
export function addDaysToDate(date, days) {
  date.setTime(date.getTime() + (24*60*60*1000*days))
  return date
}

//Formats a Date object to a long format like Dec 23, 2019 2:30 PM
export function formatDateObjectLong(date) {
  let td = date
  if (typeof td === 'string') {
    td = parseSQLDate(td)
  }
  if (td === null) {
    td = new Date()
  }
  //now format the date for output
  let longFormat = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    //timeZoneName: 'long'
  };
  return td.toLocaleDateString("en-US", longFormat)
}

//Formats a Date object to a short format like Dec 23, 2019
export function formatDateObjectMonthDay(date) {
  let td = date
  if (typeof td === 'string') {
    td = parseSQLDate(td)
  }
  if (td === null) {
    td = new Date()
  }
  //now format the date for output
  let longFormat = {
    month: 'long',
    day: 'numeric',
  };
  return td.toLocaleDateString("en-US", longFormat)
}

//Formats a Date object to a short format like Dec 23, 2019
export function formatDateObjectMonthDayTiny(date) {
  let td = date
  if (typeof td === 'string') {
    td = parseSQLDate(td)
  }
  if (td === null) {
    td = new Date()
  }
  //now format the date for output
  let longFormat = {
    month: 'short',
    day: 'numeric',
  };
  return td.toLocaleDateString("en-US", longFormat)
}

//Formats a Date object to a short format like Dec 23, 2019
export function formatDateObjectDate(date) {
  let td = date
  if (typeof td === 'string') {
    td = parseSQLDate(td)
  }
  if (td === null) {
    td = new Date()
  }
  //now format the date for output
  let longFormat = {
    day: 'numeric',
  };
  return td.toLocaleDateString("en-US", longFormat)
}

//Formats a Date object to a short format like Dec 23, 2019
export function formatDateObjectShort(date) {
  let td = date
  if (typeof td === 'string') {
    td = parseSQLDate(td)
  }
  if (td === null) {
    td = new Date()
  }
  //now format the date for output
  let longFormat = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  return td.toLocaleDateString("en-US", longFormat)
}

export function formatDateObjectDay(date) {
  let td = date
  if (typeof td === 'string') {
    td = parseSQLDate(td)
  }
  if (td === null) {
    td = new Date()
  }
  //now format the date for output
  let longFormat = {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  };
  return td.toLocaleDateString("en-US", longFormat)
}

//Formats a Date object to a short format like Dec 23 2:30 PM
export function formatDateObjectLongTime(date) {
  let td = date
  if (typeof td === 'string') {
    td = parseSQLDate(td)
  }
  if (td === null) {
    td = new Date()
  }
  //now format the date for output
  let longFormat = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return td.toLocaleDateString("en-US", longFormat)
}

//Formats a Date object to a short format like 8:23 PM
export function formatDateObjectTime(date) {
  let td = date
  if (typeof td === 'string') {
    td = parseSQLDate(td)
  }
  if (td === null) {
    td = new Date()
  }
  //now format the date for output
  let longFormat = {
    hour: 'numeric',
    minute: 'numeric',
  };
  return td.toLocaleTimeString("en-US", longFormat)
}

//Formats a Date object to the sitemap format like 1997-07-16T19:20+01:00
export function formatDateObjectSitemap(date) {
  let td = date
  if (typeof td === 'string') {
    td = parseSQLDate(td)
  }
  if (td === null) {
    td = new Date()
  }
  //now format the date for output
  let mm = (td.getMonth() + 1)
  if (mm < 10) {
    mm = "0" + mm
  }
  let dd = td.getDate()
  if (dd < 10) {
    dd = "0" + dd
  }
  return td.getFullYear() + "-" + mm + "-" + dd
}

export function convertDateToStamp(date) {
  let td = date
  if (typeof td === 'string') {
    td = parseSQLDate(td)
  }
  if (td === null) {
    td = new Date()
  }

  //apply the timezone offset back to 00
  td.setTime(td.getTime() + (td.getTimezoneOffset() * 1000 * 60))

  //now format the date for output
  let mm = (td.getMonth() + 1)
  if (mm < 10) {
    mm = "0" + mm
  }
  let dd = td.getDate()
  if (dd < 10) {
    dd = "0" + dd
  }
  //get the hours
  let hh = td.getHours()
  //get the minutes
  let ii = td.getMinutes()
  //get the seconds
  let ss = td.getSeconds()

  return td.getFullYear() + "-" + mm + "-" + dd + " " + hh + ":" + ii + ":" + ss
}

//Formats a date to a string format of: Dec 23, 2019
export function formatDate(date) {
  let t = date.split(/[- :]/)
  let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))
  //now format the date for output
  let logDateExpandedFormat = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  return d.toLocaleDateString("en-US", logDateExpandedFormat)
}

export function formatDateShort(date) {
  let t = date.split(/[- :]/)
  let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))
  //now format the date for output
  let logDateExpandedFormat = {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  };
  let dd = d.toLocaleDateString("en-US", logDateExpandedFormat)
  return dd
}

export function parsePhoneNumber(phoneNumber) {
  //parse this into a phone number with (), spaces, and dashes
  //phoneNumber = phoneNumber.substring(0, 10)
  let index = 0
  if (phoneNumber.length > 10) {
    index = phoneNumber.length - 10
  }
  let part0 = phoneNumber.substring(0, index)
  let part1 = phoneNumber.substring(index, index + 3)
  let part2 = phoneNumber.substring(index + 3, index + 6)
  let part3 = phoneNumber.substring(index + 6)

  let value = ""
  if (part0.length > 0) {
    value += part0 + " "
  }
  value += "(" + part1
  if (part1.length === 3 && part2.length > 0) {
    value += ") "
  }
  value += part2
  if (part2.length === 3 && part3.length > 0) {
    value += " - "
  }
  value += part3

  return value
}

export function parseEIN(ein) {
  //parse this into an EIN like 12-3456789
  let value = ein
  if (ein.length > 2) {
    value = ein.substring(0, 2) + "-" + ein.substring(2, 9)
  }
  return value
}

/*
Parses the input into a value to pass for a form.
*/
export function parseSSN4(ssn) {
  //parse this into a SSN4 like XXX-XX-1234 into 1234
  let value = ssn
  if (value.length > 0) {
    value = ssn.substring(7, 11)
  }
  return value
}

/*
Formats the full SSN for display
*/
export function formatSSN(ssn) {
  let value = ""
  if (ssn.length > 0) {
    value += ssn.substring(0, 3)
  }
  if (ssn.length > 3) {
    value += "-" + ssn.substring(3, 5)
  }
  if (ssn.length > 5) {
    value += "-" + ssn.substring(5)
  }
  return value
}

export function parseZip(zip) {
  //parse this into a zip like 12345-1234
  let value = zip.replace(/[^0-9]/g, '')
  if (value.length > 5) {
    value = value.substring(0, 5) + "-" + value.substring(5, 9)
  }
  return value
}

export function formatNumber(numb) {
  if (numb === undefined) {
    return ""
  }
  return numb.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export function formatNumberOnlyInts(numb) {
  if (numb % 1 !== 0) {
    return ''
  }
  return numb + ''
}

export function formatNumberDecimals(numb, decimals = 2) {
  if (numb === undefined) {
    return ""
  }
  let j = parseFloat(numb).toFixed(decimals)
  return j.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function padNumber(num, places) {
  let zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export function getAppVersion() {
  return version
}

export function dateInPast(dateString) {
  if (dateString === null || dateString === undefined || dateString === false) {
    return false
  }
  let t = dateString.split(/[- :]/)
  let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))

  let today = new Date();
  if (today > d) {
    return true
  } else {
    return false
  }
}

/*
Parses a Google Address and returns the components for form usage.
*/
export function parseGoogleAddress(address) {
  let address1 = ""
  let city = ""
  let state = ""
  let zip = ""
  let country = ""
  for (let i = 0; i < address[0].address_components.length; i = i + 1) {
    let comp = address[0].address_components[i]
    if (comp.types.includes("street_number") || comp.types.includes("route")) {
      if (address1.length > 0) {
        address1 += " " + comp.long_name
      } else {
        address1 += comp.long_name
      }
    }
    if (comp.types.includes("locality")) {
      city += comp.long_name
    }
    if (comp.types.includes("political") && comp.types.includes("administrative_area_level_1")) {
      state += comp.short_name
    }
    if (comp.types.includes("postal_code")) {
      zip += comp.long_name
    }
    if (comp.types.includes("country")) {
      country += comp.short_name
    }
  }

  return {
    address1: address1,
    city: city,
    state: state,
    zip: zip,
    country: country
  }
}

/*
Returns whether or not we are on a mobile (touch) device
*/
export function isMobile() {
  var check = false;

  if (typeof(window) !== "undefined" && typeof(navigator) !== "undefined") {
    (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  }
  return false
}

export const year2999 = new Date(32472144000000)
export const year3000 = new Date(32503723200000)
export const year3001 = new Date(32535259200000)

export function formatTokenSpecialText(token) {
  //console.log(token)
  if (token.theme === "datetime" && token.datetime) {
    //format the date into Oct 14 time
    let parts = token.datetime.date.split("-")
    let date = new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0, 0)

    let longFormat = {
      month: 'short',
      day: 'numeric',
    };

    return " - " + date.toLocaleDateString("en-US", longFormat) + ", " + token.datetime.time
  } else if (token.theme === "map" && token.details) {
    let data = {}
    try {
      data = JSON.parse(token.details)
    } catch(e) {
      console.log("couldn't parse token.details for map purchase", e)
    }
    if (data.seats && data.seats.length > token.tokenIter) {
      return " - Seat " + data.seats[token.tokenIter]
    }
  }
  return ""
}

export function formatTokenSpecialName(token) {
  if (token.theme === "tipjar") {
    return token.quantity + "x " + token.name
  }
  return token.name
}

/*
Takes in a Geoji object and adds/modifies properties so that they are easily usable.
*/
export function formatGeoji(geoji) {
  //1) figure out the expiration date
  let expDate = parseSQLDate(geoji.expirationDate)
  if (expDate > year2999) {
    geoji.infinite = true
  } else {
    geoji.infinite = false
  }
  //2) figure out the event dates
  if (!geoji.infinite && geoji.eventDates.length > 0) {
    let dds = geoji.eventDates
    let parts = dds.split(",")
    let eventDates = []
    let addCount = 0
    for (let i = 0; i < parts.length; i = i + 1) {
      let pp = parts[i]
      let parsedDate = parseSQLDate(pp)
      eventDates.push(parsedDate)
      addCount += 1
    }
    //sort the dates
    eventDates.sort(function(a, b) {
      if (a < b) {
        return -1
      } else if (a > b) {
        return 1
      }
      return 0
    })
    geoji.eventDates = eventDates
    if (addCount === 0) {
      geoji.infinite = true
    }
  } else {
    geoji.eventDates = []
    geoji.infinite = true
  }

  //3) Format the tokens
  if (!geoji.tokens) {
    geoji.tokens = []
  }
  geoji.tokens.sort(function(a, b) {
    let ai = parseInt(a.index)
    let bi = parseInt(b.index)
    if (ai === bi) {
      if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      }
      return 0
    }
    if (ai < bi) {
      return -1
    } else if (ai > bi) {
      return 1
    }
    return 0
  })
  //zero out the shopping carts, determine if sold out
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    geoji.tokens[i].cart = parseInt(geoji.tokens[i].cart || "0")
    geoji.tokens[i].quantity = parseInt(geoji.tokens[i].quantity)
    geoji.tokens[i].tokensSold = parseInt(geoji.tokens[i].tokensSold)
    geoji.tokens[i].priceCents = parseInt(geoji.tokens[i].priceCents)
    geoji.tokens[i].index = parseInt(geoji.tokens[i].index)
    geoji.tokens[i].purchaseLimit = parseInt(geoji.tokens[i].purchaseLimit)
    geoji.tokens[i].userHidden = parseInt(geoji.tokens[i].userHidden)
    if (geoji.tokens[i].customFeePercent) {
      geoji.tokens[i].customFeePercent = parseFloat(geoji.tokens[i].customFeePercent)
    }
    if (geoji.tokens[i].customTaxPercent) {
      geoji.tokens[i].customTaxPercent = parseFloat(geoji.tokens[i].customTaxPercent)
    }

    if (geoji.tokens[i].quantity !== 0 && geoji.tokens[i].tokensSold >= geoji.tokens[i].quantity) {
      geoji.tokens[i].soldOut = true
    } else {
      geoji.tokens[i].soldOut = false
    }

    if (geoji.tokens[i].mapData && geoji.tokens[i].mapData.length > 0) {
      geoji.tokens[i].mapData = JSON.parse(geoji.tokens[i].mapData)
    }
    if (geoji.tokens[i].datetimes && geoji.tokens[i].datetimes.length > 0) {
      let colorMappings = {}
      let colors = ["#09D2DE", "#03A9F4", "#0285C2", "#245C75"]

      for (let d = 0; d < geoji.tokens[i].datetimes.length; d = d + 1) {
        //1) set all datetimes to not selected
        geoji.tokens[i].datetimes[d].selected = false

        //2) set the default color for the datetime.
        let time = geoji.tokens[i].datetimes[d]
        if (parseInt(time.quantity) !== 0 && parseInt(time.tokensSold) >= parseInt(time.quantity)) {
          geoji.tokens[i].datetimes[d].color = "#8E8E93"
          continue
        }
        let selDate = parseSQLDate(time.date)
        let thisDate = new DateObject({
          year: selDate.getFullYear(),
          month: selDate.getMonth() + 1,
          day: selDate.getDate(),
        })

        let prices = []
        if (colorMappings[time.date]) {
          //already found the color mappings
          prices = colorMappings[time.date]
        } else {
          let dts = []
          for (let jj = 0; jj < geoji.tokens[i].datetimes.length; jj = jj + 1) {
            //1) Make sure the date matches.
            let parsedDate = parseSQLDate(geoji.tokens[i].datetimes[jj].date)
            let date = new DateObject({
              year: parsedDate.getFullYear(),
              month: parsedDate.getMonth() + 1,
              day: parsedDate.getDate(),
            })

            if (thisDate.year === date.year && thisDate.month.number === date.month.number && thisDate.day === date.day) {
              //same dates
              dts.push(geoji.tokens[i].datetimes[jj])
            }
          }

          //now determine the price groups.
          prices = []
          for (let q = 0; q < dts.length; q = q + 1) {
            let price = parseInt(dts[q].price)
            if (!prices.includes(price)) {
              prices.push(price)
            }
          }
          //sort the prices by value
          prices.sort()
          //store in the color mappings
          colorMappings[time.date] = prices
        }
        let indi = prices.indexOf(parseInt(time.price))
        //for each price, assign a color
        if (prices.length === 1) {
          geoji.tokens[i].datetimes[d].color = "#03A9F4"
          continue
        }

        geoji.tokens[i].datetimes[d].color = colors[indi % colors.length]
      }
    }
  }

  //4) Determine contact info
  if (geoji.contactInfo && geoji.contactInfo.length > 0) {
    let c2 = geoji.contactInfo
    let numbersOnly = c2.replace(/[^0-9]/g, "")

    let emailRegex = /^\S+@\S+\.\S+$/
    let emailMatch = geoji.contactInfo.match(emailRegex)

    if (numbersOnly.length === geoji.contactInfo.length && numbersOnly.length >= 10) {
      geoji.contactInfoPhoneNumber = true
      geoji.contactInfoEmail = false
      geoji.contactInfoDisplay = parsePhoneNumber(geoji.contactInfo)
    } else if (emailMatch !== null) {
      geoji.contactInfoPhoneNumber = false
      geoji.contactInfoEmail = true
      geoji.contactInfoDisplay = geoji.contactInfo
    } else {
      geoji.contactInfoPhoneNumber = false
      geoji.contactInfoEmail = false
      geoji.contactInfoDisplay = "@" + geoji.contactInfo
    }
  }

  //5) Website display url
  if (geoji.websiteURL && geoji.websiteURL.length > 0) {
    try {
      let link = geoji.websiteURL
      if (!link.includes("://")) {
        //add in http
        link = "http://" + link
      }
      let uu = new URL(link)
      //console.log(uu, uu.searchParams)
      let host = uu.host
      if (host.startsWith("www.")) {
        host = host.substring(4)
      }
      if (uu.pathname.length > 1 || uu.search.length > 1) {
        host = host + "/..."
      }
      geoji.displayWebsiteURL = host
    } catch (e) {
      console.log("couldn't parse url", e)
      geoji.displayWebsiteURL = geoji.websiteURL
    }
  }

  //6) Purchases
  if (geoji.purchases) {
    for (let i = 0; i < geoji.purchases.length; i = i + 1) {
      geoji.purchases[i].totalCents = parseInt(geoji.purchases[i].totalCents)
      geoji.purchases[i].priceCents = parseInt(geoji.purchases[i].priceCents)
      geoji.purchases[i].feeCents = parseInt(geoji.purchases[i].feeCents)
    }
  }

  if (geoji.processingFeeCents) {
    geoji.processingFeeCents = parseInt(geoji.processingFeeCents)
  }
  if (geoji.processingFeePercent) {
    geoji.processingFeePercent = parseFloat(geoji.processingFeePercent)
  }
  if (geoji.processingTaxPercent) {
    geoji.processingTaxPercent = parseFloat(geoji.processingTaxPercent)
  }

  //7) Determine the focus date.
  let nextDate = geojiNextEventDate(geoji)
  if (nextDate === false) {
    geoji.calendarFocusDate = new DateObject()
  } else {
    geoji.calendarFocusDate = new DateObject({
      year: nextDate.getFullYear(),
      month: nextDate.getMonth() + 1,
      day: nextDate.getDate(),
    })
  }

  //8) Parse the description if necessary
  let desc = []
  if (geoji.description && geoji.description.length > 0) {
    let cs = geoji.description.trim()
    let si = 0
    let iter = 0
    while (true) {
      iter += 1
      if (iter === 1000) {
        break
      }

      let indi = -1
      let prefixes = ["https://", "http://", "www.", "@"]
      let linkType = ""
      for (let preIndex = 0; preIndex < prefixes.length; preIndex = preIndex + 1) {
        let ii = cs.indexOf(prefixes[preIndex], si)
        if (ii !== -1) {
          if (indi === -1 || ii < indi) {
            indi = ii
            if (preIndex < 2) {
              linkType = ""
            } else if (preIndex === 2) {
              linkType = "https://"
            } else {
              linkType = "https://instagram.com/"
            }
          }
        }
      }
      if (indi === -1) {
        //end of the string
        desc.push({
          type: "text",
          value: cs.substring(si),
        })
        break
      } else {
        //found a link, add in the before text.
        desc.push({
          type: "text",
          value: cs.substring(si, indi),
        })
        //find the end of the link
        let part = cs.substring(indi)
        let el = part.search(/\s/g)
        if (el === -1) {
          //to the end of the string
          let newString = cs.substring(indi)
          if (newString === "@") {
            desc.push({
              type: "text",
              value: newString,
            })
          } else {
            if (linkType !== "https://instagram.com/") {
              //how should we display it? Parse and just show the beginning like we do with the website.
              let newDisplayString = newString
              try {
                let linky = newString
                if (!linky.includes("://")) {
                  //add in https
                  linky = "https://" + linky
                }
                let uu = new URL(linky)
                //console.log(uu, uu.searchParams)
                let host = uu.host
                if (host.startsWith("www.")) {
                  host = host.substring(4)
                }
                if (uu.pathname.length > 1 || uu.search.length > 1) {
                  host = host + "/..."
                }
                newDisplayString = host
              } catch (e) {
                console.log("couldn't parse url", e)
                newDisplayString = newString
              }

              desc.push({
                type: "link",
                display: newDisplayString,
                value: linkType + (linkType === "https://instagram.com/" ? newString.substring(1) : newString),
              })
            } else {
              desc.push({
                type: "link",
                display: newString,
                value: linkType + (linkType === "https://instagram.com/" ? newString.substring(1) : newString),
              })
            }
          }
          break
        } else {
          //to el
          let newString = cs.substring(indi, indi + el)
          if (newString === "@") {
            desc.push({
              type: "text",
              value: newString,
            })
          } else {

            if (linkType !== "https://instagram.com/") {
              let newDisplayString = newString
              try {
                let linky = newString
                if (!linky.includes("://")) {
                  //add in https
                  linky = "https://" + linky
                }
                let uu = new URL(linky)
                //console.log(uu, uu.searchParams)
                let host = uu.host
                if (host.startsWith("www.")) {
                  host = host.substring(4)
                }
                if (uu.pathname.length > 1 || uu.search.length > 1) {
                  host = host + "/..."
                }
                newDisplayString = host
              } catch (e) {
                console.log("couldn't parse url", e)
                newDisplayString = newString
              }

              desc.push({
                type: "link",
                display: newDisplayString,
                value: linkType + (linkType === "https://instagram.com/" ? newString.substring(1) : newString),
              })
            } else {
              desc.push({
                type: "link",
                display: newString,
                value: linkType + (linkType === "https://instagram.com/" ? newString.substring(1) : newString),
              })
            }
          }
          si = indi + el
        }
      }
    }
  }

  //create headers as necessay if there is all caps text on a line by itself with an empty line above it.
  let newDesc = []
  for (let i = 0; i < desc.length; i = i + 1) {
    //parse into lines.
    let components = []
    if (desc[i].type === "text") {
      let lines = desc[i].value.split(/\r?\n/)
      let previousBlank = false

      let si = 0
      for (let l = 0; l < lines.length; l = l + 1) {
        if (lines[l].trim().length === 0) {
          previousBlank = true
        } else if (lines[l] === lines[l].toUpperCase() && (previousBlank || (l === 0 && i === 0))) {
          previousBlank = false
          //BOLD THIS TEXT!
          //console.log("BOLD THIS TEXT!", lines[l])
          //now we have to find the position of this text in the original string and split it into 2 components.
          let pos = desc[i].value.indexOf(lines[l], si)
          if (pos !== -1) {
            //found it.
            let beforeString = desc[i].value.substring(si, pos)
            let contentString = desc[i].value.substring(pos, pos + lines[l].length)
            //console.log("si", si)
            //console.log("pos", pos)
            //console.log("lines[l].length", lines[l].length)
            //console.log(beforeString)
            //console.log(contentString)
            components.push({
              type: "text",
              value: beforeString
            })
            components.push({
              type: "bold",
              value: contentString
            })
            si = pos + lines[l].length
          }
        } else {
          previousBlank = false
        }
      }
      if (si !== desc[i].value.length) {
        //add in the last string
        components.push({
          type: "text",
          value: desc[i].value.substring(si)
        })
      }
    }

    if (components.length === 0) {
      newDesc.push(desc[i])
    } else {
      for (let c = 0; c < components.length; c = c + 1) {
        newDesc.push(components[c])
      }
    }
  }
  geoji.descriptionParts = newDesc

  //9) Parse the wristbands if there are any
  let wbs = []
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].massAdmissionSecret && geoji.tokens[i].massAdmissionSecret.length > 0) {
      //determine if the wristband already exists.
      let same = false
      for (let w = 0; w < wbs.length; w = w + 1) {
        if (wbs[w].secret === geoji.tokens[i].massAdmissionSecret) {
          same = true
          wbs[w]["tokenID"].push(geoji.tokens[i].id)
          wbs[w]["tokenName"].push(geoji.tokens[i].name)
          wbs[w]["text"].push(geoji.tokens[i].massAdmissionText)
          break
        }
      }
      if (!same) {
        wbs.push({
          tokenID: [geoji.tokens[i].id],
          tokenName: [geoji.tokens[i].name],
          secret: geoji.tokens[i].massAdmissionSecret,
          text: [geoji.tokens[i].massAdmissionText],
        })
      }
    }
  }
  geoji.wristbands = wbs
  //console.log("wristbands", geoji.wristbands)

  //10) Format and sort the creators if necessary
  if (geoji.creators && geoji.creators.length > 0) {
    //sort by owners, then alphabetically.
    geoji.creators.sort((a, b) => {
      if (a.role === b.role) {
        // Return based on alphabetical
        return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
      } else if (a.role === "Owner") {
        return -1
      } else if (b.role === "Owner") {
        return 1
      } else if (a.role === "Invited") {
        return 1
      } else if (b.role === "Invited") {
        return -1
      }
      // Shouldn't happen but just in case
      return -1
    })
  }

  //11) Determine if we should redirect to a different geoji
  if (geoji.redirect && geoji.redirect.length > 0) {
    geoji.redirectLink = "https://www.geoji.com/g/" + geoji.redirect
  }

  return geoji
}

export function showLocation(user, geoji) {
  if (geoji.hideLocation === "1") {
    if (geoji.purchases && geoji.purchases.length > 0) {
      return true
    } else if (user && user.accountPermissions === "Admin") {
      return true
    } else if (user && user.id && geoji.creators && geoji.creators.length > 0) {
      //are we a creator
      for (let i = 0; i < geoji.creators.length; i = i + 1) {
        if (geoji.creators[i].id === user.id) {
          //we are a creator of this geoji.
          return true
        }
      }
    }
    //otherwise hide the location
    return false
  } else {
    //show the location
    return true
  }
}

export function tokenDateTimeAvailableDates(token, limitToFuture = false) {
  //for each datetime create a DateObject.
  let dts = []
  for (let i = 0; i < token.datetimes.length; i = i + 1) {
    //create the date object from the .date field
    let parsedDate = parseSQLDate(token.datetimes[i].date)
    //console.log("ppd", parsedDate)

    let yesterday = new Date()
    yesterday.setTime(yesterday.getTime() - (24 * 60 * 60 * 1000))
    //console.log("parsedDate", parsedDate, yesterday, parsedDate.getTime() < yesterday.getTime())
    if (parsedDate.getTime() < yesterday.getTime()) {
      //date is in the past.
      continue
    }

    let found = false
    for (let j = 0; j < dts.length; j = j + 1) {
      if (dts[j].year === parsedDate.getFullYear() && dts[j].month.number === parsedDate.getMonth() + 1 && dts[j].day === parsedDate.getDate()) {
        found = true
        break
      }
    }
    if (found === true) {
      continue
    }
    let date = new DateObject({
      year: parsedDate.getFullYear(),
      month: parsedDate.getMonth() + 1,
      day: parsedDate.getDate(),
    })

    if (token.datetimeselected && token.datetimeselected.year === date.year && token.datetimeselected.month.number === date.month.number && token.datetimeselected.day === date.day) {
      //green
      date.color = "green"
    } else {
      //blue
      date.color = "blue"
    }
    dts.push(date)
  }
  /*for (let i = 0; i < dts.length; i = i + 1) {
    console.log("dts", i, dts[i].year, dts[i].month.number, dts[i].day)
  }*/

  return dts
}

export function tokenDateTimeAvailableTimes(token) {
  let dts = []

  for (let i = 0; i < token.datetimes.length; i = i + 1) {
    //1) Make sure we aren't sold out.
    let sold = parseInt(token.datetimes[i].tokensSold)
    let quantity = parseInt(token.datetimes[i].quantity)

    if (quantity !== 0 && sold >= quantity) {
      //sold out.
      continue
    }

    //2) Make sure the date matches.
    let parsedDate = parseSQLDate(token.datetimes[i].date)
    let date = new DateObject({
      year: parsedDate.getFullYear(),
      month: parsedDate.getMonth() + 1,
      day: parsedDate.getDate(),
    })

    if (token.datetimeselected && token.datetimeselected.year === date.year && token.datetimeselected.month.number === date.month.number && token.datetimeselected.day === date.day) {
      dts.push(token.datetimes[i])
    }
  }

  return dts
}

export function tokenDateTimeAllTimes(token) {
  let dts = []

  for (let i = 0; i < token.datetimes.length; i = i + 1) {
    //1) Make sure the date matches.
    let parsedDate = parseSQLDate(token.datetimes[i].date)
    let date = new DateObject({
      year: parsedDate.getFullYear(),
      month: parsedDate.getMonth() + 1,
      day: parsedDate.getDate(),
    })

    if (token.datetimeselected && token.datetimeselected.year === date.year && token.datetimeselected.month.number === date.month.number && token.datetimeselected.day === date.day) {
      dts.push(token.datetimes[i])
    }
  }

  return dts
}

export function tokenDateTimeColor(token, time) {
  if (time.selected) {
    return "#8AC349"
  } else if (parseInt(time.quantity) !== 0 && parseInt(time.tokensSold) >= parseInt(time.quantity)) {
    return "#8E8E93"
  }
  let dts = []

  for (let i = 0; i < token.datetimes.length; i = i + 1) {
    //1) Make sure the date matches.
    let parsedDate = parseSQLDate(token.datetimes[i].date)
    let date = new DateObject({
      year: parsedDate.getFullYear(),
      month: parsedDate.getMonth() + 1,
      day: parsedDate.getDate(),
    })

    if (token.datetimeselected && token.datetimeselected.year === date.year && token.datetimeselected.month.number === date.month.number && token.datetimeselected.day === date.day) {
      dts.push(token.datetimes[i])
    }
  }

  //now determine the price groups.
  let prices = []
  for (let i = 0; i < dts.length; i = i + 1) {
    let price = parseInt(dts[i].price)
    if (!prices.includes(price)) {
      prices.push(price)
    }
  }
  //sort the prices by value
  prices.sort()
  console.log(dts)
  let indi = prices.indexOf(parseInt(time.price))
  //for each price, assign a color
  let colors = ["#09D2DE", "#03A9F4", "#0285C2", "#245C75"]
  if (prices.length === 1) {
    return "#03A9F4"
  }

  return colors[indi % colors.length]
}

/*
Returns true or false if the user has revenue access.
*/
export function userHasRevenueAccess(user, geoji, checkSetting = false) {
  if (!user || !geoji) {
    return false
  }
  if (user.accountPermissions === "Admin" && !checkSetting) {
    return true
  }
  if (geoji.creators) {
    for (let i = 0; i < geoji.creators.length; i = i + 1) {
      if (geoji.creators[i].id === user.id) {
        if (geoji.creators[i].role === "Owner") {
          if (checkSetting && geoji.creators[i].hideRevenue === "1") {
            return false
          } else {
            return true
          }
        }
        if (parseFloat(geoji.creators[i].profitCut) > 0) {
          if (checkSetting && geoji.creators[i].hideRevenue === "1") {
            return false
          } else {
            return true
          }
        }
        break;
      }
    }
  }

  if (user.accountPermissions === "Admin") {
    return true
  }
  return false
}

export function canTapInWhosGoing(user, geoji) {
  //if we are a creator, or we have bought something or alwaysShow is on -> then you can tap in to see who is going.
  if (hasAdminAccess(geoji, user) || (geoji.purchases && geoji.purchases.length > 0) || geoji.showInstagramAlways === "1") {
    return true
  }
  return false
}

/*
Gets the wristbands for the provided Geoji.
*/
export function getWristbands(geoji, purchase) {
  let wbs = []
  if (!purchase.tokens || purchase.tokens.length === 0) {
    return wbs
  }
  if (!geoji.tokens || geoji.tokens.length === 0) {
    return wbs
  }

  for (let p = 0; p < purchase.tokens.length; p = p + 1) {
    for (let t = 0; t < geoji.tokens.length; t = t + 1) {
      if (geoji.tokens[t].id === purchase.tokens[p].tokenID) {
        if (geoji.tokens[t].massAdmissionSecret && geoji.tokens[t].massAdmissionSecret.length > 0) {
          //there is a mass admission secret
          let qt = parseInt(purchase.tokens[p].quantity)
          if (geoji.tokens[t].massAdmissionText && geoji.tokens[t].massAdmissionText.length > 0) {
            if (qt === 1) {
              wbs.push({
                tokenID: geoji.tokens[t].id,
                secret: geoji.tokens[t].massAdmissionSecret,
                text: geoji.tokens[t].massAdmissionText,
              })
            } else {
              wbs.push({
                tokenID: geoji.tokens[t].id,
                secret: geoji.tokens[t].massAdmissionSecret,
                text: qt + "x " + geoji.tokens[t].massAdmissionText,
              })
            }
          } else {
            wbs.push({
              tokenID: geoji.tokens[t].id,
              secret: geoji.tokens[t].massAdmissionSecret,
              text: qt,
            })
          }
        }
        break;
      }
    }
  }

  wbs.sort((wa, wb) => {
    if (wa.tokenID < wb.tokenID) {
      return -1
    } else if (wa.tokenID > wb.tokenID) {
      return 1
    }
    return 0
  })
  //console.log("wristbands", wbs)

  return wbs
}

/*
Gets the QR Codes for the provided Geoji.
*/
export function getQRCodes(purchase) {
  if (purchase) {
    return purchase.qrCodes
  }
  return []
}

/*
Gets the name of the token that this QRCode represents.
*/
export function getQRCodeLabel(geoji, qrCode) {
  if (!geoji || !qrCode) {
    return ""
  }
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].id === qrCode.tokenID) {
      return geoji.tokens[i].name
    }
  }

  return ""
}

/*
Gets the special text for this qrcode if applicable.
*/
export function getQRCodeDetailsLabel(qrCode) {
  if (!qrCode) {
    return " "
  }
  if (qrCode.specialText && qrCode.specialText.length > 0) {
    return qrCode.specialText
  }
  return " "
}

export function getDateTimeTitleText(token) {
  for (let i = 0; i < token.datetimes.length; i = i + 1) {
    for (let d = 0; d < token.datetimes.length; d = d + 1) {
      if (token.datetimes[i].id !== token.datetimes[d].id && token.datetimes[i].date === token.datetimes[d].date) {
        return "Select date, time, # of tickets"
      }
    }
  }
  return "Select date & ticket count"
}

/*
Returns the maps to display for the corresponding Geoji purchase.
*/
export function getMaps(geoji, purchase) {
  let wbs = []
  if (!purchase.tokens || purchase.tokens.length === 0) {
    return wbs
  }
  if (!geoji.tokens || geoji.tokens.length === 0) {
    return wbs
  }

  for (let p = 0; p < purchase.tokens.length; p = p + 1) {
    for (let t = 0; t < geoji.tokens.length; t = t + 1) {
      if (geoji.tokens[t].id === purchase.tokens[p].tokenID) {
        if (geoji.tokens[t].mapData && geoji.tokens[t].mapData.mapURL) {
          //there is a map for this token.
          let map = geoji.tokens[t].mapData
          let seats = []
          for (let w = 0; w < wbs.length; w = w + 1) {
            if (wbs[w].tokenID === geoji.tokens[t].id) {
              seats = wbs[w].seats
              break;
            }
          }

          if (purchase.tokens[p].details.seats) {
            seats = seats.concat(purchase.tokens[p].details.seats)
          }

          wbs.push({
            tokenID: geoji.tokens[t].id,
            token: geoji.tokens[t],
            map: map,
            seats: seats
          })
        }
        break;
      }
    }
  }

  wbs.sort((wa, wb) => {
    if (wa.tokenID < wb.tokenID) {
      return -1
    } else if (wa.tokenID > wb.tokenID) {
      return 1
    }
    return 0
  })
  //console.log("maps", wbs)

  return wbs
}

/*
Returns the next waiver that we need to sign or false.
*/
export function getNextWaiver(purchase) {
  //waivers, signedWaivers, tokens
  //1) Figure out what waivers need to be signed and how many by looking at the purchases.
  for (let w = 0; w < purchase.waivers.length; w = w + 1) {
    let tokenID = purchase.waivers[w].tokenID

    //figure out how many we have signed of this token type.
    let signed = 0
    for (let s = 0; s < purchase.signedWaivers.length; s = s + 1) {
      if (purchase.signedWaivers[s].waiverID === purchase.waivers[w].id) {
        signed += 1
      }
    }

    for (let i = 0; i < purchase.tokens.length; i = i + 1) {
      if (purchase.tokens[i].tokenID === tokenID) {
        //found the purchase token that goes with this waiver.
        let required = purchase.tokens[i].quantity

        if (signed < required) {
          //we need to sign another waiver.
          return purchase.waivers[w]
        }
      }
    }
  }
  return false
}

/*
Returns the title of the wiaver given the signedWaiver element and the list of waivers.
*/
export function getWaiverTitle(signed, waivers) {
  let title = "Waiver"
  for (let i = 0; i < waivers.length; i = i + 1) {
    if (waivers[i].id === signed.waiverID) {
      title = waivers[i].waiverTitle
      break;
    }
  }
  return title
}

/*
Returns the next event date or false for this Geoji
*/
export function geojiNextEventDate(geoji) {
  let today = new Date()
  for (let i = 0; i < geoji.eventDates.length; i = i + 1) {
    let pp = geoji.eventDates[i]
    if (sameDay(pp, today)) {
      return pp
    } else if (pp > today) {
      return pp
    }
  }
  return false
}

export function sameDay(one, two) {
  if (one.getDate() === two.getDate() && one.getMonth() === two.getMonth() && one.getFullYear() === two.getFullYear()) {
    return true
  }
  return false
}

/*
Gets the next event date and returns it as a string. If no next event, returns the end date as a string.
*/
export function geojiGetNextEventDateString(geoji, style = "default") {
  let tp = ""
  let today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  if (geoji.eventDates.length > 4 && style === "default") {
    //grab the first and last dates.
    let sd = parseSQLDate(geoji.startDate)
    let ed = parseSQLDate(geoji.endDate)
    let firstDate = formatDateObjectMonthDay(sd)
    let lastDate = formatDateObjectMonthDay(ed)
    if (sd.getMonth() === ed.getMonth()) {
      lastDate = formatDateObjectDate(ed)
    }

    return firstDate + " - " + lastDate
  } else if (geoji.eventDates.length > 4 && style === "tiny") {
    //grab the first and last dates.
    let sd = parseSQLDate(geoji.startDate)
    let ed = parseSQLDate(geoji.endDate)
    let firstDate = formatDateObjectMonthDayTiny(sd)
    let lastDate = formatDateObjectMonthDayTiny(ed)
    if (sd.getMonth() === ed.getMonth()) {
      lastDate = formatDateObjectDate(ed)
    }

    return firstDate + " - " + lastDate
  } else if (geoji.eventDates.length > 1 && style === "default") {
    if (today > geoji.eventDates[geoji.eventDates.length - 1]) {
      //no more events
      tp = "Ended "
    } else {
      tp = "Next Event "
    }
  }
  let foundDate = geojiNextEventDate(geoji)
  if (foundDate === false) {
    foundDate = parseSQLDate(geoji.endDate)
  }

  //check for today
  if (sameDay(today, foundDate)) {
    return tp + "Today"
  } else if (sameDay(tomorrow, foundDate)) {
    return tp + "Tomorrow"
  } else {
    if (style === "day") {
      return tp + formatDateObjectDay(foundDate)
    } else {
      return tp + formatDateObjectShort(foundDate)
    }
  }
}

/*
Takes the token and returns the price in cents.
*/
export function geojiTokenPriceString(token) {
  if (!["default", "map", "datetime"].includes(token.theme)) {
    return ""
  }
  if (token.priceCents === "0" || token.priceCents === 0) {
    return "Free"
  }
  let jj = token.priceCents
  if (jj === undefined) {
    jj = 0
  }
  jj = parseFloat(jj)

  if (token.mapData && token.mapData.seats && token.mapData.seats.length > 0) {
    for (let i = 0; i < token.mapData.seats.length; i = i + 1) {
      if (token.mapData.seats[i].status === "Selected") {
        if (token.mapData.seats[i].price) {
          jj = parseFloat(token.mapData.seats[i].price)
        }
      }
    }
  } else if (token.theme === "datetime") {
    for (let i = 0; i < token.datetimes.length; i = i + 1) {
      if (token.datetimes[i].selected) {
        //found the selected datetime
        if (token.datetimes[i].price !== null && typeof token.datetimes[i].price === 'string') {
          jj = parseFloat(token.datetimes[i].price)
        }
      }
    }
  }

  let rem = jj - (Math.floor(jj / 100.0) * 100)
  if (rem > 0) {
    //there are cents
    return "$" + formatNumberDecimals(jj / 100.0, 2)
  } else {
    //just dollars
    return "$" + formatNumberDecimals(jj / 100.0, 0)
  }
}

export function geojiTokenAbout(token) {
  let about = token.about
  if (token.mapData && token.mapData.seats && token.mapData.seats.length > 0) {
    for (let i = 0; i < token.mapData.seats.length; i = i + 1) {
      if (token.mapData.seats[i].status === "Selected") {
        if (token.mapData.seats[i].about && token.mapData.seats[i].about.length > 0) {
          about = token.mapData.seats[i].about
        }
      }
    }
  }
  return about
}

export function showWhosGoingSection(geoji) {
  if (geoji.showInstagram !== "1") {
    //the toggle is off -> don't show it.
    return false
  }
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].userHidden === 0) {
      //there is a visible token -> show the who's going section.
      return true
    }
  }
  //no visible token -> don't show who's going section.
  return false
}

export function geojiActiveTokens(geoji, focus = false) {
  return geoji.tokens.filter(function (gg) {
    if (gg.userHidden === 1) {
      //don't show hidden tokens.
      if (gg.theme === "promocode" && gg.promoCode) {
        //onto the next stuff - only show if the promo code is set.
      } else {
        return false
      }
    }
    if (focus !== false) {
      return gg.id === focus && gg.frozen === "0"
    }
    return gg.frozen === "0"
  })
}

export function hasDateTimeToken(geoji) {
  let at = geojiActiveTokens(geoji)
  for (let i = 0; i < at.length; i = i + 1) {
    if (at[i].theme === "datetime") {
      return true
    }
  }
  return false
}

export function geojiDefaultTokens(geoji) {
  return geoji.tokens.filter(function (gg) {
    return gg.theme === "default"
  })
}

export function geojiSendableTokens(geoji) {
  return geoji.tokens.filter(function (gg) {
    return ["default", "map", "datetime", "section"].includes(gg.theme) && gg.frozen !== "1"
  })
}

export function geojiFreshTokens(geoji) {
  return geoji.tokens.filter(function (gg) {
    if (gg.frozen === "1" && gg.tokensSold === 0) {
      return false
    }
    return true
  })
}

/*
Returns whether or not the cart is empty for this Geoji.
*/
export function geojiCartEmpty(geoji) {
  let cc = geoji.tokens.filter(function (gg) {
    return gg.cart > 0
  })
  return cc.length === 0
}

/*
Calculates and returns the price text for the current cart.
*/
export function geojiCartPriceText(geoji) {
  let prices = geojiCartPrices(geoji)

  let retString = "$" + formatNumberDecimals(prices[0] / 100.0, 0)

  let rem = prices[0] - (Math.floor(prices[0] / 100.0) * 100)
  if (rem > 0) {
    retString = "$" + formatNumberDecimals(prices[0] / 100.0, 2)
  }
  if (prices[0] === 0) {
    return retString
  }

  return retString + " + $" + formatNumberDecimals((prices[1] + prices[2]) / 100.0, 2)

  /*let totals = geoji.tokens.map(function (token) {
    if (token.theme === "map" && token.mapData && token.mapData.seats && token.mapData.seats.length > 0) {
      //this is a map token - grab the seat price
      let seatTotal = 0
      for (let i = 0; i < token.mapData.seats.length; i = i + 1) {
        if (token.mapData.seats[i].status === "Selected") {
          if (token.mapData.seats[i].price) {
            seatTotal += parseFloat(token.mapData.seats[i].price)
          } else {
            //default price
            seatTotal += token.priceCents
          }
        }
      }
      return seatTotal
    } else if (token.theme === "datetime" && token.datetimes) {
      //this is a datetime token - grab the custom price if available.
      for (let i = 0; i < token.datetimes.length; i = i + 1) {
        if (token.datetimes[i].selected) {
          //found the selected datetime
          if (token.datetimes[i].price !== null && typeof token.datetimes[i].price === 'string') {
            return token.cart * parseFloat(token.datetimes[i].price)
          }
        }
      }
    }
    return token.cart * token.priceCents
  })
  let cents = totals.reduce(function (total, currentValue) {
    return total + currentValue
  })
  //now apply the autotip tokens if necessary
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].theme === "autotip") {
      for (let tt = 0; tt < geoji.tokens[i].mapData.tips.length; tt = tt + 1) {
        if (geoji.tokens[i].mapData.tips[tt].status === "Selected") {
          let vv = parseFloat(geoji.tokens[i].mapData.tips[tt].value)
          if (vv === 0) {
            //nothing to do
          } else if (vv < 1.0) {
            //a percent tip
            cents = Math.round(cents * (1.0 + vv))
          } else {
            //a set amount
            cents += Math.round((vv * 100))
          }
        }
      }
    }
  }

  //now apply the promo codes
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].theme === "promocode") {
      if (geoji.tokens[i].promoCodeData) {
        //promo code set
        let discount = parseFloat(geoji.tokens[i].promoCodeData.discount)
        if (discount <= 1.0) {
          cents = Math.round(cents * (1.0 - discount))
        } else {
          cents = cents - Math.round(discount * 100)
        }
      }
    }
  }
  cents = Math.max(0, cents)

  let retString = "$" + formatNumberDecimals(cents / 100.0, 0)

  let rem = cents - (Math.floor(cents / 100.0) * 100)
  if (rem > 0) {
    retString = "$" + formatNumberDecimals(cents / 100.0, 2)
  }

  if (cents === 0) {
    return retString
  }

  //now calculate the fee
  let fee = 0
  fee = parseInt(Math.round(geoji.processingFeeCents + (geoji.processingFeePercent * cents)))
  return retString + " + $" + formatNumberDecimals(fee / 100.0, 2)*/
}

/*
Calculates and returns the price text for the current cart.
*/
export function geojiCartPriceTotalText(geoji) {
  let prices = geojiCartPrices(geoji)
  return "$" + formatNumberDecimals(prices[3] / 100.0, 2)

  /*let totals = geoji.tokens.map(function (token) {
    if (token.theme === "map" && token.mapData && token.mapData.seats && token.mapData.seats.length > 0) {
      //this is a map token - grab the seat price
      let seatTotal = 0
      for (let i = 0; i < token.mapData.seats.length; i = i + 1) {
        if (token.mapData.seats[i].status === "Selected") {
          if (token.mapData.seats[i].price) {
            seatTotal += parseFloat(token.mapData.seats[i].price)
          } else {
            //default price
            seatTotal += token.priceCents
          }
        }
      }
      return seatTotal
    } else if (token.theme === "datetime" && token.datetimes) {
      //this is a datetime token - grab the custom price if available.
      for (let i = 0; i < token.datetimes.length; i = i + 1) {
        if (token.datetimes[i].selected) {
          //found the selected datetime
          if (token.datetimes[i].price !== null && typeof token.datetimes[i].price === 'string') {
            return token.cart * parseFloat(token.datetimes[i].price)
          }
        }
      }
    }
    return token.cart * token.priceCents
  })
  let cents = totals.reduce(function (total, currentValue) {
    return total + currentValue
  })

  //now apply the autotip tokens if necessary
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].theme === "autotip") {
      for (let tt = 0; tt < geoji.tokens[i].mapData.tips.length; tt = tt + 1) {
        if (geoji.tokens[i].mapData.tips[tt].status === "Selected") {
          let vv = parseFloat(geoji.tokens[i].mapData.tips[tt].value)
          if (vv === 0) {
            //nothing to do
          } else if (vv < 1.0) {
            //a percent tip
            cents = Math.round(cents * (1.0 + vv))
          } else {
            //a set amount
            cents += Math.round(vv * 100)
          }
        }
      }
    }
  }
  //now apply the promo codes
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].theme === "promocode") {
      if (geoji.tokens[i].promoCodeData) {
        //promo code set
        let discount = parseFloat(geoji.tokens[i].promoCodeData.discount)
        if (discount <= 1.0) {
          cents = Math.round(cents * (1.0 - discount))
        } else {
          cents = cents - Math.round(discount * 100)
        }
      }
    }
  }
  cents = Math.max(0, cents)

  if (cents === 0) {
    return "$0.00"
  }

  //now calculate the fee
  let fee = 0
  fee = parseInt(Math.round(geoji.processingFeeCents + (geoji.processingFeePercent * cents)))
  return "$" + formatNumberDecimals((fee + cents) / 100.0, 2)*/
}

/*
Returns the price of the cart in cents.
*/
export function geojiCartPrice(geoji) {
  let prices = geojiCartPrices(geoji)
  return prices[3]

  /*let totals = geoji.tokens.map(function (token) {
    if (token.theme === "map" && token.mapData && token.mapData.seats && token.mapData.seats.length > 0) {
      //this is a map token - grab the seat price
      let seatTotal = 0
      for (let i = 0; i < token.mapData.seats.length; i = i + 1) {
        if (token.mapData.seats[i].status === "Selected") {
          if (token.mapData.seats[i].price) {
            seatTotal += parseFloat(token.mapData.seats[i].price)
          } else {
            //default price
            seatTotal += token.priceCents
          }
        }
      }
      return seatTotal
    } else if (token.theme === "datetime" && token.datetimes) {
      //this is a datetime token - grab the custom price if available.
      for (let i = 0; i < token.datetimes.length; i = i + 1) {
        if (token.datetimes[i].selected) {
          //found the selected datetime
          if (token.datetimes[i].price !== null && typeof token.datetimes[i].price === 'string') {
            return token.cart * parseFloat(token.datetimes[i].price)
          }
        }
      }
    }
    return token.cart * token.priceCents
  })
  let cents = totals.reduce(function (total, currentValue) {
    return total + currentValue
  }, 0)

  //now apply the autotip tokens if necessary
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].theme === "autotip") {
      for (let tt = 0; tt < geoji.tokens[i].mapData.tips.length; tt = tt + 1) {
        if (geoji.tokens[i].mapData.tips[tt].status === "Selected") {
          let vv = parseFloat(geoji.tokens[i].mapData.tips[tt].value)
          if (vv === 0) {
            //nothing to do
          } else if (vv < 1.0) {
            //a percent tip
            cents = Math.round(cents * (1.0 + vv))
          } else {
            //a set amount
            cents += Math.round(vv * 100)
          }
        }
      }
    }
  }

  //now apply the promo codes
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].theme === "promocode") {
      if (geoji.tokens[i].promoCodeData) {
        //promo code set
        let discount = parseFloat(geoji.tokens[i].promoCodeData.discount)
        if (discount <= 1.0) {
          cents = Math.round(cents * (1.0 - discount))
        } else {
          cents = cents - Math.round(discount * 100)
        }
      }
    }
  }
  cents = Math.max(0, cents)

  if (cents === 0) {
    return cents
  }

  //now calculate the fee
  let fee = 0
  fee = parseInt(Math.round(geoji.processingFeeCents + (geoji.processingFeePercent * cents)))
  return cents + fee*/
}

/*
Returns the priceCents, feeCents, taxCents, totalCents (price + fee + tax)
*/
export function geojiCartPrices(geoji) {
  let priceCents = 0
  let feeCents = 0
  let taxCents = 0

  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    let token = geoji.tokens[i]
    if (token.theme === "map" && token.mapData && token.mapData.seats && token.mapData.seats.length > 0) {
      //this is a map token - grab the seat price
      let seatTotal = 0
      for (let i = 0; i < token.mapData.seats.length; i = i + 1) {
        if (token.mapData.seats[i].status === "Selected") {
          if (token.mapData.seats[i].price) {
            seatTotal += parseFloat(token.mapData.seats[i].price)
          } else {
            //default price
            seatTotal += token.priceCents
          }
        }
      }

      priceCents += seatTotal
      if (token.customFeePercent != null) {
        feeCents += (token.customFeePercent * seatTotal)
      } else {
        feeCents += (geoji.processingFeePercent * seatTotal)
      }
      if (token.customTaxPercent != null) {
        taxCents += (token.customTaxPercent * seatTotal)
      } else {
        taxCents += (geoji.processingTaxPercent * seatTotal)
      }
    } else if (token.theme === "datetime" && token.datetimes) {
      //this is a datetime token - grab the custom price if available.
      let customPrice = token.priceCents
      for (let i = 0; i < token.datetimes.length; i = i + 1) {
        if (token.datetimes[i].selected) {
          //found the selected datetime
          if (token.datetimes[i].price !== null && typeof token.datetimes[i].price === 'string') {
            customPrice = parseFloat(token.datetimes[i].price)
          }
        }
      }

      let newAdd = (token.cart * customPrice)
      priceCents += newAdd
      if (token.customFeePercent != null) {
        feeCents += (token.customFeePercent * newAdd)
      } else {
        feeCents += (geoji.processingFeePercent * newAdd)
      }
      if (token.customTaxPercent != null) {
        taxCents += (token.customTaxPercent * newAdd)
      } else {
        taxCents += (geoji.processingTaxPercent * newAdd)
      }
    } else {
      let newAdd = (token.cart * token.priceCents)
      priceCents += newAdd
      if (token.customFeePercent != null) {
        feeCents += (token.customFeePercent * newAdd)
      } else {
        feeCents += (geoji.processingFeePercent * newAdd)
      }
      if (token.customTaxPercent != null) {
        taxCents += (token.customTaxPercent * newAdd)
      } else {
        taxCents += (geoji.processingTaxPercent * newAdd)
      }
    }
  }

  //now apply the autotip tokens if necessary
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].theme === "autotip") {
      for (let tt = 0; tt < geoji.tokens[i].mapData.tips.length; tt = tt + 1) {
        if (geoji.tokens[i].mapData.tips[tt].status === "Selected") {
          let vv = parseFloat(geoji.tokens[i].mapData.tips[tt].value)
          let newAdd = 0
          if (vv === 0) {
            //nothing to do
          } else if (vv < 1.0) {
            //a percent tip
            newAdd = Math.round(priceCents * vv)
            priceCents += newAdd
          } else {
            //a set amount
            newAdd = Math.round(vv * 100)
            priceCents += newAdd
          }
          //calculate the fees
          if (geoji.tokens[i].customFeePercent != null) {
            feeCents += (geoji.tokens[i].customFeePercent * newAdd)
          } else {
            feeCents += (geoji.processingFeePercent * newAdd)
          }
          if (geoji.tokens[i].customTaxPercent != null) {
            taxCents += (geoji.tokens[i].customTaxPercent * newAdd)
          } else {
            taxCents += (geoji.processingTaxPercent * newAdd)
          }
        }
      }
    }
  }

  //now apply the promo codes
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].theme === "promocode") {
      if (geoji.tokens[i].promoCodeData) {
        //promo code set
        let newAdd = 0
        let discount = parseFloat(geoji.tokens[i].promoCodeData.discount)
        if (discount <= 1.0) {
          newAdd = Math.round(priceCents * -discount)
          priceCents += newAdd
        } else {
          newAdd = -1 * Math.round(discount * 100)
          priceCents += newAdd
        }
        //calculate the fees
        if (geoji.tokens[i].customFeePercent != null) {
          feeCents += (geoji.tokens[i].customFeePercent * newAdd)
        } else {
          feeCents += (geoji.processingFeePercent * newAdd)
        }
        if (geoji.tokens[i].customTaxPercent != null) {
          taxCents += (geoji.tokens[i].customTaxPercent * newAdd)
        } else {
          taxCents += (geoji.processingTaxPercent * newAdd)
        }
      }
    }
  }
  priceCents = Math.max(0, priceCents)

  if (priceCents === 0) {
    feeCents = 0
    taxCents = 0
  } else {
    //add in the feeCents
    feeCents += geoji.processingFeeCents
  }

  feeCents = Math.round(Math.max(0, feeCents))
  taxCents = Math.round(Math.max(0, taxCents))

  return [priceCents, feeCents, taxCents, priceCents + feeCents + taxCents]
}

export function getTokenJSONwithCart(geoji) {
  let parts = geoji.tokens.map(function(token) {
    if (token.theme === "map") {
      //find the list of selected seats.
      let selectedSeats = []
      for (let s = 0; s < token.mapData.seats.length; s = s + 1) {
        if (token.mapData.seats[s].status === "Selected") {
          selectedSeats.push(token.mapData.seats[s].name)
        }
      }
      return {
        id: token.id,
        quantity: token.cart,
        name: token.name,
        seats: selectedSeats,
      }
    } else if (token.theme === "autotip") {
      let vv = 0.0
      let qq = 0
      for (let tt = 0; tt < token.mapData.tips.length; tt = tt + 1) {
        if (token.mapData.tips[tt].status === "Selected") {
          vv = token.mapData.tips[tt].value
          if (vv > 0) {
            qq = 1
          }
        }
      }
      return {
        id: token.id,
        quantity: qq,
        name: token.name,
        value: vv,
      }
    } else if (token.theme === "promocode") {
      let cv = 0.0
      let qq = 0
      if (token.promoCodeData) {
        qq = 1
        cv = token.promoCodeData.code
      }
      return {
        id: token.id,
        quantity: qq,
        name: token.name,
        code: cv,
      }
    } else if (token.theme === "datetime") {

      let sdt = ""
      for (let st = 0; st < token.datetimes.length; st = st + 1) {
        if (token.datetimes[st].selected) {
          sdt = token.datetimes[st].id
        }
      }

      return {
        id: token.id,
        quantity: token.cart,
        name: token.name,
        datetime: sdt,
      }
    } else {
      return {
        id: token.id,
        quantity: token.cart,
        name: token.name,
      }
    }
  })
  return parts
}

/*
Returns if the cart is incompatible with sending free tokens
to multiple people.
*/
export function cartIncompatibleWithMultipleBuys(geoji) {
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    let token = geoji.tokens[i]
    if (token.theme === "map" && token.cart > 0) {
      return true
    } /*else if (token.theme === "datetime" && token.cart > 0) {
      return true
    }*/
  }
  return false
}

export function getTokenJSON(geoji) {
  let tokenData = []
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    let tok = geoji.tokens[i]
    let data = {
      "id": tok.id,
      "name": tok.name,
      "quantity": tok.quantity,
      "priceCents": tok.priceCents,
      "frozen": tok.frozen,
      "index": tok.index,
      "theme": tok.theme,
      "userHidden": tok.userHidden,
      "purchaseLimit": tok.purchaseLimit,
    }
    if (tok.about && tok.about.length > 0) {
      data.about = tok.about
    }
    if (tok.aboutImage && tok.aboutImage.length > 0) {
      data.aboutImage = tok.aboutImage
    }
    tokenData.push(data)
  }
  return tokenData
}

export function cartValid(geoji) {
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].cart > 0) {
      if (geoji.tokens[i].theme === "datetime") {
        //make sure there is a selected datetime
        let found = false;
        for (let d = 0; d < geoji.tokens[i].datetimes.length; d = d + 1) {
          if (geoji.tokens[i].datetimes[d].selected === true) {
            found = true
          }
        }
        if (!found) {
          return [false, "You must select a timeslot for your " + geoji.tokens[i].name + " ticket(s). Tap on a blue timeslot to choose when you want to attend."]
        }
      }
    }
  }
  return [true, ""]
}

/*
Returns the number of items in the cart
*/
export function geojiCartItemsCount(geoji) {
  let totals = geoji.tokens.map(function (token) {
    return token.cart
  })
  let count = totals.reduce(function (total, currentValue) {
    return total + currentValue
  })
  return count
}

/*
Returns the token title for the geoji and purchase provided.
*/
export function geojiTokenTitle(geoji, tokenID) {
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    if (geoji.tokens[i].id === tokenID) {
      return geoji.tokens[i].name
    }
  }
}

/**
 Returns the displayable role for the user based on the DB value.
 */
export function displayableRole(user) {
  if (user.role === "Owner") {
    return "Creator, Owner"
  }
  return user.role
}

/**
 Returns the displayable ownership percentage for the user if they are an owner.
 */
 export function displayableOwnership(user) {
  if (user.role === "Owner") {
    //return the percentage
    let percent = user.profitCut
    if (user.editingOwnership) {
      percent = user.editingPercent
    }
    if (typeof percent === 'string') {
      percent = parseFloat(percent)
    }
    return Math.round(percent * 100) + "%"
  }
  //no ownership
  return ""
}

/**
 Returns the displayable role for the user based on the DB value.
 */
export function displayableName(user) {
  if (!user.name || user.name.length === 0) {
    return displayablePhoneNumber(user)
  }
  return user.name
}

/**
 Returns the displayable role for the user based on the DB value.
 Returns blank if no phone number or temp phone number found
 */
export function displayablePhoneNumber(user) {
  if (!user.phoneNumber || user.phoneNumber.length === 0) {
    if (!user.tempPhoneNumber || user.tempPhoneNumber.length === 0) {
      return ""
    } else {
      return parsePhoneNumber(user.tempPhoneNumber)
    }
  } else {
    return parsePhoneNumber(user.phoneNumber)
  }
}

/**
 Are these two date objects on the same day.
 */
export function sameDate(a, b) {
  if (a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear()) {
    return true
  }
  return false
}

/**
 Returns the displayable order status for this user.
 */
export function displayableOrderStatus(purchase) {
  if (purchase.redeemed === "1" && purchase.status !== "Refunded") {
    //Completion Date
    let td = parseSQLDate(purchase.redemptionTimestamp)
    let today = new Date()
    if (sameDate(td, today)) {
      //just show the time
      return "Completed " + formatDateObjectTime(purchase.redemptionTimestamp)
    } else if (td.getFullYear() === today.getFullYear()) {
      //same year, show month, day, and time
      return "Completed " + formatDateObjectLongTime(purchase.redemptionTimestamp)
    } else {
      //show the full date and time
      return "Completed " + formatDateObjectLong(purchase.redemptionTimestamp)
    }
  } else if (purchase.status === "Captured") {
    return "Waiting"
  } else if (purchase.status === "Refunded") {
    //Refunded date
    let td = parseSQLDate(purchase.refundTimestamp)
    let today = new Date()
    if (sameDate(td, today)) {
      //just show the time
      return "Refunded " + formatDateObjectTime(purchase.refundTimestamp)
    } else if (td.getFullYear() === today.getFullYear()) {
      //same year, show month, day, and time
      return "Refunded " + formatDateObjectLongTime(purchase.refundTimestamp)
    } else {
      //show the full date and time
      return "Refunded " + formatDateObjectLong(purchase.refundTimestamp)
    }
  } else {
    return purchase.status
  }
}

/**
 Returns the color of the text for the corresponding order status.
 */
export function orderStatusColor(purchase) {
  if (purchase.redeemed === "1" && purchase.status !== "Refunded") {
    //Blue
    return "#03A9F4"
  } else if (purchase.status === "Captured") {
    return "#8AC349"
  } else if (purchase.status === "Refunded") {
    //Refunded date
    return "#8E8E93"
  } else {
    return "#8E8E93"
  }
}

/**
 Returns the displayable time of the purchase.
 */
export function displayableOrderPurchaseDate(purchase) {
  if (!purchase.purchaseTimestamp) {
    return ""
  }
  let td = parseSQLDate(purchase.purchaseTimestamp)
  let today = new Date()
  if (sameDate(td, today)) {
    //just show the time
    return formatDateObjectTime(purchase.purchaseTimestamp)
  } else if (td.getFullYear() === today.getFullYear()) {
    //same year, show month, day, and time
    return formatDateObjectLongTime(purchase.purchaseTimestamp)
  } else {
    //show the full date and time
    return formatDateObjectLong(purchase.purchaseTimestamp)
  }
}

/**
 Returns an array of token objects with their redemption statuses.
 */
export function orderTokensArray(purchase) {
  if (purchase.tokens && Array.isArray(purchase.tokens)) {
    let retVals = []
    for (let i = 0; i < purchase.tokens.length; i = i + 1) {
      if (purchase.tokens[i].theme === "tipjar") {
        //group all tipjar tokens together
        let newVal = Object.assign({}, purchase.tokens[i])
        if (newVal.redeemed === purchase.tokens[i].quantity) {
          newVal.checked = true
        } else {
          newVal.checked = false
        }
        newVal.tokenIter = 0
        retVals.push(newVal)
      } else {
        //show the tokens individually
        for (let j = 0; j < purchase.tokens[i].quantity; j = j + 1) {
          let newVal = Object.assign({}, purchase.tokens[i])
          if (j < newVal.redeemed) {
            newVal.checked = true
          } else {
            newVal.checked = false
          }
          newVal.tokenIter = j
          retVals.push(newVal)
        }
      }
    }
    return retVals
  }
  return []
}

/**
 Does this pin have Admin Access.
 */
export function hasAdminAccess(geoji, user) {
  //console.log("hasAdminAccess", user)
  if (user && user.accountPermissions === "Admin") {
    //console.log("user has accountPermissions === Admin")
    return true
  } else if (!user || user === undefined) {
    //console.log("!user or user === undefined")
    return false
  }
  if (geoji.creators) {
    let userID = "nope"
    if (user && user.id) {
      userID = user.id
    }
    //console.log("userID - " + userID)
    for (let i = 0; i < geoji.creators.length; i = i + 1) {
      if (geoji.creators[i].id === userID) {
        //found the creator
        return true
      }
    }
  }
  return false
}

/**
Returns the total revenue of all the tokens
*/
export function totalRevenue(geoji) {
  if (!geoji.tokens) {
    return 0
  }
  let total = 0
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    let rc = geoji.tokens[i].revenueCents
    if (typeof rc === "string") {
      rc = parseInt(rc)
    } else if (isNaN(rc) || rc === undefined) {
      return -987654321
    }
    total += rc
  }
  return total
}

/**
Returns the today revenue of all the tokens
*/
export function todayRevenue(geoji) {
  if (!geoji.tokens) {
    return 0
  }
  let total = 0
  for (let i = 0; i < geoji.tokens.length; i = i + 1) {
    let rc = geoji.tokens[i].revenueCentsToday
    if (typeof rc === "string") {
      rc = parseInt(rc)
    } else if (isNaN(rc) || rc === undefined) {
      return -987654321
    }
    total += rc
  }
  return total
}

/**
Returns the dollars portion of the revenue like $ 99 for $99.56
*/
export function revenueToDollars(value) {
  let vv = value
  if (value === -987654321) {
    return "$ –"
  } else if (typeof vv === "string") {
    vv = parseInt(vv)
  }
  if (vv === undefined || isNaN(vv)) {
    return "$ –"
  }
  let negative = false
  let total = vv
  if (total < 0) {
    negative = true
    total = total * -1
  }
  total = Math.trunc(total / 100.0)

  return (negative ? "-" : "") + "$ " + total.toLocaleString("en-US")
}

/**
Returns the dollars portion of the revenue like .56 for $99.56
*/
export function revenueToCents(value) {
  let vv = value
  if (value === -987654321) {
    return ".––"
  } if (typeof vv === "string") {
    vv = parseInt(vv)
  }
  if (vv === undefined || isNaN(vv)) {
    return ".––"
  }
  let total = vv
  if (total < 0) {
    total = total * -1
  }
  total = total / 100.0
  total = total - Math.floor(total)
  total = Math.round(total * 100)

  return "." + total.toLocaleString("en-US", {minimumIntegerDigits: 2})
}

/**
Calculates the percentSold.
*/
export function tokenPercentSold(token) {
  return token.tokensSold / (token.quantity > 0 ? token.quantity : 1)
}

/**
Calculates the percentSold and returns as a string like 76 %
*/
export function tokenPercentSoldString(token) {
  let ps = tokenPercentSold(token)

  let dd = Math.round(ps * 100)
  return dd.toLocaleString("en-US") + " %"
}

/**
Returns the hex color string based on the index.
*/
export function tokenColor(index) {
  //orange, red, blue, pink, green
  let colors = ["F6C26F", "EE677E", "67A7EE", "EE67BD", "6DC887"]

  return colors[index % colors.length]
}

/**
Returns the hex color string based on the index.
*/
export function tokenColorTransparent(index) {
  //orange, red, blue, pink, green
  let colors = ["F6C26F1A", "EE677E1A", "67A7EE1A", "EE67BD1A", "6DC8871A"]

  return colors[index % colors.length]
}

/**
Returns the colored token image based on the index.
*/
export function tokenImage(index) {
  let colors = [ImageGeojiCoin0, ImageGeojiCoin1, ImageGeojiCoin2, ImageGeojiCoin3, ImageGeojiCoin4]

  return colors[index % colors.length]
}

/**
Returns the colored token shape image based on the index.
*/
export function tokenShapeImage(index) {
  let colors = [ImageGeojiCoinShape0, ImageGeojiCoinShape1, ImageGeojiCoinShape2, ImageGeojiCoinShape3, ImageGeojiCoinShape4]

  return colors[index % colors.length]
}

export function generateOTP(secret) {
  try {
    if (window.jsOTP !== undefined) {
      var totp = new window.jsOTP.totp(15, 6)
      return totp.getOtp(secret)
    }
  } catch (e) {
    console.log("error", e)
  }
  return 111111
}

//Parses the text and returns keywords.
export function parseKeywords(search) {
  //1) Remove all non-alphanumeric characters.
  let combined = search.replace(/[^0-9a-z]/gi, ' ')

  //2) Split into words
  let words = combined.split(' ')
  //2.1) lowercase the words and singularize
  words = words.map((word) => {
    return [pluralize(word.toLowerCase(), 1), 1]
  })
  //2.2) remove empty words & stopwords
  words = words.filter((word) => {
    if (word[0].length <= 3 || stopwords.includes(word[0])) {
      return false
    }
    return true
  })
  //2.3) combine words into word counts.
  words = words.reduce((acc, word) => {
    acc[word[0]] = (acc[word[0]] || 0) + 1
    return acc
  }, {})

  let wordsList = Object.keys(words)

  let list =  wordsList.join(", ")
  return list.substring(0, 150)
}

//Determines if there is a datetime token in this purchase that is unused where the date/time can be switched.
export function canChangeTicketDate(purchase) {
  if (!purchase.tokens) {
    return false
  }
  for (let i = 0; i < purchase.tokens.length; i = i + 1) {
    if (purchase.tokens[i].theme === "datetime" && purchase.tokens[i].redeemed === 0) {
      return true
    }
  }
  return false
}

/*
Updates the title of the page, the description meta, and the keywords meta.
If you leave off newDescription & newKeywords then they will be reset to default.
*/
export function updateTitle(newTitle, newDescription = false, newKeywords = false) {
  console.log("update title", newTitle)
  document.title = newTitle
  let allMeta = document.getElementsByTagName('meta')
  for (let i = 0; i < allMeta.length; i = i + 1) {
    if (allMeta[i].getAttribute("name") === "description") {
      if (newDescription !== false) {
        allMeta[i].setAttribute("content", newDescription)
      } else {
        allMeta[i].setAttribute("content", "Create & Discover Unique Moments with Geoji. Start your own business today with Geoji in less than one minute. Drop a pin, set the emoji, and start selling entry to your event, merchandise, rentals, etc.")
      }
    } else if (allMeta[i].getAttribute("name") === "keywords") {
      if (newKeywords !== false) {
        allMeta[i].setAttribute("content", newKeywords)
      } else {
        allMeta[i].setAttribute("content", "Geoji, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise")
      }
    }
  }
}

export function updateOGMeta(title, url, image = "", description = "You have been invited!") {
  let allMeta = document.getElementsByTagName("meta")
  for (let i = 0; i < allMeta.length; i = i + 1) {
    if (allMeta[i].getAttribute("property") === "og:title") {
      if (title !== false) {
        allMeta[i].setAttribute("content", title)
      }
    } else if (allMeta[i].getAttribute("property") === "og:url") {
      if (url !== false) {
        allMeta[i].setAttribute("content", url)
      }
    } else if (allMeta[i].getAttribute("property") === "og:image") {
      if (image !== false) {
        allMeta[i].setAttribute("content", image)
      }
    } else if (allMeta[i].getAttribute("property") === "og:description") {
      if (description !== false) {
        allMeta[i].setAttribute("content", description)
      }
    }
  }
}

export function updatePageMeta(url, geoji = false, queryParams = false) {
  if (geoji !== false) {
    if (url.startsWith("/h/")) {
      let data = getPageMetaFromHandle(geoji, url, queryParams)
      updatePageMetaWithData(data)
    } else if (url.startsWith("/blog/")) {
      let data = getPageMetaFromBlog(geoji, url)
      updatePageMetaWithData(data)
    } else {
      let data = getPageMetaFromGeoji(geoji, url, queryParams)
      updatePageMetaWithData(data)
    }
    return
  }
  getPageMeta(url).then((data) => {
    updatePageMetaWithData(data[0])
  })
}

export function getPageMetaFromGeoji(geoji, url, params = false) {

  let showAppClips = "1"
  if (params && params.hasOwnProperty("clips") && params.clips === "0") {
    showAppClips = "0"
  }

  //console.log("geoji", geoji)
  let title = geoji.title
  //add in the event date(s)
  if (geoji.eventDates.length === 1) {
    //add in the date
    let date = geoji.eventDates[0]
    title += " | " + formatDateObjectShort(date)
  }
  //add in the event location
  if (geoji.where && geoji.where.length > 0) {
    title += " | " + geoji.where
  }
  title += " | Geoji"

  let description = "Geoji | " + geoji.title + " | "
  description += (geoji.description !== null && geoji.description.length > 0) ? geoji.description.substring(0, 160) : ""

  let keywords = description + " event business do512 eventbrite ticketing sell merchandise"
  if (geoji.where && geoji.where.length > 0) {
    keywords += " " + geoji.where
  }
  keywords = parseKeywords(keywords)

  let retData = {
    title: title,
    description: description,
    keywords: keywords,
    og: {
      title: geoji.title,
      type: "website",
      image: (geoji.photoURL !== null && geoji.photoURL.length > 0) ? geoji.photoURL : (geoji.private === "1" ? "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiUnderground.jpg" : "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg"),
      url: "https://www.geoji.com" + url,
      description: "You have been invited!"
    },
    sandbox: geoji.sandbox,
    appclips: showAppClips,
  }

  //add the rich data for Google search results. If there are dates, then this is an event, otherwise it is a business.
  //console.log(url)
  if (url.startsWith("/g/")) {
    //public geoji - use rich data
    if (geoji.eventDates.length > 0) {
      //this is an event
      retData.richData = {
        "@context": "https://schema.org",
        "@type": "Event",
        "name": geoji.title,
        "startDate": formatDateObjectShort(geoji.eventDates[0]),
        "endDate": formatDateObjectShort(geoji.eventDates[geoji.eventDates.length - 1]),
        "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
        "eventStatus": "https://schema.org/EventScheduled",
        "location": {
          "@type": "Place",
          "address": geoji.where,
        },
        "description": description,
      }
      if (geoji.photoURL !== null && geoji.photoURL.length > 0) {
        retData.richData.image = [geoji.photoURL]
      }
    } else {
      //this is a business
      retData.richData = {}
    }
  } else {
    retData.richData = {}
  }

  return retData
}

export function getPageMetaFromHandle(handle, url, params = false) {
  let showAppClips = "1"
  if (params && params.clips && params.clips === "0") {
    showAppClips = "0"
  }

  //console.log("geoji", geoji)
  let title = handle.name + " @" + handle.link + " | Geoji"

  let description = "Geoji | " + handle.name + " | "
  description += (handle.bio !== null && handle.bio.length > 0) ? handle.bio.substring(0, 160) : ""

  let keywords = description + " event business do512 eventbrite ticketing sell merchandise"
  keywords = parseKeywords(keywords)

  let retData = {
    title: title,
    description: description,
    keywords: keywords,
    og: {
      title: handle.name,
      type: "website",
      image: (handle.photoURL !== null && handle.photoURL.length > 0) ? handle.photoURL : "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
      url: "https://www.geoji.com" + url,
      description: "You have been invited!"
    },
    appclips: showAppClips,
  }

  return retData
}

export function getPageMetaFromBlog(blog, url) {
  //console.log("geoji", geoji)
  let title = blog.title + " | Geoji"

  let description = blog.subtitle || ""

  let keywords = description + " event business do512 eventbrite ticketing sell merchandise"
  keywords = parseKeywords(keywords)

  let retData = {
    title: title,
    description: description,
    keywords: keywords,
    og: {
      title: title,
      type: "website",
      image: (blog.imageURL !== null && blog.imageURL.length > 0) ? blog.imageURL : "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
      url: "https://www.geoji.com/blog/" + blog.url,
      description: description
    },
    sandbox: "1",
    appclips: "0",
  }

  return retData
}

export function updatePageMetaWithData(data) {
  for (const [key, value] of Object.entries(data)) {
    if (key === "title") {
      //title of the page
      document.title = value
    } else if (key === "og") {
      //open graph data
      for (const [key2, value2] of Object.entries(value)) {
        let allMeta = document.getElementsByTagName('meta')
        for (let i = 0; i < allMeta.length; i = i + 1) {
          if (allMeta[i].getAttribute("property") === "og:" + key2) {
            allMeta[i].setAttribute("content", value2)
          }
        }
      }
    } else if (key === "richData") {
      let allScripts = document.getElementsByTagName('script')
      for (let i = 0; i < allScripts.length; i = i + 1) {
        if (allScripts[i].getAttribute("type") === "application/ld+json") {
          //update the value of the script.
          allScripts[i].innerHTML = JSON.stringify(value)
          break;
        }
      }
    } else if (key === "sandbox") {
      continue;
    } else if (key === "appclips") {
      //show or hide the app clip.
      let allMeta = document.getElementsByTagName('meta')
      for (let i = 0; i < allMeta.length; i = i + 1) {
        if (allMeta[i].getAttribute("name") === "apple-itunes-app") {
          if (value === "1") {
            allMeta[i].setAttribute("content", "app-id=1502351343, app-clip-bundle-id=com.geojidev.Geoji.Clip, app-clip-display=card")
          } else {
            allMeta[i].setAttribute("content", "app-id=1502351343")
          }
        }
      }
    } else {
      //other meta tags
      let allMeta = document.getElementsByTagName('meta')
      for (let i = 0; i < allMeta.length; i = i + 1) {
        if (allMeta[i].getAttribute("name") === key) {
          if (value !== false) {
            allMeta[i].setAttribute("content", value)
          } else {
            allMeta[i].setAttribute("content", "Create & Discover Unique Moments with Geoji. Start your own business today with Geoji in less than one minute. Drop a pin, set the emoji, and start selling entry to your event, merchandise, rentals, etc.")
          }
        }
      }
    }
  }
}

export function payPalScript(sandbox) {
  let first = "https://www.paypal.com/sdk/js?enable-funding=venmo&components=buttons&client-id="
  if (sandbox === "1") {
    return first + "AWgVVSRItUJUqTRGzOCFDVLXsXXMtRoH7w30mF3B0RK3jEh0xPQJFIVWOk_n1ckonMYMX986Ocd1GeHA"
  } else {
    return first + "ARwmqhlQh_ZFhtcv77E5KJvKnDfKd6BMU7wuMkMiFGrOIAKsuViHW61QVDQDNoPuiTT1_eRs91wPI67C"
  }
}

/**
Returns the meta data for each page. Performs an API request if necessary.
*/
export async function getPageMeta(url, queryParams) {
  let showAppClips = "1"
  if (queryParams && queryParams.hasOwnProperty("clips") && queryParams.clips === "0") {
    showAppClips = "0"
  }
  return new Promise(resolve => {
    if (url.startsWith("/g/")) {
      //public geoji
      let parts = url.split("/")
      console.log("urlparts", parts)
      let geojiID = parts[0]
      if (parts.length >= 2) {
        geojiID = parts[2]
      }
      request('https://geoji.app.darwincloud.com/geoji/' + apiVersion + '/ugeoji/' + geojiID, function (error, response, body) {
        let showError = false
        try {
          let result = JSON.parse(body)
          if (error !== undefined && error !== null) {
            console.log("Error not undefined", error)
            showError = true
          } else if ("error" in result) {
            console.log("Error", result)
            showError = true
          } else {
            let geo = formatGeoji(result.data.geoji)
            let dd = getPageMetaFromGeoji(geo, url, queryParams)
            resolve([dd, geo])
            return
          }
        } catch (ee) {
          console.log("error parsing json", ee)
          showError = true
        }
        if (showError) {
          resolve([{
            title: "Expired Geoji",
            description: "Invalid Geoji Link",
            keywords: "geoji, invalid",
            og: {
              title: "Expired Geoji",
              type: "website",
              image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
              url: "https://www.geoji.com" + url,
              description: "Invalid Geoji Link"
            },
            richData: {},
            sandbox: "1",
            appclips: showAppClips,
          }, false])
        }
      })
    } else if (url.startsWith("/p/")) {
      //private geoji
      let parts = url.split("/")
      let linkID = parts[0]
      if (parts.length >= 2) {
        linkID = parts[2]
      }
      request('https://geoji.app.darwincloud.com/geoji/' + apiVersion + '/uprivateLink/' + linkID, function (error, response, body) {
        let showError = false
        try {
          let result = JSON.parse(body)
          if (error !== undefined && error !== null) {
            console.log("Error not undefined", error)
            showError = true
          } else if ("error" in result) {
            console.log("Error", result)
            showError = true
          } else {
            let geo = formatGeoji(result.data.geoji)
            let dd = getPageMetaFromGeoji(geo, url, queryParams)
            resolve([dd, geo])
            return
          }
        } catch (ee) {
          console.log("error parsing json", ee)
          showError = true
        }
        if (showError) {
          resolve([{
            title: "Expired Geoji",
            description: "Invalid Geoji Link",
            keywords: "geoji, invalid",
            og: {
              title: "Expired Geoji",
              type: "website",
              image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
              url: "https://www.geoji.com" + url,
              description: "Invalid Geoji Link"
            },
            richData: {},
            sandbox: "1",
            appclips: showAppClips,
          }, false])
        }
      })
    } else if (url.startsWith("/h/")) {
      //handle
      let parts = url.split("/")
      let handleID = parts[0]
      if (parts.length >= 2) {
        handleID = parts[2]
      }
      //Call API to get the meta data
      request('https://geoji.app.darwincloud.com/geoji/' + apiVersion + '/handle/' + handleID, function (error, response, body) {
        let showError = false
        try {
          let result = JSON.parse(body)
          if (error !== undefined && error !== null) {
            console.log("Error not undefined", error)
            showError = true
          } else if ("error" in result) {
            console.log("Error", result)
            showError = true
          } else {
            let handle = result.data.handle
            let dd = getPageMetaFromHandle(handle, url, queryParams)
            resolve([dd, result.data])
            return
          }
        } catch (ee) {
          console.log("error parsing json", ee)
          showError = true
        }
        if (showError) {
          resolve([{
            title: "Invalid Handle",
            description: "Invalid Geoji Handle",
            keywords: "geoji, invalid",
            og: {
              title: "Invalid Handle",
              type: "website",
              image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
              url: "https://www.geoji.com" + url,
              description: "Invalid Geoji Handle"
            },
            richData: {},
            sandbox: "1",
            appclips: showAppClips,
          }, false])
        }
      })
    } else if (url.startsWith("/blog/")) {
      //blog
      let parts = url.split("/")
      let blogID = parts[0]
      if (parts.length >= 2) {
        blogID = parts[2]
      }
      //Call API to get the meta data
      request('https://geoji.app.darwincloud.com/geoji/' + apiVersion + '/blog/' + blogID, function (error, response, body) {
        let showError = false
        try {
          let result = JSON.parse(body)
          if (error !== undefined && error !== null) {
            console.log("Error not undefined", error)
            showError = true
          } else if ("error" in result) {
            console.log("Error", result)
            showError = true
          } else {
            let blog = result.data.blog
            try {
              blog.content = JSON.parse(blog.content)
            } catch (enot) {
              console.log("couldn't parse content")
              blog.content = []
            }
            blog.publishedDate = (blog.publishedTimestamp && blog.publishedTimestamp.length > 0) ? formatDateObjectShort(blog.publishedTimestamp) : "Draft"

            let dd = getPageMetaFromBlog(blog, url)
            resolve([dd, blog])
            return
          }
        } catch (ee) {
          console.log("error parsing json", ee)
          showError = true
        }
        if (showError) {
          resolve([{
            title: "Invalid Blog",
            description: "Invalid Blog Link",
            keywords: "blog, invalid",
            og: {
              title: "Invalid Blog",
              type: "website",
              image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
              url: "https://www.geoji.com" + url,
              description: "Invalid Blog"
            },
            richData: {},
            sandbox: "1",
            appclips: "0",
          }, false])
        }
      })
    } else if (url.startsWith("/terms")) {
      //terms of service
      resolve([{
        title: "Terms of Service - Geoji",
        description: "These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Geoji, Inc.",
        keywords: "Geoji, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise",
        og: {
          title: "Terms of Service",
          type: "website",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
          url: "https://www.geoji.com/terms",
          description: "These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Geoji, Inc."
        },
        richData: {},
        sandbox: "1",
        appclips: "0",
      }, false])
    } else if (url.startsWith("/privacy")) {
      //privacy policy
      resolve([{
        title: "Privacy Policy - Geoji",
        description: "Privacy Policy for Geoji, Inc. We respect the privacy of our users.",
        keywords: "Geoji, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise",
        og: {
          title: "Privacy Policy",
          type: "website",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
          url: "https://www.geoji.com/privacy",
          description: "Geoji Inc. (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”)."
        },
        richData: {},
        sandbox: "1",
        appclips: "0",
      }, false])
    } else if (url.startsWith("/acceptableuse")) {
      //acceptable use
      resolve([{
        title: "Acceptable use - Geoji",
        description: "You are independently responsible for complying with all applicable laws in all of your actions related to your use of Geoji's services.",
        keywords: "Geoji, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise",
        og: {
          title: "Acceptable Use",
          type: "website",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
          url: "https://www.geoji.com/acceptableuse",
          description: "You are independently responsible for complying with all applicable laws in all of your actions related to your use of Geoji's services."
        },
        richData: {},
        sandbox: "1",
        appclips: "0",
      }, false])
    } else if (url.startsWith("/contact")) {
      //contact
      resolve([{
        title: "Contact - Geoji",
        description: "Get in touch with Geoji and express any questions, concerns, feature requests, etc.",
        keywords: "Geoji, contact, email, support, help, hello, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise",
        og: {
          title: "Contact",
          type: "website",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
          url: "https://www.geoji.com/contact",
          description: "Get in touch with Geoji and express any questions, concerns, feature requests, etc."
        },
        richData: {},
        sandbox: "1",
        appclips: "0",
      }, false])
    } else if (url.startsWith("/about")) {
      //about
      resolve([{
        title: "About - Geoji",
        description: "Geoji is The Map for the Creator's Economy. Sell Digital Tickets for All Things.",
        keywords: "Geoji, founders, who created, mission statement, goals, about, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise",
        og: {
          title: "About",
          type: "website",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
          url: "https://www.geoji.com/about",
          description: "Geoji is The Map for the Creator's Economy. Sell Digital Tickets for All Things."
        },
        richData: {},
        sandbox: "1",
        appclips: "0",
      }, false])
    } else if (url.startsWith("/bank")) {
      //bank
      resolve([{
        title: "Link Bank Account - Geoji",
        description: "Link your Bank Account with Plaid and Geoji to get paid out quickly.",
        keywords: "Geoji, founders, who created, mission statement, goals, about, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise",
        og: {
          title: "Link Bank Account",
          type: "website",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
          url: "https://www.geoji.com/bank",
          description: "Link your Bank Account with Plaid and Geoji to get paid out quickly."
        },
        richData: {},
        sandbox: "1",
        appclips: "0",
      }, false])
    } else if (url.startsWith("/brand")) {
      //brand
      resolve([{
        title: "Brand Guidelines - Geoji",
        description: "Marketing Materials for Geoji. Download Geoji logos, assets, brand guidelines, and colors.",
        keywords: "Geoji, founders, who created, mission statement, goals, about, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise",
        og: {
          title: "Brand Guidelines",
          type: "website",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
          url: "https://www.geoji.com/brand",
          description: "Marketing Materials for Geoji. Download Geoji logos, assets, brand guidelines, and colors."
        },
        richData: {},
        sandbox: "1",
        appclips: "0",
      }, false])
    } else if (url.startsWith("/reachout")) {
      //reachout
      resolve([{
        title: "Reachout and Get Started - Geoji",
        description: "Geoji will bring you more sales and all you and your team need is your phone. Put our expertise to use with a quick call.",
        keywords: "Geoji, founders, who created, mission statement, goals, about, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise",
        og: {
          title: "Reachout and Get Started",
          type: "website",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
          url: "https://www.geoji.com/reachout",
          description: "Geoji will bring you more sales and all you and your team need is your phone. Put our expertise to use with a quick call."
        },
        richData: {},
        sandbox: "1",
        appclips: "0",
      }, false])
    } else if (url.startsWith("/download")) {
      //reachout
      resolve([{
        title: "Download Geoji for iOS and Android",
        description: "Geoji will bring you more sales and all you and your team need is your phone. Put our expertise to use with a quick call.",
        keywords: "Geoji, founders, who created, mission statement, goals, about, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise",
        og: {
          title: "Download Geoji for iOS and Android",
          type: "website",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
          url: "https://www.geoji.com/download",
          description: "Create your on-demand business or event in minutes and start making money with Geoji."
        },
        richData: {},
        sandbox: "1",
        appclips: "0",
      }, false])
    } else {
      //default - home screen
      resolve([{
        title: "Geoji - Sell Digital Tickets for All Things",
        description: "Create & Discover Unique Moments with Geoji. Start your own business today with Geoji in less than one minute. Drop a pin, set the emoji, and start selling entry to your event, merchandise, rentals, etc.",
        keywords: "Geoji, when where what, 365 things austin, Austin TX, do512, native hostel, wanderlust wine, eventbright, ticketing software, ticketing platform, sell merchandise",
        og: {
          title: "Sell Digital Tickets for All Things",
          type: "website",
          image: "https://galaxy.darwincloud.com/Apps/fs/19/Resources/GeojiSimple.jpg",
          url: "https://www.geoji.com/",
          description: "You have been invited!"
        },
        /*richData: {
          "@context": "https://schema.org",
          "@type": "Organization",
          "email": "hello@geoji.com",
          "legalName": "Geoji Inc",
          "location": "Austin, TX",
          "name": "Geoji",
          "url": "https://www.geoji.com",
          "logo": "https://galaxy.darwincloud.com/Apps/fs/19/Resources/logo.png"
        },*/
        sandbox: "1",
        appclips: "0",
      }, false])
    }
  })
}
