import React from 'react';
import $ from 'jquery';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

// import the Darwin API classes
import API from '../API.js';
import logo from '../images/GeojiLogoGreenBlue.svg';
import ImageBack from '../images/Back.svg';
import ImageGeojiTopLogo from '../images/logos/AppIconTextBlackNoBorder.svg';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export class Authorize extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    let newState = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      error: false,
      creatingAccount: false,
      authVersion: "v7-0",

      view: "Login",
      subview: "Phone",
      csrf: "",

      subloading: false,//subloading or not
      suberror: false,//error message if there is one.
    }

    this.state = newState

    this.submitForm = this.submitForm.bind(this)
    this.formChanged = this.formChanged.bind(this)
    this.shakeTheButton = this.shakeTheButton.bind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {

    let newUserID = (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.id) ? this.props.userInfo.user.id : false
    let oldUserID = (prevProps.userInfo && prevProps.userInfo.user && prevProps.userInfo.user.id) ? prevProps.userInfo.user.id : false
    if (newUserID !== false && newUserID !== oldUserID) {
      console.log("NEW USER INFO: AUTO-SUBMIT THE FORM!", this.props.userInfo)
      if (this.props.tertiaryView !== "approve") {
        this.goToAuth2Step()
      }
    }
  }

  calculateView() {
    //1) Get the csrf token.
    let csrf = ""
    let qparams = Helpers.getAllUrlParams()
    if (qparams["csrf"] && qparams["csrf"].length > 0) {
      csrf = qparams["csrf"]
    } else {
      this.setState({
        error: "CSRF not set"
      })
      //this is an error and we can't do anything about it. Malformed request.
      return
    }
    //First find out if we are showing /authorize or the /authorize/approve page.
    if (this.props.tertiaryView === "approve") {
      //Allow/Deny access to the requesting client.
      let clientName = (qparams["cn"] && qparams["cn"].length > 0) ? qparams["cn"] : ""
      let clientImage = (qparams["ci"] && qparams["ci"].length > 0) ? qparams["ci"] : ""
      let authVersion = (qparams["authVersion"] && qparams["authVersion"].length > 0) ? qparams["authVersion"] : ""

      this.setState({
        "csrf": csrf,
        "subview": "Approve",
        "approveClientName": clientName,
        "approveClientImage": clientImage,
        "authVersion": authVersion,
      })
    } else {
      //Login the user or auto-redirect to /authorizeCustom2 endpoint if logged in already.
      this.setState({
        "csrf": csrf
      }, () => {
        //If logged in - auto submit the form to go to the /authorizeCustom2 endpoint
        let newUserID = (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.id) ? this.props.userInfo.user.id : false
        if (newUserID !== false) {
          //Logged in already - autoSubmit the form.
          console.log("AUTOSUBMIT THE FORM")
          this.goToAuth2Step()
        }
      })
    }
  }

  loadHandle() {
    this.setState({
      loading: true,
      error: false,
    }, () => {
      API.callDarwinAPIUnsecured("GET", "handle/" + this.props.handleID, {}, (result) => {
        //console.log("result", result)
        if ("error" in result) {
          console.log("Error GET Handle", result)
          this.setState({
            loading: false,
            error: "Invalid link.",
          })
          return
        }

        let events = []
        let businesses = []
        for (let i = 0; i < result.data.events.length; i = i + 1) {
          events.push(Helpers.formatGeoji(result.data.events[i]))
        }
        for (let i = 0; i < result.data.businesses.length; i = i + 1) {
          businesses.push(Helpers.formatGeoji(result.data.businesses[i]))
        }
        console.log("events", events)

        //Update the metadata?
        let queryParams = Helpers.getAllUrlParams()
        Helpers.updatePageMeta("/h/" + this.props.handleID, result.data.handle, queryParams)

        this.setState({
          handle: result.data.handle,
          events: events,
          businesses: businesses,
          loading: false,
          error: false,
        })
      })
    })
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Login":
        switch (this.state.subview) {
          case "Phone":
            requiredFields = ["phoneNumber"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          case "Code":
            requiredFields = ["phoneNumber", "code"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          case "Name":
            requiredFields = ["name"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Login":
          switch (this.state.subview) {
            case "Phone":
              this.submitPhoneNumber(data)
              break;
            case "Code":
              this.submitCode(data)
              break;
            case "Name":
              this.updateName(data)
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    let shouldSubmit = false
    this.setState((prevState) => {
      let d = prevState.data
      let newRet = {
        data: d,
        forceCheck: false
      }
      if (name === "phoneNumber" && prevState.subview === "Phone") {
        if (((d[name] && d[name].value && d[name].value.length === 0) || !d[name] || !d[name].value) && value.length >= 10) {
          //should submit
          shouldSubmit = true
        }
      }
      if (name === "code" && prevState.subview === "Code") {
        if (value.length >= 6) {
          //should submit
          shouldSubmit = true
        }
      }
      if (prevState.view === "Purchase" && name === "name" && value !== this.props.userInfo.user.name) {
        newRet.nameSavedCount = 0
      }
      if (prevState.view === "Purchase" && name === "notes" && value !== (this.state.purchase.notes || "")) {
        newRet.notesSavedCount = 0
      }
      d[name] = {
        value: value,
        valid: valid
      }
      newRet.data = d
      return newRet
    }, () => {
      if (shouldSubmit) {
        this.submitForm()
      }
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  Send phone number code.
  */
  submitPhoneNumber(data) {

    this.setState({
      subloading: true,
      suberror: false,
    }, () => {
      //Determine if this is a phone number
      let pn = data.phoneNumber
      if (pn.length === 10) {
        pn = "1" + pn
      }
      let dd = {
        phoneNumber: pn
      }
      if (pn.startsWith("300011122")) {
        //This is a test account
        this.setState({
          subloading: false,
          suberror: false,
          subview: "Code",
          pnState: "12345678901234567890123456789012345678901234567890",
        })
      } else {
        API.callDarwinAPI("POST", "checkPhoneNumber", dd, (result) => {
          if ("error" in result) {
            console.log("User doesn't exist - create account.", result)
            //create new account with phone number.
            let pnState = Math.random().toString(36).replace('0.', '')
            pnState += Math.random().toString(36).replace('0.', '')
            pnState += Math.random().toString(36).replace('0.', '')
            this.setState({
              pnState: pnState,
              creatingAccount: true,
            }, () => {
              API.phoneNumberCreateAccount(pn, pnState, (res2) => {
                if ("error" in res2) {
                  console.log("phoneNumberCreateAccount", res2, pnState)
                  //Show an error for an invalid phone number.
                  this.setState({
                    subloading: false,
                    suberror: "Invalid phone number. Please try again.",
                  })
                  return
                }
                this.setState({
                  subloading: false,
                  subview: "Code",
                  suberror: false,
                })
              })
            })
            return
          }
          console.log("User exists - login.", result)
          //Login the user by phone number.
          let pnState = Math.random().toString(36).replace('0.', '')
          pnState += Math.random().toString(36).replace('0.', '')
          pnState += Math.random().toString(36).replace('0.', '')
          this.setState({
            pnState: pnState,
            creatingAccount: false,
          }, () => {
            API.phoneNumberAuthenticate(pn, pnState, (res2) => {
              if ("error" in res2) {
                console.log("phoneNumberAuthenticate", res2, pnState)
                //show an error for an invalid phone number.
                this.setState({
                  subloading: false,
                  suberror: "Couldn't reach this phone number. Please try again.",
                })
                return
              }
              this.setState({
                subloading: false,
                subview: "Code",
                suberror: false,
              })
            })
          })
        })
      }
    })
  }

  /*
  Resend code to login/create account.
  */
  resendCode() {

    this.setState({
      subloading: true,
      suberror: false,
    }, () => {
      //Determine if this is a phone number
      let pn = this.state.data.phoneNumber.value
      if (pn.length === 10) {
        pn = "1" + pn
      }
      if (pn.startsWith("300011122")) {
        //This is a test account
        this.setState({
          subloading: false,
        })
      } else {
        if (this.state.creatingAccount) {
          API.phoneNumberCreateAccountResend("text", this.state.pnState, (res2) => {
            if ("error" in res2) {
              console.log("phoneNumberCreateAccountResend", res2, this.state.pnState)
              //show an error for an invalid phone number.
              this.setState({
                subloading: false,
                suberror: "Couldn't reach this phone number. Please try again."
              })
              return
            }
            //success
            this.setState({
              subloading: false,
              suberror: false,
            })
          })
        } else {
          API.phoneNumberAuthenticateResend("text", this.state.pnState, (res2) => {
            if ("error" in res2) {
              console.log("phoneNumberAuthenticateResend", res2, this.state.pnState)
              //show an error for an invalid phone number.
              this.setState({
                subloading: false,
                suberror: "Couldn't reach this phone number. Please try again."
              })
              return
            }
            //success
            this.setState({
              subloading: false,
              suberror: false,
            })
          })
        }
      }
    })
  }

  /*
  After logging in, this method will redirect to the auth api to verify the code
  */
  goToAuth2Step() {
    console.log("Should go to the auth 2 step by submitting a post form.")

    let url = API.baseURL() + 'authorizeCustom2';
    let form = $('<form action="' + url + '" method="post">' +
    '<input type="hidden" name="csrf" value="' + this.state.csrf + '">' +
    '<input type="hidden" name="authorization" value="' + API.getAccessToken() + '">' +
    '<input type="hidden" name="xcsrf" value="' + API.getCSRFToken() + '">' +
    '</form>')
    $('body').append(form);
    form.trigger("submit")
  }

  /*
  After granting access or not, this will either show an error or return
  the user to the client with an access code.
  */
  accessGrant(granted) {
    if (!granted) {
      console.log("Not Granted")
      this.setState({
        error: "Access not granted"
      })
      return
    }
    //Go to auth step 3 which will return a code to the user.
    let url = "https://auth.app.darwincloud.com/auth/" + this.state.authVersion + '/authorize3';
    let form = $('<form action="' + url + '" method="post">' +
    '<input type="hidden" name="csrf" value="' + this.state.csrf + '">' +
    '<input type="hidden" name="style" value="light">' +
    '<input type="hidden" name="accepted" value="yes">' +
    '</form>')
    $('body').append(form);
    form.trigger("submit")
  }

  /*
  Send verification code to login the user.
  */
  submitCode(data) {

    let successFunc = () => {
      //success - load the user.
      this.props.reloadUser(() => {
        console.log("User loaded", this.props.userInfo)

        //go to the name form if necessary.
        let userName = ""
        if (this.props.userInfo.user.name && this.props.userInfo.user.name.length > 0) {
          userName = this.props.userInfo.user.name
        }
        if (userName.length === 0) {
          //name not set, we need to show the name form.
          this.setState({
            subview: "Name",
            subloading: false,
            suberror: false,
          })
        } else {
          //we have already set the name, now go to the dashboard.
          this.goToAuth2Step()
        }
      })
    }

    this.setState({
      subloading: true,
      suberror: false,
    }, () => {
      //Determine if this is a phone number
      let pn = data.phoneNumber
      if (pn.length === 10) {
        pn = "1" + pn
      }
      if (pn.startsWith("300011122")) {
        //This is a test account
        if (data.code === "111222") {
          API.manualLogin(pn, "GeojiTestAccount!", (result) => {

            if ("error" in result) {
              console.log("phone number code check", result)
              //show an error for an invalid code.
              this.setState({
                subloading: false,
                suberror: "Invalid code. Please try again.",
              })
              return
            }
            successFunc()
          })
        } else {
          this.setState({
            subloading: false,
            suberror: "Invalid code. Please try again.",
          })
        }
      } else {
        if (this.state.creatingAccount) {
          //check the code.
          API.phoneNumberCreateAccountVerify(data.code, this.state.pnState, (result) => {
            if ("error" in result) {
              console.log("phoneNumberCreateAccountVerify", result)
              //show an error for an invalid code.
              this.setState({
                subloading: false,
                suberror: "Invalid code. Please try again.",
              })
              return
            }
            //success - now create the account.
            API.phoneNumberCreateAccountFinal("", "", this.state.pnState, (res2) => {
              if ("error" in res2) {
                console.log("phoneNumberCreateAccountFinal", res2)
                //show an error for an invalid code.
                this.setState({
                  subloading: false,
                  suberror: "Internal error, please try again.",
                })
                return
              }
              //success - login the user
              API.manualLogin(pn, data.code + "_" + this.state.pnState, (res3) => {
                if ("error" in res3) {
                  console.log("Manual Login Error", res3)
                  //show an error.
                  this.setState({
                    subloading: false,
                    suberror: "Internal error, please try again.",
                  })
                  return
                }
                //success - load the user.
                successFunc()
              })
            })
          })
        } else {
          API.manualLogin(pn, data.code + "_" + this.state.pnState, (result) => {
            if ("error" in result) {
              console.log("Manual Login Error", result)
              //show an error for an invalid code.
              this.setState({
                subloading: false,
                suberror: "Invalid code, please try again.",
              })
              return
            }
            //success - load the user.
            successFunc()
          })
        }
      }
    })
  }

  /*
  Updates the name for the user.
  */
  updateName(data = {}) {
    if (data.name && data.name.length > 0) {
      //Update the name.
      let parts = data.name.split(" ")
      let shortName = parts[0]
      let dd = {
        name: data.name,
        shortName: shortName,
      }
      API.callDarwinAPI("PUT", "userProfile", dd, (result) => {
        if ("error" in result) {
          console.log("Couldn't update user", result)
          return
        }
        console.log("Updated user name to:", dd.name)

        //refresh the user's data with the new name, then go to the dashboard.
        this.props.loadUserInfo(() => {
          this.goToAuth2Step()
        })
      })
    }
  }

  openTerms() {
    window.open("/terms", '_blank')
  }

  openPrivacy() {
    window.open("/privacy", '_blank')
  }

  backToPhoneNumber() {
    this.setState({
      view: "Login",
      subview: "Phone",
      data: {},
      subloading: false,
      suberror: false,
    })
  }

  /*
  * Shows a confirmation dialog with an optional callback passing true or false
  * to whether they have accepted the action
  */
  showConfirmation(title, description, accept, deny, action) {
    this.setState({
      confirmation: true,
      confirmationTitle: title,
      confirmationDescription: description,
      confirmationAccept: accept,
      confirmationDeny: deny,
      confirmationAction: (result) => {
        this.setState({
          confirmation: false
        })
        action(result)
      }
    })
  }

  /*
  Shows an error overlay that can be dismissed.
  */
  showError(title, description) {
    this.setState({
      showError: true,
      showErrorTitle: title,
      showErrorDescription: description,
      showErrorAction: (result) => {
        this.setState({
          showError: false
        })
      }
    })
  }

  render() {

    let confirmationProps = {
      main: "Confirmation",
      title: this.state.confirmationTitle,
      description: this.state.confirmationDescription,
      accept: this.state.confirmationAccept,
      deny: this.state.confirmationDeny,
      action: this.state.confirmationAction
    }

    let showErrorProps = {
      main: "Error",
      title: this.state.showErrorTitle,
      description: this.state.showErrorDescription,
      dismiss: this.state.showErrorAction
    }

    return (
      <div className="Geoji">
        {/* Confirmation */}
        { this.state.confirmation &&
          <Components.Confirmation {...confirmationProps} />
        }
        {/* Error Popopver */}
        { this.state.showError &&
          <Components.FullScreenAlert {...showErrorProps} />
        }
        {/* Loading Indicator */}
        { this.state.loading &&
          <div className="GeojiLoading">
            <img src={logo} className="GeojiLoadingLogo" alt="logo" />
          </div>
        }
        {/* Error Handling */}
        { !this.state.loading && this.state.error !== false &&
          <Components.GeojiError error={this.state.error} message="Couldn't authorize the application. Please try again or reachout to support@geoji.com for help." />
        }
        {/* Geoji View */}
        { !this.state.loading && this.state.error === false &&
          <div className="GeojiCn">
            { this.state.view === "Login" &&
              <div className="GeojiCnInner">
                { this.state.subview === "Phone" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar GeojiCnInnerPromptTopBarMargin">
                      <img src={ImageGeojiTopLogo} alt="Geoji" className="GeojiCnInnerPromptTopBarGeojiLogo" />
                    </div>
                    <div className="GeojiCnInnerPromptEmoji">
                      <span role="img" aria-label="phone">📱</span>
                    </div>
                    <div className="GeojiCnInnerPromptTitle">
                      Enter your phone number
                    </div>
                    { this.state.subloading &&
                      <div className="GeojiCnInnerPromptLoading">
                        <img src={logo} className="GeojiCnInnerPromptLoadingLogo" alt="logo" />
                      </div>
                    }
                    { !this.state.subloading &&
                      <div>
                        <div className="GeojiCnInnerPromptField">
                          <div className="InputDiv">
                            <Components.InputBottomLine type="text" name="phoneNumber" placeholder="phone number" validation="phoneNumber10" required="true" validateTyping={false} autoComplete="tel"
                              tabIndex="1"
                              value={this.state.data.phoneNumber ? this.state.data.phoneNumber.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          </div>
                        </div>
                        { this.state.suberror !== false &&
                          <div className="GeojiCnInnerPromptError">
                            {this.state.suberror}
                          </div>
                        }
                        <div className="GeojiCnInnerPromptDescription">
                          By continuing, you are agreeing to Geoji's
                          <br/>
                          <div className="GeojiCnInnerPromptDescriptionLink" onClick={this.openTerms.bind(this)}>
                            Terms of Service
                          </div>
                          &nbsp;&&nbsp;
                          <div className="GeojiCnInnerPromptDescriptionLink" onClick={this.openPrivacy.bind(this)}>
                            Privacy Policy
                          </div>
                        </div>
                        <div className="GeojiCnInnerPromptButton" onClick={this.submitForm}>
                          Submit
                        </div>
                      </div>
                    }
                  </div>
                }
                { this.state.subview === "Code" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar GeojiCnInnerPromptTopBarMargin">
                      <img src={ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.backToPhoneNumber.bind(this)} />
                      <img src={ImageGeojiTopLogo} alt="Geoji" className="GeojiCnInnerPromptTopBarGeojiLogo" />
                      <div className="GeojiCnInnerPromptTopBarRight"></div>
                    </div>
                    <div className="GeojiCnInnerPromptEmoji">
                      <span role="img" aria-label="key">🔑</span>
                    </div>
                    <div className="GeojiCnInnerPromptTitle">
                      Enter 6-digit verification code
                    </div>
                    { this.state.subloading &&
                      <div className="GeojiCnInnerPromptLoading">
                        <img src={logo} className="GeojiCnInnerPromptLoadingLogo" alt="logo" />
                      </div>
                    }
                    { !this.state.subloading &&
                      <div>
                        <div className="GeojiCnInnerPromptField">
                          <div className="InputDiv">
                            <Components.InputBottomLine type="text" name="code" placeholder="verification code" validation="code" required="true" validateTyping={false} autoComplete="one-time-code"
                              tabIndex="1"
                              value={this.state.data.code ? this.state.data.code.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          </div>
                        </div>
                        { this.state.suberror !== false &&
                          <div className="GeojiCnInnerPromptError">
                            {this.state.suberror}
                          </div>
                        }
                        <div className="GeojiCnInnerPromptDescription">
                          A verification code was sent to mobile number
                          <br/>
                          {this.state.data.phoneNumber && Helpers.parsePhoneNumber(this.state.data.phoneNumber.value)}
                          <br/>
                          Didn't receive a code?&nbsp;
                          <div className="GeojiCnInnerPromptDescriptionLink" onClick={this.resendCode.bind(this)}>
                            Resend Code
                          </div>
                        </div>
                        <div className="GeojiCnInnerPromptButton" onClick={this.submitForm}>
                          Submit
                        </div>
                      </div>
                    }
                  </div>
                }
                { this.state.subview === "Name" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar GeojiCnInnerPromptTopBarMargin">
                      <img src={ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.props.changeView.bind(this, "Home")} />
                      <img src={ImageGeojiTopLogo} alt="Geoji" className="GeojiCnInnerPromptTopBarGeojiLogo" />
                      <div className="GeojiCnInnerPromptTopBarRight"></div>
                    </div>
                    <div className="GeojiCnInnerPromptEmoji">
                      <span role="img" aria-label="token">🎟</span>
                    </div>
                    <div className="GeojiCnInnerPromptTitle">
                      Your Name
                    </div>
                    { this.state.subloading &&
                      <div className="GeojiCnInnerPromptLoading">
                        <img src={logo} className="GeojiCnInnerPromptLoadingLogo" alt="logo" />
                      </div>
                    }
                    { !this.state.subloading &&
                      <div>
                        <div className="GeojiCnInnerPromptField">
                          <div className="InputDiv">
                            <Components.InputBottomLine type="text" name="name" placeholder="full name" validation="text" required="true" validateTyping={false} autoComplete="name"
                              tabIndex="1"
                              value={this.state.data.name ? this.state.data.name.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                          </div>
                        </div>
                        { this.state.suberror !== false &&
                          <div className="GeojiCnInnerPromptError">
                            {this.state.suberror}
                          </div>
                        }
                        {/*<div className="GeojiCnInnerPromptDescription">
                          You will say this to the vendor or event to redeem your tokens.
                        </div>*/}
                        <div className="GeojiCnInnerPromptButton GeojiCnInnerPromptButton2" onClick={this.submitForm}>
                          Submit
                        </div>
                      </div>
                    }
                  </div>
                }
                { this.state.subview === "Approve" &&
                  <div className="GeojiCnInnerPrompt">
                    <div className="GeojiCnInnerPromptTopBar GeojiCnInnerPromptTopBarMargin">
                      <img src={ImageGeojiTopLogo} alt="Geoji" className="GeojiCnInnerPromptTopBarGeojiLogo" />
                    </div>
                    <div className="GeojiCnInnerPromptClient">
                      <img src={this.state.approveClientImage} className="GeojiCnInnerPromptClientImage" alt={this.state.approveClientName} />
                      <div className="GeojiCnInnerPromptClientName">
                          {this.state.approveClientName}
                      </div>
                    </div>
                    <div className="GeojiCnInnerPromptAccess">
                      would like to access your Geoji account.
                    </div>
                    <div className="GeojiCnInnerPromptAccessButtons">
                      <div className="GeojiCnInnerPromptAccessButton" onClick={this.accessGrant.bind(this, false)}>
                        Deny
                      </div>
                      <div className="GeojiCnInnerPromptAccessButton GeojiCnInnerPromptAccessButtonAllow" onClick={this.accessGrant.bind(this, true)}>
                        Allow
                      </div>
                    </div>
                    <div className="GeojiCnInnerPromptAccessBottom">
                      By clicking Allow, you are allowing {this.state.approveClientName} to use your information in accordance with their terms of service and privacy policy.
                    </div>
                    <div className="GeojiCnInnerPromptAccessCopyright">
                      © {} Geoji, Inc. • All Rights Reserved.
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    )
  }
}
