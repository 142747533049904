import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as App from './App';
// import * as Helpers from './resources/Helpers.js';
import * as serviceWorker from './serviceWorker';

let appProps = {}

/*let handle = {
  "events": [
    {
      "geojiID": "2004",
      "creator": "49aaa7d2e02a11ebb9470eda07841c88",
      "emoji": "🤣",
      "baseEmoji": "🤣",
      "title": "High Smiles Comedy",
      "description": "An Elevated Wellness Comedy Show hosted by Shannon Simms.",
      "photoURL": "https://galaxy.darwincloud.com/Apps/fs/19/Geojis/2b49706e1cd711ebb9470eda07841c88/e6603e3360f830660ca73df1c9edec70.jpg",
      "who": null,
      "what": null,
      "when": "5:30 - 8:00 pm",
      "where": "4700 W Guadalupe St, Austin, TX 78751 ",
      "websiteURL": null,
      "contactInfo": null,
      "startDate": "2021-08-14",
      "endDate": "2021-08-14",
      "eventDates": "2021-08-14",
      "likeCount": "1",
      "flagCount": "0",
      "originalExpirationDate": "2021-08-15 10:00:00",
      "expirationDate": "2021-08-15 10:00:00",
      "creationTimestamp": "2021-07-08 19:59:13",
      "lastUpdateTimestamp": "2021-08-09 19:54:38",
      "imageScale": "1",
      "imageOffsetX": "0",
      "imageOffsetY": "0",
      "imageSizeWidth": "0",
      "imageSizeHeight": "0",
      "activeNow": "0",
      "activeNowTitle": "",
      "scrapeEventID": null,
      "adminID": null,
      "private": "0",
      "sandbox": "0",
      "deleted": "0",
      "notesTitle": null,
      "notesPlaceholder": null,
      "notesDescription": null,
      "sendEmailReceipt": "0",
      "checkoutMethods": "CPZ",
      "geojiLongitude": "-97.7327187",
      "geojiLatitude": "30.3154028",
      "distance": "0.0",
      "likeTimestamp": null,
      "flagTimestamp": null
    },
    {
      "geojiID": "2013",
      "creator": "49aaa7d2e02a11ebb9470eda07841c88",
      "emoji": "🎤",
      "baseEmoji": "🎤",
      "title": "Lo-Fi ‘n’ Wine",
      "description": null,
      "photoURL": "https://galaxy.darwincloud.com/Apps/fs/19/Geojis/2b49706e1cd711ebb9470eda07841c88/2a5fcca81cb51443820a98f7bc0c26a7.jpg",
      "who": null,
      "what": null,
      "when": null,
      "where": "610 N I-35, Austin, TX 78701 ",
      "websiteURL": null,
      "contactInfo": "pmjams",
      "startDate": "2021-08-21",
      "endDate": "2021-08-21",
      "eventDates": "2021-08-21",
      "likeCount": "0",
      "flagCount": "0",
      "originalExpirationDate": "2021-08-22 10:00:00",
      "expirationDate": "2021-08-22 10:00:00",
      "creationTimestamp": "2021-08-10 20:43:45",
      "lastUpdateTimestamp": "2021-08-12 17:31:25",
      "imageScale": "1",
      "imageOffsetX": "0",
      "imageOffsetY": "0",
      "imageSizeWidth": "0",
      "imageSizeHeight": "0",
      "activeNow": "0",
      "activeNowTitle": "",
      "scrapeEventID": null,
      "adminID": null,
      "private": "0",
      "sandbox": "0",
      "deleted": "0",
      "notesTitle": null,
      "notesPlaceholder": null,
      "notesDescription": null,
      "sendEmailReceipt": "0",
      "checkoutMethods": "CPZ",
      "geojiLongitude": "-97.735007372539",
      "geojiLatitude": "30.266317325364",
      "distance": "0.0",
      "likeTimestamp": null,
      "flagTimestamp": null
    }
  ],
  "businesses": [],
  "handle": {
    "link": "pmjams",
    "name": "PM Jams",
    "bio": "Bringing happiness to the world since 2019. Making music and manifesting greatness. Join us at an event and have a blast.",
    "photoURL": "https://galaxy.darwincloud.com/Apps/fs/19/Resources/PMJams.png",
    "backgroundURL": "https://galaxy.darwincloud.com/Apps/fs/19/Resources/CityScape.jpg",
    "creationTimestamp": "2021-08-12 20:52:30",
    "lastUpdateTimestamp": "2021-08-12 22:50:50"
  }
}
for (let i = 0; i < handle.events.length; i = i + 1) {
  handle.events[i] = Helpers.formatGeoji(handle.events[i])
}
for (let i = 0; i < handle.businesses.length; i = i + 1) {
  handle.businesses[i] = Helpers.formatGeoji(handle.businesses[i])
}
appProps.handle = handle*/

/*let geoji = {
  "geojiID": "1817",
  "creator": "b09f233f1d9811ebb9470eda07843c88",
  "emoji": "🍋",
  "baseEmoji": "🍋",
  "title": "Lemonade Stand",
  "description": "Tap on the plus button above and buy 1 lemonade to simulate Apple Pay. Buy 2 lemonades for Stripe Credit Card test.",
  "photoURL": null,
  "who": null,
  "what": null,
  "when": "all day",
  "where": "Austin, TX 78704 ",
  "websiteURL": "darwincloud.com",
  "contactInfo": "8165476500",
  "startDate": "2021-07-29",
  "endDate": "3000-01-01",
  "eventDates": "",
  "likeCount": "1",
  "flagCount": "0",
  "originalExpirationDate": "3000-01-02 10:00:00",
  "expirationDate": "3000-01-02 10:00:00",
  "creationTimestamp": "2021-05-06 20:58:24",
  "lastUpdateTimestamp": "2021-07-29 22:39:01",
  "imageScale": "1",
  "imageOffsetX": "0",
  "imageOffsetY": "0",
  "imageSizeWidth": "0",
  "imageSizeHeight": "0",
  "activeNow": "0",
  "activeNowTitle": "",
  "scrapeEventID": null,
  "adminID": null,
  "private": "0",
  "sandbox": "1",
  "deleted": "0",
  "notesTitle": "Email",
  "notesPlaceholder": "email@address.com",
  "notesDescription": " ",
  "sendEmailReceipt": "1",
  "checkoutMethods": "CPZ",
  "geojiLongitude": "-97.752349955308",
  "geojiLatitude": "30.261771708502",
  "distance": "0.0",
  "likeTimestamp": null,
  "flagTimestamp": null,
  "filters": [],
  "tokens": [
    {
      "id": "110",
      "geojiID": "1817",
      "name": "🍋Lemonade",
      "quantity": "0",
      "priceCents": "300",
      "frozen": "0",
      "tokensSold": "430",
      "index": "0",
      "theme": "default",
      "creationTimestamp": "2021-05-06 20:58:24",
      "lastUpdateTimestamp": "2021-08-09 21:14:31",
      "timezoneOffset": "-05:00"
    },
    {
      "id": "112",
      "geojiID": "1817",
      "name": "🍓Strawberry Lemonade",
      "quantity": "0",
      "priceCents": "0",
      "frozen": "0",
      "tokensSold": "87",
      "index": "1",
      "theme": "default",
      "creationTimestamp": "2021-05-11 23:38:58",
      "lastUpdateTimestamp": "2021-08-05 23:10:00",
      "timezoneOffset": "-05:00"
    },
    {
      "id": "150",
      "geojiID": "1817",
      "name": "💰Tip Jar",
      "quantity": "0",
      "priceCents": "100",
      "frozen": "0",
      "tokensSold": "84",
      "index": "5",
      "theme": "tipjar",
      "creationTimestamp": "2021-06-10 17:31:54",
      "lastUpdateTimestamp": "2021-08-07 00:00:42",
      "timezoneOffset": "-05:00"
    },
    {
      "id": "199",
      "geojiID": "1817",
      "name": "🍒 Cherry Lemonade",
      "quantity": "0",
      "priceCents": "100",
      "frozen": "0",
      "tokensSold": "10",
      "index": "2",
      "theme": "default",
      "creationTimestamp": "2021-07-29 22:39:01",
      "lastUpdateTimestamp": "2021-08-05 23:08:46",
      "timezoneOffset": "-05:00"
    },
    {
      "id": "200",
      "geojiID": "1817",
      "name": "🍉 Watermelon Lemonade",
      "quantity": "0",
      "priceCents": "200",
      "frozen": "0",
      "tokensSold": "9",
      "index": "3",
      "theme": "default",
      "creationTimestamp": "2021-07-29 22:39:01",
      "lastUpdateTimestamp": "2021-08-07 00:00:42",
      "timezoneOffset": "-05:00"
    },
    {
      "id": "201",
      "geojiID": "1817",
      "name": "🥨 Soft Pretzel",
      "quantity": "100",
      "priceCents": "400",
      "frozen": "0",
      "tokensSold": "2",
      "index": "4",
      "theme": "default",
      "creationTimestamp": "2021-07-29 22:39:01",
      "lastUpdateTimestamp": "2021-08-05 22:19:47",
      "timezoneOffset": "-05:00"
    }
  ]
}
geoji = Helpers.formatGeoji(geoji)
appProps.geoji = geoji
appProps.location = "https://geoji.com/g/1817"*/

ReactDOM.render(<App.App {...appProps} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
