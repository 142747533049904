import React from 'react';
//import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
//import * as Helpers from '../Helpers.js';
import * as HomePage from './HomePage.js';

import ImageLemon from '../images/shots/Lemon.png';


//import the Darwin API classes
//import API from '../API.js';

export class About extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",
    }
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
  }

  render() {

    return (
      <div className="AboutPage">

        {/* Page Header */}
        <HomePage.PageHeader />

        <HomePage.HomeHeader selected="about" openCloseMenu={this.props.openCloseMenu} userInfo={this.props.userInfo} />

        <div className="AboutPageContent">
          <div className="AboutPageLeft">
            <h1 className="AboutPageHeader1">
              Creator's
              <br/>
              <span className="AboutPageHeader2">
                Economy
              </span>
            </h1>
            <div className="AboutPageHeaderQuote">
              When life gives you lemons, start a lemonade stand.
            </div>
            <img className="AboutPageImage" src={ImageLemon} alt="Lemon" />
          </div>
          <div className="AboutPageRight">
            <h2 className="AboutPageSectionTitle">
              Artists, Musicians, & Creatives
            </h2>
            <div className="AboutPageSectionBody">
              are unbelievably productive economically and vital to innovation, but it is very hard for them to monetize <span role="img" aria-label="money">💰</span> their productivity <span role="img" aria-label="work">💪</span>
            </div>
            <h2 className="AboutPageSectionTitle">
              That is why we created Geoji
            </h2>
            <div className="AboutPageSectionBody">
              allowing creators to monetize with ease by simply selling <span className="AboutPageSectionBodyDTFAT">Digital Tickets for All Things</span> and allowing supporters to easily discover <span role="img" aria-label="discover">🕵</span>️ and directly contribute to creators within their community <span role="img" aria-label="community">🏡</span>
            </div>
            <h2 className="AboutPageSectionTitle">
              Events, PopUps, Experiences
            </h2>
            <div className="AboutPageSectionBody">
              and so much more... the possibilities are truly endless in the new Creator's Economy that has been brought to you by <span className="AboutPageSectionBodyGeoji">Geoji</span>
            </div>
            <h3 className="AboutPageSectionFooter">
              We know how good it feels to
              <br/>
              <span className="AboutPageSectionFooterHighlight">do what you love.</span>
            </h3>
          </div>
        </div>

        {/* Footer */}
        <Components.GeojiFooter nohide={true} />
      </div>
    )
  }
}
