import React from 'react';
//import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
//import * as Helpers from '../Helpers.js';
import * as HomePage from './HomePage.js';

//import the Darwin API classes
//import API from '../API.js';

export class Unsubscribed extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",
    }
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
  }

  render() {

    return (
      <div className="UnsubscribedPage">
        <HomePage.HomeHeader selected="unsubscribed" openCloseMenu={this.props.openCloseMenu} userInfo={this.props.userInfo} />

        <div className="UnsubscribedPageContent">
          <h1 className="UnsubscribedPageHeader">
            You Are Unsubscribed
          </h1>
          <div className="UnsubscribedPageBody">
            You have been removed from the mailing list and will no longer receive any related emails.
          </div>
        </div>

        {/* Footer */}
        <Components.GeojiFooter nohide={true} />
      </div>
    )
  }
}
