import React from 'react';
//import autoBind from 'react-autobind';
import Script from 'react-load-script';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';
import * as HomePage from './HomePage.js';

import logo from '../images/GeojiLogoWhite.svg';
import ImageBack from '../images/Back.svg';


//import the Darwin API classes
import API from '../API.js';

export class Bank extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",
      subloading: false,
      suberror: false,
      plaidToken: "loading",
    }

    this.submitForm = this.submitForm.bind(this)
    this.formChanged = this.formChanged.bind(this)
    this.shakeTheButton = this.shakeTheButton.bind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }

    let newUserID = (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.id) ? this.props.userInfo.user.id : false
    let oldUserID = (prevProps.userInfo && prevProps.userInfo.user && prevProps.userInfo.user.id) ? prevProps.userInfo.user.id : false
    if (newUserID !== false && newUserID !== oldUserID) {
      console.log("NEW USER INFO", this.props.userInfo)
      this.getPlaidToken()
      return
    }
  }

  calculateView() {

  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Login":
        switch (this.state.subview) {
          case "Phone":
            requiredFields = ["phoneNumber"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          case "Code":
            requiredFields = ["phoneNumber", "code"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Login":
          switch (this.state.subview) {
            case "Phone":
              this.submitPhoneNumber(data)
              break;
            case "Code":
              this.submitCode(data)
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    let shouldSubmit = false
    this.setState((prevState) => {
      let d = prevState.data
      let newRet = {
        data: d,
        forceCheck: false
      }
      if (name === "phoneNumber" && prevState.subview === "Phone") {
        if (((d[name] && d[name].value && d[name].value.length === 0) || !d[name] || !d[name].value) && value.length >= 10) {
          //should submit
          shouldSubmit = true
        }
      }
      if (name === "code" && prevState.subview === "Code") {
        if (value.length >= 6) {
          //should submit
          shouldSubmit = true
        }
      }
      if (prevState.view === "Purchase" && name === "name" && value !== this.props.userInfo.user.name) {
        newRet.nameSavedCount = 0
      }
      if (prevState.view === "Purchase" && name === "notes" && value !== (this.state.purchase.notes || "")) {
        newRet.notesSavedCount = 0
      }
      d[name] = {
        value: value,
        valid: valid
      }
      newRet.data = d
      return newRet
    }, () => {
      if (shouldSubmit) {
        this.submitForm()
      }
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  Shows an error overlay that can be dismissed.
  */
  showError(title, description) {
    this.setState({
      showError: true,
      showErrorTitle: title,
      showErrorDescription: description,
      showErrorAction: (result) => {
        this.setState({
          showError: false
        })
      }
    })
  }

  openTerms() {
    window.open("/terms", '_blank')
  }

  openPrivacy() {
    window.open("/privacy", '_blank')
  }

  showLogin() {

    this.setState({
      view: "Login",
      subview: "Phone",
      data: {},
      subloading: false,
      suberror: false,
    })
  }

  backHome() {

    this.setState({
      view: "Home",
      subview: "Home",
      data: {},
      subloading: false,
      suberror: false,
    })
  }

  backToPhoneNumber() {
    this.setState({
      view: "Login",
      subview: "Phone",
      data: {},
      subloading: false,
      suberror: false,
    })
  }

  getPlaidToken() {

    //determine if we are from oauth.
    let params = Helpers.getAllUrlParams()
    console.log("query params", params)
    let plaidCookie = API.getCookie("plaidToken")
    if (params.oauth_state_id && params.oauth_state_id.length > 0 && plaidCookie) {
      //back from oauth
      console.log("Back from OAuth")
      this.setState({
        plaidToken: plaidCookie
      })
    } else {
      let dd = {
      }
      API.callDarwinAPI("GET", "plaidToken", dd, (result) => {
        if ("error" in result) {
          console.log("Error Getting Plaid Token", result)
          if (result.error === "Out of Links") {
            this.showError("Contact Support", "To prevent fraud, after your bank account has been switched 3 times, we require that you reach out to support@geoji.com to switch your bank account. This helps keeps your account secure and prevents fraud.")
          } else {
            this.showError("Oh No!", "We couldn't communicate with Plaid. Please check your internet connection and try again.")
          }
          return
        }
        //console.log(result.data)
        API.setCookie("plaidToken", result.data.plaidToken, 60*30)//30 minutes.
        //update the plaid token
        this.setState({
          plaidToken: result.data.plaidToken
        })
      })
    }
  }

  /*
  Send phone number code.
  */
  submitPhoneNumber(data) {

    this.setState({
      subloading: true,
      suberror: false,
    }, () => {
      //Determine if this is a phone number
      let pn = data.phoneNumber
      if (pn.length === 10) {
        pn = "1" + pn
      }
      let dd = {
        phoneNumber: pn
      }
      if (pn.startsWith("300011122")) {
        //This is a test account
        this.setState({
          subloading: false,
          suberror: false,
          subview: "Code",
          pnState: "12345678901234567890123456789012345678901234567890",
        })
      } else {
        API.callDarwinAPI("POST", "checkPhoneNumber", dd, (result) => {
          if ("error" in result) {
            console.log("User doesn't exist - create account.", result)
            //create new account with phone number.
            let pnState = Math.random().toString(36).replace('0.', '')
            pnState += Math.random().toString(36).replace('0.', '')
            pnState += Math.random().toString(36).replace('0.', '')
            this.setState({
              pnState: pnState,
              creatingAccount: true,
            }, () => {
              API.phoneNumberCreateAccount(pn, pnState, (res2) => {
                if ("error" in res2) {
                  console.log("phoneNumberCreateAccount", res2, pnState)
                  //Show an error for an invalid phone number.
                  this.setState({
                    subloading: false,
                    suberror: "Invalid phone number. Please try again.",
                  })
                  return
                }
                this.setState({
                  subloading: false,
                  subview: "Code",
                  suberror: false,
                })
              })
            })
            return
          }
          console.log("User exists - login.", result)
          //Login the user by phone number.
          let pnState = Math.random().toString(36).replace('0.', '')
          pnState += Math.random().toString(36).replace('0.', '')
          pnState += Math.random().toString(36).replace('0.', '')
          this.setState({
            pnState: pnState,
            creatingAccount: false,
          }, () => {
            API.phoneNumberAuthenticate(pn, pnState, (res2) => {
              if ("error" in res2) {
                console.log("phoneNumberAuthenticate", res2, pnState)
                //show an error for an invalid phone number.
                this.setState({
                  subloading: false,
                  suberror: "Couldn't reach this phone number. Please try again.",
                })
                return
              }
              this.setState({
                subloading: false,
                subview: "Code",
                suberror: false,
              })
            })
          })
        })
      }
    })
  }

  /*
  Resends the code to the provided phone number.
  */
  resendCode() {

    this.setState({
      subloading: true,
      suberror: false,
    }, () => {
      //Determine if this is a phone number
      let pn = this.state.data.phoneNumber.value
      if (pn.length === 10) {
        pn = "1" + pn
      }
      if (pn.startsWith("300011122")) {
        //This is a test account
        this.setState({
          subloading: false,
        })
      } else {
        if (this.state.creatingAccount) {
          API.phoneNumberCreateAccountResend("text", this.state.pnState, (res2) => {
            if ("error" in res2) {
              console.log("phoneNumberCreateAccountResend", res2, this.state.pnState)
              //show an error for an invalid phone number.
              this.setState({
                subloading: false,
                suberror: "Couldn't reach this phone number. Please try again."
              })
              return
            }
            //success
            this.setState({
              subloading: false,
              suberror: false,
            })
          })
        } else {
          API.phoneNumberAuthenticateResend("text", this.state.pnState, (res2) => {
            if ("error" in res2) {
              console.log("phoneNumberAuthenticateResend", res2, this.state.pnState)
              //show an error for an invalid phone number.
              this.setState({
                subloading: false,
                suberror: "Couldn't reach this phone number. Please try again."
              })
              return
            }
            //success
            this.setState({
              subloading: false,
              suberror: false,
            })
          })
        }
      }
    })
  }

  /*
  Send verification code to login the user.
  */
  submitCode(data) {

    let successFunc = () => {
      //success - load the user.
      this.props.reloadUser(() => {
        console.log("User loaded", this.props.userInfo)
        this.backHome()
      })
    }

    this.setState({
      subloading: true,
      suberror: false,
    }, () => {
      //Determine if this is a phone number
      let pn = data.phoneNumber
      if (pn.length === 10) {
        pn = "1" + pn
      }
      if (pn.startsWith("300011122")) {
        //This is a test account
        if (data.code === "111222") {
          API.manualLogin(pn, "GeojiTestAccount!", (result) => {

            if ("error" in result) {
              console.log("phone number code check", result)
              //show an error for an invalid code.
              this.setState({
                subloading: false,
                suberror: "Invalid code. Please try again.",
              })
              return
            }
            successFunc()
          })
        } else {
          this.setState({
            subloading: false,
            suberror: "Invalid code. Please try again.",
          })
        }
      } else {
        if (this.state.creatingAccount) {
          //check the code.
          API.phoneNumberCreateAccountVerify(data.code, this.state.pnState, (result) => {
            if ("error" in result) {
              console.log("phoneNumberCreateAccountVerify", result)
              //show an error for an invalid code.
              this.setState({
                subloading: false,
                suberror: "Invalid code. Please try again.",
              })
              return
            }
            //success - now create the account.
            API.phoneNumberCreateAccountFinal("", "", this.state.pnState, (res2) => {
              if ("error" in res2) {
                console.log("phoneNumberCreateAccountFinal", res2)
                //show an error for an invalid code.
                this.setState({
                  subloading: false,
                  suberror: "Internal error, please try again.",
                })
                return
              }
              //success - login the user
              API.manualLogin(pn, data.code + "_" + this.state.pnState, (res3) => {
                if ("error" in res3) {
                  console.log("Manual Login Error", res3)
                  //show an error.
                  this.setState({
                    subloading: false,
                    suberror: "Internal error, please try again.",
                  })
                  return
                }
                //success - load the user.
                successFunc()
              })
            })
          })
        } else {
          API.manualLogin(pn, data.code + "_" + this.state.pnState, (result) => {
            if ("error" in result) {
              console.log("Manual Login Error", result)
              //show an error for an invalid code.
              this.setState({
                subloading: false,
                suberror: "Invalid code, please try again.",
              })
              return
            }
            //success - load the user.
            successFunc()
          })
        }
      }
    })
  }

  plaidHandleOnEvent(ev) {
    console.log("plaid event", ev)
  }

  plaidHandleOnExit(ev) {
    console.log("plaid exit", ev)
  }

  plaidHandleOnLoad(ev) {
    console.log("plaid load", ev)
  }

  plaidHandleOnSuccess(publicToken, metadata) {
    console.log("plaid success", publicToken, metadata)
    let dd = {
      "publicToken": publicToken,
    }
    API.callDarwinAPI("POST", "plaidToken", dd, (result) => {
      if ("error" in result) {
        console.log("Error Transfering Plaid Token", result)
        this.showError("Oh No!", "We couldn't communicate with Plaid. Please check your internet connection and try again.")
        return
      }
      console.log(result)
      //success!
      this.setState({
        view: "Success",
        subview: "Home",
      })
    })
  }

  render() {

    let showErrorProps = {
      main: "Error",
      title: this.state.showErrorTitle,
      description: this.state.showErrorDescription,
      dismiss: this.state.showErrorAction
    }

    let params = Helpers.getAllUrlParams()

    return (
      <div className="BankPage">
        <HomePage.HomeHeader selected="bank" openCloseMenu={this.props.openCloseMenu} userInfo={this.props.userInfo} />

        {/* Error Popopver */}
        { this.state.showError &&
          <Components.FullScreenAlert {...showErrorProps} />
        }

        { this.state.view === "Home" &&
          <div className="BankPageContent">
            { params.oauth_state_id &&
              <div className="BankPageContentLink">
                <div className="BankPageContentLinkTitle">
                  Finish Linking Bank Account
                </div>
                <div className="BankPageContentLinkDescription">
                  Tap the button below to finish linking your account.
                </div>

                <PlaidLink
                  token={this.state.plaidToken}
                  onEvent={this.plaidHandleOnEvent.bind(this)}
                  onExit={this.plaidHandleOnExit.bind(this)}
                  onLoad={this.plaidHandleOnLoad.bind(this)}
                  onSuccess={this.plaidHandleOnSuccess.bind(this)}
                  className="BankPageContentLinkButton"
                  style={{}}
                  >
                  <span>
                    Finish Linking Account
                  </span>
                </PlaidLink>
              </div>
            }
            { !params.oauth_state_id && (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.id) && this.props.userInfo.user.bankAccountStatus === "0" &&
              <div className="BankPageContentLink">
                <div className="BankPageContentLinkTitle">
                  Link your Bank Account
                </div>
                { (this.props.userInfo.user.name && this.props.userInfo.user.name.length > 0) &&
                  <div className="BankPageContentLinkName">
                    Hello {this.props.userInfo.user.name},
                  </div>
                }
                <div className="BankPageContentLinkDescription">
                  Tap the button below to link your bank account with Plaid.
                  Plaid is a 3rd party that keeps your information secure.
                </div>

                <PlaidLink
                  token={this.state.plaidToken}
                  onEvent={this.plaidHandleOnEvent.bind(this)}
                  onExit={this.plaidHandleOnExit.bind(this)}
                  onLoad={this.plaidHandleOnLoad.bind(this)}
                  onSuccess={this.plaidHandleOnSuccess.bind(this)}
                  className="BankPageContentLinkButton"
                  style={{}}
                  >
                  <span>
                    Link Bank Account
                  </span>
                </PlaidLink>
              </div>
            }
            { !params.oauth_state_id && (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.id) && this.props.userInfo.user.bankAccountStatus === "1" &&
              <div className="BankPageContentLink">
                <div className="BankPageContentLinkTitle">
                  Bank Account Linked
                </div>
                { (this.props.userInfo.user.name && this.props.userInfo.user.name.length > 0) &&
                  <div className="BankPageContentLinkName">
                    Hello {this.props.userInfo.user.name},
                  </div>
                }
                <div className="BankPageContentLinkDescription">
                  Your bank account has already been linked with Plaid.
                  You can change the linked account by clicking the button below.
                </div>

                <PlaidLink
                  token={this.state.plaidToken}
                  onEvent={this.plaidHandleOnEvent.bind(this)}
                  onExit={this.plaidHandleOnExit.bind(this)}
                  onLoad={this.plaidHandleOnLoad.bind(this)}
                  onSuccess={this.plaidHandleOnSuccess.bind(this)}
                  className="BankPageContentLinkButton"
                  style={{}}
                  >
                  <span>
                    Change Bank Account
                  </span>
                </PlaidLink>
              </div>
            }
            { !params.oauth_state_id && (this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.id) && this.props.userInfo.user.bankAccountStatus === "2" &&
              <div className="BankPageContentLink">
                <div className="BankPageContentLinkTitle">
                  Update your Bank Account
                </div>
                { (this.props.userInfo.user.name && this.props.userInfo.user.name.length > 0) &&
                  <div className="BankPageContentLinkName">
                    Hello {this.props.userInfo.user.name},
                  </div>
                }
                <div className="BankPageContentLinkDescription">
                  Tap the button below to update your bank account with Plaid.
                  Plaid is a 3rd party that keeps your information secure.
                </div>

                <PlaidLink
                  token={this.state.plaidToken}
                  onEvent={this.plaidHandleOnEvent.bind(this)}
                  onExit={this.plaidHandleOnExit.bind(this)}
                  onLoad={this.plaidHandleOnLoad.bind(this)}
                  onSuccess={this.plaidHandleOnSuccess.bind(this)}
                  className="BankPageContentLinkButton"
                  style={{}}
                  >
                  <span>
                    Update Bank Account
                  </span>
                </PlaidLink>
              </div>
            }
            { !params.oauth_state_id && !(this.props.userInfo && this.props.userInfo.user && this.props.userInfo.user.id) &&
              <div className="BankPageContentLogin">
                <div className="BankPageContentLoginTitle">
                  Link your Bank Account
                </div>
                <div className="BankPageContentLoginDescription">
                  First, login with your phone number below, then you'll be able to link your bank account with Plaid.
                </div>
                <div className="BankPageContentLoginButton" onClick={this.showLogin.bind(this)}>
                  Login
                </div>
              </div>
            }
          </div>
        }
        { this.state.view === "Login" &&
          <div className="BankPageContent">
            { this.state.subview === "Phone" &&
              <div className="GeojiCnInnerPrompt">
                <div className="GeojiCnInnerPromptTopBar">
                  <img src={ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.backHome.bind(this)} />
                </div>
                <div className="GeojiCnInnerPromptEmoji">
                  <span role="img" aria-label="phone">📱</span>
                </div>
                <div className="GeojiCnInnerPromptTitle">
                  Enter your phone number
                </div>
                { this.state.subloading &&
                  <div className="GeojiCnInnerPromptLoading">
                    <img src={logo} className="GeojiCnInnerPromptLoadingLogo" alt="logo" />
                  </div>
                }
                { !this.state.subloading &&
                  <div>
                    <div className="GeojiCnInnerPromptField">
                      <div className="InputDiv">
                        <Components.InputBottomLine type="text" name="phoneNumber" placeholder="phone number" validation="phoneNumber10" required="true" validateTyping={false} autoComplete="tel"
                          tabIndex="1"
                          value={this.state.data.phoneNumber ? this.state.data.phoneNumber.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                    </div>
                    { this.state.suberror !== false &&
                      <div className="GeojiCnInnerPromptError">
                        {this.state.suberror}
                      </div>
                    }
                    <div className="GeojiCnInnerPromptDescription">
                      By continuing, you are agreeing to Geoji's
                      <br/>
                      <div className="GeojiCnInnerPromptDescriptionLink" onClick={this.openTerms.bind(this)}>
                        Terms of Service
                      </div>
                      &nbsp;&&nbsp;
                      <div className="GeojiCnInnerPromptDescriptionLink" onClick={this.openPrivacy.bind(this)}>
                        Privacy Policy
                      </div>
                    </div>
                    <div className="GeojiCnInnerPromptButton" onClick={this.submitForm}>
                      Submit
                    </div>
                  </div>
                }
              </div>
            }
            { this.state.subview === "Code" &&
              <div className="GeojiCnInnerPrompt">
                <div className="GeojiCnInnerPromptTopBar">
                  <img src={ImageBack} alt="back" className="GeojiCnInnerPromptTopBarBackArrow" onClick={this.backToPhoneNumber.bind(this)} />
                </div>
                <div className="GeojiCnInnerPromptEmoji">
                  <span role="img" aria-label="key">🔑</span>
                </div>
                <div className="GeojiCnInnerPromptTitle">
                  Enter 6-digit verification code
                </div>
                { this.state.subloading &&
                  <div className="GeojiCnInnerPromptLoading">
                    <img src={logo} className="GeojiCnInnerPromptLoadingLogo" alt="logo" />
                  </div>
                }
                { !this.state.subloading &&
                  <div>
                    <div className="GeojiCnInnerPromptField">
                      <div className="InputDiv">
                        <Components.InputBottomLine type="text" name="code" placeholder="verification code" validation="code" required="true" validateTyping={false} autoComplete="one-time-code"
                          tabIndex="1"
                          value={this.state.data.code ? this.state.data.code.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                    </div>
                    { this.state.suberror !== false &&
                      <div className="GeojiCnInnerPromptError">
                        {this.state.suberror}
                      </div>
                    }
                    <div className="GeojiCnInnerPromptDescription">
                      A verification code was sent to mobile number
                      <br/>
                      {this.state.data.phoneNumber && Helpers.parsePhoneNumber(this.state.data.phoneNumber.value)}
                      <br/>
                      Didn't receive a code?&nbsp;
                      <div className="GeojiCnInnerPromptDescriptionLink" onClick={this.resendCode.bind(this)}>
                        Resend Code
                      </div>
                    </div>
                    <div className="GeojiCnInnerPromptButton" onClick={this.submitForm}>
                      Submit
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
        { this.state.view === "Success" &&
          <div className="BankPageContent">
            <div className="BankPageContentSuccess">
              <div className="BankPageContentSuccessTitle">
                <span role="img" aria-label="party">🎉</span> Success <span role="img" aria-label="party">🎉</span>
              </div>
              <div className="BankPageContentSuccessDescription">
                Your Bank Account has been successfully linked.
                You can now get paid out directly to your Bank.
              </div>
            </div>
          </div>
        }

        {/* Footer */}
        <Components.GeojiFooter nohide={true} />
      </div>
    )
  }
}

export class PlaidLink extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      disabledButton: true,
      linkLoaded: false,
      initializeURL: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
    }

    this.onScriptError = this.onScriptError.bind(this);
    this.onScriptLoaded = this.onScriptLoaded.bind(this);
    this.handleLinkOnLoad = this.handleLinkOnLoad.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.initializePlaid = this.initializePlaid.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.token !== this.props.token && prevProps.token === "loading") {
      //we have loaded the token.
      if (!this.state.disabledButton) {
        this.initializePlaid()
      }
    }
  }

  onScriptError() {
    console.error("There was an issue loading the link-initialize.js script")
  }

  onScriptLoaded() {
    this.setState({
      disabledButton: false
    });
    if (this.props.token !== "loading") {
      this.initializePlaid()
    }
  }

  initializePlaid() {
    let rru = null
    let params = Helpers.getAllUrlParams()
    if (params.oauth_state_id && params.oauth_state_id.length > 0) {
      rru = window.location.href
    }

    let data = {
      token: this.props.token,
      onSuccess: this.props.onSuccess,
      onExit: this.props.onExit,
      onEvent: this.props.onEvent,
      onLoad: this.props.onLoad,
      receivedRedirectUri: rru,
    }
    //console.log(data)
    if (typeof(window) !== "undefined") {
      this.linkHandler = window.Plaid.create(data);
    }
  }

  handleLinkOnLoad() {
    if (this.props.onLoad != null) {
      this.props.onLoad()
    }
    this.setState({
      linkLoaded: true
    })
  }

  handleOnClick(event) {
    if (this.props.onClick != null) {
      this.props.onClick(event)
    }
    //Show Plaid if we can
    console.log("link handler", this.linkHandler)
    if (this.linkHandler) {
      this.linkHandler.open()
    }
  }

  exit(configurationObject) {
    if (this.linkHandler) {
      this.linkHandler.exit(configurationObject);
    }
  }

  render() {
    return (
      <div>
        <div
          type="button"
          onClick={this.handleOnClick}
          style={this.props.style}
          className={this.props.className + ((this.state.disabledButton || this.props.token === "loading") ? " " + this.props.className + "Disabled" : "")}>
          {this.props.children}
        </div>
        <Script
          url={this.state.initializeURL}
          onError={this.onScriptError}
          onLoad={this.onScriptLoaded} />
      </div>
    )
  }
}
