import React from 'react';
// import logo from './resources/images/GeojiLogoWhite.svg';
// import logoText from './resources/images/GeojiTextWhite.svg';
//import appStoreIcon from './resources/images/AppStoreBadge.svg';
import './App.scss';

import * as Legal from './resources/screens/Legal.js';
import * as Geoji from './resources/screens/Geoji.js';
import * as Handle from './resources/screens/Handle.js';
import * as AmazonPay from './resources/screens/AmazonPay.js';
import * as AmazonLink from './resources/screens/AmazonLink.js';
import * as AmazonLinkRes from './resources/screens/AmazonLinkRes.js';
import * as Demo from './resources/screens/Demo.js';
import * as HomePage from './resources/screens/HomePage.js';
import * as Pricing from './resources/screens/Pricing.js';
import * as Reachout from './resources/screens/Reachout.js';
import * as Download from './resources/screens/Download.js';
import * as About from './resources/screens/About.js';
import * as Brand from './resources/screens/Brand.js';
import * as Contact from './resources/screens/Contact.js';
import * as Unsubscribed from './resources/screens/Unsubscribed.js';
import * as Dashboard from './resources/screens/Dashboard.js';
import * as Login from './resources/screens/Login.js';
import * as Authorize from './resources/screens/Authorize.js';
import * as Bank from './resources/screens/Bank.js';
import * as Blog from './resources/screens/Blog.js';
import * as PageEditor from './resources/screens/PageEditor.js';

import * as Helpers from './resources/Helpers.js';
import * as Components from './resources/Components.js';
import API from './resources/API.js';

import smoothscroll from 'smoothscroll-polyfill';
if (typeof window !== 'undefined') {
  // kick off the polyfill!
  smoothscroll.polyfill();
}

export class App extends React.Component {

  constructor(props) {
    super(props)

    let newState = {
      view: "",
      userInfo: {},
      purchaseID: "",
      menuOpen: false,

      popup: false, //is there a popup to show
      popupType: "", //the type of popup to show ("Error", "Success")
      popupTitle: "", //the popup title if there is one
      popupDescription: "", //the popup description if there is one
    }

    let pathComponents = Helpers.getWindowPathComponents(props)
    if (pathComponents[0] === "gsite") {
      //remove the first element.
      if (pathComponents.length === 1) {
        pathComponents[0] = ""
      } else {
        pathComponents.shift()
      }
    }

    //if path components are /g and a geoji has been provided in the props
    if (pathComponents[0] === "g" && this.props.geoji !== undefined) {
      newState.view = "Geoji"
      newState.geoji = this.props.geoji

      newState.geojiAccess = "public"
      newState.geojiLink = pathComponents[1]
      newState.purchaseID = false
    //if path components are /h and a handle has been provided in the props
    } else if (pathComponents[0] === "h" && this.props.handle !== undefined) {
      newState.view = "Handle"
      newState.handle = this.props.handle
    //if path components are /blog and a blog has been provided in the props
    } else if (pathComponents[0] === "blog" && this.props.blog !== undefined) {
      newState.view = "Blog"
      newState.blog = this.props.blog
    }

    this.state = newState
    
    //console.log("referrer", document.referrer)

    //turn off logs if in deployment.
    console.log("Build Version", API.buildVersion())
    console.log("API Version", API.version())
    if (typeof(window) !== "undefined") {
      
      if (window.location.hostname === "www.geoji.com" || window.location.hostname === "geoji.com") {
        //disable all logs
        console.log = function() {}
      }

      //parse the query params
      let qparams = Helpers.getAllUrlParams()
      console.log("queryParams", qparams)
      if (qparams["ll"] && qparams["ll"].length > 0) {
        //We have a one time login link. We should try to login if necessary.
        if (!API.hasLoggedIn()) {
          console.log("One Time Login with link", qparams["ll"])
          API.manualLogin("Geoji_Link_Login385", qparams["ll"], (result) => {
            if ("error" in result) {
              console.log("Couldn't login with the provided link.", result)
              //show an error for an invalid code.
              return
            }
            //now reload the user.
            this.reloadUser()
            //now that we have successfully used the link. Decrement the link.
            API.callDarwinAPI("PUT", "geojiOneTimeLink/" + qparams["ll"], {}, (result) => {
              if ("error" in result) {
                console.log("Couldn't decrement the link", result)
                return
              }
              console.log("Link decremented")
            })
          })
        }
      }
      if (pathComponents.length > 1 && qparams["r"] && qparams["r"].length > 0) {
        console.log("Promoter Link", qparams["r"])
        if (pathComponents[0] === "g") {
          let pls = API.getCookie("promoterLinks")
          console.log("pls", pls)
          try {
            pls = JSON.parse(pls)
          } catch (e) {
            console.log("error parsing promoterLinks")
            API.setCookie("promoterLinks", "{}")
            pls = {}
          }
          pls[pathComponents[1]] = qparams["r"]
          console.log("pls", pls)
          API.setCookie("promoterLinks", JSON.stringify(pls))
        } else if (pathComponents[0] === "p") {
          //Call the API to get the real geojiID.
          API.callDarwinAPIUnsecured("GET", "privateLink/" + pathComponents[1], {}, (result) => {
            if ("error" in result) {
              console.log("Error Get Private Link", result)
              return
            }
            let gid = result.data.link.geojiID
            console.log("private link to geoji", gid)

            let pls = API.getCookie("promoterLinks")
            console.log("pls", pls)
            try {
              pls = JSON.parse(pls)
            } catch (e) {
              console.log("error parsing promoterLinks")
              API.setCookie("promoterLinks", "{}")
              pls = {}
            }
            pls[gid] = qparams["r"]
            console.log("pls", pls)
            API.setCookie("promoterLinks", JSON.stringify(pls))
          })
        }
      }
      if (pathComponents.length > 1 && qparams["c"] && qparams["c"].length > 0) {
        console.log("Promo Code", qparams["c"])
        if (pathComponents[0] === "g") {
          let pls = API.getCookie("promoCodes")
          console.log("pcodes", pls)
          try {
            pls = JSON.parse(pls)
          } catch (e) {
            console.log("error parsing promoCodes")
            API.setCookie("promoCodes", "{}")
            pls = {}
          }
          pls[pathComponents[1]] = qparams["c"]
          console.log("pcodes", pls)
          API.setCookie("promoCodes", JSON.stringify(pls))
        }
      } else if (pathComponents[0] === "p") {
        //Call the API to get the real geojiID.
        API.callDarwinAPIUnsecured("GET", "privateLink/" + pathComponents[1], {}, (result) => {
          if ("error" in result) {
            console.log("Error Get Private Link", result)
            return
          }
          let gid = result.data.link.geojiID
          console.log("private link to geoji", gid)

          let pls = API.getCookie("promoCodes")
          console.log("pcodes", pls)
          try {
            pls = JSON.parse(pls)
          } catch (e) {
            console.log("error parsing promoCodes")
            API.setCookie("promoCodes", "{}")
            pls = {}
          }
          pls[gid] = qparams["c"]
          console.log("pcodes", pls)
          API.setCookie("promoCodes", JSON.stringify(pls))
        })
      }
    }
  }

  componentDidMount() {
    let shouldCheckAuth = this.updateViewBasedOnPath(false)

    //1) Check to see if we are logged in and if not redirect to the login screen
    if (shouldCheckAuth) {
      this.reloadUser()
    } else {
      //get the user info if we are logged in.
      if (API.hasLoggedIn()) {
        this.loadUserInfo()
      } else if (API.hasRefreshToken()) {
        API.refreshTokens(() => {
          console.log("refreshed")
          this.loadUserInfo()
        })
      }
    }

    //2) Detect back/forward buttons.
    window.onpopstate = () => {
      console.log("on pop state", Helpers.getWindowPathComponents())
      this.updateViewBasedOnPath(false)
    }

    this.updateWindowSize()
    window.addEventListener('resize', () => {
      this.updateWindowSize()
    })
    setTimeout(() => {
      this.updateWindowSize()
    }, 300)
    setTimeout(() => {
      this.updateWindowSize()
    }, 800)

    window.onerror = function(message, source, lineno, colno, error) {
      console.error(message, source, lineno, colno, error)
      if (window.location.hostname !== "localhost") {
        API.logIssue("Browser JS Error", {
          message: message,
          source: source,
          lineno: lineno,
          colno: colno,
          error: error,
        })
      }
    }
  }

  updateWindowSize() {
    if (typeof (window.innerHeight) == 'number') {
      //console.log(window.innerWidth + " x " + window.innerHeight)
      let html = document.getElementsByTagName("html")
      if (html !== undefined && html.length > 0) {
        html[0].style.height = window.innerHeight + "px"
      }
    }
  }

  /*
  Updates which view we are on based on the current url path.
  Returns whether or not we should reload user info and check if we are logged in.
  */
  updateViewBasedOnPath(updatePath = true) {
    let pathComponents = Helpers.getWindowPathComponents()
    console.log("update view based on path", pathComponents)
    if (pathComponents[0] === "gsite") {
      //remove the first element.
      if (pathComponents.length === 1) {
        pathComponents[0] = ""
      } else {
        pathComponents.shift()
      }
    }

    let shouldCheckAuth = true
    switch (pathComponents[0]) {
      case "":
      case "home":
        this.changeView("Home", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      /*case "pricing":
        this.changeView("Pricing", false, false, null, updatePath)
        shouldCheckAuth = false
        break;*/
      case "reachout":
        this.changeView("Reachout", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "download":
        this.changeView("Download", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "about":
        this.changeView("About", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "bank":
        this.changeView("Bank", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "brand":
        this.changeView("Brand", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "contact":
        this.changeView("Contact", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "unsubscribed":
        this.changeView("Unsubscribed", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "dashboard":
        if (pathComponents.length > 1 && pathComponents[1].length > 0) {
          this.changeView("Dashboard", pathComponents[1], pathComponents.length > 2 ? (pathComponents[2].length > 0 ? pathComponents[2] : false) : false, null, updatePath)
          shouldCheckAuth = true
        } else {
          this.changeView("Dashboard", false, false, null, updatePath)
          shouldCheckAuth = true
        }
        break;
      case "editor":
        if (pathComponents.length > 1 && pathComponents[1].length > 0) {
          this.changeView("PageEditor", pathComponents[1], pathComponents.length > 2 ? (pathComponents[2].length > 0 ? pathComponents[2] : false) : false, null, updatePath)
          shouldCheckAuth = true
        } else {
          this.changeView("PageEditor", false, false, null, updatePath)
          shouldCheckAuth = true
        }
        break;
      case "login":
        this.changeView("Login", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "authorize":
        if (pathComponents.length > 1 && pathComponents[1].length > 0) {
          this.changeView("Authorize", pathComponents[1], pathComponents.length > 2 ? (pathComponents[2].length > 0 ? pathComponents[2] : false) : false, null, updatePath)
          shouldCheckAuth = false
        } else {
          this.changeView("Authorize", false, false, null, updatePath)
          shouldCheckAuth = false
        }
        break;
      case "blog":
        if (pathComponents.length > 1 && pathComponents[1].length > 0) {
          this.changeView("Blog", pathComponents[1], pathComponents.length > 2 ? (pathComponents[2].length > 0 ? pathComponents[2] : false) : false, null, updatePath)
          shouldCheckAuth = false
        } else {
          this.changeView("Home", false, false, null, updatePath)
          shouldCheckAuth = false
        }
        break;
      case "g":
        if (pathComponents.length > 1 && pathComponents[1].length > 0) {
          this.changeView("Geoji", pathComponents[1], pathComponents.length > 2 ? (pathComponents[2].length > 0 ? pathComponents[2] : false) : false, null, updatePath)
          shouldCheckAuth = false
        } else {
          this.changeView("Home", false, false, null, updatePath)
          shouldCheckAuth = false
        }
        break;
      case "p":
        if (pathComponents.length > 1 && pathComponents[1].length > 0) {
          this.changeView("GeojiPrivate", pathComponents[1], pathComponents.length > 2 ? (pathComponents[2].length > 0 ? pathComponents[2] : false) : false, null, updatePath)
          shouldCheckAuth = false
        } else {
          this.changeView("Home", false, false, null, updatePath)
          shouldCheckAuth = false
        }
        break;
      case "h":
        if (pathComponents.length > 1 && pathComponents[1].length > 0) {
          this.changeView("Handle", pathComponents[1], false, null, updatePath)
          shouldCheckAuth = false
        } else {
          this.changeView("Home", false, false, null, updatePath)
          shouldCheckAuth = false
        }
        break;
      case "privacy":
        this.changeView("Privacy", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "terms":
        this.changeView("Terms", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "deletedata":
        this.changeView("DeleteData", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "acceptableuse":
        this.changeView("AcceptableUse", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
      case "apay":
        if (pathComponents.length > 1 && pathComponents[1].length > 0) {
          this.changeView("AmazonPay", pathComponents[1], false, null, updatePath)
          shouldCheckAuth = false
        } else {
          this.changeView("Home", false, false, null, updatePath)
          shouldCheckAuth = false
        }
        break;
      case "azlink":
        if (pathComponents.length > 2) {
          this.changeView("AmazonLink", pathComponents[1].length > 0 ? pathComponents[1] : "", pathComponents[2].length > 0 ? pathComponents[2] : "", null, updatePath)
          shouldCheckAuth = false
        } else {
          this.changeView("Home", false, false, null, updatePath)
          shouldCheckAuth = false
        }
        break;
      case "azlinkres":
        if (pathComponents.length > 1 && pathComponents[1].length > 0) {
          this.changeView("AmazonLinkRes", pathComponents[1].length > 0 ? pathComponents[1] : "", false, null, updatePath)
          shouldCheckAuth = false
        } else {
          this.changeView("Home", false, false, null, updatePath)
          shouldCheckAuth = false
        }
        break;
      case "demo":
        this.changeView("Demo", pathComponents.length > 1 ? pathComponents[1] : "", false, null, updatePath)
        shouldCheckAuth = false
        break;
      default:
        this.changeView("Home", false, false, null, updatePath)
        shouldCheckAuth = false
        break;
    }
    return shouldCheckAuth
  }

  /*
  Call this to change and update the view.
  This will handle resetting the state for you.
  */
  changeView(newView, tertiaryView = false, fourthView = false, callback = null, updatePath = true) {
    console.log("changeView", newView, tertiaryView, fourthView)

    switch (newView) {
      case "Home": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/")
        }
        Helpers.updatePageMeta("/")
        //2) Get the user info
        this.setState({
          view: "Home",
        })
        break;
      }
      case "Reachout": {
        if (updatePath) {
          Helpers.updateWindowPath("/reachout")
        }
        Helpers.updatePageMeta("/reachout")
        //2) Get the user info
        this.setState({
          view: "Reachout",
        })
        break;
      }
      case "Download": {
        if (updatePath) {
          Helpers.updateWindowPath("/download")
        }
        Helpers.updatePageMeta("/download")
        //2) Get the user info
        this.setState({
          view: "Download",
        })
        break;
      }
      /*case "Pricing": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/pricing")
        }
        Helpers.updatePageMeta("/pricing")
        //2) Get the user info
        this.setState({
          view: "Pricing",
        })
        break;
      }*/
      case "About": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/about")
        }
        Helpers.updatePageMeta("/about")
        //2) Get the user info
        this.setState({
          view: "About",
        })
        break;
      }
      case "Bank": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/bank")
        }
        Helpers.updatePageMeta("/bank")
        //2) Get the user info
        this.setState({
          view: "Bank",
        })
        break;
      }
      case "Brand": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/brand")
        }
        Helpers.updatePageMeta("/brand")
        //2) Get the user info
        this.setState({
          view: "Brand",
        })
        break;
      }
      case "Contact": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/contact")
        }
        Helpers.updatePageMeta("/contact")
        //2) Get the user info
        this.setState({
          view: "Contact",
        })
        break;
      }
      case "Unsubscribed": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/unsubscribed")
        }
        Helpers.updatePageMeta("/unsubscribed")
        //2) Get the user info
        this.setState({
          view: "Unsubscribed",
        })
        break;
      }
      case "Dashboard": {
        //0) Make sure we are logged in.
        if (API.hasLoggedIn()) {
          //1) Update the window path.
          if (updatePath) {
            if (tertiaryView !== false) {
              if (fourthView !== false) {
                Helpers.updateWindowPath("/dashboard/" + tertiaryView + "/" + fourthView)
              } else {
                Helpers.updateWindowPath("/dashboard/" + tertiaryView)
              }
            } else {
              Helpers.updateWindowPath("/dashboard")
            }
          }
          //2) Get the user info
          this.setState({
            view: "Dashboard",
            dashboardView: tertiaryView,
            dashboardView2: fourthView,
          })
        } else {
          this.changeView("Login")
        }
        break
      }
      case "PageEditor": {
        //0) Make sure we are logged in.
        if (API.hasLoggedIn()) {
          //1) Update the window path.
          if (updatePath) {
            if (tertiaryView !== false) {
              if (fourthView !== false) {
                Helpers.updateWindowPath("/editor/" + tertiaryView + "/" + fourthView)
              } else {
                Helpers.updateWindowPath("/editor/" + tertiaryView)
              }
            } else {
              Helpers.updateWindowPath("/editor")
            }
          }
          //2) Get the user info
          this.setState({
            view: "PageEditor",
            pageID: tertiaryView,
          })
        } else {
          this.changeView("Login")
        }
        break
      }
      case "Login": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/login")
        }
        //2) Get the user info
        this.setState({
          view: "Login",
        })
        break
      }
      case "Authorize": {
        //1) Update the window path.
        if (updatePath) {
          if (tertiaryView !== false) {
            if (fourthView !== false) {
              Helpers.updateWindowPath("/authorize/" + tertiaryView + "/" + fourthView)
            } else {
              Helpers.updateWindowPath("/authorize/" + tertiaryView)
            }
          } else {
            Helpers.updateWindowPath("/authorize")
          }
        }
        //2) Get the user info
        this.setState({
          view: "Authorize",
          tertiaryView: tertiaryView,
          fourthView: fourthView,
        })
        break
      }
      case "Blog": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/blog/" + tertiaryView)
        }
        //2) Get the user info
        this.setState({
          view: "Blog",
          blogID: tertiaryView,
        })
        break
      }
      case "Geoji": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/g/" + tertiaryView)
        }
        //2) Get the user info
        this.setState({
          view: "Geoji",
          geojiAccess: "public",
          geojiLink: tertiaryView,
          purchaseID: fourthView,
        })
        break
      }
      case "GeojiPrivate": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/p/" + tertiaryView)
        }
        //2) Get the user info
        this.setState({
          view: "Geoji",
          geojiAccess: "private",
          geojiLink: tertiaryView,
          purchaseID: fourthView
        })
        break
      }
      case "AmazonPay": {
        //1) Get the user info
        this.setState({
          view: "AmazonPay",
          purchaseID: tertiaryView
        })
        break
      }
      case "AmazonLink": {
        //1) Get the user info
        this.setState({
          view: "AmazonLink",
          purchaseID: tertiaryView,
          tempKey: fourthView,
        })
        break
      }
      case "AmazonLinkRes": {
        //1) Get the user info
        this.setState({
          view: "AmazonLinkRes",
          purchaseID: tertiaryView,
        })
        break
      }
      case "Handle": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/h/" + tertiaryView)
        }
        this.setState({
          view: "Handle",
          handleID: tertiaryView,
        })
        break
      }
      case "Demo": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/demo/" + tertiaryView)
        }
        this.setState({
          view: "Demo",
          demoID: tertiaryView,
        })
        break
      }
      case "Privacy": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/privacy")
        }
        Helpers.updatePageMeta("/privacy")
        //2) Get the user info
        this.setState({
          view: "Privacy",
        })
        break
      }
      case "Terms": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/terms")
        }
        Helpers.updatePageMeta("/terms")
        //2) Get the user info
        this.setState({
          view: "Terms",
        })
        break
      }
      case "DeleteData": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/deletedata")
        }
        Helpers.updatePageMeta("/deletedata")
        //2) Get the user info
        this.setState({
          view: "DeleteData",
        })
        break
      }
      case "AcceptableUse": {
        //1) Update the window path.
        if (updatePath) {
          Helpers.updateWindowPath("/acceptableuse")
        }
        Helpers.updatePageMeta("/acceptableuse")
        //2) Get the user info
        this.setState({
          view: "AcceptableUse",
        })
        break
      }
      default:
        console.log("Unknown Change View:", newView, tertiaryView, fourthView)
        break;
    }
    window.scrollTo(0, 0);
    if (callback !== null && typeof callback === "function") {
      callback()
    }
  }

  /*
  * Loads the user information for the user.
  * If accessing an account, then load the info for that user.
  */
  loadUserInfo(callback = null, forceCallback = false) {
    this.setState({
      loadingUserInfo: true,
      fourthView: "Home",
    }, () => {
      API.callDarwinAPI("GET", "userProfile", {}, (result) => {
        if ("error" in result) {
          this.setState({
            loadingUserInfo: false
          }, () => {
            console.log("Error - couldn't get the user profile", result.error)
          })
          if (forceCallback === true && callback !== null && typeof callback === "function") {
            callback()
          }
          return
        }
        console.log("User Profile", result.data)
        let data = {
          userInfo: result.data,
          loadingUserInfo: false,
        }
        //store this data in localStorage
        if (data.userInfo.user && data.userInfo.user.profilePicture) {
          API.setCookie("profilePicture", data.userInfo.user.profilePicture)
        } else {
          API.deleteCookie("profilePicture")
        }
        if (data.userInfo.user && data.userInfo.user.name) {
          API.setCookie("name", data.userInfo.user.name)
        } else {
          API.deleteCookie("name")
        }

        if (!this.state.accountTakeover) {
          data['adminInfo'] = result.data
        }
        this.setState(data, () => {
          if (callback !== null && typeof callback === "function") {
            callback()
          }
        })
      })
    })
  }

  /*
  Reloads this user's information and resets the accessAccountID.
  */
  reloadUser(callback = null) {
    API.checkAuthentication(() => {
      //2) Load the current user's information
      this.setState({
        userInfo: {},
        adminInfo: {},
        accountTakeover: false,
        messages: []
      })
      API.accessAccountID = false
      this.loadUserInfo(callback)
    })
  }

  openCloseMenu(open = true) {

    this.setState({
      menuOpen: open
    })
  }

  updatePurchaseID(newPurchaseID) {
    console.log("updating purchaseID: ", newPurchaseID)
    this.setState({
      purchaseID: newPurchaseID
    })
  }

  /*
  * Show the error with a dismiss button
  */
  showPopup(type, title, description) {
    let ds = description
    if (typeof ds === 'object') {
      //turn this into a JSON array
      try {
        ds = JSON.stringify(ds)
      } catch (e) {
        ds = "Unparseable issue"
      }
    }
    this.setState({
      popup: true,
      popupType: type,
      popupTitle: title,
      popupDescription: ds
    })
  }

  /*
  * Dismiss the error message.
  */
  dismissPopup() {
    this.setState({
      popup: false,
      popupTitle: "",
      popupDescription: "",
    })
  }

  render() {

    //props to pass into each view.
    let viewProps = {
      view: this.state.view,
      changeView: this.changeView.bind(this),
      geojiAccess: this.state.geojiAccess,
      geojiLink: this.state.geojiLink,
      geoji: this.state.geoji,
      purchaseID: this.state.purchaseID,
      updatePurchaseID: this.updatePurchaseID.bind(this),
      dashboardView: this.state.dashboardView,
      dashboardView2: this.state.dashboardView2,
      reloadUser: this.reloadUser.bind(this),
      loadUserInfo: this.loadUserInfo.bind(this),
      userInfo: this.state.userInfo,
      handle: this.state.handle,
      handleID: this.state.handleID,
      demoID: this.state.demoID,
      tempKey: this.state.tempKey,
      openCloseMenu: this.openCloseMenu.bind(this),
      showPopup: this.showPopup,
      blog: this.state.blog,
      blogID: this.state.blogID,
      tertiaryView: this.state.tertiaryView,
      fourthView: this.state.fourthView,
      pageID: this.state.pageID,
    }

    //shown for a popup.
    let popupProps = {
      main: this.state.popupType,
      title: this.state.popupTitle,
      description: this.state.popupDescription,
      dismiss: this.dismissPopup,
    }

    return (
      <div className={"App " + (["Home", "Reachout", "Download", "Pricing", "About", "Brand", "Contact", "Bank", "Unsubscribed", "Dashboard", "Blog", "PageEditor"].includes(this.state.view) ? "AppHome " : "") + (this.state.menuOpen ? "AppHomeMenuOpen " : "") + (["PageEditor"].includes(this.state.view) ? "App100 " : "")}>
        { this.state.popup &&
          <Components.Popup {...popupProps} />
        }
        { this.state.menuOpen &&
          <HomePage.HomeHeaderOpen {...viewProps} />
        }
        { this.state.view === "Home" &&
          <HomePage.HomePage {...viewProps} />
        }
        { this.state.view === "Pricing" &&
          <Pricing.Pricing {...viewProps} />
        }
        { this.state.view === "Reachout" &&
          <Reachout.Reachout {...viewProps} />
        }
        { this.state.view === "Download" &&
          <Download.Download {...viewProps} />
        }
        { this.state.view === "About" &&
          <About.About {...viewProps} />
        }
        { this.state.view === "Bank" &&
          <Bank.Bank {...viewProps} />
        }
        { this.state.view === "Brand" &&
          <Brand.Brand {...viewProps} />
        }
        { this.state.view === "Contact" &&
          <Contact.Contact {...viewProps} />
        }
        { this.state.view === "Unsubscribed" &&
          <Unsubscribed.Unsubscribed {...viewProps} />
        }
        { this.state.view === "Dashboard" &&
          <Dashboard.Dashboard {...viewProps} />
        }
        { this.state.view === "PageEditor" &&
          <PageEditor.PageEditor {...viewProps} />
        }
        { this.state.view === "Login" &&
          <Login.Login {...viewProps} />
        }
        { this.state.view === "Authorize" &&
          <Authorize.Authorize {...viewProps} />
        }
        { this.state.view === "Privacy" &&
          <Legal.Legal {...viewProps} />
        }
        { this.state.view === "Terms" &&
          <Legal.Legal {...viewProps} />
        }
        { this.state.view === "DeleteData" &&
          <Legal.Legal {...viewProps} />
        }
        { this.state.view === "AcceptableUse" &&
          <Legal.Legal {...viewProps} />
        }
        { this.state.view === "Blog" &&
          <Blog.BlogPage {...viewProps} />
        }
        { this.state.view === "Geoji" &&
          <Geoji.Geoji {...viewProps} />
        }
        { this.state.view === "AmazonPay" &&
          <AmazonPay.AmazonPay {...viewProps} />
        }
        { this.state.view === "AmazonLink" &&
          <AmazonLink.AmazonLink {...viewProps} />
        }
        { this.state.view === "AmazonLinkRes" &&
          <AmazonLinkRes.AmazonLinkRes {...viewProps} />
        }
        { this.state.view === "Handle" &&
          <Handle.Handle {...viewProps} />
        }
        { this.state.view === "Demo" &&
          <Demo.Demo {...viewProps} />
        }
      </div>
    );
  }
}
