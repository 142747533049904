import React from 'react';
//import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
//import * as Helpers from '../Helpers.js';
import * as HomePage from './HomePage.js';

//import the Darwin API classes
//import API from '../API.js';

export class Contact extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",
    }
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
  }

  render() {

    return (
      <div className="ContactPage">

        {/* Page Header */}
        <HomePage.PageHeader />
        
        <HomePage.HomeHeader selected="contact" openCloseMenu={this.props.openCloseMenu} userInfo={this.props.userInfo} />

        <div className="ContactPageContent">
          <h1 className="ContactPageHeader">
            Get in Touch
          </h1>
          <div className="ContactPageMethods">
            {/* General Inquiries */}
            <div className="ContactPageMethod">
              <div className="ContactPageMethodBar">
              </div>
              <h2 className="ContactPageMethodTitle">
                For general inquiries
              </h2>
              <div className="ContactPageMethodBody">
                hello@geoji.com
              </div>
            </div>
            {/* For Help & Support */}
            <div className="ContactPageMethod">
              <div className="ContactPageMethodBar">
              </div>
              <h2 className="ContactPageMethodTitle">
                For help & support
              </h2>
              <div className="ContactPageMethodBody">
                support@geoji.com
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Components.GeojiFooter nohide={true} />
      </div>
    )
  }
}
