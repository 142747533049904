import React from 'react';
//import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
//import * as Helpers from '../Helpers.js';
import * as HomePage from './HomePage.js';

import ImageDownloadiOS from '../images/DownloadiOS.svg';
import ImageDownloadAndroid from '../images/DownloadAndroid.svg';
import ImageDownloadWebsite from '../images/DownloadWebsite.svg';

// import ImageCompanyAmazon from '../images/companies/AmazonGray.svg';
// import ImageCompanyApple from '../images/companies/AppleGray.svg';
// import ImageCompanyGoogle from '../images/companies/GoogleGray.svg';
// import ImageCompanyPayPal from '../images/companies/PayPalGray.svg';
// import ImageCompanyVenmo from '../images/companies/VenmoGray.svg';

//import the Darwin API classes
import API from '../API.js';

export class Download extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",
    }

    this.formChanged = this.formChanged.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        switch (this.state.subview) {
          case "Home":
            requiredFields = ["phoneNumber"]
            optionals = []
            requiredIf = []
            requiredSometimes = []
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Home":
          switch (this.state.subview) {
            case "Home":
              this.reachout(data)
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    let shouldSubmit = false
    this.setState((prevState) => {
      let d = prevState.data
      let newRet = {
        data: d,
        forceCheck: false
      }
      d[name] = {
        value: value,
        valid: valid
      }
      newRet.data = d
      return newRet
    }, () => {
      if (shouldSubmit) {
        this.submitForm()
      }
    })
  }

  reachout(data) {
    this.setState({
      loading: true,
    }, () => {
      //call the API to submit the phone number to our contact list in the database
      //and send a slack notification.
      let dd = {
        phoneNumber: data.phoneNumber,
        capturedFrom: "website",
      }
      API.callDarwinAPIUnsecured("POST", "reachout", dd, (result) => {
        if ("error" in result) {
          console.log("Error Submitting Phone Number", result)
          this.setState({
            loading: false,
            view: "Home",
          })
          return
        }
        this.setState({
          loading: false,
          view: "Success",
        })
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  render() {
    return (
      <div className="ReachoutPage">

        <HomePage.HomeHeader selected="reachout" openCloseMenu={this.props.openCloseMenu} userInfo={this.props.userInfo} />

        <div className="ReachoutPageContent">
          <div className="ReachoutPageTitle">
            Create your own event or business
          </div>
          <div className="ReachoutPageDescription">
            It's fast, it's simple, and anyone can do it.
          </div>
          <div className="ReachoutPageDownloads">
            <a href="https://apps.apple.com/us/app/geoji/id1502351343">
              <img src={ImageDownloadiOS} alt="Download on the App Store" />
            </a>
            <br/>
            <a href="https://play.google.com/store/apps/details?id=io.geoji.geoji&hl=en_IN&gl=US">
              <img src={ImageDownloadAndroid} alt="Get it on Google Play" />
            </a>
            <div className="ReachoutPageDownloadsBar"></div>
            <div className="ReachoutPageDownloadsAlternative">
              Want to create on a larger screen?
            </div>
            <a href="/dashboard">
              <img src={ImageDownloadWebsite} alt="Get started on the website" />
            </a>
          </div>
          {/*<div className="ReachoutPageTrust">
            <div className="ReachoutPageTrustTitle">
              Trusted by the world's leading brands.
            </div>
            <div className="ReachoutPageTrustBrands">
              <img src={ImageCompanyAmazon} alt="Amazon" className="ReachoutPageTrustBrand" style={{paddingTop:"16px"}} />
              <img src={ImageCompanyPayPal} alt="PayPal" className="ReachoutPageTrustBrand" style={{paddingTop:"4px"}} />
              <img src={ImageCompanyVenmo} alt="Venmo" className="ReachoutPageTrustBrand" style={{height:"20px"}} />
              <img src={ImageCompanyApple} alt="Apple" className="ReachoutPageTrustBrand" style={{height:"34px", paddingBottom:"6px"}} />
              <img src={ImageCompanyGoogle} alt="Google" className="ReachoutPageTrustBrand" style={{height:"30px"}} />
            </div>
          </div>*/}
        </div>

        {/* Footer */}
        <Components.GeojiFooter nohide={true} />
      </div>
    )
  }
}
